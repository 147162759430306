import React, { useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { throttle } from "lodash";
import { Redirect, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Grid,
  Dimmer,
  Loader,
  Modal,
  Image,
  Segment,
  Placeholder,
  Form,
  Input,
  Button,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import { Container } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTools,
  faWindowClose,
  faDesktop,
  faLaptop,
  faPhone,
  faKeyboard,
  faQuestionCircle,
  faExclamation,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import {
  getIndividualTicket,
  getTicketAttachments,
  checkNewStatus,
  adminStatusUpdate,
  clearAttachments,
  addAdminItAttachment,
  getAllActiveItUser,
  changeTicketsAssignedTo,
  getITTicketAdminAttachments
} from "../../../Redux/Actions/ticketActions";
import {
  get_ITticket_comments,
  add_ITticket_comment,
} from "../../../Redux/Actions/commentActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers,
} from "../../../Redux/Actions/inventoryActions";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
registerPlugin(FilePondPluginImagePreview);
function ITTicketMain({
  match,
  getIndividualTicket,
  auth,
  ticket,
  getTicketAttachments,
  attachments,
  comments,
  checkNewStatus,
  adminStatusUpdate,
  clearAttachments,
  get_ITticket_comments,
  add_ITticket_comment,
  redirect,
  addAdminItAttachment,
  getAllActiveItUser,
  allActiveItUsers,
  changeTicketsAssignedTo,
  lookupUsersForInventory,
  clearUsersForInventory,
  users,
  allDBUsers,
  allUsers,
  getITTicketAdminAttachments,
  admin_attachments
}) {
  useEffect(() => {
    setAlready(false)
    setAlreadyAss(false)
    setAlreadyExplanation(false)
    setRequesterFunction("");
    setAssignedTo("")
    allDBUsers();
    getAllActiveItUser();      
    const fd = new FormData();
    fd.append("ti_id", match.params.id);
    fd.append("ticket_type", "IT");
    fd.append("it_ticket_id", match.params.id);
    getIndividualTicket(fd);
    get_ITticket_comments(fd);
    checkNewStatus(fd);
    getTicketAttachments(fd);
    getITTicketAdminAttachments(fd)
  }, []);
  const username = auth.token.name;
  const [itComment, setItComment] = useState({
    it_comment: "",
  });
  
  const [requesterName, setRequesterName] = useState("");
  React.useEffect(() => {
    clearUsersForInventory();
  }, [requesterName]);
  
  const handleAutoProductChange = (e) => {
    setRequesterName(e.target.value.trim());
    delayedQuery(e.target.value);
  };

  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );

  const sendQuery = (query) => {
    if (query.length > 0) {
            lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };

  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });
  const { cursor } = highlightSelection;

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        // const fd = new FormData();
        // fd.append("reassign_user", selected[i].getAttribute("data-key"));

        // need to get the name nd user from the database and make the change immediaty
        setRequesterName("");
        // clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0,
        });
      }
    }
  };

  function setRequesterFunction(name) {
        setRequesterName(name.trim());
  }

  const [alreadyReq, setAlready] = useState(false);
  const [alreadyAss, setAlreadyAss] = useState(false);
  const [alreadyExplanation,setAlreadyExplanation] = useState(false)
  const [rootCauseExplanation, setRootCauseExplanation] = useState({
    explanation: "",
  });
  const { explanation } = rootCauseExplanation;

  const [assignedTo, setAssignedTo] = useState({});
  const [currentStatus, setcurrentStatus] = useState("");
  const [checkAssigned, setCheckAssigned] = useState("");

  if (
    allActiveItUsers &&
    Array.isArray(allActiveItUsers) &&
    allActiveItUsers.length > 0
  ) {
    allActiveItUsers = allActiveItUsers.map((x) => {
      return {
        key: x.id,
        value: x.first_name + " " + x.last_name,
        text: x.first_name + " " + x.last_name,
        email: x.email,
      };
    });
    allActiveItUsers.unshift({
      key: "",
      value: "",
      text: "SELECT ASSIGNED TO",
      email: "",
    });
    if (ticket && ticket[0] && ticket[0].requesterName) {
      if (!alreadyReq) {
        setRequesterFunction(ticket[0].requesterName);
        setAlready(true);
      }
    }
    if (ticket && ticket[0] && ticket[0].ticket_assigned_to) {
      if(!alreadyAss){
          setAssignedTo(ticket[0].ticket_assigned_to);
          setAlreadyAss(true)
        }
    }
    if(ticket && ticket[0] && ticket[0].resolved_comment){
      if(!alreadyExplanation){
        setRootCauseExplanation({
          explanation: ticket[0].resolved_comment
        })
        setAlreadyExplanation(true)
      }
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  
  const [adminStatus, setAdminStatus] = useState("");
  const { it_comment } = itComment;
  const handleAddITComment = (e, id, user) => {
    const urlLocation = window.location.href;
    const fd = new FormData();
    fd.append("url_location", urlLocation);
    fd.append("it_comment", it_comment);
    fd.append("user", user);
    fd.append("id", id);
    fd.append("commentor_id", auth.user[0].id);
    fd.append("commentor", username);
    fd.append("authorEmail", ticket[0].ti_ticket_author_email)
    add_ITticket_comment(fd).then(() => {
      setItComment({
        ...itComment,
        it_comment: "",
      });
      const fd = new FormData();
      fd.append("ti_id", match.params.id);
      fd.append("ticket_type", "IT");
      fd.append("it_ticket_id", match.params.id);
      getIndividualTicket(fd);
      get_ITticket_comments(fd);
      checkNewStatus(fd);
      getTicketAttachments(fd);
      getITTicketAdminAttachments(fd)
    });
  };
  const adminStatusOptions = [
    
    { key: "IN PR", value: "IN PROGRESS", text: "IN PROGRESS" },
    { key: "P", value: "PENDING", text: "PENDING" },
    { key: "RES", value: "RESOLVED", text: "RESOLVED" },
  ];

  const handleITCommentChange = (e) => {
    setItComment({
      ...itComment,
      it_comment: e.target.value,
    });
  };
  const handleRootExplination = (e) => {
    setRootCauseExplanation({
      ...rootCauseExplanation,
      explanation: e.target.value,
    });
  };
  const handleRootCauseSubmit = (e, ticket_id) => {
    e.preventDefault();
    const fd = new FormData();
    if (adminStatus === "RESOLVED") {
      fd.append("resolved_by", auth.user[0].id);
    }
    if(explanation){
      fd.append("explination", explanation);
    }else if(ticket[0].resolved_comment){
      fd.append("explination", ticket[0].resolved_comment)
    }
    fd.append("ticket_to_update", ticket_id);
    fd.append("new_admin_status", adminStatus);
    if(!ticket[0].ticket_assigned_to || (ticket[0].ticket_assigned_to && ticket[0].ticket_assigned_to.match(/unassig/gi))){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Please Assign Ticket To Someone"), 300);
      return;
    }
    adminStatusUpdate(fd).then((res) => {
      const fd = new FormData();
      fd.append("ti_id", match.params.id);
      fd.append("ticket_type", "IT");
      fd.append("it_ticket_id", match.params.id);
      getIndividualTicket(fd);
      get_ITticket_comments(fd);
      checkNewStatus(fd);
      getTicketAttachments(fd);
      getITTicketAdminAttachments(fd)
    });
    // fd.append("set_assigned_to", assignedTo);
    // changeTicketsAssignedTo(fd)
  };

  // added malik start //
  const handleAssigned = (e, ticket_id, data) => {
    if (!data) {
      return false;
    }
    setIsLoading(true);
    e.preventDefault();
    const fd = new FormData();
    let index = allActiveItUsers.findIndex((x) => x.value === data);
    if (index !== -1) {
      fd.append("assigned_to_email", allActiveItUsers[index]["email"]);
    }
    fd.append("ticket_to_update", ticket_id);
    fd.append("new_admin_status", adminStatus);
    fd.append("explination", explanation);
    fd.append("set_assigned_to", data);
    changeTicketsAssignedTo(fd).then((res) => {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.success("Ticket is Assgined To " + data), 300);
      fd.append("ti_id", match.params.id);
      fd.append("ticket_type", "IT");
      fd.append("it_ticket_id", match.params.id);
      getIndividualTicket(fd);
      get_ITticket_comments(fd);
      checkNewStatus(fd);
      getTicketAttachments(fd);
      setIsLoading(false);
      getITTicketAdminAttachments(fd);
    });
  };

  const handleAssignTo = (event, data) => {
       setAssignedTo(data);
    handleAssigned(event, ticket[0].ti_id, data);
  };

  // added malik end //
  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
  });
  const { ticketAttachment_one } = picture;
  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
      });
    }
  };
  const handleItAttachmentSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("it_ticket_id", match.params.id);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    addAdminItAttachment(fd).then(() => {
      addAttachment({
        ticketAttachment_one: "",
      });
      const fd = new FormData();
    fd.append("ti_id", match.params.id);
    fd.append("ticket_type", "IT");
    fd.append("it_ticket_id", match.params.id);
    getIndividualTicket(fd);
    get_ITticket_comments(fd);
    checkNewStatus(fd);
    getTicketAttachments(fd);
    getITTicketAdminAttachments(fd)
    });
  };

  function changeSpecialCharacters(text) {
    if (!text) {
      return;
    }
    var entities = [
      ['amp', '&'],
      ['apos', '\''],
      ['#x27', '\''],
      ['#x2F', '/'],
      ['#39', '\''],
      ['#47', '/'],
      ['lt', '<'],
      ['gt', '>'],
      ['nbsp', ' '],
      ['quot', '"']
  ];

  for (var i = 0, max = entities.length; i < max; ++i) 
      text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

  //return text;
  return JSON.parse(JSON.stringify(text).split("\\r\\n").join("<br/>"));
  }

  const [openAttachment, setOpenAttachment] = useState("");
  const [open, setOpen] = useState(false);

  function openPopup(attachment) {
    if(attachment.path && (attachment.path.match(/.xls/gi) || 
    attachment.path.match(/.xlsx/gi) || 
    attachment.path.match(/.doc/gi) || 
    attachment.path.match(/.pdf/gi))){
      downloadFile(window.location.origin+attachment.path,attachment.path)
      return true
    }
    setOpen(true);
    setOpenAttachment(attachment.path);
  }

  function downloadFile(fileURL,filename) {
    if (
      fileURL        
    ) {
      console.log("fileURL",fileURL)
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        save.download = filename.substring(filename.lastIndexOf("/") + 1)
        var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
    }
  }
  const continueToTicket = () => {
    if(!ticket[0].ticket_assigned_to || (ticket[0].ticket_assigned_to && ticket[0].ticket_assigned_to.match(/unassig/gi))){
      console.log(ticket[0].ticket_assigned_to)
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Please Assign Ticket To Someone"), 300);
      return;
    }
    window.location.href = `/new-hire-IT-stage/${ticket[0].newhire_access_id}`
  }

  function markTicketAsReopen(ticket_id){
    const fd = new FormData();
    fd.append("ticket_to_update", ticket_id);
    fd.append("new_admin_status", "PENDING");
    adminStatusUpdate(fd).then((res) => {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.success("Ticket Reopend again"), 300);
      const fd = new FormData();
      fd.append("ti_id", match.params.id);
      fd.append("ticket_type", "IT");
      fd.append("it_ticket_id", match.params.id);
      getIndividualTicket(fd);
      get_ITticket_comments(fd);
      checkNewStatus(fd);
      getTicketAttachments(fd);
      getITTicketAdminAttachments(fd)
    });
  }

  let ticketMain;
  if (!ticket) {
    ticketMain = (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  } else {
    ticketMain = (
      <Grid columns="equal" stackable>
        <Grid.Row>
              <Grid.Column>
                <Modal
                  open={open}
                  closeIcon
                  basic={true}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                >
                  <Modal.Content>
                  <Image src={openAttachment} alt="Attachment" fluid />
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {isLoading && (
              <div className="loadingNew">
                <div className="loaderNew"></div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        {ticket[0].ti_admin_status === "RESOLVED" && <Grid.Row style={{display: "flex",flexDirection: "row-reverse",padding:"0px 250px"}}>
          <Button
              type="button"
              basic
              color="red"
              onClick={()=>markTicketAsReopen(ticket[0].ti_id)}
            >
                Reopen Ticket
            </Button>
        </Grid.Row>}
        <Grid.Row>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Ticket ID: </span>
            {ticket[0].ti_id}
          </Grid.Column>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Status: </span>
            {ticket[0].ti_admin_status}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Priority: </span>
            {ticket[0].ti_priority}
          </Grid.Column>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Created Date: </span>
            <Moment date={ticket[0].created} format="MM/DD/YY LTS" />
          </Grid.Column>
        </Grid.Row>
        {ticket[0] && ticket[0]['reqUserInventoryData'] && Object.keys(ticket[0]['reqUserInventoryData']).length > 0 && <Grid.Row>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Model Number: </span>
            {ticket[0]['reqUserInventoryData']['description']}
          </Grid.Column>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>ASA Tag: </span>
            {ticket[0]['reqUserInventoryData']['asa_tag']}
          </Grid.Column>
        </Grid.Row>}
        <Grid.Row>
        <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>From: </span>
            {ticket[0].ti_ticket_author_name} |{" "}
            {ticket[0].ti_ticket_author_email} 
          </Grid.Column>  
          <Grid.Column>
            <Dropdown
              placeholder="Assign To"
              options={allActiveItUsers}
              onChange={(event, data) => {
                handleAssignTo(event, data.value);
              }}
              disabled={ticket[0].ti_admin_status === "RESOLVED"}
              value={assignedTo}
              selection
            />
          </Grid.Column>        
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Issue: </span>{" "}
            {ticket[0].ti_subject}
          </Grid.Column>
        </Grid.Row>
        
        <Grid.Row>
          <Grid.Column>
          {
          // (ticket[0].ti_admin_status && ticket[0].ti_admin_status !== "RESOLVED") && 
          ticket[0].is_new_access ? (
              <span onClick={()=>continueToTicket()} style={{color:"blue", cursor:"pointer"}}>
              {ticket[0].ti_admin_status === "RESOLVED" ? 'View Details' : 'Continue to Ticket'}
              </span>
            ) : (
              <p>
                <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Description: </span>
                {ticket[0].ti_description}
              </p>
            )}
            </Grid.Column>
          </Grid.Row>

         
         
          {ticket[0].ti_subject && ticket[0].ti_subject.match(/termination/gi) &&(
          <><Grid.Row>
            <Grid.Column>
            <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Equipment: </span>
              {ticket[0].hardwares && ticket[0].hardwares.length > 0 ? (
                ticket[0].hardwares.map((access) => (
                  // <p key={access.id}>{access.model_number}</p>
                  <div className="chip" style={{backgroundColor:"#1b75bb"}} key={access.id}>
                  <div className="chip-content">{access.model_number}</div>
                </div>
                ))
              ) : (
                <span>This employee has no hardware access</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column>
            <p>
              <span className="colorBrandColor" style={{ fontWeight: "bold" }}>
                System Access:{" "}
              </span>
              {ticket[0].softwareProducts &&
              ticket[0].softwareProducts.length > 0 ? (
                ticket[0].softwareProducts.map((access) => (
                  <div
                    className="chip"
                    style={{ backgroundColor: "#1b75bb" }}
                    key={access.id}
                  >
                    <div className="chip-content">
                      {access.system_application}
                    </div>
                  </div>
                ))
              ) : (
                <span>This employee has no system access</span>
              )}{" "}
            </p>
          </Grid.Column>
        </Grid.Row>
           <Grid.Row>
           <Grid.Column>
             <span
               className="colorBrandColor"
               style={{ fontWeight: "bold" }}
             >
               Termination Date:{" "}
             </span>
             {moment(ticket[0].terminationDate).format("YYYY-MM-DD hh:mm:a")}
           </Grid.Column>
           </Grid.Row>
           </>)
          }


        <Grid.Row>
          <Grid.Column>
            {!attachments ? (
              <Segment>
                <Dimmer active>
                  <Loader inverted>Loading Attachments</Loader>
                </Dimmer>
                <Placeholder style={{ height: 50, width: 50 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Segment>
            ) : attachments.length === 0 ? (
              <div>No Attachments</div>
            ) : (
              <div style={{ margin: "5px" }}>
                    <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                    <Grid stackable>
                      <Grid.Row>
                        {attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "120px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                {/* Attachment {index+1} */}
                                {attachment.name}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </div>
            )}
          </Grid.Column>
        </Grid.Row>

        {ticket[0].ti_admin_status === "RESOLVED" ? (
          <React.Fragment>
            {/* .................................................................. */}
            {/* This is for comments to display resolved tickets */}

            <Grid.Row>
              <Grid.Column>
                <span className="colorBrandColor" style={{ fontWeight: "bold" }}>Comments:</span>
                <span style={{ float: "right" }}></span>
                <span style={{ textAlign: "left" }}>
                  {comments.itComments
                    ? comments.itComments.map((comment, index) => (
                        // <div key={index}>
                        //   {comment.commentor} :{" "}
                        //   <span>
                        //     {changeSpecialCharacters(comment.comment)}
                        //   </span>
                        //   <span style={{ float: "right" }}>
                        //     <Moment
                        //       date={comment.comment_time}
                        //       format="MM/DD/YY LTS"
                        //     />
                        //   </span>
                        //   <hr style={{ marginTop: "20px" }} />
                        // </div>
                        <>
                          <Grid>
                              <Grid.Row>
                              <Grid.Column width="2">
                              {comment.commentor} :{" "}
                              </Grid.Column>
                              <Grid.Column width="4">
                              <span dangerouslySetInnerHTML={{__html: changeSpecialCharacters(comment.comment)}}>
  
                               </span>
                              </Grid.Column>
                              <Grid.Column width="9" style={{textAlign:"right"}}>
                              <Moment
                                   date={comment.comment_time}
                                   format="MM/DD/YY LTS"
                                 />
                              </Grid.Column>
                            </Grid.Row>
                            </Grid>
                            <hr style={{ marginTop: "20px" }} />
                            </>
                      ))
                    : null}
                </span>
              </Grid.Column>
            </Grid.Row>
            {/* ............................................................................ */}
            <Grid.Row>
              <Grid.Column>
                <h4>
                  This ticket has been resolved by {ticket[0].resolved_first}{" "}
                  {ticket[0].resolved_last}.
                </h4>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0" }}>
              <Grid.Column>
                <h5>Internals Comment: </h5>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0" }}>
              <Grid.Column>
                <p>{ticket[0].resolved_comment}</p>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        ) : (
          // added malik start //
          <Grid.Row>
            <Grid.Column>
              <Form
                onSubmit={(e, id, user) =>
                  handleAddITComment(
                    e,
                    ticket[0].ti_id,
                    comments.itComments.length > 0
                      ? comments.itComments[comments.itComments.length - 1]
                          .commentor_id
                      : ticket[0].ti_users_id
                  )
                }
              >
                <hr />
                <div>
                  <h3 className="colorBrandColor" style={{ textAlign: "left" }}>Comments:</h3>
                  <div style={{ textAlign: "left" }}>
                    {comments.itComments
                      ? comments.itComments.map((comment, index) => (
                          // <div key={index}>
                          //   {comment.commentor} :{" "}
                          //   <span>
                          //     {changeSpecialCharacters(comment.comment)}
                          //   </span>
                          //   <span style={{ float: "right" }}>
                          //     <Moment
                          //       date={comment.comment_time}
                          //       format="MM/DD/YY LTS"
                          //     />
                          //   </span>
                          //   <hr style={{ marginTop: "20px" }} />
                          // </div>
                          <>
                          <Grid>
                              <Grid.Row>
                              <Grid.Column width="2">
                              {comment.commentor} :{" "}
                              </Grid.Column>
                              <Grid.Column width="4">
                              <span dangerouslySetInnerHTML={{__html: changeSpecialCharacters(comment.comment)}}>
  
                               </span>
                              </Grid.Column>
                              <Grid.Column width="9" style={{textAlign:"right"}}>
                              <Moment
                                   date={comment.comment_time}
                                   format="MM/DD/YY LTS"
                                 />
                              </Grid.Column>
                            </Grid.Row>
                            </Grid>
                            <hr style={{ marginTop: "20px" }} />
                            </>
                        ))
                      : null}
                  </div>
                </div>

                <Form.Field>
                  <TextArea
                    type="text"
                    name="itComment"
                    value={it_comment}
                    id="Comment"
                    onChange={handleITCommentChange}
                  />
                </Form.Field>
                <Form.Field style={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    basic
                    color="green"
                    disabled={
                      ticket[0].ti_admin_status === "RESOLVED" ||
                      it_comment === ""
                    }
                  >
                    Add Comment
                  </Button>
                </Form.Field>
              </Form>

              <Grid
                stackable
                style={{ borderTop: "1px solid grey", marginTop: "50px" }}
              >
                <Grid.Row>
                      <Grid.Column>
                        {!admin_attachments ? (
                          <Segment>
                            <Dimmer active>
                              <Loader inverted>Loading Attachments</Loader>
                            </Dimmer>
                            <Placeholder style={{ height: 50, width: 50 }}>
                              <Placeholder.Image />
                            </Placeholder>
                          </Segment>
                        ) : admin_attachments.length === 0 ? (
                          <div>No Attachments</div>
                        ) : (
                          <div style={{ margin: "5px" }}>
                            <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                            <Grid stackable>
                      <Grid.Row>
                        {admin_attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "120px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                Attachment {index+1}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                      </Grid>
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form onSubmit={handleItAttachmentSubmit}>
                      <Form.Field>
                        <label className="colorBrandColor">Attachment(s): </label>

                        <FilePond
                          maxFiles={1}
                          files={ticketAttachment_one}
                          allowMultiple={false}
                          allowImagePreview="false"
                          onupdatefiles={(fileItems) =>
                            handleFilepondUpdate(fileItems)
                          }
                        />
                      </Form.Field>
                      <Form.Field style={{ textAlign: "right" }}>
                        <Button
                          basic
                          color="red"
                          type="button"
                          onClick={() =>
                            addAttachment({
                              ...picture,
                              ticketAttachment_one: "",
                            })
                          }
                        >
                          Clear Attachment
                        </Button>
                        <Button basic color="green">
                          Add Attachment
                        </Button>
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form
                      onSubmit={(e, ticket_id) =>
                        handleRootCauseSubmit(e, ticket[0].ti_id)
                      }
                    >
                      <h4 style={{ textAlign: "left" }} className="colorBrandColor">
                        Ticket Status: {ticket[0].ti_admin_status}
                      </h4>
                      <Form.Field style={{ textAlign: "left" }}>
                        <Dropdown
                          options={adminStatusOptions}
                          placeholder="Status Action"
                          value={adminStatus}
                          onChange={(event, data) => {
                            setAdminStatus(data.value);
                          }}
                          inline
                        />
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          maxLength="400"
                          value={explanation}
                          name="explanation"
                          placeholder="What was the resolution.?"
                          // disabled={adminStatus !== "RESOLVED"}
                          onChange={handleRootExplination}
                        />
                        <span
                          style={{
                            padding: "5px",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          Please enter comment(s) upto 400 word.
                        </span>
                      </Form.Field>
                      <Form.Field style={{ textAlign: "right" }}>
                        <Button
                          basic
                          color="green"
                          // disabled={
                          //   // ticket[0].ti_admin_status === "RESOLVED" ||
                          //   // !adminStatus ||
                          //   !(adminStatus && explanation)
                          // }
                        >
                          Submit
                        </Button>
                        {/* ...Added some logic above for validation...08/17/2020 */}
                      </Form.Field>
                    </Form>
                  </Grid.Column>{" "}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          // added malik start //
        )}
      </Grid>
    );
  }
  const { formOk } = redirect;

  return formOk ? (
    <Redirect to="/pending-tickets" />
  ) : (
    <Container maxWidth="lg">{ticketMain}</Container>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  ticket: state.tickets.singleTickets,
  comments: state.comments,
  attachments: state.tickets.attachments,
  redirect: state.redirect,
  allActiveItUsers: state.allActiveItUsers.allActiveItUsers,
  users: state.inventory.users,
  allUsers: state.inventory.allUsers,
  admin_attachments: state.tickets.admin_attachments
});
export default withRouter(
  connect(mapStateToProps, {
    getIndividualTicket,
    getTicketAttachments,
    checkNewStatus,
    adminStatusUpdate,
    clearAttachments,
    get_ITticket_comments,
    add_ITticket_comment,
    addAdminItAttachment,
    getAllActiveItUser,
    changeTicketsAssignedTo,
    lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers,
  getITTicketAdminAttachments
  })(ITTicketMain)
);