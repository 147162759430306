import {
    GET_CHANGED_EMPLOYEE_SUCESS,
    GET_CHANGED_EMPLOYEE_REQUEST,
    GET_CHANGED_EMPLOYEE_FAILED,
    UPDATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_SUCESS,
    UPDATE_EMPLOYEE_FAILED,
  } from "../Actions/types";
  
  const initialState = {
    employee: null,
  
    loading: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_CHANGED_EMPLOYEE_REQUEST:
        return {
          ...state,
          loading: true,
          employee: action.payload,
        };
      case GET_CHANGED_EMPLOYEE_SUCESS:
        return {
          ...state,
          loading: false,
          employee: action.payload,
        };
      case GET_CHANGED_EMPLOYEE_FAILED:
        return {
          ...state,
          loading: false,
          employee: action.payload,
        };
  
      case UPDATE_EMPLOYEE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_EMPLOYEE_SUCESS:
        return {
          ...state,
          loading: false,
        };
      case UPDATE_EMPLOYEE_FAILED:
        return {
          ...state,
          loading: false,
        };
  
      default:
        return state;
    }
  }