import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { logout } from "../../Redux/Actions/userActions";
import AppsIcon from "@material-ui/icons/Apps";
import CustomNavLink  from "./CustomNavLink";


const MainNavigation = ({ logout, auth }) => {
  return (
    <Menu
      icon
      vertical
      inverted
      className={`left fixed hoverMenu`}
    >
      <div className="logo">
        <div className="logoContainer"></div>
      </div>
      <ReactTooltip />
      <CustomNavLink name="Home" as={Link} to="/" dataTip="Home">
        <Icon name="home" />
      </CustomNavLink>
      <CustomNavLink
        name="New IT Ticket"
        as={Link}
        to="/new-ticket/IT"
        dataTip="Create Ticket"
      >
        <FontAwesomeIcon icon={faHeadset} />
      </CustomNavLink>
      {/* <div data-tip="Ticket History">
       
          <CustomNavLink
            name="applications"
            as={Link}
            to="/my-ticket-history"
          >
            <Icon name="history" />
          </CustomNavLink>
        
      </div> */}

      {/* <CustomNavLink name="order supplies" dataTip="Order Supplies">
        <Icon name="shopping basket" />
      </CustomNavLink> */}
      <CustomNavLink
        name="Access Request"
        dataTip="Access Request"
        as={Link}
        to="/access-request"
      >
        <Icon name="lock open" />
      </CustomNavLink>
      <div data-tip="HR">
      {(auth.user && [1,2,4].includes(auth.user[0].privilege_level)) && <CustomNavLink
        name="HR"
        dataTip="HR"
        as={Link}
        to="/hr"
      >
        <Icon name="add user" />
      </CustomNavLink>}
      </div>

      <div data-tip="Applications">
        {(auth.user && auth.user[0].privilege_level === 6) ||
        (auth.user && auth.user[0].privilege_level) < 2 ? (
          <CustomNavLink
            name="Applications"
            as={Link}
            to="/third-party-applications"
          >
            <AppsIcon />
          </CustomNavLink>
        ) : null}
      </div>
{/* this will come in later phase */}
      <div data-tip="Pending Approval">
        {auth.user && auth.user[0].privilege_level < 9 ? (
          <CustomNavLink
            name="Pending Approval"
            as={Link}
            to="/pending-access-requests"
          >
            <Icon name="lock" />
          </CustomNavLink>
        ) : null}
      </div>

      <div data-tip="Compliance Request">
        {auth.user && [1,9].includes(auth.user[0].privilege_level) ? (
          <CustomNavLink
            name="Compliance Request"
            as={Link}
            to="/intake-forms-requests"
          >
            <Icon name="file alternate" />
          </CustomNavLink>
        ) : null}
      </div>

      <div data-tip="Ticket Reporting">
      {(auth.user && auth.user[0].privilege_level < 3) ||
        (auth.user && auth.user[0].privilege_level === 6) || 
        (auth.user && auth.user[0].privilege_level === 5) ? (
          <CustomNavLink
            name="Ticket Reporting"
            dataTip="Ticket Reporting"
            as={Link}
            to="/ticket-reporting"
          >
            <Icon name="chart pie" />
          </CustomNavLink>
        ) : null}
      </div>

      <div data-tip="IT Tickets">
        {(auth.user && auth.user[0].privilege_level < 3) ||
        (auth.user && auth.user[0].privilege_level === 6) ? (
          <CustomNavLink name="IT Tickets" as={Link} to="/pending-tickets">
            <Icon name="ticket alternate" />
          </CustomNavLink>
        ) : null}
      </div>
      <div data-tip="Data Ops Tickets">
        {(auth.user && auth.user[0].privilege_level < 3) ||
        (auth.user && auth.user[0].privilege_level === 5) ? (
          <CustomNavLink name="Data Ops Tickets" as={Link} to="/pending-data-ops">
            <Icon name="database" />
          </CustomNavLink>
        ) : null}
      </div>
      <div data-tip="Quality Request Tickets">
        {(auth.user && auth.user[0].privilege_level < 3) ||
        (auth.user && auth.user[0].privilege_level === 8) ? (
          <CustomNavLink name="Quility Request Tickets" as={Link} to="/pending-qr">
            <Icon name="clipboard outline" />
          </CustomNavLink>
        ) : null}
      </div>
      <div data-tip="Inventory">
        {(auth.user && auth.user[0].privilege_level < 3) ||
        (auth.user && auth.user[0].privilege_level === 6) ? (
          <CustomNavLink name="Inventory" as={Link} to="/inventory">
            <Icon name="barcode" />
          </CustomNavLink>
        ) : null}
      </div>
      {/* <div data-tip="Inventory Reporting">
        {(auth.user && auth.user[0].privilege_level < 3) ||
        (auth.user && auth.user[0].privilege_level === 6) ? (
          <CustomNavLink name="file alternate" as={Link} to="/inventory-reporting">
            <Icon name="file alternate" />
          </CustomNavLink>
        ) : null}
      </div> */}
      {/* <CustomNavLink name="my supply orders" dataTip="My Supply Orders">
        <Icon name="dolly" />
      </CustomNavLink>
      <CustomNavLink name="search" dataTip="Search">
        <Icon name="search" />
      </CustomNavLink> */}
      <div data-tip="User Lookup">
        {auth.user && auth.user[0].privilege_level < 3 ? (
          <CustomNavLink name="User Lookup" as={Link} to="/user-lookup">
            <Icon name="user circle" />
          </CustomNavLink>
        ) : null}
      </div>
      {/* add a dashboard feature for hax database dashboard reports 
      claime */}
      {/* <CustomNavLink
        name="my profile"
        dataTip="My Profile"
        as={Link}
        to="/user-profile"
      >
        <Icon name="user" />
      </CustomNavLink> */}
      <CustomNavLink
        name="Log Out"
        onClick={logout}
        className="hover-pointer"
        dataTip="Log Out"
      >
        <Icon name="log out" />
      </CustomNavLink>
    </Menu>
  );
};

MainNavigation.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  notifications: state.notifications.notifications
});

export default connect(mapStateToProps, { logout })(MainNavigation);
