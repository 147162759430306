import React, { useState, useEffect } from "react";
import MyResponsivePie from "./PieChart";
import MyResponsiveLine from "./LineChart";
import _ from "lodash";
import moment from "moment";
import {
  Form,
  Button,
  Dropdown,
  Grid,
  Dimmer,
  Loader,
  Icon,
  Radio
} from "semantic-ui-react";
import { connect } from "react-redux";
import Datepicker from "react-datepicker";
import {
  XYPlot,
    XAxis, // Shows the values on x axis
    YAxis, // Shows the values on y axis
    VerticalBarSeries,
    LabelSeries
} from 'react-vis';
import { Container } from "@material-ui/core";
import {
  getAllTicketsForReporting,
  getSelectedTicketsForReporting,
  getReportingData,
  getInventoryReportingData,
} from "../../../Redux/Actions/reportingActions";
import { LineChart, PieChart,ColumnChart,BarChart } from "react-chartkick";
import "chart.js";

const dataRangeData = [
  // { key: "ALL", value: "ALL", text: "ALL" },
  { key: "7", value: 7, text: "PAST WEEK" },
  { key: "15", value: 15, text: "PAST 15 DAYS" },
  { key: "30", value: 30, text: "PAST 30 DAYS" },
  // { key: "CS", value: "CUSTOM", text: "CUSTOM TIME FRAME" },
];

function InventoryReportMain({
  getAllTicketsForReporting,
  reporting_tickets,
  getSelectedTicketsForReporting,
  getInventoryReportingData,
  reportingInvData,
  loadUser,
  auth
}) {
  useEffect(() => {
    setIsLoading(true)
    setType(type)
    getData(type)
    setTimeFrame(30)
  }, []);

  const [type, setType] = useState("it")

  const [timeFrame, setTimeFrame] = useState("");

  const getData = (value) => {
    setIsLoading(true)
    let fromDate;
    getInventoryReportingData().then(()=>{
      setTimeout(()=>{
        setIsLoading(false)
        setType(value)
      },0)
    })
  }

  React.useEffect(() => {
    setType(type)
  }, [type]);

  const [isLoading, setIsLoading] = useState(false);

  const [customTime, setCustomTime] = useState({
    fromCustom: "",
    toCustom: "",
  });

  const { fromCustom, toCustom } = customTime;
  let pieChart;
  if (reporting_tickets.tickets) {
    const dataPie = reporting_tickets.tickets.map((ticket) => {
      return {
        id: ticket.ticket_type + ", " + ticket.action_type,
        label: ticket.ticket_type,
        value: ticket.ticket_number,
      };
    });
    pieChart = <MyResponsivePie data={dataPie} />;
  }
  if (reporting_tickets.tickets && reporting_tickets.tickets.length === 0) {
    pieChart = (
      <h3 style={{ color: "red", textAlign: "center" }}>
        <Icon name="ban" />
        No Tickets in This Time Frame
      </h3>
    );
  }
  if (!reporting_tickets.tickets) {
    pieChart = (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  
  const onSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault();
    let fromDate;
    let dateToday =
      moment().toISOString("YYYY-MM-DD").split("T")[0] + " 23:59:59";
    let fd = new FormData();
    fd.append("type", type)
    fd.append("todays_date", dateToday);
    if (timeFrame === "ALL") {
      fd.append("from_date", "ALL");
    }
    if (timeFrame === 7) {
      fromDate = moment().subtract(7, "days");
      fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("from_date", fromDate);
    }
    if (timeFrame === 15) {
      fromDate = moment().subtract(15, "days");
      fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("from_date", fromDate);
      getAllTicketsForReporting(fd);
      getInventoryReportingData(fd);
    }
    if (timeFrame === 30) {
      fromDate = moment().subtract(30, "days");
      fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("from_date", fromDate);
    }
    if (timeFrame === "CUSTOM") {
      dateToday =
        toCustom.toISOString("YYYY-MM-DD").split("T")[0] + " 23:59:59";
      fromDate =
        fromCustom.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("todays_date", dateToday);
      fd.append("from_date", fromDate);
    }
    getInventoryReportingData(fd).then(()=>{
      setIsLoading(false)
    })
  };

  const customDates = (
    <Grid.Row>
      <Grid.Column width="3">
        <Datepicker
          placeholderText="From Date"
          dateFormat="MMMM d, yyyy"
          name="fromCustom"
          selected={fromCustom}
          value={fromCustom}
          onChange={(date) =>
            setCustomTime({
              ...customTime,
              fromCustom: date,
            })
          }
        />
      </Grid.Column>
      <Grid.Column width="3">
        <Datepicker
          placeholderText="To Date"
          dateFormat="MMMM d, yyyy"
          name="toCustom"
          selected={toCustom}
          value={toCustom}
          onChange={(date) =>
            setCustomTime({
              ...customTime,
              toCustom: date,
            })
          }
        />
      </Grid.Column>
    </Grid.Row>
  );


  const handleSetType = (value) => {
    console.log(value)
    setTimeFrame(30)
    setType(value)
    getData(value)
  }

  const colorCode = [
    "#1b75bb",
    "#6d6e70",
    "#55b4c8",
    "#90278e"
  ]

  return (
    <Container>
      <Grid.Row>
          <Grid.Column>
            {isLoading && (
              <div className="loadingNew">
                <div className="loaderNew"></div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      
      <Grid columns="equal" stackable>        
        <Grid.Row>
          <Grid.Column>
              <h5
                style={{
                  margin: "unset",
                  textAlign: "center",
                  marginTop: "15px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
                className="colorBrandColor"
              >
               MONITOR Inventory Based On Status
              </h5>
              {!isLoading && reportingInvData && (
            
            
              <PieChart
              colors={["#90278e", "#6d6e70", "#1b75bb", "#55b4c8"]}
                data={
                  reportingInvData["groupByType"] && reportingInvData["groupByType"].map((x) => [
                    x["status"] ? x["status"] : "No Status",
                    x["COUNT(id)"],
                  ])
              }
              />
              )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
              <h5
                style={{
                  margin: "unset",
                  textAlign: "center",
                  marginTop: "15px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
                className="colorBrandColor"
              >
               Laptop Inventory Based On Status
              </h5>
              {!isLoading && reportingInvData && (
            
            
              <PieChart
              colors={["#90278e", "#6d6e70", "#1b75bb", "#55b4c8"]}
                data={
                  reportingInvData["groupByTypeLap"] && reportingInvData["groupByTypeLap"].map((x) => [
                    x["status"] ? x["status"] : "No Status",
                    x["COUNT(id)"],
                  ])
              }
              />
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  reporting_tickets: state.reportingTickets,
  reportingInvData: state.reportingTickets.reportingInvData,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getAllTicketsForReporting,
  getSelectedTicketsForReporting,
  getInventoryReportingData,
})(InventoryReportMain);