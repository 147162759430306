import React, { useState, useRef, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";

import { throttle } from "lodash";
import { Container, Form, Grid, Segment, Button, Input,Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { newHireHRFormStart,getDepartmentDropdown } from "../../../Redux/Actions/accessRequestActions";

import { Redirect } from "react-router-dom";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  lookupUsersForInventory,
  clearUsersForInventory,allDBUsers
} from "../../../Redux/Actions/inventoryActions";

function NewHire({ newHireHRFormStart, redirect, auth, getDepartmentDropdown,departmentList,lookupUsersForInventory,
  clearUsersForInventory,users,allUsers,allDBUsers }) {

  useEffect(() => {
    getDepartmentDropdown()
    allDBUsers();
  }, []);

  
  const [newHireHRData, setnewHireHRData] = useState({
    employee_first_name:"",
    employee_last_name:"",
    manager_email:"",
    department:"",
    employee_birth_date: "",
    job_title:"",
    manager_name:"",
  
    employee_start_date: "",
    cube_off_number: "",
    isDirector:null,

    isManager: null,
    writingField:null,
    phone_number:null,
    personal_email:null
  });
  const onInputChange = e => {
    setnewHireHRData({
      ...newHireHRData,
      [e.target.name]: e.target.value
    });
  };

  let deptArray = departmentList

  let deptDropwDown = [
    {
      key: "",
      value: "",
      text: "SELECT",
    }
  ]

  if(deptArray && Array.isArray(deptArray) && deptArray.length > 0){
    deptArray.forEach(x=>{
      deptDropwDown.push({
        key: x,
        value: x,
        text: x,
      })
    })
  }

  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });

  const {
    cursor,
    // result
  } = highlightSelection;

  let yesNoDropwDown = ["Yes", "No"].map(x=>{
    return{
      key: x,
      value: x,
      text: x,
    }
  }) 


  const {
    employee_first_name,
    employee_last_name,
    manager_email,
    manager_name,
    job_title,
    department, 
    employee_start_date,
    employee_birth_date,
    cube_off_number,
    isManager,
    isDirector,

    writingField,
    phone_number,
    personal_email
  } = newHireHRData;
  const firstInput = useRef(null);
  useEffect(() => {
    firstInput.current.focus();
  }, []);

  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
    ticketAttachment_two: "",
    ticketAttachment_three: "",
    ticketAttachment_four: "",
  });

  const {
    ticketAttachment_one,
    ticketAttachment_two,
    ticketAttachment_three,
    ticketAttachment_four,
  } = picture;

  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
        ticketAttachment_two: !fileItems[1] ? null : fileItems[1].file,
        ticketAttachment_three: !fileItems[2] ? null : fileItems[2].file,
        ticketAttachment_four: !fileItems[3] ? null : fileItems[3].file,
      });
    }
  };

  function setRequesterFunction(key,name, id) {
    // setUSerLookup(name.trim());
    console.log(key,name, id)
    setnewHireHRData({
      ...newHireHRData,
      [key]: name
    });
    if(key === "manager_name" && allUsers && allUsers.length > 0){
      let manEmail = allUsers.find(x=>x["id"] === id)
      if(manEmail){
        setnewHireHRData({
          ...newHireHRData,
          "manager_email": manEmail["email"],
          [key]: name
        });
      }
    }
    if(key === "trainer_name" && allUsers && allUsers.length > 0){
      let manEmail = allUsers.find(x=>x["id"] === id)
      if(manEmail){
        setnewHireHRData({
          ...newHireHRData,
          "trainer_email": manEmail["email"],
          [key]: name
        });
      }
    }
    clearUsersForInventory();
  }

  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );

  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  const handleSubmit = e => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    e.preventDefault();
    const ISOstartdate = employee_start_date.toISOString().split("T")[0];
    const localDate = employee_birth_date.toLocaleDateString();
    const empDOB = localDate
    console.log('empDOB: ', empDOB);

    const fd = new FormData();
    fd.append('new_employee_first_name',employee_first_name);
    fd.append('new_employee_last_name',employee_last_name)
    fd.append('manager_email',manager_email);
    fd.append('department', department);
    fd.append('job_title', job_title);
    fd.append('manager_name', manager_name);
    fd.append("requestor", auth.user[0].email)
    fd.append("employee_start_date", ISOstartdate)
    fd.append("cube_off_number", cube_off_number)
    fd.append("ticketAttachment_one", ticketAttachment_one);
    fd.append("ticketAttachment_two", ticketAttachment_two);
    fd.append("ticketAttachment_three", ticketAttachment_three);
    fd.append("ticketAttachment_four", ticketAttachment_four);
    fd.append("isManager", isManager)
    fd.append("isDirector", isDirector);
    fd.append("employee_birth_date", empDOB);


    fd.append('personal_email', personal_email);
    fd.append('phone_number', phone_number);
    newHireHRFormStart(fd).then(()=>{})
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        // const fd = new FormData();
        // fd.append("reassign_user", selected[i].getAttribute("data-key"));

        // need to get the name nd user from the database and make the change immediaty
        // setModifyInventory({
        //   ...modifyInventory,
        //   newUser: selected[i].getAttribute("data-name"),
        //   newUserID: selected[i].getAttribute("data-key"),
        // });
        // setUSerLookup("");
        // setUSerLookupId("");
        setnewHireHRData({
          ...newHireHRData,
          [e.target.name]: e.target.value,
          writingField: e.target.name
        });
        clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0,
        });
      }
    }
  };

  const handleAutoProductChange = (e) => {
    setnewHireHRData({
      ...newHireHRData,
      [e.target.name]: e.target.value,
      writingField: e.target.name
    });
    delayedQuery(e.target.value);
  };

  const sendQuery = (query) => {
    if (query.length > 0) {
      lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };

  return (
    <Container>
      <h2 style={{ paddingTop: "30px" }}>
        New Hire Access Request{" "}
        <span id="accessTipsAndTricks">To be completed by HR</span>
      </h2>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Grid columns="equal" stackable>
            <Grid.Row>
              <Grid.Column>
                <h3>Human Resources Information</h3>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    ref={firstInput}
                    type="text"
                    placeholder="First Name"
                    onChange={onInputChange}
                    name="employee_first_name"
                    value={employee_first_name}
                    required
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    onChange={onInputChange}
                    name="employee_last_name"
                    value={employee_last_name}
                    required
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Personal Email"
                    onChange={onInputChange}
                    name="personal_email"
                    value={personal_email}
                    required                    
                  />
                  <p className="small-text-new-hire">For MFA</p>
                </Segment>
                
              </Grid.Column>
              

            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="number"
                    placeholder="Personal Cell Number"
                    onChange={onInputChange}
                    name="phone_number"
                    value={phone_number}
                    required
                  />
                  <p className="small-text-new-hire">For MFA</p>
                </Segment>
              </Grid.Column>
            <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Manager Name"
                    // onChange={onInputChange}
                    onKeyDown={handleKeyDown}
                    onChange={handleAutoProductChange}
                    name="manager_name"
                    value={manager_name}
                     required
                  />
                  {users && writingField === "manager_name" ? (
                            <div
                              style={{
                                position: "absolute",
                                background: "white",
                                width: "91%",
                                border: "1px solid #bfbfbf",
                                borderRadius: "3px",
                                zIndex: "99999",
                              }}
                            >
                              {users.length === 0 ? (
                                <p style={{ padding: "5px", margin: "0px" }}>
                                  No Results Found
                                </p>
                              ) : (
                                users.map((user, i) => (
                                  <p
                                    style={{ padding: "5px", margin: "0px" }}
                                    key={user.id}
                                    onClick={() => {
                                      setRequesterFunction(
                                        "manager_name",
                                        user.first_name + " " + user.last_name,
                                        user.id
                                      );
                                    }}
                                    data-key={user.id}
                                    data-name={
                                      user.first_name + " " + user.last_name
                                    }
                                    className={
                                      cursor === i
                                        ? "active-software-selection"
                                        : null
                                    }
                                  >
                                    {user.first_name} <br /> {user.last_name}
                                  </p>
                                ))
                              )}
                            </div>
                          ) : null}
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Manager Email"
                    onChange={onInputChange}
                    name="manager_email"
                    value={manager_email}
                    required                    
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            
            <Grid.Column>
                <Segment className="access-focus">
                  <Dropdown
                   style={{width:"100%"}}
                  placeholder="Department"
                  options={deptDropwDown}
                  // onChange={onInputChange}
                  onChange={(event, data) => {
                    onInputChange({target:{
                      name: "department",
                      value:data.value
                    }});
                  }}
                  name="department"
                  value={department}
                  required
                  selection
                />
                </Segment>
              </Grid.Column>

              <Grid.Column>
                <Segment className="access-focus">
                  <DatePicker
                    onChange={(event, data) => {
                      onInputChange({
                        target: {
                          name: "employee_birth_date",
                          value: event,
                        },
                      });
                    }}
                    showYearDropdown
                    dropdownMode="select"
                    showMonthDropdown
                    yearsRange={`${new Date().getUTCFullYear()}:${new Date().getUTCFullYear()}`}
                    name="employee_birth_date"
                    value={employee_birth_date}
                    selected={employee_birth_date}
                    maxDate={new Date()}
                    dateFormat="dd/MM"
                    placeholderText="Birth Date"
                    required
                  />
                </Segment>
              </Grid.Column>


              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Job Title"
                    onChange={onInputChange}
                    name="job_title"
                    value={job_title}
                    required
                  />
                </Segment>
              </Grid.Column>
              
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Cubicle / Office Number"
                    onChange={onInputChange}
                    name="cube_off_number"
                    value={cube_off_number}
                    required
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            
              <Grid.Column>
                <Segment className="access-focus">
                <DatePicker
                    onChange={(event, data) => {
                      onInputChange({
                        target: {
                          name: "employee_start_date",
                          value: event,
                        },
                      });
                    }}
                    name="employee_start_date"
                    value={employee_start_date}
                    selected={employee_start_date}
                    placeholderText="Hire Date"
                    minDate={new Date()}

                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
              <Segment className="access-focus">
                  <Dropdown
                   style={{width:"100%"}}
                  placeholder="Is Manager"
                  options={yesNoDropwDown}
                  onChange={(event, data) => {
                    onInputChange({target:{
                      name: "isManager",
                      value:data.value
                    }});
                  }}
                  name="isManager"
                  value={isManager}
                  required
                  selection
                />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                
              <Segment className="access-focus">
                  <Dropdown
                    style={{ width: "100%" }}
                    placeholder="Is Director/VP"
                    options={yesNoDropwDown}
                    onChange={(event, data) => {
                      onInputChange({
                        target: {
                          name: "isDirector",
                          value: data.value,
                        },
                      });
                    }}
                    name="isDirector"
                    value={isDirector}
                    required
                    selection
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
            <label>Attachment: </label>
          <span>up to four</span>
          <FilePond
            maxFiles={4}
            files={
              (ticketAttachment_one,
              ticketAttachment_two,
              ticketAttachment_three,
              ticketAttachment_four)
            }
            allowMultiple={true}
            allowImagePreview="false"
            onupdatefiles={(fileItems) => handleFilepondUpdate(fileItems)}
          />
            </Grid.Column>
            </Grid.Row>
            </Grid>
        </Form.Field>
        <Form.Field style={{textAlign:"right"}}>
          <Button type="submit" basic color="green">Submit</Button>
        </Form.Field>
      </Form>
    </Container>
  );
}
const mapStateToProps = state => ({
  redirect: state.redirect,
  auth:state.auth,
  departmentList: state.accessRequests.departmentList,
  users: state.inventory.users,
  allUsers: state.inventory.allUsers,
});
export default connect(
  mapStateToProps,
  {  newHireHRFormStart,getDepartmentDropdown,lookupUsersForInventory,
    clearUsersForInventory,allDBUsers }
)(NewHire);