import React, { useState, useEffect } from "react";
import MyResponsivePie from "./PieChart";
import MyResponsiveLine from "./LineChart";
import _ from "lodash";
import moment from "moment";
import {
  Form,
  Button,
  Dropdown,
  Grid,
  Dimmer,
  Loader,
  Icon,
  Radio
} from "semantic-ui-react";
import { connect } from "react-redux";
import Datepicker from "react-datepicker";
import {
  XYPlot,
    XAxis, // Shows the values on x axis
    YAxis, // Shows the values on y axis
    VerticalBarSeries,
    LabelSeries
} from 'react-vis';
import { Container } from "@material-ui/core";
import {
  getAllTicketsForReporting,
  getSelectedTicketsForReporting,
  getReportingData,
} from "../../../Redux/Actions/reportingActions";
import { LineChart, PieChart,ColumnChart,BarChart } from "react-chartkick";
import "chart.js";

const dataRangeData = [
  // { key: "ALL", value: "ALL", text: "ALL" },
  { key: "7", value: 7, text: "PAST WEEK" },
  { key: "15", value: 15, text: "PAST 15 DAYS" },
  { key: "30", value: 30, text: "PAST 30 DAYS" },
  // { key: "CS", value: "CUSTOM", text: "CUSTOM TIME FRAME" },
];

function TicketReportMain({
  getAllTicketsForReporting,
  reporting_tickets,
  getSelectedTicketsForReporting,
  getReportingData,
  reportingData,
  loadUser,
  auth
}) {
  useEffect(() => {
    setIsLoading(true)
    let type = "it"
    if(localStorage.getItem("userData")){
      let tempUserData = JSON.parse(localStorage.getItem("userData"))
    if(tempUserData && tempUserData.length > 0){
      let privilege_level = tempUserData[0].privilege_level
      if(privilege_level === 5){
        type = "data"
      }else if(privilege_level === 6){
        type = "it"
      }
    }
    }
    setType(type)
    getData(type)
    setTimeFrame(30)
  }, []);

  const [type, setType] = useState("it")

  const [timeFrame, setTimeFrame] = useState("");

  const getData = (value) => {
    setIsLoading(true)
    let fromDate;
    let dateToday = moment().subtract(0, "days");
    dateToday = dateToday.toISOString("YYYY-MM-DD").split("T")[0] + " 23:59:59";
    let fd = new FormData();
    fd.append("todays_date", dateToday);
    fromDate = moment().subtract(30, "days");
    fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
    fd.append("from_date", fromDate);
    fd.append("type", value)
    getReportingData(fd).then(()=>{
      setTimeout(()=>{
        setIsLoading(false)
        setType(value)
      },0)
    })
  }

  React.useEffect(() => {
    setType(type)
  }, [type]);

  const [isLoading, setIsLoading] = useState(false);

  const [customTime, setCustomTime] = useState({
    fromCustom: "",
    toCustom: "",
  });

  const { fromCustom, toCustom } = customTime;
  let pieChart;
  if (reporting_tickets.tickets) {
    const dataPie = reporting_tickets.tickets.map((ticket) => {
      return {
        id: ticket.ticket_type + ", " + ticket.action_type,
        label: ticket.ticket_type,
        value: ticket.ticket_number,
      };
    });
    pieChart = <MyResponsivePie data={dataPie} />;
  }
  if (reporting_tickets.tickets && reporting_tickets.tickets.length === 0) {
    pieChart = (
      <h3 style={{ color: "red", textAlign: "center" }}>
        <Icon name="ban" />
        No Tickets in This Time Frame
      </h3>
    );
  }
  if (!reporting_tickets.tickets) {
    pieChart = (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  if (reportingData) {
    if (
      reportingData["authorWiseITData"] &&
      reportingData["authorWiseITData"].filter(Boolean).length > 0
    ) {
      let tempAuthorWise = JSON.parse(JSON.stringify(reportingData["authorWiseITData"])).filter(Boolean).map(
        (x) => {
          return {
            count: x["COUNT(ti_id)"],
            author_name: x["ti_ticket_author_name"]
          };
        }
      );

      let allAuthorList = _.uniq(
        JSON.parse(JSON.stringify(tempAuthorWise)).map(
          (x) => x["author_name"]
        )
      );
      reportingData["finalAuthorITData"] = [];
      allAuthorList.forEach((x) => {
        let itLength = tempAuthorWise
          .filter((y) => y["author_name"] === x)
        reportingData["finalAuthorITData"].push([x, itLength[0]["count"]]);
      });
    }
    if (
      reportingData["authorWiseDataOpsData"] &&
      reportingData["authorWiseDataOpsData"].filter(Boolean).length > 0
    ) {
      let tempAuthorWiseData = JSON.parse(JSON.stringify(reportingData["authorWiseDataOpsData"])).filter(Boolean).map((x) => {
        return {
          count: x["COUNT(dr_id)"],
          author_name: x["dr_ticket_author_name"]
        };
      });

      let allAuthorList = _.uniq(
        JSON.parse(JSON.stringify(tempAuthorWiseData)).map(
          (x) => x["author_name"]
        )
      );
      reportingData["finalAuthorDRData"] = [];
      allAuthorList.forEach((x) => {
        let dataOpsLength = tempAuthorWiseData
          .filter((y) => y["author_name"] === x)
        reportingData["finalAuthorDRData"].push([x,dataOpsLength[0]["count"]]);
      });
    }
  }

   // const itPieData = []

  // if(reportingData && reportingData["it"]){
  //   itPieData = reportingData["it"].map(x=>[x["COUNT(ti_id)"], x["ti_admin_status"]])
  // }

  // const dataPie = [
  //   {
  //     id: "It Tickets Opened",
  //     label: "It Tickets Opened",
  //     value: 298,
  //     color: "hsl(132, 70%, 50%)"
  //   },
  //   {
  //     id: "Closed",
  //     label: "Closed",
  //     value: 369,
  //     color: "hsl(314, 70%, 50%)"
  //   }
  // ];
  const onSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault();
    let fromDate;
    let dateToday =
      moment().toISOString("YYYY-MM-DD").split("T")[0] + " 23:59:59";
    let fd = new FormData();
    fd.append("type", type)
    fd.append("todays_date", dateToday);
    if (timeFrame === "ALL") {
      fd.append("from_date", "ALL");
    }
    if (timeFrame === 7) {
      fromDate = moment().subtract(7, "days");
      fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("from_date", fromDate);
    }
    if (timeFrame === 15) {
      fromDate = moment().subtract(15, "days");
      fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("from_date", fromDate);
      getAllTicketsForReporting(fd);
      getReportingData(fd);
    }
    if (timeFrame === 30) {
      fromDate = moment().subtract(30, "days");
      fromDate = fromDate.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("from_date", fromDate);
    }
    if (timeFrame === "CUSTOM") {
      dateToday =
        toCustom.toISOString("YYYY-MM-DD").split("T")[0] + " 23:59:59";
      fromDate =
        fromCustom.toISOString("YYYY-MM-DD").split("T")[0] + " 00:00:00";
      fd.append("todays_date", dateToday);
      fd.append("from_date", fromDate);
    }
    getReportingData(fd).then(()=>{
      setIsLoading(false)
    })
  };

  const customDates = (
    <Grid.Row>
      <Grid.Column width="3">
        <Datepicker
          placeholderText="From Date"
          dateFormat="MMMM d, yyyy"
          name="fromCustom"
          selected={fromCustom}
          value={fromCustom}
          onChange={(date) =>
            setCustomTime({
              ...customTime,
              fromCustom: date,
            })
          }
        />
      </Grid.Column>
      <Grid.Column width="3">
        <Datepicker
          placeholderText="To Date"
          dateFormat="MMMM d, yyyy"
          name="toCustom"
          selected={toCustom}
          value={toCustom}
          onChange={(date) =>
            setCustomTime({
              ...customTime,
              toCustom: date,
            })
          }
        />
      </Grid.Column>
    </Grid.Row>
  );

  const handleSetType = (value) => {
    setTimeFrame(30)
    setType(value)
    getData(value)
  }

  const colorCode = [
    "#1b75bb",
    "#6d6e70",
    "#55b4c8",
    "#90278e"
  ]

  return (
    <Container>
      <Grid.Row>
          <Grid.Column>
            {isLoading && (
              <div className="loadingNew">
                <div className="loaderNew"></div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      <Form
        onSubmit={(e) => onSubmit(e)}
        style={{ height: "100px", padding: "10px" }}
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width="6">
              <Form.Field>
                <Dropdown
                  placeholder="Select Date Range"
                  options={dataRangeData}
                  onChange={(event, data) => {
                    setTimeFrame(data.value);
                  }}
                  value={timeFrame}
                  selection
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width="3">
              <Form.Field>
                <Button type="submit" basic color="green">
                  Submit
                </Button>
              </Form.Field>
            </Grid.Column>
            {auth.user && (auth.user[0].privilege_level < 3) && 
            <Grid.Column width="2">
            <Radio label='IT Tickets'
            name='radioGroup'
            value='it'
            checked={type === 'it'}
            onChange={()=> handleSetType('it')}
             />
            </Grid.Column>}

            {auth.user && (auth.user[0].privilege_level < 3) && 
              <Grid.Column width="2">
            <Radio label='Data Tickets'
            name='radioGroup'
            value='data'
            checked={type === 'data'}
            onChange={()=> handleSetType('data')}
             />
            </Grid.Column>}
          </Grid.Row>
          {timeFrame === "CUSTOM" ? customDates : null}
        </Grid>
      </Form>
      <Grid columns="equal" stackable>
      <br/>
      <br/>
        <Grid.Row>

        <Grid.Column>
            <div className="tile invoice">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["new"] ? reportingData["new"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">New</div>
              </div>
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className="tile quote">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["openCount"] ? reportingData["openCount"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">Open</div>
              </div>
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className="tile job">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["pending"] ? reportingData["pending"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">Pending</div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="tile quote">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["inPro"] ? reportingData["inPro"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">In Progress</div>
              </div>
            </div>
          </Grid.Column>
          
          <Grid.Column>
            <div className="tile invoice">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["resolvedCount"] ? reportingData["resolvedCount"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">Resolved</div>
              </div>
            </div>
          </Grid.Column>


          <Grid.Column>
            <div className="tile job">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["usersCount"] ? reportingData["usersCount"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">Total Tickets</div>
              </div>
            </div>
          </Grid.Column>
          
        </Grid.Row>
        <Grid.Row>

        {!isLoading && reportingData && <Grid.Column>
          <br/>
              <br/>
            <h5
              style={{
                margin: "unset",
                textAlign: "center",
                marginTop: "10px",
                fontSize: "20px",
                fontWeight: "bolder",
              }}
              className="colorBrandColor"
            >
              Resolved By
            </h5>
                <Grid stackable  style={{marginTop:"20px"}}>
                {reportingData["resolvedBy"].map((x, i)=>{
                    return (
                      <Grid.Row style={{textAlign:"center"}}>
                <Grid.Column width="13" style={{padding:"10px"}}>
                  <Grid stackable style={{paddingLeft: "0px"}} >
                    <Grid.Column width="4" style={{paddingRight: "0px"}}>
                    <div className="circleProfile" style={{background: colorCode[i%4]}}>
                      {x["x"].charAt(0)}
                      </div>
                      </Grid.Column>
                      <Grid.Column width="11" style={{
                            lineHeight: "70px",
                            paddingLeft: "0rem",
                            textAlign: "left",
                            marginLeft: "10px",
                            fontSize: "17px",
                            paddingRight:"0px"
                      }}>
                      {x["x"]}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width="2" style={{padding:"10px", fontSize: "18px", paddingTop:"18px"}}>
                    <div className="tile invoice" style={{
                          height: "20px",
                          width: "95px",
                          margin: "unset",
                    }}>
              <div className="header" style={{height:"40px", background:colorCode[i%4]}}>
                <div className="count" style={{
                      fontSize: "25px"
                }}>{x["y"]}</div>
              </div>
              <div className="body" style={{height:"40px"}}>
                <div className="title" style={{backgroundColor:"white"}}>Tickets</div>
              </div>
            </div>
                  </Grid.Column>
                      </Grid.Row>
                    )
                  })}
                  </Grid>
          </Grid.Column>}
          <Grid.Column>
            {/* <div className="tile resource">
              <div className="header">
                <div className="count" style={{
                      paddingTop: "50px",
                      fontSize: "70px"
                }}>{reportingData && reportingData["unassignedCount"] ? reportingData["unassignedCount"] : 0}</div>
              </div>
              <div className="body">
                <div className="title">Unassigned</div>
              </div>
            </div> */}
            <div className="parent" style={{marginLeft:"30%"}}>
            <div className="orangeNew"></div>        
    <div className="date">
        <h4>UNASSIGNED</h4>
        <p>{reportingData && reportingData["unassignedCount"] ? reportingData["unassignedCount"] : 0}</p>
    </div> 
    <div className="orangeNew"></div>        
</div>
         
              <h5
                style={{
                  margin: "unset",
                  textAlign: "center",
                  marginTop: "85px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
                className="colorBrandColor"
              >
                {type.toUpperCase()} Tickets Based On Status
              </h5>
              {!isLoading && reportingData && (
              <PieChart
              colors={["#E41A1C", "#FF7F00","#377EB8","#FD026C","#204A87"]}
                data={
                type === 'it' ? 
                reportingData["it"] && reportingData["it"].map((x) => [
                  x["ti_admin_status"] ? x["ti_admin_status"] : "No Status",
                  x["COUNT(ti_id)"],
                ]) : 
                reportingData["dataOps"] && reportingData["dataOps"].map((x) => [
                  x["dr_admin_status"] ? x["dr_admin_status"] : "No Status",
                  x["COUNT(dr_id)"],
                ])}
              />
              )}
              </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  reporting_tickets: state.reportingTickets,
  reportingData: state.reportingTickets.reportingData,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getAllTicketsForReporting,
  getSelectedTicketsForReporting,
  getReportingData,
})(TicketReportMain);