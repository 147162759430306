import axios from "axios";
import { setAlert } from "./alertActions";
import {NEW_HIRE_ACCESS_REQUEST,FORM_OK, FORM_NOT_OK} from "../Actions/types"

export const switchRedirect =() => async dispatch => {
dispatch({type:FORM_OK})
setTimeout(() => {
    dispatch({
      type: FORM_NOT_OK,
    });
  }, 1000);
}


