import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {
    Segment,
    Image,
    Grid,
    Icon,
    Modal,
    Header
  } from "semantic-ui-react";
import { getUserTickets } from "../../../Redux/Actions/ticketActions";
const MyTickets = ({ tickets: { tickets },getUserTickets}) => {
  useEffect(() => {
    getUserTickets();
  }, [getUserTickets]);
  let singleTicket;
  if (!tickets || tickets.length === 0) {
    singleTicket = <LoaderPlaceholder />;
  } else {
    singleTicket = tickets.map(ticket => (
      <Modal
        key={ticket.id}
        dimmer="blurring"
        trigger={
          <Segment
            className="individual-ticket-reporting-wrapper"
            style={
              ticket.priority === "LOW"
                ? {
                    borderTop: "5px solid #f2e6ff",
                    overflow: "hidden"
                  }
                : ticket.priority === "NORMAL"
                ? {
                    borderTop: "5px solid #bcddf5",
                    overflow: "hidden"
                  }
                : ticket.priority === "HIGH"
                ? {
                    borderTop: "5px solid #ffd9b3",
                    overflow: "hidden"
                  }
                : ticket.priority === "URGENT"
                ? {
                    borderTop: "5px solid #ff9980",
                    overflow: "hidden"
                  }
                : {}
            }
          >
            <ReactTooltip />
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <h4>{ticket.ticket_author_name}</h4>

                  <hr className="style-six" />
                  <h4>{ticket.subject}</h4>

                  <hr className="style-six" />
                </Grid.Column>
                <Grid.Column>
                  {ticket.type === "Question" ? (
                    <Icon
                      name="question"
                      data-tip="Question"
                      size="large"
                      color="red"
                    />
                  ) : ticket.type === "Incident" ? (
                    <Icon
                      name="exclamation triangle"
                      data-tip="Incident"
                      size="large"
                      color="red"
                    />
                  ) : ticket.type === "Problem" ? (
                    <Icon
                      name="exclamation"
                      data-tip="Problem"
                      size="large"
                      color="red"
                    />
                  ) : ticket.type === "Task" ? (
                    <Icon
                      name="calendar check outline"
                      data-tip="Task"
                      size="large"
                      color="red"
                    />
                  ) : null}
                  {ticket.admin_status === "active_no_comment" ? (
                    <Icon
                      name="cog"
                      data-tip="Active No Comments"
                      size="large"
                      color="blue"
                    />
                  ) : ticket.admin_status === "active_with_comment" ? (
                    <Icon
                      name="cogs"
                      data-tip="Active With Comments"
                      size="large"
                      color="blue"
                    />
                  ) : ticket.admin_status === "new" ? (
                    <Icon
                      name="bell"
                      data-tip="New Ticket"
                      size="large"
                      color="orange"
                    />
                  ) : null}
                  <p>{ticket.description.slice(0, 50)}...see more.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        }
        closeIcon
      >
        <Modal.Header>Ticket ID: {ticket.id}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>Issue: {ticket.subject}</Header>
            <p>
              From: {ticket.ticket_author_name} | {ticket.ticket_author_email}{" "}
            </p>
            <p>Description: {ticket.description}</p>
            {ticket.attachment ? (
              <div style={{ margin: "5px" }}>
                <h4>
                  Attachments: <span>Click to enlarge</span>
                </h4>
                <Modal
                  trigger={
                    <Image
                      src={`${ticket.attachment}`}
                      size="small"
                      alt="Attachment Preview"
                    />
                  }
                  closeIcon
                >
                  <img
                    src={`${ticket.attachment}`}
                    alt="Attachment"
                  />
                </Modal>
              </div>
            ) : (
              <span />
            )}
          </Modal.Description>
        </Modal.Content>
       
      </Modal>
    ));
  }
  return <Container style={{paddingTop:'50px'}}>{singleTicket}</Container>;
};
const mapStateToProps = state => ({
  auth: state.auth,
  tickets: state.tickets
});
export default connect(
  mapStateToProps,
  { getUserTickets }
)(MyTickets);
