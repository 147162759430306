import React from "react";
import { Container, Icon, Modal, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import AddBug from "./ReportABug/AddBug"
import { connect } from "react-redux";

const Footer = ({ auth }) => {

  return (
    <footer style={{ paddingTop: "30px" }}>
      <Container>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              {/* <div>
                <Link to="/dashboard/suggestions" style={{ color: "white" }}>
                  Suggestions <Icon name="pencil alternate" size="small" />
                </Link>
              </div> */}
              {/* <div>
                <Link to="/dashboard/roadmap" style={{ color: "white" }}>
                  RoadMap <Icon name="map" size="small" />
                </Link>
              </div> */}
              <div>
                <Modal
                  trigger={
                    <p style={{ color: "white", cursor: "pointer" }}>
                      Feedback
                    </p>
                  }
                  closeIcon
                >
                  <Modal.Header>Give us Some Feedback!</Modal.Header>
                  <AddBug props={auth} />
                </Modal>
              </div>
            </Grid.Column>

            {auth.user && auth.user[0].email === "tflock@healthteamadvantage.com" ? (
              <Grid.Column style={{ textAlign: "center" }} />
            ) : null}
            {auth.user && auth.user[0].email === "tflock@healthteamadvantage.com" ? (
              <Grid.Column style={{ textAlign: "right" }}>
                <Modal
                  trigger={
                    <p style={{ color: "white", cursor: "pointer" }}>
                      Super User Menu
                    </p>
                  }
                  closeIcon
                >
                  <Modal.Header>Super User Links</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <Link to="/bug-reports">Bug Reports</Link>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
        <p style={{ textAlign: "center", color: "white" }}>
          &copy;{new Date().getFullYear()} HTA
        </p>
      </Container>
    </footer>
  );
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  null
)(Footer);
