import { GET_ALL_DASHBOARD_TICKETS, MODAL_OPEN, GET_ALL_ASSIGNED_DASHBOARD_TICKETS } from "./types";

import axios from "axios";
import { setAlert } from "./alertActions";

export const getAllDashboardTickets = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/dashboard/get-all-dashboard-tickets",
      body
    );
    dispatch({
      type: GET_ALL_DASHBOARD_TICKETS,
      payload: res.data,
    });
  } catch {
    const res = {
      data: {
        message:
          "There was an error retrieving your dashboard tickets, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getAllAssignedDashboardTickets = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/dashboard/get-all-assigned-dashboard-tickets",
      body
    );
    dispatch({
      type: GET_ALL_ASSIGNED_DASHBOARD_TICKETS,
      payload: res.data,
    });
  } catch {
    const res = {
      data: {
        message:
          "There was an error retrieving your dashboard tickets, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getSingleDashboardModifyRequest = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/dashboard/get-single-dashboard-modify-request",
      body
    );
    dispatch({
      type: GET_ALL_DASHBOARD_TICKETS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getSingleDashboardAccessRequest = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/dashboard/get-single-access-request-ticket",
      body
    );
    console.log(res);
    dispatch({
      type: GET_ALL_DASHBOARD_TICKETS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getDashboardTicket = (body) => async (dispatch) => {
  try {
  } catch (error) {
    console.log(error);
  }
};

export const getUserTicketHistory = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/dashboard/get-history-tickets", body);
    dispatch({
      type: GET_ALL_DASHBOARD_TICKETS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error retrieving your dashboard tickets, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
