// Created by Thomas Flock Jr on 8/12/2019
// https://github.com/mazer-rakham

// this needs refactoring!!!!!!!!!
import React, { useState,useEffect, useCallback } from "react";
import { throttle } from "lodash";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers
} from "../../../Redux/Actions/inventoryActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

import { Form, Input, TextArea, Container, Button, Grid } from "semantic-ui-react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { newDataTicket } from "../../../Redux/Actions/ticketActions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { loadUser } from "../../../Redux/Actions/userActions";
registerPlugin(FilePondPluginImagePreview);
const NewDataTicket = ({ newDataTicket, redirect, loadUser, auth, lookupUsersForInventory,
  clearUsersForInventory,users,allDBUsers,allUsers }) => {
    useEffect(() => {
      allDBUsers()
    }, []);
  const [ticketData, setTicketData] = useState({
    ticketType: "DATA",
    ticketSubject: "",
    ticketDescription: "",
    itHelpType: "",
    ticketPriority: "",
    organization: ""
  });
  const spanStyle = {
    marginRight: "15px",
    position: "relative",
    top: "-2px"
  };
  const inputStyle = {
    cursor: "pointer",
    marginRight: "2px"
  };
  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
    ticketAttachment_two: "",
    ticketAttachment_three: "",
    ticketAttachment_four: ""
  });
  const [dates, setDates] = useState({
    claimsBeginingDate: "",
    claimsEndDate: "",
    dueDate: ""
  });
  const {
    ticketType,
    ticketSubject,
    ticketDescription,
    itHelpType,
    ticketPriority,
    organization
  } = ticketData;
  const {
    ticketAttachment_one,
    ticketAttachment_two,
    ticketAttachment_three,
    ticketAttachment_four
  } = picture;
  React.useEffect(()=>{
    setRemainingDes(1000 - ticketDescription.length)
  },[ticketDescription])

  const [remainingDes, setRemainingDes]=useState(1000)

  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: []
  });
  const {
    cursor
  } = highlightSelection;

  const [requesterName, setRequesterName] = useState("");

  // React.useEffect(() => {
  //   console.log(requesterName, users)
  //   clearUsersForInventory()
  // }, [requesterName]);

  const handleAutoProductChange = e => {
    setRequesterName(e.target.value.trim());
    delayedQuery(e.target.value);
  };

  const delayedQuery = useCallback(
    throttle(q => sendQuery(q), 500),
    []
  );

  const sendQuery = query => {
    if (query.length > 0) {
      console.log(query)
      lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };

  const { claimsBeginingDate, claimsEndDate, dueDate } = dates;
  const onChange = e =>
    setTicketData({ ...ticketData, [e.target.name]: e.target.value });
  const handleFilepondUpdate = fileItems => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
        ticketAttachment_two: !fileItems[1] ? null : fileItems[1].file,
        ticketAttachment_three: !fileItems[2] ? null : fileItems[2].file,
        ticketAttachment_four: !fileItems[3] ? null : fileItems[3].file
      });
    }
  };
  const onSubmit = e => {
    e.preventDefault();
    const fd = new FormData();
    if(claimsBeginingDate && !claimsEndDate){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Claim End Date is Mandatory"), 300)
      return false;
    }
    if(!claimsBeginingDate && claimsEndDate){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Claim Start Date is Mandatory"), 300)
      return false;
    }
    if(claimsBeginingDate && claimsEndDate){
      const ISOFromDate = claimsBeginingDate.toISOString().split("T")[0] + " 00:00:00";
    const ISOToDate = claimsEndDate.toISOString().split("T")[0] + " 23:59:59";
    fd.append("claimsBeginingDate", ISOFromDate);
    fd.append("claimsEndDate", ISOToDate);
    }
    const ISODueDate = dueDate.toISOString().split("T")[0] + " 23:59:59";
    // let index = allUsers.findIndex(x=>x.first_name === requesterName.split(" ")[0] && x.last_name === requesterName.split(" ")[1])
    // if(index === -1){
    //   toastr.options = {
    //     positionClass: "toast-top-center",
    //     hideDuration: 300,
    //     timeOut: 2000,
    //     closeButton: false,
    //     progressBar: true,
    //   }
    //   toastr.clear()
    //   setTimeout(() => toastr.error("Please Select User From The List"), 300)
    //   return false;
    // }
    if(!requesterName){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Requester Name is Mandatory"), 300)
      return false;
    }
    fd.append("user_id", auth.user[0].id);
    fd.append("ticketType", ticketType);
    fd.append("ticketSubject", ticketSubject);
    fd.append("ticketDescription", ticketDescription);
    fd.append("itHelpType", itHelpType);
    fd.append("ticketPriority", ticketPriority);
    fd.append("organization", organization);
    fd.append("dueDate", ISODueDate);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    fd.append("ticketAttachment_two", ticketAttachment_two);
    fd.append("ticketAttachment_three", ticketAttachment_three);
    fd.append("ticketAttachment_four", ticketAttachment_four);
    fd.append("requesterName", requesterName.trim())
    newDataTicket(fd);    
  };

  const handleKeyDown = e => {
    if (e.keyCode === 38) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor - 1
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor + 1
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        // const fd = new FormData();
        // fd.append("reassign_user", selected[i].getAttribute("data-key"));

        // need to get the name nd user from the database and make the change immediaty
        setRequesterName("");
        // clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0
        });
      }
    }
  };

  function setRequesterFunction(name){
    setRequesterName(name.target.value)
  }
  
  const handleDueDate = date => {
    console.log(date)
    setDates({
      ...dates,
      dueDate: date
    });
  };
  const handleBeginingDate = date => {
    setDates({
      ...dates,
      claimsBeginingDate: date
    });
  };
  const handleEndDate = date => {
    setDates({
      ...dates,
      claimsEndDate: date
    });
  };
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <h1>New Data Ticket</h1>
      <Form onSubmit={e => onSubmit(e)}>
        <Form.Field>
          <Link to="/new-ticket/IT" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>IT Ticket</span>{" "}
          </Link>
          <input
            type="radio"
            name="ticketType"
            value="DATA"
            onChange={onChange}
            checked={ticketType === "DATA"}
            style={inputStyle}
          />
          <span style={spanStyle}>DATA Request</span>
          <Link to="/new-ticket/Quality" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>Quality Request</span>{" "}
          </Link>
        </Form.Field>
        <Form.Field>
          <label className="mandatory-star">Subject:</label>
          <Input
            type="text"
            maxLength="50"
            onChange={onChange}
            name="ticketSubject"
            placeholder="Please enter the subject of the ticket"
            value={ticketSubject}
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 50
          </span>
        </Form.Field>
        <Form.Field>
          <label className="mandatory-star">Description:</label>
          <TextArea
            maxLength="1000"
            name="ticketDescription"
            value={ticketDescription}
            onChange={onChange}
            placeholder="Please enter a brief description for the ticket"
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 1000
          </span>
          <span style={{ padding: "5px", color: "red", fontSize: "12px", float:"right" }}>
          {remainingDes} Characters remaining
          </span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">Type:</label>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Question"
            checked={itHelpType === "Question"}
          />
          <span style={spanStyle}>Question</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Incident"
            checked={itHelpType === "Incident"}
          />
          <span style={spanStyle}>Incident</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Problem"
            checked={itHelpType === "Problem"}
          />
          <span style={spanStyle}>Problem</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Task"
            checked={itHelpType === "Task"}
          />
          <span style={spanStyle}>Task</span>
        </Form.Field>
        <Form.Field
          style={
            ticketPriority === "LOW"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#f2e6ff"
                }
              : ticketPriority === "NORMAL"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#bcddf5"
                }
              : ticketPriority === "HIGH"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ffd9b3"
                }
              : ticketPriority === "URGENT"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ff9980"
                }
              : {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px"
                }
          }
        >
          <label className="mandatory-star">Priority:</label>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="LOW"
            checked={ticketPriority === "LOW"}
          />
          <span style={spanStyle}>Low</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="NORMAL"
            checked={ticketPriority === "NORMAL"}
          />
          <span style={spanStyle}>Normal</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="HIGH"
            checked={ticketPriority === "HIGH"}
          />
          <span style={spanStyle}>High</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="URGENT"
            checked={ticketPriority === "URGENT"}
          />
          <span style={spanStyle}>Urgent</span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">Organization</label>
          <input
            type="radio"
            name="organization"
            value="HTA"
            onChange={onChange}
            checked={organization === "HTA"}
            style={inputStyle}
          />
          <span style={spanStyle}>HTA</span>
          <input
            type="radio"
            name="organization"
            value="THN"
            onChange={onChange}
            checked={organization === "THN"}
            style={inputStyle}
          />
          <span style={spanStyle}>THN</span>
          <input
            value="Cone"
            name="organization"
            onChange={onChange}
            checked={organization === "Cone"}
            type="radio"
            style={inputStyle}
          />
          <span style={spanStyle}>Cone</span>
        </Form.Field>
        <Form.Group widths='equal'>
        <Form.Field>
          <label className="mandatory-star">Due Date</label>
          <DatePicker
            onChange={handleDueDate}
            selected={dueDate}
            name="dueDate"
          />
        </Form.Field>
        <Form.Field>
        <label style={{fontWeight: "bold", fontSize: "12px"}} className="mandatory-star">Requester Name</label>
        <Input
                              onChange={(e)=> setRequesterFunction(e)}
                              value={requesterName}
                              placeholder="Enter Name"
                              style={{ maxWidth: "auto", width:"auto" }}
                            />

                          {/* {users ? (
                            <div
                              style={{
                                position: "absolute",
                                background: "white",
                                width: "18.5%",
                                border: "1px solid #bfbfbf",
                                borderRadius: "3px",
                                zIndex: "99999"
                              }}
                            >
                              {users.length === 0 ? (
                                <p style={{ padding: "5px", margin: "0px" }}>
                                  No Results Found
                                </p>
                              ) : (
                                users.map((user, i) => (
                                  <p
                                    style={{ padding: "5px", margin: "0px" }}
                                    key={user.id}
                                    data-key={user.id}
                                    onClick={()=>{setRequesterFunction(user.first_name + " " +user.last_name)}}
                                    data-name={
                                      user.first_name + " " +user.last_name
                                    }
                                    className={
                                      cursor === i
                                        ? "active-software-selection"
                                        : null
                                    }
                                  >
                                    {user.first_name} <br /> {user.last_name}
                                  </p>
                                ))
                              )}
                            </div>
                          ) : null} */}
          </Form.Field>
          </Form.Group>
        <Form.Group widths='equal'>
        <Form.Field>
          <label>Date of Services for Claims, Beginning Date:</label>
          <DatePicker
            onChange={handleBeginingDate}
            selected={claimsBeginingDate}
            name="claimsBeginingDate"
          />
        </Form.Field>
        <Form.Field>
          <label>Date of Services for Claims, End Date:</label>
          <DatePicker
            onChange={handleEndDate}
            selected={claimsEndDate}
            name="claimsEndDate"
            value={claimsEndDate}
          />
        </Form.Field>
        </Form.Group>
        
        <Form.Field>
          <label>Attachment: </label>
          <span>up to four</span>
          <FilePond
            maxFiles={4}
            files={
              (ticketAttachment_one,
              ticketAttachment_two,
              ticketAttachment_three,
              ticketAttachment_four)
            }
            allowMultiple={true}
            allowImagePreview="false"
            onupdatefiles={fileItems => handleFilepondUpdate(fileItems)}
          />
        </Form.Field>
        <Form.Field>
          <Button
            basic
            color="red"
            type="reset"
            onClick={() => {
              setTicketData({
                ticketType: "DATA",
                ticketSubject: "",
                ticketDescription: "",
                itHelpType: "",
                ticketPriority: "",
                organization: ""
              });
              setDates({
                claimsBeginingDate: "",
                claimsEndDate: "",
                dueDate: ""
              });
              addAttachment({
                ...picture,
                ticketAttachment_one: "",
                ticketAttachment_two: "",
                ticketAttachment_three: "",
                ticketAttachment_four: "",
              });
            }}
          >
            Clear
          </Button>
          <Button
            type="submit"
            basic
            color="green"
            disabled={
              !ticketSubject ||
              !ticketDescription ||
              !itHelpType ||
              !ticketPriority ||
              !organization ||
              !dueDate
            }
          >
            Submit
          </Button>
        </Form.Field>
      </Form>
    </Container>
  );
};
const mapStateToProps = state => ({
  tickets: state.tickets,
  redirect: state.redirect,
  auth: state.auth,
  users: state.inventory.users,
  allUsers: state.inventory.allUsers
});
export default connect(
  mapStateToProps,
  { newDataTicket, loadUser, lookupUsersForInventory,
    clearUsersForInventory,allDBUsers }
)(NewDataTicket);