import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { Dimmer, Loader, Grid, Sticky } from "semantic-ui-react";
import { getallApplications } from "../../../Redux/Actions/applicationActions";
function ApplicationsMain({ getallApplications, applications }) {
  useEffect(() => {
    getallApplications();
  }, [getallApplications]);

  const applicationsAll = applications.applications;
  let applicationsDisplay;
  !applicationsAll
    ? (applicationsDisplay = (
        <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer>
      ))
    : (applicationsDisplay = applicationsAll.map(app => (
        <Grid.Row key={app.id}>
          <Grid.Column width="2">{app.vendor}</Grid.Column>
          <Grid.Column width="2">{app.system_application}</Grid.Column>
          <Grid.Column width="3">{app.responsibility}</Grid.Column>
          <Grid.Column width="2">{app.poc_name}</Grid.Column>
          <Grid.Column width="2">
            {!app.poc_phone ? <span>N / A</span> : <a href={`tel:${app.poc_phone}`}>{app.poc_phone}</a>}
          </Grid.Column>
          <Grid.Column width="2">
            <a href={`mailto:${app.poc_email}`}>{app.poc_email}</a>
          </Grid.Column>
        </Grid.Row>
      )));
  return (
    <Container maxWidth="xl" style={{ minWidth: "1112px" }}>
      {/* <Sticky> */}
        <Grid celled="internally" style={{borderBottom:"1px solid grey", backgroundColor:"white"}}>
          <Grid.Row style={{ fontWeight: "bold" }}>
            <Grid.Column width="2">VENDOR</Grid.Column>
            <Grid.Column width="2">SYSTEM / APPLICATION</Grid.Column>
            <Grid.Column width="3">RESPONSIBILITY</Grid.Column>
            <Grid.Column width="2">CONTACT NAME</Grid.Column>
            <Grid.Column width="2">CONTACT PHONE</Grid.Column>
            <Grid.Column width="2">CONTACT EMAIL</Grid.Column>
          </Grid.Row>
        </Grid>
      {/* </Sticky> */}
      <Grid celled="internally">{applicationsDisplay}</Grid>
    </Container>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  applications: state.applications
});
export default connect(mapStateToProps, { getallApplications })(
  ApplicationsMain
);
