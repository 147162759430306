import {
  LOAD_USER_PROFILE,
  RESET_PROFILE_SEARCH,
  GET_USER_PROFILE_SUGGESTIONS,
  CLEAR_ALL_LOOKUP_DATA
} from "../Actions/types";
const initialState = {
  user_profile: null,
  suggestions: null,
  isLoading: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_USER_PROFILE:
      if (payload.length === 0) {
        return {
          ...state,
          user_profile: null
        };
      } else {
        return {
          ...state,
          user_profile: payload
        };
      }
    case GET_USER_PROFILE_SUGGESTIONS:
      return {
        ...state,
        suggestions: payload
      };
    case RESET_PROFILE_SEARCH:
      return {
        ...state,
        suggestions: null,
        isLoading: false
      };
    case CLEAR_ALL_LOOKUP_DATA:
      return {
        ...state,
        user_profile: null,
        suggestions: null,
        isLoading: false
      };
    default:
      return state;
  }
}
