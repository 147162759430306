import {MODAL_CLOSE, MODAL_OPEN} from '../Actions/types';

const initialState = {
  open:false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN: 
  
      return {
        ...state,
        open:true,
        modalMessage: action.payload };
    
    case MODAL_CLOSE: 
      return {
        ...state,
        open:false,
        modalMessage: null
      }
    
    default:
      return state;
  }
};