import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Form,
  Grid,
  Input,
  Icon,
  Modal,
  Button,
  Dropdown
} from "semantic-ui-react";
import { modifyLookup } from "../../../Redux/Actions/accessRequestActions";
import { throttle } from "lodash";
import { Redirect } from "react-router-dom";
import {
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  setRequestedAccessModification,
} from "../../../Redux/Actions/inventoryActions";
function ModifyAccess({
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  auth,
  redirect,
  inventory,
  setRequestedAccessModification,
}) {
  useEffect(() => {
    lookupSoftwareSuggestions();
  }, []);
  // const [noproductsNeeded, setnoproductsNeeded] = useState({
  //   notneeded: false
  // });

  const [modalOpen, setmodalOpen] = useState({
    second_modal_open: false,
  });
  const { second_modal_open } = modalOpen;
  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });
  const {
    cursor,
    //  result
  } = highlightSelection;
  const [softwareInventory, setSoftwareInventory] = useState([]);
  const [clonesoftwareInventory, clonesetSoftwareInventory] = useState([]);
  const [userselectedPro, usersetSelectedPro] = useState([])
  // const [userTakeAction, setUserAc]
  
  function handleSoftwareInventory(data,i){
    let originalProArr = JSON.parse(JSON.stringify(softwareInventory)).filter(x=>x['system_application'] !== data['system_application'])
    setSoftwareInventory(
      originalProArr.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0))
    )
    let newArr = JSON.parse(JSON.stringify(userselectedPro))
    newArr.push(data)
    usersetSelectedPro(
      newArr
    )
  }

  function handleSelectedSoftwareInventory(data,i){
    let originalProArr = JSON.parse(JSON.stringify(userselectedPro)).filter(x=>x['system_application'] !== data['system_application'])
    usersetSelectedPro(
      originalProArr
    )
    let newArr = JSON.parse(JSON.stringify(softwareInventory))
    newArr.push(data)
    setSoftwareInventory(
      newArr.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0))
    )
  }

  const [selectedPro, setSelectedPro] = useState({})

  const handleRequestAccessSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("requestor", auth.user[0].id);
    fd.append("person_modified_id", auth.user[0].id);
    fd.append("requestor_manager", auth.user[0].manager_email);
    if (userselectedPro.length > 0) {
      userselectedPro.map((product) =>
        fd.append(product.system_shortname, product.system_application)
      );
    }
    setRequestedAccessModification(fd);
  };
  const { formOk } = redirect;

  React.useEffect(()=>{
    if(inventory && inventory.suggested_software_products && inventory.suggested_software_products.length > 0){
      if(softwareInventory.length === 0){
        setSoftwareInventory(inventory.suggested_software_products.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0)))
        clonesetSoftwareInventory(inventory.suggested_software_products.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0)))
      } 
    }
  }, [inventory])

  if (formOk) {
    return <Redirect to="/" />;
  }

  // if(inventory && inventory.suggested_software_products && inventory.suggested_software_products.length > 0){
  //   if(softwareInventory.length === 0){
  //     setSoftwareInventory(inventory.suggested_software_products.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0)))
  //     clonesetSoftwareInventory(inventory.suggested_software_products.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0)))
  //   } 
  // }

  // const checkAlreadyIn = (id) => {
  //   let findIndex = inventory && inventory.software_products ? inventory.software_products.findIndex(x=>x["id"] === id) : -1
  //   if(findIndex !== -1){
  //     return true
  //   }else{
  //     return false
  //   }
  // }

  // const handleMouseKeyClick = (event, id) => {
  //   var highlightedText = event.target.innerText;
  //   const fd = new FormData();
  //   fd.append("added_software", highlightedText);
  //   if(!checkAlreadyIn(id)){
  //     getSelectedProduct(fd).then(() => {
  //       sethighlightSelection({
  //         ...highlightSelection,
  //         cursor: 0,
  //       });
  //     });
  //   }else{
  //     console.log("not in")
  //     removeSelectedSoftwareFromList(id)
  //   }
  // };

  // checkAlreadyIn()

  const handleMouseKeyClick = (highlightedText) => {
    // var highlightedText = event.target.innerText;
    const fd = new FormData();
    fd.append("added_software", highlightedText);
    // if(!checkAlreadyIn(id)){
      
    // }else{
    //   console.log("not in")
    //   removeSelectedSoftwareFromList(id)
    // }
    getSelectedProduct(fd).then(() => {
      sethighlightSelection({
        ...highlightSelection,
        cursor: 0,
      });
    });
  };

  const returnRequiredObject = (x) => {
    return {
      ...x,
      key: x["system_application"],
      text: x["system_application"],
      value: x["system_application"]
    }
  }

  let envision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let beaconh = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let conehealth = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let cotiviti = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let sharepoint = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let argusdentalvision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allenvision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allbeaconh = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allconehealth = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allcotiviti = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allargusdentalvision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }] 
  let allsharepoint = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]

  // if(inventory && inventory.suggested_software_products && inventory.suggested_software_products.length > 0){
  //   inventory.suggested_software_products.forEach(x=>{
  //     if(x && x["poc_email"]){
  //       getType(x)
  //     }
  //   })
  //   console.log("inventory.suggested_software_products", inventory.suggested_software_products)
  // }

  // function getType(x){
  //   let tempObj = returnRequiredObject(x)
  //   if(x["poc_email"].match(/conehealth/gi)){
  //     conehealth.push(tempObj)
  //     allconehealth.push(tempObj)
  //   }
  //   if(x["poc_email"].match(/cotiviti/gi)){
  //     cotiviti.push(tempObj)
  //     allcotiviti.push(tempObj)
  //   }
  //   if(x["poc_email"].match(/argusdentalvision/gi)){
  //     argusdentalvision.push(tempObj)
  //     allargusdentalvision.push(tempObj)
  //   }
  //   if(x["poc_email"].match(/beaconh/gi)){
  //     beaconh.push(tempObj)
  //     allbeaconh.push(tempObj)
  //   }
  //   if(x["poc_email"].match(/envision/gi)){
  //     envision.push(tempObj)
  //     allenvision.push(tempObj)
  //   }
  //   if(x["poc_email"].match(/sharepoint/gi)){
  //     sharepoint.push(tempObj)
  //     allsharepoint.push(tempObj)
  //   }
  // }

  function getType(x){
    if(!x["category"]){
      return false;
    }
    let tempObj = returnRequiredObject(x)
    if(x["category"].match(/1/gi)){
      conehealth.push(tempObj)
      allconehealth.push(tempObj)
    }
    if(x["category"].match(/5/gi)){
      cotiviti.push(tempObj)
      allcotiviti.push(tempObj)
    }
    if(x["category"].match(/4/gi)){
      argusdentalvision.push(tempObj)
      allargusdentalvision.push(tempObj)
    }
    if(x["category"].match(/2/gi)){
      beaconh.push(tempObj)
      allbeaconh.push(tempObj)
    }
    if(x["category"].match(/3/gi)){
      envision.push(tempObj)
      allenvision.push(tempObj)
    }
    if(x["category"].match(/0/gi)){
      sharepoint.push(tempObj)
      allsharepoint.push(tempObj)
    }
  }

  const checkAlreadyIn = (array, allArray) => {
    if(inventory.software_products && inventory.software_products.length > 0){
      let alreadyIds = JSON.parse(JSON.stringify(inventory.software_products)).map(x=>x["id"])
      array = JSON.parse(JSON.stringify(allArray)).filter(x=>{
        if(!alreadyIds.includes(x.id)){
          return true
        }
      })
    }
    return array
  }

  function removeChip(item){
    if(!item){
      return;
    }
    removeSelectedSoftwareFromList(item.id)
    getType(item)
  }

  const handleDropdownChange = (e,data) => {
    setSelectedPro({
      ...selectedPro,
      [data["name"]]: data.value
    })
  }

  function belongsToSameDiv(item, allEachArray){
    let index = allEachArray.findIndex(x=>x["id"] === item)
    return index !== -1 ? true : false
  }

  return (
    <Container style={{marginTop:"10px", padding:"auto 10px"}}>
      <h1>Request access</h1>
      <Form>
        <Form.Field>
          <Grid>
          <Grid.Row>
              <Grid.Column style={{ textAlign: "right" }}>
                {/* <Button type="button" color="red" basic>
                  Reset
                  <Icon name="dont" style={{ marginLeft: "5px" }} />
                </Button> */}
                <Modal
                  open={second_modal_open}
                  onClose={() =>
                    setmodalOpen({
                      ...modalOpen,
                      second_modal_open: false,
                    })
                  }
                  trigger={
                    <Button
                      type="button"
                      color="green"
                      basic
                      disabled={userselectedPro.length === 0}
                      onClick={() =>
                        setmodalOpen({
                          ...modalOpen,
                          second_modal_open: true,
                        })
                      }
                    >
                      Review
                    </Button>
                  }
                  closeIcon
                >
                  <Modal.Header>Products Needed</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      {userselectedPro
                        ? userselectedPro.map((prod) => (
                            <p
                              key={prod.id}
                              style={{
                                borderBottom: "1px solid #90278E",
                                margin: "0px",
                                padding: "5px",
                              }}
                            >
                              {prod.system_application}
                            </p>
                          ))
                        : null}
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      onClick={() =>
                        setmodalOpen({
                          ...modalOpen,
                          second_modal_open: false,
                        })
                      }
                      basic
                      color="red"
                      type="button"
                    >
                      No
                    </Button>
                    <Button
                      basic
                      color="green"
                      type="button"
                      onClick={(e) => handleRequestAccessSubmit(e)}
                    >
                      Yes
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Column>
            </Grid.Row>
            
            <Grid.Row>
            <Grid.Column width="7">
               <h3 style={{textDecoration:"underline"}}>Select a product From below</h3>
               </Grid.Column>
               <Grid.Column width="1">
               
               </Grid.Column>
               <Grid.Column width="7">
               <h3 style={{textDecoration:"underline"}}>Selected Products</h3>
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width="7">
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
              {softwareInventory && softwareInventory.length > 0 && softwareInventory.map((x,i)=>{
                return (
                  <button onClick={()=>handleSoftwareInventory(x,i)} type="button" class='btn-new first' key={i}>{x['system_application']}</button>
                )
              })}
              </div>
              </Grid.Column>
              <Grid.Column width="1">
              <div class="vl"></div>
              </Grid.Column>
              <Grid.Column width="7">
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
              {userselectedPro && userselectedPro.length > 0 && userselectedPro.map((x,i)=>{
                return (
                  <button onClick={()=>handleSelectedSoftwareInventory(x,i)} type="button" class='btn-new first btn-new-selected' key={i}>{x['system_application']}</button>
                )
              })}
              </div>
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
               <Grid.Column width="3">
                 <label>Envision</label>
               <Dropdown name="Envision" placeholder='Envision' fluid selection options={checkAlreadyIn(envision,allenvision)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column width="12">
               {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    if(belongsToSameDiv(product.id, allenvision)){
                      return (
                       <div className="chip" style={{backgroundColor:"#1b75bb"}} key={product.id}>
                        <div className="chip-content">{product.system_application}</div>
                        <div className="chip-close-software" onClick={()=>removeChip(product)}>
                            <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                        
                        </div>
                      </div>
                      );
                    }
                  })} 
               </Grid.Column>
            </Grid.Row> 
            <Grid.Row>
            <Grid.Column width="3">
                 <label>Beaconh</label>
               <Dropdown placeholder='beaconh' name="beaconh" fluid selection options={checkAlreadyIn(beaconh,allbeaconh)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column width="12">
               {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    if(belongsToSameDiv(product.id, allbeaconh)){
                      return (
                       <div className="chip" style={{backgroundColor:"#1b75bb"}} key={product.id}>
                        <div className="chip-content">{product.system_application}</div>
                        <div className="chip-close-software" onClick={()=>removeChip(product)}>
                            <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                        
                        </div>
                      </div>
                      );
                    }
                  })} 
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
           <Grid.Column width="3">
                 <label>Conehealth</label>
               <Dropdown placeholder='conehealth' name="conehealth" fluid selection options={checkAlreadyIn(conehealth,allconehealth)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column width="12">
               {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    if(belongsToSameDiv(product.id, allconehealth)){
                      return (
                       <div className="chip" style={{backgroundColor:"#1b75bb"}} key={product.id}>
                        <div className="chip-content">{product.system_application}</div>
                        <div className="chip-close-software" onClick={()=>removeChip(product)}>
                            <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                        
                        </div>
                      </div>
                      );
                    }
                  })} 
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
               <Grid.Column width="3">
                 <label>Cotiviti</label>
               <Dropdown placeholder='cotiviti' name="cotiviti" fluid selection options={checkAlreadyIn(cotiviti,allcotiviti)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column width="12">
               {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    if(belongsToSameDiv(product.id, allcotiviti)){
                      return (
                       <div className="chip" style={{backgroundColor:"#1b75bb"}} key={product.id}>
                        <div className="chip-content">{product.system_application}</div>
                        <div className="chip-close-software" onClick={()=>removeChip(product)}>
                            <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                        
                        </div>
                      </div>
                      );
                    }
                  })} 
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
               <Grid.Column width="3">
                 <label>Argusdentalvision</label>
               <Dropdown placeholder='argus' name="argus" fluid selection options={checkAlreadyIn(argusdentalvision,allargusdentalvision)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column width="12">
               {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    if(belongsToSameDiv(product.id, allargusdentalvision)){
                      return (
                       <div className="chip" style={{backgroundColor:"#1b75bb"}} key={product.id}>
                        <div className="chip-content">{product.system_application}</div>
                        <div className="chip-close-software" onClick={()=>removeChip(product)}>
                            <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                        
                        </div>
                      </div>
                      );
                    }
                  })} 
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width="3">
                 <label>Others</label>
               <Dropdown placeholder='others' name="others" fluid selection options={checkAlreadyIn(sharepoint,allsharepoint)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column width="12">
               {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    if(belongsToSameDiv(product.id, allsharepoint)){
                      return (
                       <div className="chip" style={{backgroundColor:"#1b75bb"}} key={product.id}>
                        <div className="chip-content">{product.system_application}</div>
                        <div className="chip-close-software" onClick={()=>removeChip(product)}>
                            <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                        
                        </div>
                      </div>
                      );
                    }
                  })} 
               </Grid.Column>
            </Grid.Row>
             */}
          </Grid>
        </Form.Field>
      </Form>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  redirect: state.redirect,
  inventory: state.inventory,
});
export default connect(mapStateToProps, {
  modifyLookup,
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  setRequestedAccessModification,
})(ModifyAccess);