// needs refactoring

import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Icon,
  Modal,
  Image,
  Button
} from "semantic-ui-react";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import {
  getAllBugs,
  setBugResolved
} from "../../../Redux/Actions/ticketActions";
import ReactTooltip from "react-tooltip";
const AllBugs = ({ tickets, getAllBugs, setBugResolved }) => {
    
  const handleResolveClick = id => {
    const fd = new FormData();
    fd.append('bugID', id)
    setBugResolved(fd).then(()=>{
    });
  };
  useEffect(() => {
    getAllBugs();  
  }, [getAllBugs]);
  return (
    <Container>
      <h1>Superuser bug reports</h1>
      {tickets.bugs ? (
        tickets.bugs.map(bug => (
          <Paper vertical key={bug.id} style={{padding:"10px"}}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={5}>{bug.reporter}</Grid.Column>
                <Grid.Column width={10}>{bug.description}</Grid.Column>
                <Grid.Column width={1}>
                  <ReactTooltip />
                  <Modal
                    trigger={
                      bug.resolved ? (
                        <Icon
                          name="check circle"
                          color="green"
                          style={{ cursor: "pointer" }}
                          data-tip="Ticket Resolved"
                        />
                      ) : (
                        <Icon
                          name="check circle"
                          color="red"
                          style={{ cursor: "pointer" }}
                          data-tip="Resolve Ticket"
                        />
                      )
                    }
                    closeIcon
                  >
                    <Modal.Header>Resolve bug {bug.id} ?</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <p>{bug.reporter}</p>
                        <p>{bug.description}</p>
                        <Image src={bug.bug_attachment} />
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        basic
                        color="green"
                        onClick={(id) =>
                          handleResolveClick(bug.id)
                        }
                      >
                        Yes
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Paper>
        ))
      ) : (
        <span>There are no bugs!</span>
      )}
    </Container>
  );
};
const mapStateToProps = state => ({
  tickets: state.tickets
});
export default connect(
  mapStateToProps,
  { getAllBugs, setBugResolved }
)(AllBugs);
