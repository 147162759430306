import React, { useState } from "react";
import { Grid, Dimmer, Loader, Form, Button, Input, Modal, Image, Icon, Segment,
  Placeholder, } from "semantic-ui-react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Container } from "@material-ui/core";
import { getSingleDashboardTicket,removeTicketFromDashboard,addPostAttachment, getTicketAttachments,ReopenAndMakeTicketClone } from "../../../../../Redux/Actions/ticketActions";
import {
  addDashboardTicketComment,
  get_ITticket_comments,
} from "../../../../../Redux/Actions/commentActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

function NewITTicketModal({
  getSingleDashboardTicket,
  comments,
  auth,
  addDashboardTicketComment,
  ticket,
  get_ITticket_comments,
  match,
  removeTicketFromDashboard,
  addPostAttachment,
  attachments,
  getTicketAttachments,
  ReopenAndMakeTicketClone
}) {
  useState(() => {
    const fd = new FormData();
    fd.append("it", match.params.id);
    fd.append("ti_id", match.params.id);
    fd.append("ticket_type", "IT");
    fd.append("it_ticket_id", match.params.id);
    get_ITticket_comments(fd);
    getSingleDashboardTicket(fd);
    getTicketAttachments(fd);
  }, []);
  const propCode = "itticket";
  let itTicketMain;
  const username = auth.token.name;
  const [itComment, setItComment] = useState({
    it_comment: "",
  });
  const { it_comment } = itComment;
  const handleITCommentChange = (e) => {
    setItComment({
      ...itComment,
      it_comment: e.target.value,
    });
  };
  const handleDashboardComment = (e, id, user) => {
    const fd = new FormData();
    fd.append("it_comment", it_comment);
    fd.append("user", user);
    fd.append("commentor_id", auth.user[0].id);
    fd.append("id", id);
    fd.append("commentor", username);
    fd.append("authorEmail", ticket[0].ti_ticket_author_email)
    addDashboardTicketComment(fd).then(() => {
      setItComment({
        ...itComment,
        it_comment: "",
      });
    });
  };
  const handleRemoveFromDashboard = () => {
    const fd = new FormData();
    fd.append("ti_id",match.params.id)
    removeTicketFromDashboard(fd)
  }

  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
      });
    }
  };

  const handleItAttachmentSubmit = (id) => {
    const fd = new FormData();
    fd.append("ti_id", id);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    addPostAttachment(fd).then(() => {
      addAttachment({
        ticketAttachment_one: "",
      });
      const fd = new FormData();
    fd.append("it", match.params.id);
    fd.append("ti_id", match.params.id);
    fd.append("ticket_type", "IT");
    fd.append("it_ticket_id", match.params.id);
    get_ITticket_comments(fd);
    getSingleDashboardTicket(fd);
    getTicketAttachments(fd);      
    });
  };

  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
  });
  const { ticketAttachment_one } = picture;

  const [openAttachment, setOpenAttachment] = useState("");
  const [open, setOpen] = useState(false);

  function openPopup(attachment) {
    if(attachment.path && (attachment.path.match(/.xls/gi) || 
    attachment.path.match(/.xlsx/gi) || 
    attachment.path.match(/.doc/gi) || 
    attachment.path.match(/.pdf/gi))){
      console.log(window.location.origin+attachment.path)
      downloadFile(window.location.origin+attachment.path,attachment.path)
      return true
    }
    setOpen(true);
    setOpenAttachment(attachment.path);
  }

  function downloadFile(fileURL,filename) {
    if (
      fileURL        
    ) {
        var save = document.createElement("a");
        console.log(__dirname)
        save.href = fileURL;
        save.target = "_blank";
        save.download = filename.substring(filename.lastIndexOf("/") + 1)
        var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
    }
  }

  function markTicketAsReopen(ticket_id){
    let confirmUser = window.confirm("Are you sure want to reopen this ticket?")
    if(confirmUser){
      const fd = new FormData();
      fd.append("ticket_to_update", ticket_id);
      fd.append("user", auth.user[0].id);
      fd.append("userEmail", auth.user[0].email);
      fd.append("userName", auth.user[0].first_name+" "+auth.user[0].last_name);
      ReopenAndMakeTicketClone(fd).then(()=>{
        const fd = new FormData();
    fd.append("it", match.params.id);
    fd.append("ti_id", match.params.id);
    fd.append("ticket_type", "IT");
    fd.append("it_ticket_id", match.params.id);
    get_ITticket_comments(fd);
    getSingleDashboardTicket(fd);
    getTicketAttachments(fd);
      })
    }
  }

  if (ticket) {
    itTicketMain = (
      <Grid columns="equal" stackable>
        {ticket[0] && ticket[0].ti_admin_status === "RESOLVED" && ticket[0]['canreopen'] && <Grid.Row style={{display: "flex",flexDirection: "row-reverse",padding:"25px 250px",paddingBottom:"0px"}}>
          <Button
              type="button"
              basic
              color="red"
              onClick={()=>markTicketAsReopen(ticket[0].ti_id)}
            >
                Reopen Ticket
            </Button>
        </Grid.Row>}
        <Grid.Row>
              <Grid.Column>
                <Modal
                  open={open}
                  closeIcon
                  basic={true}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                >
                  <Modal.Content>
                  <Image src={openAttachment} alt="Attachment" fluid />
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            </Grid.Row>
        <Grid.Row style={{paddingTop:"55px"}}>
          <Grid.Column>
            <h3>Ticket ID: {ticket[0].ti_id}</h3>
          </Grid.Column>
          {/* <Grid.Column tyle={{textAlign:"right"}}>
          <Button basic color="orange" onClick={handleRemoveFromDashboard}>Remove from Dashboard</Button>
          </Grid.Column> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>
              <span style={{ fontWeight: "bold" }}>Subject: </span>
              {ticket[0].ti_subject}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Description: </span>
              {ticket[0].ti_description}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Priority: </span>
              {ticket[0].ti_priority}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Assigned To: </span>
              {ticket[0].ticket_assigned_to}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              <span style={{ fontWeight: "bold" }}>Type: </span>
              {ticket[0].ti_type}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Status: </span>
              {ticket[0].ti_admin_status}
            </p>
            {ticket[0].ti_admin_status &&
            ticket[0].ti_admin_status === "RESOLVED" ? (
              <p>
                <span style={{ fontWeight: "bold" }}>Resolved By: </span>
                {ticket[0].resolved_first} {ticket[0].resolved_last}
              </p>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        {ticket[0].admin_path ? (
          <Grid.Row>
            <Grid.Column>
              <Modal
                trigger={<Button basic color="blue">View Attachment</Button>}
                closeIcon
                basic={true}
              >
                <Modal.Content>
                {ticket[0].admin_path.split(".").pop() === "jpg" ||
                          ticket[0].admin_path.split(".").pop() === "png" ? (
                            <Image
                              src={ticket[0].admin_path}
                              alt="Attachment"
                              fluid
                            />
                          ) : ticket[0].admin_path.split(".").pop() === "pdf" ? (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <a href={ticket[0].admin_path} target="_blank">
                                <Icon name="file pdf" size="massive" />
                                <p>Click to download PDF</p>
                              </a>
                            </div>
                          ) : ticket[0].admin_path.split(".").pop() === "doc" ||
                            ticket[0].admin_path.split(".").pop() === "docx" ||
                            ticket[0].admin_path.split(".").pop() === "docm" ||
                            ticket[0].admin_path.split(".").pop() === "txt" ||
                            ticket[0].admin_path.split(".").pop() === "wps" ? (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <a href={ticket[0].admin_path} target="_blank">
                                <Icon name="file word" size="massive" />
                                <p>Download Text File</p>
                              </a>
                            </div>
                          ) : ticket[0].admin_path.split(".").pop() === "xlsx" ||
                            ticket[0].admin_path.split(".").pop() === "xlsm" ||
                            ticket[0].admin_path.split(".").pop() === "docm" ||
                            ticket[0].admin_path.split(".").pop() === "xlsb" ||
                            ticket[0].admin_path.split(".").pop() === "xltx" ||
                            ticket[0].admin_path.split(".").pop() === "xltm" ||
                            ticket[0].admin_path.split(".").pop() === "xls" ||
                            ticket[0].admin_path.split(".").pop() === "xlt" ||
                            ticket[0].admin_path.split(".").pop() === "xml" ||
                            ticket[0].admin_path.split(".").pop() === "xlam" ||
                            ticket[0].admin_path.split(".").pop() === "xla" ||
                            ticket[0].admin_path.split(".").pop() === "xlw" ||
                            ticket[0].admin_path.split(".").pop() === "xlr" ? (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <Icon name="file excel" size="massive" />
                              <p>Download Excel File</p>
                            </div>
                          ) : null}




                  {/* <Image src={ticket[0].admin_path} alt="Attachment" fluid /> */}
                </Modal.Content>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            {!attachments ? (
              <Segment>
                <Dimmer active>
                  <Loader inverted>Loading Attachments</Loader>
                </Dimmer>
                <Placeholder style={{ height: 50, width: 50 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Segment>
            ) : attachments.length === 0 ? (
              <div>No Attachments</div>
            ) : (
              <div style={{ margin: "5px" }}>
                    <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                    <Grid stackable>
                      <Grid.Row>
                        {attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "120px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                Attachment {index+1}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
          <label
                        style={{ textAlign: "left" }}
                        className="colorBrandColor"
                      >
                        Attachment:{" "}
                      </label>

                      <FilePond
                        maxFiles={1}
                        files={ticketAttachment_one}
                        allowMultiple={false}
                        allowImagePreview="false"
                        onupdatefiles={(fileItems) =>
                          handleFilepondUpdate(fileItems)
                        }
                      />
                      <Button
                        style={{ float: "right", cursor: "pointer" }}
                        type="button"
                        basic
                        color="orange"
                        onClick={(id) =>
                          handleItAttachmentSubmit(ticket[0].ti_id)
                        }
                      >
                        Attachment Upload
                      </Button>
          </Grid.Column>
          </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {comments.itComments && comments.itComments.length > 0 ? (
              <div
                style={{ borderTop: "2px solid #1B75BB", paddingTop: "10px" }}
              >
                <h3>Comments:</h3>
                {comments.itComments.map((comment) => (
                  <span key={comment.ti_id}>
                    <p style={{ textAlign: "left" }}>
                      <span style={{ fontWeight: "bold" }}>
                        {comment.commentor}:
                      </span>{" "}
                      {comment.comment}
                      <span style={{ float: "right" }}>
                        <Moment
                          date={comment.comment_time}
                          format="MM/DD/YY LTS"
                        />
                      </span>
                    </p>
                    <hr />
                  </span>
                ))}
              </div>
            ) : null}
            <Form
              onSubmit={(e, id, user) =>
                handleDashboardComment(
                  e,
                  ticket[0].ti_id,
                  comments.itComments && comments.itComments.length > 0
                    ? comments.itComments[comments.itComments.length - 1]
                        .commentor_id
                    : ticket[0].ti_users_id
                )
              }
            >
              <Form.Field>
                {ticket[0].ti_admin_status === "RESOLVED" ? (
                  <h4>Comments cannot be added after ticket is resolved.</h4>
                ) : (
                  <Input
                    type="text"
                    name="itComment"
                    value={it_comment}
                    id="Comment"
                    onChange={handleITCommentChange}
                  />
                )}
              </Form.Field>
              <Form.Field>
                <Button
                  type="submit"
                  basic
                  color="green"
                  disabled={ticket[0].ti_admin_status === "RESOLVED"}
                >
                  Add Comment
                </Button>
              </Form.Field>
            </Form>
            
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  } else {
    itTicketMain = (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }

  return <Container maxWidth="lg">{itTicketMain}</Container>;
}
const mapStateToProps = (state) => ({
  comments: state.comments,
  auth: state.auth,
  ticket: state.dashboard_tickets.dashboard_tickets,
  attachments: state.tickets.attachments,
});
export default connect(mapStateToProps, {
  getSingleDashboardTicket,
  addDashboardTicketComment,
  get_ITticket_comments,
  removeTicketFromDashboard,
  addPostAttachment,
  getTicketAttachments,
  ReopenAndMakeTicketClone
})(NewITTicketModal);