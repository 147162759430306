import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";


export const adalConfig = {
  tenant: "0f33f4b2-c599-48f7-bd82-3352c3dda453",
  clientId: "0131c48e-6f05-4197-948e-787bb4b1ee18",
  endpoints: {
    api: "https://graph.microsoft.com"
  },
  cacheLocation: "localStorage",
  redirectUri: process.env.REACT_APP_ADAL_URI
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
