import React, { useState } from "react";
import { Modal, Form, TextArea, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { addBug } from "../../../Redux/Actions/ticketActions";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
registerPlugin(FilePondPluginImagePreview);

const AddBug = ({ addBug, props }) => {
  const [bug, setBug] = useState({
    bugDescription: ""
  });
  const [picture, addAttachment] = useState({
    bugAttachment: ""
  });
  const onChange = e => {
    setBug({
      ...bug,
      [e.target.name]: e.target.value
    });
  };
  const { bugAttachment } = picture;
  const onSubmit = e => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("email", props.user[0].email);
    fd.append("bugDescription", bugDescription);
    fd.append("bugAttachment", bugAttachment);
    addBug(fd).then(() => {
      setBug({
        bugDescription: ""
      });  
      addAttachment({
        ...picture,
        bugAttachment: ""
      });   
    });
  };
  const handleFilepondUpdate = fileItems => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        bugAttachment: null
      });
    } else {
      addAttachment({
        ...picture,
        bugAttachment: fileItems[0].file
      });   
    }
  };

  const { bugDescription } = bug;
  return (
    <Modal.Content>
      <Form onSubmit={e => onSubmit(e)}>
        <Form.Field>
          <TextArea
            onChange={onChange}
            value={bugDescription}
            name="bugDescription"
          />
        </Form.Field>
        <Form.Field>
          <FilePond 
           files={bugAttachment}         
            onupdatefiles={fileItems => handleFilepondUpdate(fileItems)}            
          />
        </Form.Field>
        <Form.Field>
          <Button type="submit" basic color="green">
            Submit
          </Button>
        </Form.Field>
      </Form>
    </Modal.Content>
  );
};

export default connect(null, { addBug })(AddBug);
