import React, { useCallback, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {useReactToPrint} from 'react-to-print';
import {
  Grid,
  Dimmer,
  Loader,
  Modal,
  Image,
  Segment,
  Placeholder,
  Form,
  Input,
  Button,
  TextArea,
  Dropdown,
  Container
} from "semantic-ui-react";
import { getSingleIntakeForm, modifyLookup,updateIntakeForm } from "../../../Redux/Actions/accessRequestActions";
import { throttle } from "lodash";
import { Redirect } from "react-router-dom";
import {
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  setRequestedAccessModification,
} from "../../../Redux/Actions/inventoryActions";
function IntakeCompilanceForm({
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  auth,
  redirect,
  inventory,
  setRequestedAccessModification,
  updateIntakeForm,
  accessRequests,
  match,
  getSingleIntakeForm
}) {
  useEffect(() => {
      console.log("1",match.params.id)
      getSingleIntakeForm(match.params.id)
  }, []);
 
  const [moveBack, setmoveBack] = useState(false)
  

  const [checkBoxArr1, setcheckBoxArr1] = useState([
    {
      label: "Debarment Screening Completed?",
    },
    {
      label: "Exclusion Screening Completed?",
    },
    {
      label: "Is the vendor an FDR?",
    },
    {
      label: "If FDR, has Code of Conduct been sent?",
    },
    {
      label: "Was an entry made the in the Contracts Library for the vendor?",
    },
  ]);

  const componentRef = useRef()

   const [processing, setProcessing] = useState(false)

  const userChecked1 = (index, value) => {
    let tempArr = JSON.parse(JSON.stringify(checkBoxArr1));
    tempArr[index]["value"] = value;
    setcheckBoxArr1(tempArr);
  };

  const handleSubmit = (e) => {
      e.preventDefault()
      if(!(checkBoxArr1)){
        return alert("All Fields are Mandatory")
      }
      if(JSON.parse(JSON.stringify(checkBoxArr1)).map(x=>x['value']).filter(Boolean).length === 0){
        return alert("Please Fill At least on Value From Table")
      }
      const formData = new FormData()
    formData.append("checkBoxArr1", JSON.stringify(checkBoxArr1));
    formData.append("id", accessRequests.intakeForm.id);
    formData.append("userId", auth.user[0].id);
    setProcessing(true)
    updateIntakeForm(formData)
    .then(()=>{
      if(JSON.parse(JSON.stringify(checkBoxArr1)).map(x=>x['value']).filter(Boolean).length === JSON.parse(JSON.stringify(checkBoxArr1)).length){
        setmoveBack(true)
      }else{
        getSingleIntakeForm(match.params.id)
      setProcessing(false)
      }
        //  setcheckBoxArr1([
        //     {
        //       label: "Debarment Screening Completed?",
        //     },
        //     {
        //       label: "Exclusion Screening Completed?",
        //     },
        //     {
        //       label: "Is the vendor an FDR?",
        //     },
        //     {
        //       label: "If FDR, has Code of Conduct been sent?",
        //     },
        //     {
        //       label: "Was an entry made the in the Contracts Library for the vendor?",
        //     },
        //   ])
    })
  };

  console.log(accessRequests.intakeForm)

  const handleDownload = useReactToPrint({
    content:() => componentRef.current
  })

  const [openAttachment, setOpenAttachment] = useState("");
  const [open, setOpen] = useState(false);

  function openPopup(attachment) {
    if(attachment.path && (attachment.path.match(/.xls/gi) || 
    attachment.path.match(/.xlsx/gi) || 
    attachment.path.match(/.doc/gi) || 
    attachment.path.match(/.pdf/gi))){
      downloadFile(window.location.origin+attachment.path,attachment.name)
      return true
    }
    setOpen(true);
    setOpenAttachment(attachment.path);
  }

  function downloadFile(fileURL,filename) {
    if (
      fileURL        
    ) {
      console.log("fileURL",fileURL)
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        save.download = filename
        var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
    }
  }

  const generateHTML = () => {
    let {cnc_nc_requestername,todayDate,dateServiceToBegin,contractProperty,criticalDate,fdrType,serviceRequire, vendorContactName, vendorContactEmail, vendorLegalName, vendorFunctions,serviceCharge,checkBoxArr} = accessRequests.intakeForm

    let table1 = ''
    checkBoxArr.forEach(x=>{
      table1 += `<tr><td>${x['label']}</td><td>${x['value']?x['value']:""}</td></tr>`
    })

    let table2 = ''
    checkBoxArr1.forEach(x=>{
      table2 += `<tr><td>${x['label']}</td><td>${x['value']?x['value']:""}</td></tr>`
    })

      let temphtml = `<html>
  <head>
      <style>
          * {
  font-size: 13px;
  }

  .mainContainer {
  margin-top: 10px;
  padding: 0px 10px;
  }

  .topHeading {
  padding: 0px 200px;
  text-align: center;
  }

  .topContent {
  padding: 0px 40px;
  }

  label {
  font-weight: bold;
  }

  .flex33 {
  flex-basis: 33.333333%;
  }

  .flex10 {
  flex-basis: 10%;
  }

  input {
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  }

  select {
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  background: #fff;
  width: 200px;
  }

  textarea {
  resize: none;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  background: #fff;
  width: 100%;
  height: 80px;
  }

  table,
  th,
  td {
  border: solid 1px #000;
  padding: 10px;
  }

  table {
  border-collapse: collapse;
  caption-side: bottom;
  }

  .thirdPage {
  padding: 0px 180px;
  text-align: center;
  }

  .buttonSubmit {
  text-align: center;
  cursor: pointer;
  font-size: 22px;
  color: white;
  padding: 9px 35px;
  outline: none;
  background-color: #27ae60;
  border: none;
  margin-top: 10px;
  border-radius: 5px;
  }

  .buttonSubmit:hover {
  background-color: #2ecc71;
  }

  .buttonSubmit:active {
  background-color: #2ecc71;
  transform: translateY(4px);
  }

  table{
  width: 92%;
  margin:unset !important;
  }

  hr{
    width: 95%;
  }
      </style>
  </head>
  <body>
      <div class="mainContainer">
          <div class="topHeading">
            Care N’ Care Insurance Company of North Carolina (“CNC-NC”), Inc.
            Contract Request Intake Form (v. 3.0)
          </div>
          <hr />
          <div class="topContent">
            <h3>Background</h3>
            <p>
              The purpose of the Contract Request Intake Form is to streamline the
              contracting process to ensure business areas have external vendor
              resources to support tactical and strategic goals of CNC-NC. Every
              contract request must include this completed document, along with the
              contract documents.
            </p>
            <p>
              <b>
                Dollar Amount: If a contract totals less than $10,000, after adding
                all costs over the life of the contract, once this Form is completed
                and the requesting party has been notified that the exclusion and/or
                preclusion checks are completed satisfactorily, Compliance does not
                need to review the contract any further, unless the requesting party
                asks Compliance to review the contract.
              </b>
              Reasons that the requesting party may want Compliance to review a
              contract would be if the contract includes legal language or items the
              requesting party does not understand.
            </p>
            <p>
              <b>Two Types of Vendors:</b> There are two types of vendors: vendors
              that are <b style='text-decoration: underline'>NOT</b> designated
              as first-tier, downstream or related (FDR) entities and those are
              designated as an FDR.
            </p>
            <p>
              In general, vendors that are
              <b style='text-decoration: underline'>NOT</b> FDRs include
              restaurants, office supplies, member giveaways, paper shredding
              vendors, computer hardware and most software, rental car companies,
              furniture suppliers, event sponsors, trophy and awards vendors,
              construction and repair vendors, and organizations that provide
              training and videos. This is
              <b style='text-decoration: underline'>NOT</b> an exhaustive list.
            </p>
            <p>
              In general, vendors that
              <b style='text-decoration: underline'>ARE</b> FDRs have direct
              contact with our members via in-person engagements, telephone,
              surveys, or sales agents taking enrollment applications. FDRs may have
              member data (i.e., enrollment and clinical information) that is used
              to perform a function that would otherwise be conducted by CNC-NC.
              FDRs usually make decisions on behalf of CNC-NC and perform functions
              that CNC-NC is required to undertake based on our agreement with CMS.
              For example, Beacon processes Part C medical claims and enrollment
              applications, which makes it an FDR.
            </p>
            <p>
              All fields
              <b style='text-decoration: underline'>must be completed</b> prior
              to submission to:
              <b style='text-decoration: underline;color:blue'>
                Contracts@healthteamadvantage.com
              </b>
              . Please also include the proposed vendor agreement, statement of work
              or terms and conditions.
            </p>
            <p>
              <b>Required Information</b>
            </p>
            <div style='display: flex'>
              <div class="flex33">
                <label>CNC-NC Requestor Name</label>
              </div>
              <div class="flex33">
                <label>Today’s Date</label>
              </div>
              <div class="flex33">
                <label>Date Service to Begin</label>
              </div>
            </div>
            <div style='display: flex; margin-top: 10px'>
              <div class="flex33">
                ${cnc_nc_requestername}
              </div>
              <div class="flex33">
                ${todayDate}
              </div>
              <div class="flex33">
                ${dateServiceToBegin}
              </div>
            </div>
            <div style='display: flex; margin-top: 10px'>
              <div class="flex33">
                <label>Contract Property</label>
              </div>
              <div class="flex33">
                <label>
                  Critical Date(s) Before
                  <br />
                  Service to Begin
                </label>
              </div>
              <div class="flex33">
                <label>FDR/Non-FDR</label>
              </div>
            </div>
            <div style='display: flex; margin-top: 10px'>
              <div class="flex33">
                ${contractProperty}
              </div>
              <div class="flex33">
                ${criticalDate}
              </div>
              <div class="flex33">
                ${fdrType}
              </div>
            </div>
            <br/>
            <div style='display: flex'>
            <div class="flex33">
              <label>Will Service Require IT Review</label>
            </div>
            <div class="flex33">
              <label>Vendor Contact Name</label>
            </div>
            <div class="flex33">
              <label>Vendor Contact Email</label>
            </div>
          </div>
          
          
          <div style='display: flex; margin-top: 10px'>
            <div class="flex33">
              ${serviceRequire}
            </div>
            <div class="flex33">
              ${vendorContactName}
            </div>
            <div class="flex33">
              ${vendorContactEmail}
            </div>
          </div>
          <div style='margin-top: 10px'>
            <label>Vendor Full Legal Name and Mailing Address</label>
          </div>
          <div style='margin-top: 10px'>
            ${vendorLegalName}
          </div>
          <div style='margin-top: 10px'>
            <label>
              Briefly describe the function(s) to be performed by the vendor.
            </label>
          </div>
          <div style='margin-top: 10px'>
            ${vendorFunctions}
          </div>
          </div>
          
          <br />
          <hr />
          <p>CNC-NC Confidential</p>
          <br />
          <br />
          <div class="topHeading">
            Care N’ Care Insurance Company of North Carolina (“CNC-NC”), Inc.
            Contract Request Intake Form (v. 3.0)
          </div>
          <hr />
          <div class="">
            <div>
              <label>Does this service or product exceed $10,000?</label> : ${serviceCharge}
            </div>
            <br />
            <p>
              Please complete the following survey questions regarding the proposed
              vendor’s services.
            </p>
            <br />
          </div>
          <table>
            <thead>
              <tr>
                <th>Vendor will ...</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              ${table1}
            </tbody>
          </table>
            <h3>COMPLIANCE STAFF ONLY – COMPLETE</h3>
            <table>
              <thead>
                <tr>
                  <th>Vendor</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
              ${table2}
              </tbody>
            </table>
        </div>
        <br />
        <hr />
        <p>CNC-NC Confidential</p>
      </body>
      </html>`

  return temphtml
  }

  const [setKey, setSetKey] = useState(false)
  if(!setKey && accessRequests.intakeForm && accessRequests.intakeForm.checkBoxArr1){
    setcheckBoxArr1(accessRequests.intakeForm.checkBoxArr1)
    setSetKey(true)
  }

  if(moveBack){
    return <Redirect to="/intake-forms-requests" />
  }

  return (
    <>
    <Modal
                  open={open}
                  closeIcon
                  basic={true}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                >
                  <Modal.Content>
                  <Image src={openAttachment} alt="Attachment" fluid />
                  </Modal.Content>
                </Modal>
    {processing && <Container style={{marginTop:"10px", padding:"auto 10px",textAlign:"center"}}>
    <h1>Submitting, Please Wait ...</h1>
    </Container>}
    {accessRequests.intakeForm && <div style={{display:"none"}}>
      <div ref={componentRef} id="newHTML" dangerouslySetInnerHTML={{__html:generateHTML()}}></div>
      </div>}
    {!processing && accessRequests.intakeForm && <Container id='newHTML' style={{marginTop:"10px", padding:"auto 10px"}}>
      <h1>Intake Form</h1>
      <div className="mainContainer">
      <div className="topContent">
        <h3>Background</h3>
        <p>
          The purpose of the Contract Request intake form is to streamline the
          contracting process to ensure business areas have external vendor
          resources to support tactical and strategic goals of CNC-NC. Every
          contract request must include this completed document, along with the
          contract documents.
        </p>
        <p>
          <b>
            Dollar Amount: If a contract totals less than $10,000, after adding
            all costs over the life of the contract, once this Form is completed
            and the requesting party has been notified that the exclusion and/or
            preclusion checks are completed satisfactorily, Compliance does not
            need to review the contract any further, unless the requesting party
            asks Compliance to review the contract.
          </b>
          Reasons that the requesting party may want Compliance to review a
          contract would be if the contract includes legal language or items the
          requesting party does not understand.
        </p>
        <p>
          <b>Two Types of Vendors:</b> There are two types of vendors: vendors
          that are <b style={{ textDecoration: "underline" }}>NOT</b> designated
          as first-tier, downstream or related (FDR) entities and those are
          designated as an FDR.
        </p>
        <p>
          In general, vendors that are{" "}
          <b style={{ textDecoration: "underline" }}>NOT</b> FDRs include
          restaurants, office supplies, member giveaways, paper shredding
          vendors, computer hardware and most software, rental car companies,
          furniture suppliers, event sponsors, trophy and awards vendors,
          construction and repair vendors, and organizations that provide
          training and videos. This is{" "}
          <b style={{ textDecoration: "underline" }}>NOT</b> an exhaustive list.
        </p>
        <p>
          In general, vendors that{" "}
          <b style={{ textDecoration: "underline" }}>ARE</b> FDRs have direct
          contact with our members via in-person engagements, telephone,
          surveys, or sales agents taking enrollment applications. FDRs may have
          member data (i.e., enrollment and clinical information) that is used
          to perform a function that would otherwise be conducted by CNC-NC.
          FDRs usually make decisions on behalf of CNC-NC and perform functions
          that CNC-NC is required to undertake based on our agreement with CMS.
          For example, Beacon processes Part C medical claims and enrollment
          applications, which makes it an FDR.
        </p>
        <p>
          All fields{" "}
          <b style={{ textDecoration: "underline" }}>must be completed</b> prior
          to submission to:{" "}
          <b style={{ textDecoration: "underline", color: "blue" }}>
            Contracts@healthteamadvantage.com
          </b>
          . Please also include the proposed vendor agreement, statement of work
          or terms and conditions.
        </p>
        <p>
          <b>Required Information</b>
        </p>
        <div style={{ display: "flex" }}>
          <div className="flex33">
            <label className='label-new'>CNC-NC Requestor Name</label>
          </div>
          <div className="flex33">
            <label className='label-new'>Today’s Date</label>
          </div>
          <div className="flex33">
            <label className='label-new'>Date Service to Begin</label>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className="flex33">
            {accessRequests.intakeForm.cnc_nc_requestername}
          </div>
          <div className="flex33">
            {accessRequests.intakeForm.todayDate}
          </div>
          <div className="flex33">
            {accessRequests.intakeForm.dateServiceToBegin}
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className="flex33">
            <label className='label-new'>Contract Property</label>
          </div>
          <div className="flex33">
            <label className='label-new'>
              Critical Date(s) Before
              <br />
              Service to Begin
            </label>
          </div>
          <div className="flex33">
            <label className='label-new'>FDR/Non-FDR</label>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className="flex33">
            {accessRequests.intakeForm.contractProperty}
          </div>
          <div className="flex33">
            {accessRequests.intakeForm.criticalDate}
          </div>
          <div className="flex33">
            {accessRequests.intakeForm.fdrType}
          </div>
        </div>
        <div style={{ display: "flex" }}>
        <div className="flex33">
          <label className='label-new'>Will Service Require IT Review</label>
        </div>
        <div className="flex33">
          <label className='label-new'>Vendor Contact Name</label>
        </div>
        <div className="flex33">
          <label className='label-new'>Vendor Contact Email</label>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div className="flex33">
          {accessRequests.intakeForm.serviceRequire}
        </div>
        <div className="flex33">
          {accessRequests.intakeForm.vendorContactName}
        </div>
        <div className="flex33">
          {accessRequests.intakeForm.vendorContactEmail}
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <label className='label-new'>Vendor Full Legal Name and Mailing Address</label>
      </div>
      <div style={{ marginTop: "10px" }}>
        {accessRequests.intakeForm.vendorLegalName}
      </div>
      <div style={{ marginTop: "10px" }}>
        <label className='label-new'>
          Briefly describe the function(s) to be performed by the vendor.
        </label>
      </div>
      <div style={{ marginTop: "10px" }}>
        {accessRequests.intakeForm.vendorFunctions}
      </div>
      </div>
      
      <br />
      
      
     
      <div className="thirdPage">
        <div>
          <label className='label-new'>Does this service or product exceed $10,000?</label>
        </div>
        <div style={{ textAlign:"center", padding: "0px 140px" }}>
          {accessRequests.intakeForm.serviceCharge}
        </div>
        <br/>
        <p>
          Please complete the following survey questions regarding the proposed
          vendor’s services.
        </p>
        <table className="table-new">
          <thead>
            <tr>
              <th>Vendor will ...</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {accessRequests.intakeForm.checkBoxArr &&
              accessRequests.intakeForm.checkBoxArr.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.label}</td>
                    <td>{x.value}</td>
                    </tr>
                );
              })}
          </tbody>
        </table>
        <Grid.Row>
          <Grid.Column>
            {!accessRequests.intakeForm.attachments ? (
              <Segment>
                <Dimmer active>
                  <Loader inverted>Loading Attachments</Loader>
                </Dimmer>
                <Placeholder style={{ height: 50, width: 50 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Segment>
            ) : accessRequests.intakeForm.attachments.length === 0 ? (
              <div>No Attachments</div>
            ) : (
              <div style={{ margin: "5px" }}>
                    <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                    <Grid stackable>
                      <Grid.Row style={{flexDirection:"column",textAlign:"center"}}>
                        {accessRequests.intakeForm.attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "100%",margin:"5px 0px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  fontSize:"14px"
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                {/* Attachment {index+1} */}
                                {attachment.name}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <h3>COMPLIANCE STAFF ONLY – COMPLETE</h3>
        <table className="table-new">
          <thead>
            <tr>
              <th>Vendor</th>
              <th>Yes</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            {checkBoxArr1 &&
              checkBoxArr1.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.label}</td>
                    <td>
                      <input
                        type="radio"
                        value='Yes'
                        onChange={(e) => userChecked1(i, "Yes")}
                        name={i}
                        checked={x['value'] === 'Yes'}
                        disabled={accessRequests.intakeForm.status !== 'Review'}
                      />{" "}
                      Yes
                    </td>
                    <td>
                      <input 
                        type="radio"
                        value='No'
                        onChange={(e) => userChecked1(i, "No")}
                        name={i}
                        checked={x['value'] === 'No'}
                        disabled={accessRequests.intakeForm.status !== 'Review'}
                      />{" "}
                      No
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
         {accessRequests.intakeForm.status === 'Review' && <button
          type="button"
          className="buttonSubmit"
          style={{background:JSON.parse(JSON.stringify(checkBoxArr1)).map(x=>x['value']).filter(Boolean).length === JSON.parse(JSON.stringify(checkBoxArr1)).length ? "green" :"linear-gradient(to bottom right, #55b4c8, #55b4c8)"}}
          onClick={(e) => handleSubmit(e)}
        >
          {
            JSON.parse(JSON.stringify(checkBoxArr1)).map(x=>x['value']).filter(Boolean).length === JSON.parse(JSON.stringify(checkBoxArr1)).length ? "Complete" : "Save"
          }
        </button>}
        <button
          type="button"
          className="buttonSubmit"
          style={{background:"linear-gradient(to bottom right, #90278e, #90278e)",marginLeft:"10px"}}
          onClick={handleDownload}
        >
          Print & Download PDF
        </button>
        <br />
        <br />
      </div>
    </div>
    </Container>}
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  redirect: state.redirect,
  inventory: state.inventory,
  accessRequests:state.accessRequests
});
export default connect(mapStateToProps, {
  modifyLookup,
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  setRequestedAccessModification,
  updateIntakeForm,
  getSingleIntakeForm
})(IntakeCompilanceForm);