export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT = "SET_ALERT";
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const NEW_TICKET_SUCCESS = "NEW_TICKET_SUCCESS";
export const NEW_TICKET_FAILURE = "NEW_TICKET_FAILURE";
export const NEW_TICKET_MESSAGE_REMOVE = "NEW_TICKET_MESSAGE_REMOVE";
export const GET_ADMIN_TICKETS = "GET_ADMIN_TICKETS";
export const ADMIN_TICKET_FALIURE = "ADMIN_TICKET_FALIURE";
export const CLEAR_ADMIN_TICKET_SEARCH = "CLEAR_ADMIN_TICKET_SEARCH";
export const ADMIN_ADD_TICKET_COMMENTS = "ADMIN_ADD_TICKET_COMMENTS";
export const GET_ALL_USER_TICKETS = "GET_ALL_USER_TICKETS";
export const SEND_BUG = "SEND_BUG";
export const ADD_SUGGESTION = "ADD_SUGGESTION";
export const GET_BUGS = "GET_BUGS";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const FORM_OK = "FORM_OK";
export const FORM_NOT_OK = "FORM_NOT_OK";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const NEW_HIRE_ACCESS_REQUEST = "NEW_HIRE_ACCESS_REQUEST";
export const GET_ALL_PENDING_ACCESS_REQUESTS =
  "GET_ALL_PENDING_ACCESS_REQUESTS";
export const MICROSOFT_LOADED = "MICROSOFT_LOADED";
export const GET_ALL_PENDING_DATAOPS = "GET_ALL_PENDING_DATAOPS";
export const GET_MICROSOFT_GRAPH_DATA = "GET_MICROSOFT_GRAPH_DATA";

export const GET_ALL_DATA_COMMENTS = "GET_ALL_DATA_COMMENTS";
export const CHANGE_DATAOPS_ASSIGNED_TO = "CHANGE_DATAOPS_ASSIGNED_TO";
export const CHANGE_DATAOPS_STATUS = "CHANGE_DATAOPS_STATUS";
export const GET_ALL_IT_COMMENTS = "GET_ALL_IT_COMMENTS";
export const START_NEW_HIRE_FORM = "START_NEW_HIRE_FORM";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_MANAGER_PENDING_ACCESS_REQUEST =
  "GET_MANAGER_PENDING_ACCESS_REQUEST";
export const GET_AR_COMMENTS = "GET_AR_COMMENTS";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const ADMIN_UPDATE = "ADMIN_UPDATE";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const GET_INVENTORY = "GET_INVENTORY";
export const ACCRESS_REQUEST_LOOKUP = "ACCRESS_REQUEST_LOOKUP";
export const GET_ALL_DASHBOARD_TICKETS = "GET_ALL_DASHBOARD_TICKETS";
export const RESET_COMMENTS = "RESET_COMMENTS";
export const LOAD_ME_MICROSOFT = "LOAD_ME_MICROSOFT";
export const LOAD_MY_MANAGER_MICROSOFT = "LOAD_MY_MANAGER_MICROSOFT";
export const GET_MY_PHOTO = "GET_MY_PHOTO";
export const RESET_PROFILE_SEARCH = "RESET_PROFILE_SEARCH";
export const GET_USER_PROFILE_SUGGESTIONS = "GET_USER_PROFILE_SUGGESTIONS";
export const CLEAR_ALL_LOOKUP_DATA = "CLEAR_ALL_LOOKUP_DATA";
export const GET_SOFTWARE_SUGGESTIONS = "GET_SOFTWARE_SUGGESTIONS";
export const SET_SOFTWARE = "SET_SOFTWARE";
export const CLEAR_SOFTWARE_SUGGESTIONS = "CLEAR_SOFTWARE_SUGGESTIONS";
export const REMOVE_SELECTED_SOFTWARE = "REMOVE_SELECTED_SOFTWARE";
export const GET_PRODUCT_FROM_DATABASE = "GET_PRODUCT_FROM_DATABASE";
export const GET_ALL_INVENTORY = "GET_ALL_INVENTORY";
export const MODIFY_INVENTORY = "MODIFY_INVENTORY";
export const GET_USERS_FOR_INVENTORY = "GET_USERS_FOR_INVENTORY";
export const CLEAR_GET_USERS_FOR_INVENTORY = "CLEAR_GET_USERS_FOR_INVENTORY";
export const CLEAR_USERS_FOR_TERMINATION = "CLEAR_USERS_FOR_TERMINATION";
export const GET_USERS_FOR_TERMINATION = "GET_USERS_FOR_TERMINATION";
export const SELECT_USER_FOR_TERMINATION = "SELECT_USER_FOR_TERMINATION";
export const CLEAR_ALL_TERMINATION = "CLEAR_ALL_TERMINATION";
export const GET_IT_NEWHIRE_REQUEST = "CLEAR_ALL_TERMINATION";

export const GET_ALL_TICKETS_FOR_REPORTING = "GET_ALL_TICKETS_FOR_REPORTING";
export const ADD_COMMENT = "ADD_COMMENT";
export const GET_COMMENT = "GET_COMMENT";
export const CLEAR_ALL_INVENTORY = "CLEAR_ALL_INVENTORY";

export const GET_TICKET_ATTACHMENTS = "GET_TICKET_ATTACHMENTS";
export const GET_DATA_TICKET_ATTACHMENTS = "GET_DATA_TICKET_ATTACHMENTS";
export const CLEAR_DATA_TICKET_ATTACHMENTS = "CLEAR_DATA_TICKET_ATTACHMENTS";

export const CLEAR_TICKET_ATTACHMENTS = "CLEAR_TICKET_ATTACHMENTS";
export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const FORM_DATA_PROCESS = "FORM_DATA_PROCESS";
export const GET_SINGLE_DASHBOARD_DATA_OP = "GET_SINGLE_DASHBOARD_DATA_OP";
export const REMOVE_FROM_USER_DASHBOARD = "REMOVE_FROM_USER_DASHBOARD";
// all it users
export const GET_ALL_IT_USERS = "GET_ALL_IT_USERS";
export const CHANGE_TICKETS_ASSIGNED_TO = "CHANGE_TICKETS_ASSIGNED_TO";

// added Malik start //
export const CLEAR_ADMIN_DR_SEARCH = "CLEAR_ADMIN_DR_SEARCH";
export const GET_ALL_DR_USERS = "GET_ALL_DR_USERS";
export const GET_ALL_USERS_FOR_INVENTORY = "GET_ALL_USERS_FOR_INVENTORY";
export const GET_ALL_ASSIGNED_DASHBOARD_TICKETS =
  "GET_ALL_ASSIGNED_DASHBOARD_TICKETS";
export const GET_SINGLE_PENDING_DATAOPS = "GET_SINGLE_PENDING_DATAOPS";
export const GET_ADMIN_TICKETS_SINGLE = "GET_ADMIN_TICKETS_SINGLE";
export const GET_DATA_TICKET_ADMIN_ATTACHMENTS =
  "GET_DATA_TICKET_ADMIN_ATTACHMENTS";
export const GET_IT_TICKET_ADMIN_ATTACHMENTS =
  "GET_IT_TICKET_ADMIN_ATTACHMENTS";
export const REPORTING_DATA = "REPORTING_DATA";
export const CLEAR_SELECTED_SOFTWARE = "CLEAR_SELECTED_SOFTWARE";
export const SET_NO_MESSAGE = "SET_NO_MESSAGE";
export const GET_ALL_DROPDOWN_MODELS = "GET_ALL_DROPDOWN_MODELS";
export const GET_DROPDOWN_LIST = "GET_DROPDOWN_LIST";
export const GET_HR_LIST = "GET_HR_LIST";
export const GET_ALL_INVENTORY_COMMENTS = "GET_ALL_INVENTORY_COMMENTS";
export const INVENTORY_REPORTING_DATA = "INVENTORY_REPORTING_DATA";
export const ADD_STATUS_COMMENT = "ADD_STATUS_COMMENT";
export const GET_ALL_IT_STATUS_COMMENTS = "GET_ALL_IT_STATUS_COMMENTS";
export const GET_ALL_INTAKE_REQUESTS = "GET_ALL_INTAKE_REQUESTS";
export const GET_SINGLE_INTAKE = "GET_SINGLE_INTAKE";
export const ADD_SYSTEM_PRODUCTS = "ADD_SYSTEM_PRODUCTS";
export const GET_ACCESS_BY_USER = "GET_ACCESS_BY_USER";


export const REMOVE_ACCESS_FROM_USER_REQUEST =
  "REMOVE_ACCESS_FROM_USER_REQUEST";
export const REMOVE_ACCESS_FROM_USER_SUCESS = "REMOVE_ACCESS_FROM_USER_SUCESS";
export const REMOVE_ACCESS_FROM_USER_FAILURE =
  "REMOVE_ACCESS_FROM_USER_FAILURE";


  
export const GET_CHANGED_EMPLOYEE_REQUEST = "GET_CHANGED_EMPLOYEE_REQUEST";
export const GET_CHANGED_EMPLOYEE_SUCESS = "GET_CHANGED_EMPLOYEE_SUCESS";
export const GET_CHANGED_EMPLOYEE_FAILED = "GET_CHANGED_EMPLOYEE_FAILED";



export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCESS = "UPDATE_EMPLOYEE_SUCESS";
export const UPDATE_EMPLOYEE_FAILED = "UPDATE_EMPLOYEE_FAILED";

// added Malik end //
