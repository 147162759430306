import {
  GET_ALL_DATA_COMMENTS,
  GET_ALL_IT_COMMENTS,
  GET_AR_COMMENTS,
  RESET_COMMENTS
} from "../Actions/types";
const initialState = {
  dataComments: null,
  itComments: null,
  arComments: null
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_DATA_COMMENTS:      
        return {
          ...state,
          dataComments: payload
        };      
    case GET_ALL_IT_COMMENTS:
      if (payload.length === 0) {
        return {
          ...state,
          itComments: payload
        };
      } else {
        return {
          ...state,
          itComments: payload
        };
      }
    case GET_AR_COMMENTS:
      if (payload && payload.length === 0) {
        return {
          ...state,
          arComments: payload
        };
      } else {
        return {
          ...state,
          arComments: payload
        };
      }
    case RESET_COMMENTS:
      return {
        ...state,
        dataComments: null,
        itComments: null,
        arComments: null
      };
    default:
      return state;
  }
}
