import React, { useEffect } from "react";
import { Menu, Icon } from "semantic-ui-react";
import { NavLink, Link, useMatch, useResolvedPath } from "react-router-dom";

 const CustomNavLink = ({
  name,
  to,
  className,
  dataTip,
  as,
  children,
  onClick,
  show
}) => {
  const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    if (match?.url === pathname) return true;
  };
  return (
    <Menu.Item
    onClick={()=>onClick&&onClick()}
      name={name}
      as={NavLink}
      isActive={checkActive}
      to={to || "#"}
      
    >
      <div className="showFull">
        <div className="" style={{ width: "50px" }}>
          {children}
        </div>
        <span className={`${show ? "showTitle" : "hideTitle"}`}>{name}</span>
      </div>
    </Menu.Item>
  );
};

export default CustomNavLink