import React, { useState,useEffect, useCallback } from "react";
import { throttle } from "lodash";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers
} from "../../../Redux/Actions/inventoryActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond/dist/filepond.min.css";

import { Form, Input, TextArea, Container, Button, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { newQRTicket } from "../../../Redux/Actions/ticketActions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { loadUser } from "../../../Redux/Actions/userActions";
registerPlugin(FilePondPluginImagePreview);
const QualtityRequestTicket = ({ newQRTicket, redirect, loadUser, auth, lookupUsersForInventory,
  clearUsersForInventory,users,allDBUsers,allUsers }) => {
    useEffect(() => {
      allDBUsers()
    }, []);
  const [ticketData, setTicketData] = useState({
    ticketType: "QualtityRequest",
    ticketSubject: "",
    ticketDescription: "",
    itHelpType: "",
    ticketPriority: "",
    organization: "",
    itHelpOtherType:"",
    organizationOther:"",
    dataUse:"",
    dataUse1:"",
    fileLocation:"",
    fileLocationLink:"",
  });
  const spanStyle = {
    marginRight: "15px",
    position: "relative",
    top: "-2px"
  };
  const inputStyle = {
    cursor: "pointer",
    marginRight: "2px"
  };
  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
    ticketAttachment_two: "",
    ticketAttachment_three: "",
    ticketAttachment_four: ""
  });
  const [dates, setDates] = useState({
    claimsBeginingDate: "",
    claimsEndDate: "",
    dueDate: ""
  });
  const {
    ticketType,
    ticketSubject,
    ticketDescription,
    itHelpType,
    ticketPriority,
    organization,
    itHelpOtherType,
    organizationOther,
    dataUse,
    fileLocation,
    fileLocationLink,
    dataUse1
  } = ticketData;
  const {
    ticketAttachment_one,
    ticketAttachment_two,
    ticketAttachment_three,
    ticketAttachment_four
  } = picture;

  React.useEffect(()=>{
    setRemainingDes(1000 - ticketDescription.length)
  },[ticketDescription])

  const [remainingDes, setRemainingDes]=useState(1000)

  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: []
  });
  const {
    cursor
  } = highlightSelection;

  const [requesterName, setRequesterName] = useState("");

  const [requesterEmail, setRequesterEmail] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");

  const handleAutoProductChange = e => {
    setRequesterName(e.target.value.trim());
    delayedQuery(e.target.value);
  };

  const delayedQuery = useCallback(
    throttle(q => sendQuery(q), 500),
    []
  );

  const sendQuery = query => {
    if (query.length > 0) {
      console.log(query)
      lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };

  const { claimsBeginingDate, claimsEndDate, dueDate } = dates;
  const onChange = e =>
    setTicketData({ ...ticketData, [e.target.name]: e.target.value });
  const handleFilepondUpdate = fileItems => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
        ticketAttachment_two: !fileItems[1] ? null : fileItems[1].file,
        ticketAttachment_three: !fileItems[2] ? null : fileItems[2].file,
        ticketAttachment_four: !fileItems[3] ? null : fileItems[3].file
      });
    }
  };
  const onSubmit = e => {
    e.preventDefault();
    const fd = new FormData();
    if(itHelpType === "Other" && !itHelpOtherType){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Please Write Other Type"), 300)
      return false;
    }
    if(organization === "Other" && !organizationOther){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Please Write Other Organization"), 300)
      return false;
    }
    if(claimsBeginingDate && !claimsEndDate){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Claim End Date is Mandatory"), 300)
      return false;
    }
    if(!claimsBeginingDate && claimsEndDate){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Claim Start Date is Mandatory"), 300)
      return false;
    }
    if(claimsBeginingDate && claimsEndDate){
      const ISOFromDate = claimsBeginingDate.toISOString().split("T")[0] + " 00:00:00";
    const ISOToDate = claimsEndDate.toISOString().split("T")[0] + " 23:59:59";
    fd.append("claimsBeginingDate", ISOFromDate);
    fd.append("claimsEndDate", ISOToDate);
    }
    const ISODueDate = dueDate.toISOString().split("T")[0] + " 23:59:59";

    if(!requesterName){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      }
      toastr.clear()
      setTimeout(() => toastr.error("Requester Name is Mandatory"), 300)
      return false;
    }
    fd.append("user_id", auth.user[0].id);
    fd.append("ticketType", ticketType);
    fd.append("ticketSubject", ticketSubject);
    fd.append("ticketDescription", ticketDescription);
    fd.append("itHelpType", itHelpType);
    fd.append("ticketPriority", ticketPriority);
    fd.append("organization", organization);
    fd.append("dueDate", ISODueDate);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    fd.append("ticketAttachment_two", ticketAttachment_two);
    fd.append("ticketAttachment_three", ticketAttachment_three);
    fd.append("ticketAttachment_four", ticketAttachment_four);
    fd.append("requesterName", requesterName.trim())
    fd.append("requesterEmail", requesterEmail)
    fd.append("requesterPhone", requesterPhone)
    fd.append("dataUse", dataUse)
    fd.append("dataUseType", dataUse1)
    fd.append("fileLocation", fileLocation)
    fd.append("fileLocationLink", fileLocationLink)
    newQRTicket(fd);    
  };

  const handleKeyDown = e => {
    if (e.keyCode === 38) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor - 1
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor + 1
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        // const fd = new FormData();
        // fd.append("reassign_user", selected[i].getAttribute("data-key"));

        // need to get the name nd user from the database and make the change immediaty
        setRequesterName("");
        // clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0
        });
      }
    }
  };

  function setRequesterFunction(name){
    setRequesterName(name.target.value)
  }

  const handleDueDate = date => {
    console.log(date)
    setDates({
      ...dates,
      dueDate: date
    });
  };
  const handleBeginingDate = date => {
    setDates({
      ...dates,
      claimsBeginingDate: date
    });
  };
  const handleEndDate = date => {
    setDates({
      ...dates,
      claimsEndDate: date
    });
  };
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <h1>New Quality Ticket</h1>
      <Form onSubmit={e => onSubmit(e)}>
        <Form.Field>
          <Link to="/new-ticket/IT" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>IT Ticket</span>{" "}
          </Link>
          <Link to="/new-ticket/Data" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>Data Ticket</span>{" "}
          </Link>
          <input
            type="radio"
            name="ticketType"
            value="QualtityRequest"
            onChange={onChange}
            checked={ticketType === "QualtityRequest"}
            style={inputStyle}
          />
          <span style={spanStyle}>Quality Request</span>
        </Form.Field>
        <Form.Field>
          <label className="mandatory-star">Subject:</label>
          <Input
            type="text"
            maxLength="50"
            onChange={onChange}
            name="ticketSubject"
            placeholder="Please enter the subject of the ticket"
            value={ticketSubject}
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 50
          </span>
        </Form.Field>
        <Form.Field>
          <label className="mandatory-star">Description:</label>
          <TextArea
          maxLength="1000"
            name="ticketDescription"
            value={ticketDescription}
            onChange={onChange}
            placeholder="Please enter a brief description for the ticket"
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 1000
          </span>
          <span style={{ padding: "5px", color: "red", fontSize: "12px", float:"right" }}>
          {remainingDes} Characters remaining
          </span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">Type:</label>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Question"
            checked={itHelpType === "Question"}
          />
           <span style={spanStyle}>Question</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="File Pull"
            checked={itHelpType === "File Pull"}
          />
          <span style={spanStyle}>File Pull</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="File Push"
            checked={itHelpType === "File Push"}
          />
          <span style={spanStyle}>File Push</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Data Request"
            checked={itHelpType === "Data Request"}
          />
          <span style={spanStyle}>Data Request</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Table Load"
            checked={itHelpType === "Table Load"}
          />
          <span style={spanStyle}>Table Load</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Task"
            checked={itHelpType === "Task"}
          />
          <span style={spanStyle}>Task</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Other"
            checked={itHelpType === "Other"}
          />
          <span style={spanStyle}>Other </span>
          {itHelpType === "Other" && <input
            type="text"
            name="itHelpOtherType"
            onChange={onChange}
          />}
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label>
          <div className="row" style={{display:"flex"}}>
          <div className="col-2 mandatory-star">
          Data Use:
          </div>
          <div className="col-2" style={{marginLeft:"30px",marginTop:"3px"}}>
          <input
            type="radio"
            name="dataUse1"
            onChange={onChange}
            style={inputStyle}
            value="Internal"
            checked={dataUse1 === "Internal"}
          />
          <span style={spanStyle}>Internal</span>
          </div>
          <div className="col-2" style={{marginLeft:"10px",marginTop:"3px"}}>
          <input
            type="radio"
            name="dataUse1"
            onChange={onChange}
            style={inputStyle}
            value="External"
            checked={dataUse1 === "External"}
          />
          <span style={spanStyle}>External</span>
          </div>
            </div>
          </label>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="HEDIS"
            checked={dataUse === "HEDIS"}
          />
           <span style={spanStyle}>HEDIS</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Stars"
            checked={dataUse === "Stars"}
          />
          <span style={spanStyle}>Stars</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Gaps"
            checked={dataUse === "Gaps"}
          />
          <span style={spanStyle}>Gaps</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Provider Report"
            checked={dataUse === "Provider Report"}
          />
          <span style={spanStyle}>Provider Report</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Scorecard"
            checked={dataUse === "Scorecard"}
          />
          <span style={spanStyle}>Scorecard</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Risk"
            checked={dataUse === "Risk"}
          />
          <span style={spanStyle}>Risk</span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">File Location:</label>
          
          {/* <input
            type="text"
            name="fileLocation"
            onChange={onChange}
            value={fileLocation}
          /> */}
          <input
            type="radio"
            name="fileLocation"
            onChange={onChange}
            style={inputStyle}
            value="URL(Links To Files)"
            checked={fileLocation === "URL(Links To Files)"}
          />
          <span style={spanStyle}>URL(Links To Files)</span>
          <input
            type="radio"
            name="fileLocation"
            onChange={onChange}
            style={inputStyle}
            value="SharePoint Link"
            checked={fileLocation === "SharePoint Link"}
          />
           <span style={spanStyle}>SharePoint Link</span>
          <input
            type="radio"
            name="fileLocation"
            onChange={onChange}
            style={inputStyle}
            value="FTP Site(link,user & pw)"
            checked={fileLocation === "FTP Site(link,user & pw)"}
          />
          <span style={spanStyle}>FTP Site(link,user & pw)</span>
          <br/>
          {fileLocation && <input
            type="text"
            name="fileLocationLink"
            onChange={onChange}
            value={fileLocationLink}
          />}

        </Form.Field>
        <Form.Field
          style={
            ticketPriority === "LOW"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#f2e6ff"
                }
              : ticketPriority === "NORMAL"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#bcddf5"
                }
              : ticketPriority === "HIGH"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ffd9b3"
                }
              : ticketPriority === "URGENT"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ff9980"
                }
              : {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px"
                }
          }
        >
          <label className="mandatory-star">Priority:</label>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="LOW"
            checked={ticketPriority === "LOW"}
          />
          <span style={spanStyle}>Low</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="NORMAL"
            checked={ticketPriority === "NORMAL"}
          />
          <span style={spanStyle}>Normal</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="HIGH"
            checked={ticketPriority === "HIGH"}
          />
          <span style={spanStyle}>High</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="URGENT"
            checked={ticketPriority === "URGENT"}
          />
          <span style={spanStyle}>Urgent</span>
        </Form.Field>

        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">Organization</label>
          <input
            type="radio"
            name="organization"
            value="Requesting"
            onChange={onChange}
            checked={organization === "Requesting"}
            style={inputStyle}
          />
          <span style={spanStyle}>Requesting</span>
          <input
            type="radio"
            name="organization"
            value="HTA"
            onChange={onChange}
            checked={organization === "HTA"}
            style={inputStyle}
          />
          <span style={spanStyle}>HTA</span>
          <input
            type="radio"
            name="organization"
            value="THN"
            onChange={onChange}
            checked={organization === "THN"}
            style={inputStyle}
          />
          <span style={spanStyle}>THN</span>
          <input
            value="Cone"
            name="organization"
            onChange={onChange}
            checked={organization === "Cone"}
            type="radio"
            style={inputStyle}
          />
          <span style={spanStyle}>Cone</span>
          <input
            type="radio"
            name="organization"
            onChange={onChange}
            style={inputStyle}
            value="Other"
            checked={organization === "Other"}
          />
          <span style={spanStyle}>Other </span>
          {organization === "Other" && <input
            type="text"
            name="organizationOther"
            onChange={onChange}
          />}
        </Form.Field>
        <Form.Group widths='equal'>
        <Form.Field>
          <label className="mandatory-star">Due Date</label>
          <DatePicker
            onChange={handleDueDate}
            selected={dueDate}
            name="dueDate"
          />
        </Form.Field>
        <Form.Field>
        <label style={{fontWeight: "bold", fontSize: "12px"}} className="mandatory-star">Requester Name</label>
        <Input
            onChange={(e)=> setRequesterFunction(e)}
            value={requesterName}
            placeholder="Requester Name..."
            style={{ maxWidth: "auto", width:"auto" }}
          />
          </Form.Field>
          <Form.Field>
        <label style={{fontWeight: "bold", fontSize: "12px"}} className="mandatory-star">Requester Email</label>
        <Input
            onChange={(e)=> setRequesterEmail(e.target.value)}
            value={requesterEmail}
            placeholder="Requester Email..."
            style={{ maxWidth: "auto", width:"auto" }}
          />
          </Form.Field>
          
          </Form.Group>
        <Form.Group widths='equal'>
       
        <Form.Field>
          <label>Beginning Date For Claims</label>
          <DatePicker
            onChange={handleEndDate}
            selected={claimsEndDate}
            name="claimsEndDate"
            value={claimsEndDate}
          />
        </Form.Field>
        <Form.Field>
          <label>End Date For Claims:</label>
          <DatePicker
            onChange={handleBeginingDate}
            selected={claimsBeginingDate}
            name="claimsBeginingDate"
          />
        </Form.Field>
        </Form.Group>

        <Form.Field>
          <label>Attachment: </label>
          <span>up to four</span>
          <FilePond
            maxFiles={4}
            files={
              (ticketAttachment_one,
              ticketAttachment_two,
              ticketAttachment_three,
              ticketAttachment_four)
            }
            allowMultiple={true}
            allowImagePreview="false"
            onupdatefiles={fileItems => handleFilepondUpdate(fileItems)}
          />
        </Form.Field>
        <Form.Field>
          <Button
            basic
            color="red"
            type="reset"
            onClick={() => {
              setTicketData({
                ticketType: "QualityRequest",
                ticketSubject: "",
                ticketDescription: "",
                itHelpType: "",
                ticketPriority: "",
                organization: ""
              });
              setDates({
                claimsBeginingDate: "",
                claimsEndDate: "",
                dueDate: ""
              });
              addAttachment({
                ...picture,
                ticketAttachment_one: "",
                ticketAttachment_two: "",
                ticketAttachment_three: "",
                ticketAttachment_four: "",
              });
            }}
          >
            Clear
          </Button>
          <Button
            type="submit"
            basic
            color="green"
            disabled={
              !ticketSubject ||
              !ticketDescription ||
              !itHelpType ||
              !ticketPriority ||
              !organization ||
              !dueDate
            }
          >
            Submit
          </Button>
        </Form.Field>
      </Form>
    </Container>
  );
};
const mapStateToProps = state => ({
  tickets: state.tickets,
  redirect: state.redirect,
  auth: state.auth,
  users: state.inventory.users,
  allUsers: state.inventory.allUsers
});
export default connect(
  mapStateToProps,
  { loadUser, lookupUsersForInventory,
    clearUsersForInventory,allDBUsers,newQRTicket }
)(QualtityRequestTicket);