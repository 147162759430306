import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { Form, Button, Grid } from "semantic-ui-react";
import Container from "@material-ui/core/Container";
// added malik start //
import {
  getPendingDevRequests,
  clearAdminDRSearch
} from "../../../Redux/Actions/dataOpsActions";
import ModalTicketTest from "./ModalFilterDataops";

function PendingTicketsMain({
  auth,
  getPendingDevRequests,
  clearAdminDRSearch,
  loadUser,
  tickets: { tickets }
}) {
  useEffect(() => {
    setIsLoading(true)
    const fd = new FormData();
    let ISOToDate = null;
    let ISOFromDate = null;
    if (ticketFromDate !== null) {
      ISOFromDate = ticketFromDate.toISOString().split("T")[0] + " 00:00:00";
    }
    if (ticketToDate !== null) {
      ISOToDate = ticketToDate.toISOString().split("T")[0] + " 23:59:59";
    }
    fd.append("ticketsTypeToView", ticketsTypeToView);
    fd.append("ticketPriorityToView", ticketPriorityToView);
    fd.append("ticketFromDate", ISOFromDate);
    fd.append("ticketToDate", ISOToDate);
    getPendingDevRequests(fd).then(()=>{
      setIsLoading(false)
    })
  }, []);
  const { user } = auth;
  const [ticktsToView, setTicketViewData] = useState({
    ticketsTypeToView: "",
    ticketPriorityToView: ""
  });
  const [dates, setDates] = useState({
    ticketFromDate: null,
    ticketToDate: null
  });
  const spanStyle = {
    marginRight: "15px",
    position: "relative",
    top: "-2px"
  };
  const inputStyle = {
    cursor: "pointer",
    marginRight: "2px"
  };
  const { ticketFromDate, ticketToDate } = dates;
  const { ticketsTypeToView, ticketPriorityToView } = ticktsToView;
  const onChange = e => {
    setTicketViewData({ ...ticktsToView, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    setIsLoading(true)
    e.preventDefault();
    const fd = new FormData();
    let ISOToDate = null;
    let ISOFromDate = null;
    if (ticketFromDate !== null) {
      ISOFromDate = ticketFromDate.toISOString().split("T")[0] + " 00:00:00";
    }
    if (ticketToDate !== null) {
      ISOToDate = ticketToDate.toISOString().split("T")[0] + " 23:59:59";
    }
    fd.append("ticketsTypeToView", ticketsTypeToView);
    fd.append("ticketPriorityToView", ticketPriorityToView);
    fd.append("ticketFromDate", ISOFromDate);
    fd.append("ticketToDate", ISOToDate);
    getPendingDevRequests(fd).then(()=>{
      setIsLoading(false)
    })
  };
  const handleFromDate = date => {
    setDates({
      ...dates,
      ticketFromDate: date
    });
  };
  const handleToDate = date => {
    setDates({
      ...dates,
      ticketToDate: date
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container style={{ paddingTop: "20px" }} maxWidth="lg">
      <Grid columns={2} divided stackable>
      <Grid.Row>
          <Grid.Column>
            {isLoading && (
              <div className="loadingNew">
                <div className="loaderNew"></div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h3>Welcome {user ? user.name : null}</h3>
            <Form
              className="admin-ticket-form-wrapper"
              onSubmit={e => onSubmit(e)}
            >
              <Form.Field>
                <label>Select Ticket Types</label>
                {/* <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketsTypeToView"
                  value="new"
                  checked={ticketsTypeToView === "new"}
                />
                <span style={spanStyle}>New</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketsTypeToView"
                  value="opened"
                  checked={ticketsTypeToView === "opened"}
                />
                <span style={spanStyle}>Opened</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketsTypeToView"
                  value="pending"
                  checked={ticketsTypeToView === "pending"}
                /> */}
                {/* <span style={spanStyle}>Active</span> */}
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketsTypeToView"
                  value=""
                  checked={ticketsTypeToView === ""}
                />
                <span style={spanStyle}>Current</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketsTypeToView"
                  value="resolved"
                  checked={ticketsTypeToView === "resolved"}
                />
                <span style={spanStyle}>Resolved</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketsTypeToView"
                  value="unassigned"
                  checked={ticketsTypeToView === "unassigned"}
                />
                <span style={spanStyle}>Unassigned</span>
              </Form.Field>
              <Form.Field>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketPriorityToView"
                  value="LOW"
                  checked={ticketPriorityToView === "LOW"}
                />
                <span style={spanStyle}>Low</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketPriorityToView"
                  value="NORMAL"
                  checked={ticketPriorityToView === "NORMAL"}
                />
                <span style={spanStyle}>Normal</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketPriorityToView"
                  value="HIGH"
                  checked={ticketPriorityToView === "HIGH"}
                />
                <span style={spanStyle}>High</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketPriorityToView"
                  value="URGENT"
                  checked={ticketPriorityToView === "URGENT"}
                />
                <span style={spanStyle}>Urgent</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketPriorityToView"
                  value="All"
                  checked={ticketPriorityToView === "All"}
                />
                <span style={spanStyle}>All</span>
              </Form.Field>
              <h4>Date range </h4>
              <h6>(leave blank to see ALL tickets)</h6>
              <Form.Field>
                <label>From:</label>
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  onChange={handleFromDate}
                  selected={ticketFromDate}
                  name="ticketFromDate"
                  value={ticketFromDate}
                />
              </Form.Field>
              <Form.Field>
                <label>To:</label>
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  onChange={handleToDate}
                  selected={ticketToDate}
                  name="ticketToDate"
                  value={ticketToDate}
                />
              </Form.Field>
              <Button
                color="red"
                type="reset"
                basic
                onClick={() => {
                  clearAdminDRSearch();
                  setDates({
                    ticketFromDate: null,
                    ticketToDate: null
                  });
                }}
              >
                Clear Results
              </Button>
              <Button type="submit" basic color="green">
                Go!
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column style={{ maxHeight: "100vh", overflowY: "scroll" }}>
            {tickets ? (
              <h4>Click to expand:</h4>
            ) : (
              <h4>Please Select an Option</h4>
            )}
            <ModalTicketTest />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  tickets: state.tickets
});
export default connect(mapStateToProps, {
  getPendingDevRequests,
  clearAdminDRSearch
})(PendingTicketsMain);
