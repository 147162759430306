// // Created by Thomas Flock Jr on 8/12/2019
// // https://github.com/mazer-rakham
// I am sorry for this mess, there was no outline or anything to what was going on
// I had no clue what I was building and it kept changing every month. 
// Sorry


// EVERYTHING NEEDS REFACTORING!
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
// import { Provider } from "react-redux";
// import store from "./store";

// import setAuthToken from "./utils/setAuthToken";
// import io from 'socket.io-client';

import "react-image-lightbox/style.css";
// import ConfirmEmail from "./Components/Auth/ConfirmEmail";
import Routes from "./Routes";

const App = () => {
  return (
    <Router>     
      <Switch>
        <Route component={Routes} />
      </Switch>
    </Router>
  );
};

export default App;
