import axios from "axios";
import {
  GET_ALL_PENDING_DATAOPS,
  CHANGE_DATAOPS_ASSIGNED_TO,
  CHANGE_DATAOPS_STATUS,
  MODAL_OPEN,
  GET_SINGLE_DASHBOARD_DATA_OP,
  //CLEAR_ADMIN_DR_SEARCH, // added Malik //
  CLEAR_ADMIN_DR_SEARCH,
  GET_ALL_DR_USERS,  
  GET_SINGLE_PENDING_DATAOPS
} from "./types";

// added Malik start //
export const getPendingDevRequests = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/data-ops/dr-it-tickets", body);
    dispatch({
      type: GET_ALL_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const clearAdminDRSearch = () => (dispatch) => {
  dispatch({ type: CLEAR_ADMIN_DR_SEARCH });
};
export const changeDataOpsAssignedTo = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/data-ops/assign-dataops-ticket", body);
    dispatch({
      type: CHANGE_DATAOPS_ASSIGNED_TO,
      payload: res.data,
    });
     } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

// added Malik end //

export const chageDataOpsTicketStatus = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/data-ops/change-dataops-status", body);
    dispatch({
      type: CHANGE_DATAOPS_STATUS,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: {
        message: "Ticket Status Changed Successfully"
      },
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getAllMyPendingDataOps = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/data-ops/get-my-pending-data-tickets",
      body
    );
    dispatch({
      type: GET_ALL_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getAllPendingDdataOps = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/data-ops/get-all-data-ops");
    dispatch({
      type: GET_ALL_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getSingleDashboardDataOp = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/data-ops/get-my-dashboard-data-ops",
      body
    );
    dispatch({
      type: GET_SINGLE_DASHBOARD_DATA_OP,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const addAdminDataOpAttachment = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/data-ops/admin-dataop-attachment-upload",
      body
    );
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getIndividualDataOpInfo = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/data-ops/dataop-individual-ticket-search",
      body
    );
    dispatch({
      type: GET_SINGLE_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getAllActiveDSUser = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/data-ops/get-all-dr-users");
    dispatch({
      type: GET_ALL_DR_USERS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const addPostAttachmentDR = (body) => async(dispatch) =>{
  try {
    const res = await axios.post("/api/data-ops/addPostAttachmentDR", body);

  } catch (err) {
    const errors = err.response.data.errors;

  }
}

export const getPendingDevRequestsQR = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/qr-tickets/qr-tickets", body);
    dispatch({
      type: GET_ALL_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const changeQRAssignedTo = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/qr-tickets/assign-qr-ticket", body);
    dispatch({
      type: CHANGE_DATAOPS_ASSIGNED_TO,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const chageQRTicketStatus = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/qr-tickets/change-qr-status", body);
    dispatch({
      type: CHANGE_DATAOPS_STATUS,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: {
        message: "Ticket Status Changed Successfully"
      },
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getAllPendingQR = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/qr-tickets/get-all-qr");
    dispatch({
      type: GET_ALL_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const addAdminQRAttachment = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/qr-tickets/admin-qr-attachment-upload",
      body
    );
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getIndividualQRInfo = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/qr-tickets/qr-individual-ticket-search",
      body
    );
    dispatch({
      type: GET_SINGLE_PENDING_DATAOPS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getAllActiveQRUser = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/qr-tickets/get-all-qr-users");
    dispatch({
      type: GET_ALL_DR_USERS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
}; 