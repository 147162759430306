import React, { useEffect, useState } from "react";
import {
  Grid,
  Dimmer,
  Loader,
  Modal,
  Button,
  Image,
  Form,
  Input,
  Icon,
  Segment,
  Placeholder,
} from "semantic-ui-react";
import { Container, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import Moment from "react-moment";
import { getSingleDashboardDataOp, addPostAttachmentDR } from "../../../../../Redux/Actions/dataOpsActions";
import {
  getDataTicketComments,
  addDataComment,
} from "../../../../../Redux/Actions/commentActions";
import {getDataTicketAttachments} from "../../../../../Redux/Actions/ticketActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";


function DataOpsRequestModal({
  match,
  getSingleDashboardDataOp,
  dashboard_tickets,
  getDataTicketComments,
  comments,
  auth,
  addDataComment,
  addPostAttachmentDR,
  getDataTicketAttachments,
  attachments
}) {
  useEffect(() => {
    const fd = new FormData();
    fd.append("dr_id", match.params.id);
    fd.append("data_ticket_id", match.params.id);
    getSingleDashboardDataOp(fd);
    getDataTicketComments(fd);
    getDataTicketAttachments(fd);
  }, []);
  const [dataOpscomments, setDataOpsComment] = useState({
    comment: "",
  });
  const { comment } = dataOpscomments;
  const handleCommentChange = (e, data) => {
    e.preventDefault();
    setDataOpsComment({
      ...comments,
      comment: e.target.value,
    });
  };
  const handleCommentSubmit = (id, user) => {
    const fd = new FormData();
    fd.append("data_comment", comment);
    fd.append("id", id);
    fd.append("url_location", window.location.href);
    fd.append("commentor_id", auth.user[0].id);
    fd.append(
      "commentor",
      auth.user[0].first_name + " " + auth.user[0].last_name
    );  
    fd.append("user", user)
    fd.append("authorEmail", dashboard_tickets.dashboard_tickets[0].dr_ticket_author_email)
    addDataComment(fd).then(() => {
      setDataOpsComment({
        ...comments,
        comment: "",
      });
    });
  };
  const spanStyle = {
    fontWeight: "bold",
  };

  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
      });
    }
  };

  const handleItAttachmentSubmit = (id) => {
    const fd = new FormData();
    fd.append("dr_id", id);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    addPostAttachmentDR(fd).then(() => {
      addAttachment({
        ticketAttachment_one: "",
      });
      const fd = new FormData();
      fd.append("dr_id", match.params.id);
      fd.append("data_ticket_id", match.params.id);
      getSingleDashboardDataOp(fd);
      getDataTicketComments(fd);
      getDataTicketAttachments(fd);    
    });
  };

  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
  });
  const { ticketAttachment_one } = picture;

  const [openAttachment, setOpenAttachment] = useState("");
  const [open, setOpen] = useState(false);

  function openPopup(attachment) {
    if(attachment.path && (attachment.path.match(/.xls/gi) || 
    attachment.path.match(/.xlsx/gi) || 
    attachment.path.match(/.doc/gi) || 
    attachment.path.match(/.pdf/gi))){
      console.log(window.location.origin+attachment.path)
      downloadFile(window.location.origin+attachment.path,attachment.path)
      return true
    }
    setOpen(true);
    setOpenAttachment(attachment.path);
  }

  function downloadFile(fileURL,filename) {
    if (
      fileURL        
    ) {
        var save = document.createElement("a");
        console.log(__dirname)
        save.href = fileURL;
        save.target = "_blank";
        save.download = filename.substring(filename.lastIndexOf("/") + 1)
        var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
    }
  }

  let dataTicket;
  dashboard_tickets.dashboard_tickets
    ? (dataTicket = (
       
        <Grid columns="equal" stackable>
          <Grid.Row>
              <Grid.Column>
                <Modal
                  open={open}
                  closeIcon
                  basic={true}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                >
                  <Modal.Content>
                  <Image src={openAttachment} alt="Attachment" fluid />
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            </Grid.Row>
        <Grid.Row style={{paddingTop:"55px"}}>
          <Grid.Column>
            <h3>Ticket ID: {dashboard_tickets.dashboard_tickets[0].dr_id}</h3>
          </Grid.Column>
          {/* <Grid.Column tyle={{textAlign:"right"}}>
          <Button basic color="orange" onClick={handleRemoveFromDashboard}>Remove from Dashboard</Button>
          </Grid.Column> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>
              <span style={{ fontWeight: "bold" }}>Subject: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_subject}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Description: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_description}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Priority: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_priority}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Claims Beginning Date: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_claims_begining_date}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Assigned To: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_assigned_to}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              <span style={{ fontWeight: "bold" }}>Type: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_type}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Status: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_admin_status}
            </p>
            {dashboard_tickets.dashboard_tickets[0].dr_admin_status &&
            dashboard_tickets.dashboard_tickets[0].dr_admin_status === "RESOLVED" ? (
              <p>
                <span style={{ fontWeight: "bold" }}>Resolved By: </span>
                {dashboard_tickets.dashboard_tickets[0].resolved_first} {dashboard_tickets.dashboard_tickets[0].resolved_last}
              </p>
            ) : null}
            <p>
              <span style={{ fontWeight: "bold" }}>Organization: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_organization}
            </p>
            
            <p>
              <span style={{ fontWeight: "bold" }}>Claims End Date: </span>
              {dashboard_tickets.dashboard_tickets[0].dr_claims_end_date}
            </p>
          </Grid.Column>
        </Grid.Row>
        {dashboard_tickets.dashboard_tickets[0].admin_path ? (
          <Grid.Row>
            <Grid.Column>
              <Modal
                trigger={<Button basic color="blue">View Attachment</Button>}
                closeIcon
                basic={true}
              >
                <Modal.Content>
                {dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "jpg" ||
                          dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "png" ? (
                            <Image
                              src={dashboard_tickets.dashboard_tickets[0].admin_path}
                              alt="Attachment"
                              fluid
                            />
                          ) : dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "pdf" ? (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <a href={dashboard_tickets.dashboard_tickets[0].admin_path} target="_blank">
                                <Icon name="file pdf" size="massive" />
                                <p>Click to download PDF</p>
                              </a>
                            </div>
                          ) : dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "doc" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "docx" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "docm" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "txt" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "wps" ? (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <a href={dashboard_tickets.dashboard_tickets[0].admin_path} target="_blank">
                                <Icon name="file word" size="massive" />
                                <p>Download Text File</p>
                              </a>
                            </div>
                          ) : dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlsx" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlsm" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "docm" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlsb" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xltx" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xltm" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xls" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlt" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xml" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlam" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xla" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlw" ||
                            dashboard_tickets.dashboard_tickets[0].admin_path.split(".").pop() === "xlr" ? (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <Icon name="file excel" size="massive" />
                              <p>Download Excel File</p>
                            </div>
                          ) : null}

                  {/* <Image src={dashboard_tickets.dashboard_tickets[0].admin_path} alt="Attachment" fluid /> */}
                </Modal.Content>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        ) : null}
<Grid.Row>
          <Grid.Column>
            {!attachments ? (
              <Segment>
                <Dimmer active>
                  <Loader inverted>Loading Attachments</Loader>
                </Dimmer>
                <Placeholder style={{ height: 50, width: 50 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Segment>
            ) : attachments.length === 0 ? (
              <div>No Attachments</div>
            ) : (
              <div style={{ margin: "5px" }}>
                    <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                    <Grid stackable>
                      <Grid.Row>
                        {attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "120px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                Attachment {index+1}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
          <label
                        style={{ textAlign: "left" }}
                        className="colorBrandColor"
                      >
                        Attachment:{" "}
                      </label>

                      <FilePond
                        maxFiles={1}
                        files={ticketAttachment_one}
                        allowMultiple={false}
                        allowImagePreview="false"
                        onupdatefiles={(fileItems) =>
                          handleFilepondUpdate(fileItems)
                        }
                      />
                      <Button
                        style={{ float: "right", cursor: "pointer" }}
                        type="button"
                        basic
                        color="orange"
                        onClick={(id) =>
                          handleItAttachmentSubmit(dashboard_tickets.dashboard_tickets[0].dr_id)
                        }
                      >
                        Attachment Upload
                      </Button>
          </Grid.Column>
          </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {comments.dataComments && comments.dataComments.length > 0 ? (
              <div
                style={{ borderTop: "2px solid #1B75BB", paddingTop: "10px" }}
              >
                <h3>Comments:</h3>
                {comments.dataComments.map((comment) => (
                  <span key={comment.dr_id}>
                    <p style={{ textAlign: "left" }}>
                      <span style={{ fontWeight: "bold" }}>
                        {comment.data_commentor_name}:
                      </span>{" "}
                      {comment.comment}
                      <span style={{ float: "right" }}>
                        <Moment
                          date={comment.comment_time}
                          format="MM/DD/YY LTS"
                        />
                      </span>
                    </p>
                    <hr />
                  </span>
                ))}
              </div>
            ) : null}
            <Form
              onSubmit={(e, id, user) =>
              handleCommentSubmit(dashboard_tickets.dashboard_tickets[0].dr_id, comments.dataComments.length > 0
                ? comments.dataComments[
                    comments.dataComments.length - 1
                  ].commentor_id
                : auth.user[0].id)
              }
            >
              <Form.Field>
                {dashboard_tickets.dashboard_tickets[0].dr_admin_status === "RESOLVED" ? (
                  <h4>Comments cannot be added after ticket is resolved.</h4>
                ) : (
                  <Input
                    type="text"
                    name="itComment"
                    value={comment}
                    id="Comment"
                    // onChange={handleITCommentChange}
                    onChange={handleCommentChange}
                  />
                )}
              </Form.Field>
              <Form.Field>
                <Button
                  type="submit"
                  basic
                  color="green"
                  disabled={dashboard_tickets.dashboard_tickets[0].dr_admin_status === "RESOLVED"}
                >
                  Add Comment
                </Button>
              </Form.Field>
            </Form>
            
          </Grid.Column>
        </Grid.Row>
      </Grid>
      ))
    : (dataTicket = (
        <Dimmer>
          <Loader inverted content="Loading" />
        </Dimmer>
      ));
  return <Container maxWidth="lg">{dataTicket}</Container>;
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard_tickets: state.dashboard_tickets,
  comments: state.comments,
  attachments: state.dataOps.attachments,
});
export default connect(mapStateToProps, {
  getSingleDashboardDataOp,
  getDataTicketComments,
  addDataComment,
  getDataTicketAttachments,
  addPostAttachmentDR
})(DataOpsRequestModal);