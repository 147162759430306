import React, { useState, useEffect, useCallback } from "react";
import { throttle } from "lodash";
// import _ from "lodash";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Input,
  Form,
  Button,
  Grid,
  Transition,
  Icon,
  Modal,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  getUserInventory,
  addProfileInventory,
  removeItemFromUser,
  getAccessbyUser,
  lookupSoftwareSuggestions,
  removeAcessFromUser,

} from "../../../Redux/Actions/inventoryActions";
import { newHireAccessRequestTwo } from "../../../Redux/Actions/accessRequestActions";

import {
  lookupUserProfile,
  updateUserProfile,
  clearUserProfileSearch,
  getTheLookedUpUser,
  clearAllLookupDAta,
} from "../../../Redux/Actions/profileActions";
import { Paper } from "@material-ui/core";
function UserLookup({
  lookupUserProfile,
  userProfile,
  updateUserProfile,
  getUserInventory,
  inventory,
  addProfileInventory,
  removeItemFromUser,
  isLoading,
  clearUserProfileSearch,
  getTheLookedUpUser,
  profileSuggestions,
  clearAllLookupDAta,
  getAccessbyUser,
  newHireAccessRequestTwo,
  auth,
  removeAcessFromUser,
  lookupSoftwareSuggestions,

}) {
  const [userToLookUp, setUserToLookUp] = useState({
    user_profile: "",
  });

  const [updatedData, setUpdatedData] = useState({
    newFirstName: "",
    newMiddleName: "",
    newLastName: "",
    newEmail: "",
    newPaycomID: "",
    newManager: "",
    newDeptSpecialization: "",
    newAccessLevel: "",
    statusVisible: ""

  });
  const [userQuery, setUserQuery] = useState("");
  const [newRecord, setNewRecord] = useState(false);

  const [softwareInventory, setSoftwareInventory] = useState("");

  const [userselectedPro, usersetSelectedPro] = useState([]);

  const [noproductsNeeded, setnoproductsNeeded] = useState({
    notneeded: false,
  });
  const { notneeded } = noproductsNeeded;

  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );
  const sendQuery = (query) => {
    if (query.length > 0) {
      lookupSoftwareSuggestions(query);
      lookupUserProfile(query);
    } else {
      clearUserProfileSearch();
    }
  };

  const showPopup = (msg) => {
    toastr.options = {
      positionClass: "toast-top-center",
      hideDuration: 300,
      timeOut: 2000,
      closeButton: false,
      progressBar: true,
    };
    toastr.clear();
    setTimeout(() => toastr.success(msg), 300);
  };

  const getTheIDOfUser = (id, email) => {
    const fd = new FormData();
    fd.append("id_to_get", id);
    fd.append("user_email", email);
    getTheLookedUpUser(fd);
    getUserInventory(fd);
    getAccessbyUser(fd);
  };

  const onAuttoSuggestChange = (e) => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  const [visiblity, setVisibilty] = useState({
    namevisible: false,
    emailvisible: false,
    paycomidvisible: false,
    managernamevisible: false,
    userlevelvisible: false,
    deptspealizationvisible: false,
    accesslevelvisible: false,
    itemdescriptionvisible: false,
    itemasatagvisible: false,
    itemmodelnumbervisible: false,
    statusvisible: false,

  });
  const {
    emailvisible,
    // paycomidvisible,
    managernamevisible,
    deptspealizationvisible,
    namevisible,
    accesslevelvisible,
    statusvisible

  } = visiblity;
  const {
    newFirstName,
    newLastName,
    newMiddleName,
    newEmail,
    // newPaycomID,
    newManager,
    newDeptSpecialization,
    newAccessLevel,
    statusVisible

  } = updatedData;
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   const fd = new FormData();
  //   fd.append("profile_to_lookup", user_profile);
  //   fd.append("user_email", user_profile);
  //   lookupUserProfile(fd);
  //   getUserInventory(fd);
  // };
  // const onChange = e => {
  //   setUserToLookUp({
  //     ...userToLookUp,
  //     [e.target.name]: e.target.value
  //   });
  // };
  const onUserUpdateChange = (e) => {
    setUpdatedData({
      ...updatedData,
      [e.target.name]: e.target.value,
    });
  };
  const spanstyle = {
    fontWeight: "bold",
    display: "inline-block",
    margin: "10px auto",
  };
  // const { user_profile } = userToLookUp;
  const profileUpdate = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("user_id", userProfile[0].id);
    fd.append(
      "first_name",
      newFirstName ? newFirstName : userProfile[0].first_name
    );
    fd.append(
      "middle_initial",
      newMiddleName ? newMiddleName : userProfile[0].middle_initial
    );
    fd.append(
      "last_name",
      newLastName ? newLastName : userProfile[0].last_name
    );
    fd.append("email", newEmail ? newEmail : userProfile[0].email);
    fd.append(
      "manager_name",
      newManager ? newManager : userProfile[0].manager_name
    );
    fd.append(
      "dept_specialization",
      newDeptSpecialization
        ? newDeptSpecialization
        : userProfile[0].dept_specialization
    );
    fd.append(
      "privilege_level",
      newAccessLevel ? newAccessLevel : userProfile[0].privilege_level
    );
    fd.append(
      "status",
      statusVisible ? statusVisible : userProfile[0].status
    );
    // updateUserProfile(fd);
    updateUserProfile(fd).then((res) => {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.success("Profile is Updated"), 300);
      setVisibilty({
        namevisible: false,
        emailvisible: false,
        paycomidvisible: false,
        managernamevisible: false,
        userlevelvisible: false,
        deptspealizationvisible: false,
        accesslevelvisible: false,
        itemdescriptionvisible: false,
        itemasatagvisible: false,
        itemmodelnumbervisible: false,
        statusvisible: false,

      });
    });
  };
  const [modalOpen, setModalOpen] = useState("");

  // const handleModalOpen = () => {
  //   setModalOpen({
  //     ...modalOpen,
  //     open: true
  //   });
  // };
  // const handleModalClose = () => {
  //   setModalOpen({
  //     ...modalOpen,
  //     open: false
  //   });
  // };
  const [hardware, setHardware] = useState({
    new_email: "",
    asa_tag: "",
    description: "",
    model_number: "",
  });
  const { asa_tag, description, model_number, new_email } = hardware;
  const onHardwareChange = (e) => {
    setHardware({
      ...hardware,
      [e.target.name]: e.target.value,
    });
  };
  const [buttonText, setButtonText] = useState({
    text: "Submit",
  });
  const handleHardwareSubmit = (e) => {
    e.preventDefault(e);
    const fd = new FormData();
    fd.append("new_email", userProfile[0].email);
    fd.append("asa_tag", asa_tag);
    fd.append("user_firstName", userProfile[0]?.first_name);
    fd.append("user_lastName", userProfile[0].last_name);
    fd.append("description", description);
    fd.append("model_number", model_number);
    addProfileInventory(fd)
      .then(() => {
        setHardware({
          ...hardware,
          asa_tag: "",
          description: "",
          model_number: "",
        });
        setButtonText({
          ...buttonText,
          text: "Submit Another?",
        });

        showPopup(
          "Hardware Updated Successfully for " +
            userProfile[0]?.first_name +
            " " +
            userProfile[0]?.last_name
        );
      })
      .catch((error) => {});
  };
  const removeInventory = (asset,type) => {
    const fd = new FormData();
    if (type === "access") {
      fd.append("user_id", userProfile[0]?.id);
      fd.append("product_id", asset?.product_id);


      removeAcessFromUser(fd).then(() => {
        fd.append("id_to_get", userProfile[0]?.id);
        setModalOpen(false);
        getAccessbyUser(fd);
        handleSelectedSoftwareInventory(asset);


      });
    } else {
      fd.append("email", userProfile[0].email);
      fd.append("inventory_to_modify", asset?.asa_tag);

      removeItemFromUser(fd).then(() => {
        setModalOpen(false);
      });
    }
  };
  const clearLook = () => {
    clearAllLookupDAta();
  };

  function handleSoftwareInventory(data, i) {
    let originalProArr = JSON.parse(JSON.stringify(softwareInventory)).filter(
      (x) => x["system_application"] !== data["system_application"]
    );
    setSoftwareInventory(
      originalProArr.sort((a, b) =>
        a.system_application > b.system_application
          ? 1
          : b.system_application > a.system_application
          ? -1
          : 0
      )
    );
    let newArr = JSON.parse(JSON.stringify(userselectedPro));
    newArr.push(data);
    usersetSelectedPro(newArr);
  }

  function handleSelectedSoftwareInventory(data, i) {
    let originalProArr = JSON.parse(JSON.stringify(userselectedPro)).filter(
      (x) => x["system_application"] !== data["system_application"]
    );
    usersetSelectedPro(originalProArr);
    let newArr = JSON.parse(JSON.stringify(softwareInventory));
    newArr.push(data);
    setSoftwareInventory(
      newArr.sort((a, b) =>
        a.system_application > b.system_application
          ? 1
          : b.system_application > a.system_application
          ? -1
          : 0
      )
    );
  }

  useEffect(() => {
    if (
      inventory &&
      inventory.suggested_software_products &&
      inventory.suggested_software_products.length > 0
    ) {
      if (softwareInventory.length === 0) {
        setSoftwareInventory(
          inventory.suggested_software_products.sort((a, b) =>
            a.system_application > b.system_application
              ? 1
              : b.system_application > a.system_application
              ? -1
              : 0
          )
        );
      } else {
        let { access } = inventory;

        const res = softwareInventory.filter(
          (x) => !access.some((y) => y.product_id === x.id)
        );
        setSoftwareInventory(res);
      }
    }
  }, [inventory]);

  const handleSaveAccess = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("users_id", userProfile[0].id);
    if (userselectedPro.length > 0) {
      userselectedPro.map((product) =>
        fd.append(product.system_shortname, product.system_application)
      );

      newHireAccessRequestTwo(fd).then(() => {
        showPopup(
          "Data Updated Successfully for " +
            userProfile[0]?.first_name +
            " " +
            userProfile[0]?.last_name
        );
        getTheIDOfUser(userProfile[0]?.id, userProfile[0]?.email);
        setNewRecord(false);
        usersetSelectedPro([]);
        lookupSoftwareSuggestions();
      });
    }
  };

  return (
    <Container maxWidth="lg">
      <h2>Look up by users by first or last name:</h2>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <div>
              {!userProfile ? (
                <Input
                  style={{ width: "100%" }}
                  icon="search"
                  placeholder="Search..."
                  onChange={onAuttoSuggestChange}
                  value={userQuery}
                />
              ) : (
                <Button onClick={clearLook}>Search Again</Button>
              )}
              {profileSuggestions
                ? profileSuggestions.map((user) => (
                    <Segment
                      key={user.id}
                      style={{ maxWidth: "100%", cursor: "pointer" }}
                      onClick={(id, email) => {
                        getTheIDOfUser(user.id, user.email);
                      }}
                    >
                      {user.first_name + " " + user.last_name}
                    </Segment>
                  ))
                : null}
              {profileSuggestions && profileSuggestions.length === 0 ? (
                <Segment>No Users Found</Segment>
              ) : null}
            </div>
            {userProfile ? (
              <Form onSubmit={(e) => handleHardwareSubmit(e)}>
                <hr />
                <h4>Add User Inventory</h4>
                <Form.Field>
                  <Input
                    placeholder="Asset Tag"
                    onChange={onHardwareChange}
                    name="asa_tag"
                    value={asa_tag}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    placeholder="Serial Number"
                    onChange={onHardwareChange}
                    name="model_number"
                    value={model_number}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    placeholder="Description"
                    onChange={onHardwareChange}
                    name="description"
                    value={description}
                  />
                </Form.Field>
                <Form.Field>
                  <Button type="button" onClick={() => setModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button type="submit">Submit</Button>
                </Form.Field>
                {inventory.message ? (
                  <div style={{ color: "red" }}>
                    {inventory.message.message}
                  </div>
                ) : null}
              </Form>
            ) : null}
          </Grid.Column>
          <Grid.Column>
            {userProfile ? (
              <div>
                <h5>Click to edit.</h5>
                <Paper
                  style={{
                    padding: "10px",
                    margin: "10px auto",
                  }}
                >
                  <div>
                    <span style={spanstyle}>
                      {auth.token.upn !== userProfile[0].email ? (
                        <Icon
                          name="edit"
                          style={{ color: "orange", cursor: "pointer" }}
                          onClick={() =>
                            setVisibilty({
                              ...visiblity,
                              namevisible: !namevisible,
                            })
                          }
                        />
                      ) : null}
                      Name:&nbsp;
                    </span>
                    {userProfile[0].first_name} {userProfile[0].middle_initial}{" "}
                    {userProfile[0].last_name}
                    <Transition
                      visible={namevisible}
                      animation="scale"
                      duration={500}
                    >
                      <Form onSubmit={(e) => profileUpdate(e)}>
                        <Form.Field>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="4">
                                <Input
                                  placeholder={userProfile[0].first_name}
                                  name="newFirstName"
                                  value={newFirstName}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="4">
                                <Input
                                  placeholder={userProfile[0].middle_initial}
                                  name="newMiddleName"
                                  value={newMiddleName}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="4">
                                <Input
                                  placeholder={userProfile[0].last_name}
                                  name="newLastName"
                                  value={newLastName}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="4">
                                <Button>Submit</Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      </Form>
                    </Transition>
                  </div>
                  <div>
                    <span style={spanstyle}>
                      {auth.token.upn !== userProfile[0].email ? (
                        <Icon
                          name="edit"
                          style={{ color: "orange", cursor: "pointer" }}
                          onClick={() =>
                            setVisibilty({
                              ...visiblity,
                              emailvisible: !emailvisible,
                            })
                          }
                        />
                      ) : null}
                      Email:&nbsp;
                    </span>
                    {userProfile[0].email}
                    <Transition
                      visible={emailvisible}
                      animation="scale"
                      duration={500}
                    >
                      <Form onSubmit={(e) => profileUpdate(e)}>
                        <Form.Field>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="13">
                                <Input
                                  placeholder={userProfile[0].email}
                                  name="newEmail"
                                  value={newEmail}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="3">
                                <Button>Submit</Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      </Form>
                    </Transition>
                  </div>

                  <div>
                    <span style={spanstyle}>
                      {auth.token.upn !== userProfile[0].email ? (
                        <Icon
                          name="edit"
                          style={{ color: "orange", cursor: "pointer" }}
                          onClick={() =>
                            setVisibilty({
                              ...visiblity,
                              managernamevisible: !managernamevisible,
                            })
                          }
                        />
                      ) : null}
                      Manager:&nbsp;{" "}
                    </span>
                    {userProfile[0].manager_name}
                    <Transition
                      visible={managernamevisible}
                      animation="scale"
                      duration={500}
                    >
                      <Form onSubmit={(e) => profileUpdate(e)}>
                        <Form.Field>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="13">
                                <Input
                                  placeholder={userProfile[0].manager_name}
                                  name="newManager"
                                  value={newManager}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="3">
                                <Button>Submit</Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      </Form>
                    </Transition>
                  </div>
                  <div>
                    <span style={spanstyle}>
                      {auth.token.upn !== userProfile[0].email ? (
                        <Icon
                          name="edit"
                          style={{ color: "orange", cursor: "pointer" }}
                          onClick={() =>
                            setVisibilty({
                              ...visiblity,
                              deptspealizationvisible: !deptspealizationvisible,
                            })
                          }
                        />
                      ) : null}
                      Department Specialization:&nbsp;{" "}
                    </span>
                    {userProfile[0].dept_specialization}
                    <Transition
                      visible={deptspealizationvisible}
                      animation="scale"
                      duration={500}
                    >
                      <Form onSubmit={(e) => profileUpdate(e)}>
                        <Form.Field>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="13">
                                <Input
                                  placeholder={
                                    userProfile[0].dept_specialization
                                  }
                                  name="newDeptSpecialization"
                                  value={newDeptSpecialization}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="3">
                                <Button>Submit</Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      </Form>
                    </Transition>
                  </div>
                  <div>
                    <span style={spanstyle}>
                      {auth.token.upn !== userProfile[0].email ? (
                        <Icon
                          name="edit"
                          style={{ color: "orange", cursor: "pointer" }}
                          onClick={() =>
                            setVisibilty({
                              ...visiblity,
                              accesslevelvisible: !accesslevelvisible,
                            })
                          }
                        />
                      ) : null}
                      Access Level:&nbsp;{" "}
                    </span>
                    {userProfile[0].privilege_level === 1 ? (
                      <span>Super Admin</span>
                    ) : userProfile[0].privilege_level === 2 ? (
                      <span>Admin</span>
                    ) : userProfile[0].privilege_level === 3 ? (
                      <span>Manager</span>
                    ) : userProfile[0].privilege_level === 4 ? (
                      <span>HR Generalist</span>
                    ) : userProfile[0].privilege_level === 5 ? (
                      <span>Data OPS Agent</span>
                    ) : userProfile[0].privilege_level === 6 ? (
                      <span>IT Agent</span>
                    ) : userProfile[0].privilege_level === 7 ? (
                      <span>User</span>
                    ) : null}
                    <Transition
                      visible={accesslevelvisible}
                      animation="scale"
                      duration={500}
                    >
                      <Form onSubmit={(e) => profileUpdate(e)}>
                        <Form.Field>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="13">
                                <Input
                                  placeholder={userProfile[0].privilege_level}
                                  name="newAccessLevel"
                                  value={newAccessLevel}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="3">
                                <Button>Submit</Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      </Form>
                    </Transition>
                  </div>
                  <div>
                    <span style={spanstyle}>
                      {auth.token.upn !== userProfile[0].email ? (
                        <Icon
                          name="edit"
                          style={{ color: "orange", cursor: "pointer" }}
                          onClick={() =>
                            setVisibilty({
                              ...visiblity,
                              statusvisible: !statusvisible,
                            })
                          }
                        />
                      ) : null}
                      Status:&nbsp;{" "}
                    </span>
                    {userProfile[0].status === "Y" ? (
                      <span>Active</span>
                    ) : userProfile[0].status === "N" ? (
                      <span>Inactive</span>
                    ) : userProfile[0].status === "D" ? (
                      <span>Deleted</span>
                    )
                      : null}
                    <Transition
                      visible={statusvisible}
                      animation="scale"
                      duration={500}
                    >
                      <Form onSubmit={(e) => profileUpdate(e)}>
                        <Form.Field>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="13">
                                <Input
                                  placeholder={"Y, N"}
                                  name="statusVisible"
                                  value={statusVisible}
                                  onChange={onUserUpdateChange}
                                />
                              </Grid.Column>
                              <Grid.Column width="3">
                                <Button>Submit</Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      </Form>
                    </Transition>
                  </div>
                </Paper>
              </div>
              
            ) : null}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {inventory.inventory && <h2>Equipments</h2>}
          
            {userProfile
              ? !inventory.inventory
                ? null
                : inventory.inventory.map((item) => (
                    <Paper
                      key={item.id}
                      style={{
                        position: "relative",
                        padding: "10px",
                        margin: "10px auto",
                      }}
                    >
                      <p>
                        <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                        {item.description}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Asset Tag: </span>
                        {item.asa_tag}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Model Number:{" "}
                        </span>
                        {item.model_number}
                      </p>
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        <Modal
                          open={modalOpen === item.id}
                          onOpen={() => setModalOpen(item.id)}
                          onClose={() => setModalOpen(false)}
                          trigger={
                            <Icon
                              name="trash alternate outline"
                              style={{
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          }
                          closeIcon
                        >
                          <Modal.Header>
                            Remove {item.asa_tag} from{" "}
                            {userProfile[0].first_name} ?
                          </Modal.Header>
                          <Modal.Actions>
                            <Button
                              type="button"
                              onClick={() => setModalOpen(false)}
                            >
                              No
                            </Button>
                            <Button
                              type="submit"
                              onClick={(asset) =>  removeInventory(item, "hardware")}
                            >
                              Yes
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </span>
                    </Paper>
                  ))
              : null}
          </Grid.Column>
          <Grid.Column>
            {inventory.inventory && (
              <Grid columns={3} verticalAlign="middle">
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <h2>System Access </h2>
                  </Grid.Column>
                  <Grid.Column align="right">
                    <h4
                      onClick={() => setNewRecord(true)}
                      style={{
                        textAlign: "right",
                        textDecoration: "underline",

                        cursor: "pointer",
                        display: "inline-block",
                      }}
                    >
                      Add New
                    </h4>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {userProfile
              ? !inventory.access
                ? null
                : inventory.access.map((item) => (
                    <Paper
                      key={item.id}
                      style={{
                        position: "relative",
                        padding: "10px",
                        margin: "10px auto",
                      }}
                    >
                      {}
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Product Name:
                        </span>{" "}
                        {item.system_application}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Vendor: </span>
                        {item.vendor}
                      </p>
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        <Modal
                          open={modalOpen === item.id}
                          onOpen={() => setModalOpen(item.id)}
                          onClose={() => setModalOpen(false)}
                          trigger={
                            <Icon
                              name="trash alternate outline"
                              style={{
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          }
                          closeIcon
                        >
                          <Modal.Header>
                            Remove {item.system_application} from{" "}
                            {userProfile[0].first_name} ?
                          </Modal.Header>
                          <Modal.Actions>
                            <Button
                              type="button"
                              onClick={() => setModalOpen(false)}
                            >
                              No
                            </Button>
                            <Button
                              type="submit"
                              onClick={(asset) =>
                                removeInventory(item, "access")
                              }
                            >
                              Yes
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </span>
                    </Paper>
                  ))
              : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {newRecord && (
        <Modal
          open={newRecord}
          onClose={() => setNewRecord(false)}
          trigger={
            <Icon
              name="trash alternate outline"
              style={{
                color: "red",
                cursor: "pointer",
              }}
            />
          }
          closeIcon
        >
          <Modal.Header>Add New </Modal.Header>
          <Modal.Content>
            <div>
              <Form>
                <Form.Field>
                  <Grid columns="equal">
                    <Grid.Row>
                      <Grid.Column width="7">
                        <h3 style={{ textDecoration: "underline" }}>
                          Select a product From below
                        </h3>
                      </Grid.Column>
                      <Grid.Column width="1"></Grid.Column>
                      <Grid.Column width="7">
                        <h3 style={{ textDecoration: "underline" }}>
                          Selected Products
                        </h3>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="7">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {console.log(
                            "softwareInventory.length: ",
                            softwareInventory.length
                          )}
                          {softwareInventory &&
                            softwareInventory.length > 0 &&
                            softwareInventory.map((x, i) => {
                              return (
                                <button
                                  onClick={() => handleSoftwareInventory(x, i)}
                                  type="button"
                                  class="btn-new first"
                                  key={i}
                                >
                                  {x["system_application"]}
                                </button>
                              );
                            })}
                        </div>
                      </Grid.Column>
                      <Grid.Column width="1">
                        <div class="vl"></div>
                      </Grid.Column>
                      <Grid.Column width="7">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {userselectedPro &&
                            userselectedPro.length > 0 &&
                            userselectedPro.map((x, i) => {
                              return (
                                <button
                                  onClick={() =>
                                    handleSelectedSoftwareInventory(x, i)
                                  }
                                  type="button"
                                  class="btn-new first btn-new-selected"
                                  key={i}
                                >
                                  {x["system_application"]}
                                </button>
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Form>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button type="button" onClick={() => setNewRecord(false)}>
              No
            </Button>
            <Button type="submit" onClick={(e) => handleSaveAccess(e)}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.profile.isLoading,
  userProfile: state.profile.user_profile,
  inventory: state.inventory,
  profileSuggestions: state.profile.suggestions,
  auth: state.auth,

});

export default connect(mapStateToProps, {
  lookupUserProfile,
  updateUserProfile,
  getUserInventory,
  addProfileInventory,
  removeItemFromUser,
  clearUserProfileSearch,
  getTheLookedUpUser,
  clearAllLookupDAta,
  getAccessbyUser,
  removeAcessFromUser,
lookupSoftwareSuggestions,
newHireAccessRequestTwo
})(UserLookup);
