import axios from "axios";
import { setAlert } from "./alertActions";
import {
  GET_ALL_PENDING_ACCESS_REQUESTS,  
  MODAL_OPEN,
  FORM_OK,
  FORM_NOT_OK,
  START_NEW_HIRE_FORM,
  GET_AR_COMMENTS,
  ACCRESS_REQUEST_LOOKUP,
  REMOVE_SELECTED_SOFTWARE,
  CLEAR_SOFTWARE_SUGGESTIONS,
  CLEAR_USERS_FOR_TERMINATION,
  GET_USERS_FOR_TERMINATION,
  SELECT_USER_FOR_TERMINATION,
  CLEAR_ALL_TERMINATION,
  GET_IT_NEWHIRE_REQUEST,
  GET_DROPDOWN_LIST,
  GET_HR_LIST,
  GET_ALL_INTAKE_REQUESTS,
  GET_SINGLE_INTAKE
} from "./types";

export const getAllPendingAccessRequests = body => async dispatch => {
  try {
    const res = await axios.post("/api/access-request/admin-get-all", body);
    dispatch({
      type: GET_ALL_PENDING_ACCESS_REQUESTS,
      payload: res.data
    });
  } catch (err) {
    const res = {data:{message:"There was an error retrieving the access requests, please refresh the page. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const newHireAccessRequestOne = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/new-hire-access-request-one",
      body
    );
    // await dispatch({
    //   type: MODAL_OPEN,
    //   payload: res.data
    // });
  } catch (err) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const managerApproveModifyRequest = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/manager-approved-modify-request",
      body
    );
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    await dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const newHireAccessFinalRequest = body => async dispatch => {
  try {
    const res = await axios.post("/api/access-request/final-hr-stage", body);
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    await dispatch({
      type: REMOVE_SELECTED_SOFTWARE
    });
    await dispatch({
      type: CLEAR_SOFTWARE_SUGGESTIONS
    });
    await dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (err) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};
export const newHireAccessRequestTwo = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/new-hire-access-request-two",
      body
    );
    // await dispatch({
    //   type: MODAL_OPEN,
    //   payload: res.data
    // });
  } catch (err) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const sendNewHireDataThrough = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/modify-access-request",
      body
    );
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    await dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const denyModificationRequest = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/deny-modification-request",
      body
    );
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    await dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const getMyAccessRequests = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/get-my-access-requests",
      body
    );
    dispatch({
      type: GET_ALL_PENDING_ACCESS_REQUESTS,
      payload: res.data
    });
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const getMAnagerApprovedAccessRequests = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/get-manager-approved-access-request",
      body
    );

    dispatch({
      type: GET_IT_NEWHIRE_REQUEST,
      payload: res.data
    });

    dispatch({
      type: GET_AR_COMMENTS,
      payload: res.data.comments
    });
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const getManagerPendingARRequests = body => async dispatch => {
  try {
   await axios.post(
      "/api/access-request/get-manager-start-form",
      body
    );
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const newHireITFinish = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/complete-access-request",
      body
    );
    // dispatch({
    //   type: MODAL_OPEN,
    //   payload: res.data
    // });
    // dispatch({
    //   type: FORM_OK
    // });
    setTimeout(() => {
      // dispatch({
      //   type: FORM_NOT_OK
      // });
      if(res.data.ticketId){
        window.location.href = "/pending-tickets/"+res.data.ticketId
      }
    }, 1000);
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};
export const modifyLookup = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/modify-user-lookup",
      body
    );
    dispatch({
      type: ACCRESS_REQUEST_LOOKUP,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const newHireHRFormStart = body => async dispatch => {
  try {
    const res = await axios.post("/api/access-request/new-hire-hr-start", body);
    dispatch({
      type: START_NEW_HIRE_FORM
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (err) {
    console.log('err In CATCH BLOCK: ', err);
    const errors = err.response.data.errors;
  
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const lookupUsersForTermination = body => async dispatch => {
  try {
    const fd = new FormData();
    fd.append("term_user_lookup", body);
    const res = await axios.post(
      "/api/access-request/lookup-users-for-termination",
      fd
    );
    dispatch({
      type: GET_USERS_FOR_TERMINATION,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const selectedUserToTerminate = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/select-user-for-termination",
      body
    );
    dispatch({
      type: SELECT_USER_FOR_TERMINATION,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const terminateEmployee = body => async dispatch => {
  try {
    const res = await axios.post("/api/access-request/terminate", body);
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

// this is the function that approves the termination in PendingModifyRequest.js

export const hrApproveTermination = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/hr-approved-termination",
      body
    );
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    dispatch({
      type: FORM_OK
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK
      });
    }, 1000);
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};
export const getDepartmentDropdown = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/get-department-dropdown",
      body
    );

    dispatch({
      type: GET_DROPDOWN_LIST,
      payload: res.data
    });

  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};
export const getHRDropdown = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/get-hr-list",
      body
    );

    dispatch({
      type: GET_HR_LIST,
      payload: res.data
    });

  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const clearUsersForTermination = () => dispatch => {
  dispatch({ type: CLEAR_USERS_FOR_TERMINATION });
};
export const clearAllTermination = () => dispatch => {
  dispatch({ type: CLEAR_ALL_TERMINATION });
};


export const saveIntakeForm = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/save-intake-form",
      body
    );
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  } catch (err) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const updateIntakeForm = body => async dispatch => {
  try {
    const res = await axios.post(
      "/api/access-request/update-intake-form",
      body
    );
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  } catch (err) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const getAllIntakeFormRequests = body => async dispatch => {
  try {
    const res = await axios.post("/api/access-request/get-intake-form-list", body);
    dispatch({
      type: GET_ALL_INTAKE_REQUESTS,
      payload: res.data
    });
  } catch (err) {
    const res = {data:{message:"There was an error retrieving the access requests, please refresh the page. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const getSingleIntakeForm = body => async dispatch => {
  console.log("body",body)
  try {
    const fd = new FormData()
    fd.append("id",body)
    const res = await axios.post("/api/access-request/get-singe-intake-form", fd);
    await dispatch({
      type: GET_SINGLE_INTAKE,
      payload: res.data
    });
    // window.location.href = "/compilance-form/"
  } catch (err) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};