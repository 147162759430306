// for now this is for the admins, need to refactor to use anywhere.

import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";

import {
  checkNewStatus,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
} from "../../../Redux/Actions/ticketActions";
import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {
  get_ITticket_comments,
  add_ITticket_comment
} from "../../../Redux/Actions/commentActions";
import { Redirect, Link } from "react-router-dom";
import { Segment, Grid,Form, Icon } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

const ModalTicketTest = ({
  tickets: { tickets },
  redirect,
  get_ITticket_comments,
  checkNewStatus,
  auth,
  add_ITticket_comment,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
}) => {
  const username = auth.token.name;
  const [itComment, setItComment] = useState({
    it_comment: ""
  });
  const [rootCauseExplanation, setRootCauseExplanation] = useState({
    explanation: ""
  });

  const [allTickets,setAllTickets] = useState('')
  const [filTickets, setFilterTikcets] = useState('')
  const { explanation } = rootCauseExplanation;
  const { it_comment } = itComment;
 useEffect(()=>{ 
   setFilterTikcets(tickets)
   setAllTickets(tickets)
 },[tickets])
  const searchTickets = (event) => {
    let searchValue = event.currentTarget.value;    
    if(searchValue){
      var array = [...tickets];
      //var value = action.payload;
      var index = array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
      var items = index;
      setFilterTikcets(items);     
    }else{
      setFilterTikcets(allTickets)
    }
   
  }
  // const handleAddITComment = (e, id, user) => {
  //   const fd = new FormData();
  //   fd.append("it_comment", it_comment);
  //   fd.append("user", user);
  //   fd.append("id", id);
  //   fd.append("commentor", username);
  //   add_ITticket_comment(fd).then(() => {
  //     setItComment({
  //       ...itComment,
  //       it_comment: ""
  //     });
  //   });
  // };
  // const adminStatusOptions = [
  //   { key: "PNA", value: "ACTION NEEDED", text: "ACTION NEEDED" },
  //   { key: "P", value: "PENDING", text: "PENDING" },
  //   { key: "RES", value: "RESOLVED", text: "RESOLVED" }
  // ];
  // const handleModalOpen = (event, data, id) => {
  //   const fd = new FormData();
  //   fd.append("ticket_type", "IT");
  //   fd.append("it_ticket_id", id);
  //   get_ITticket_comments(fd);
  //   checkNewStatus(fd);
  //   getTicketAttachments(fd);
  // };
  // const handleModalClose = () => {  
  //   clearAttachments();
  // };
  // const handleITCommentChange = e => {
  //   setItComment({
  //     ...itComment,
  //     it_comment: e.target.value
  //   });
  // };
  // const handleRootExplination = e => {
  //   setRootCauseExplanation({
  //     ...rootCauseExplanation,
  //     explanation: e.target.value
  //   });
  // };
  // const handleRootCauseSubmit = (e, ticket_id) => {
  //   e.preventDefault();
  //   const fd = new FormData();
  //   if (adminStatus === "RESOLVED") {
  //     fd.append("resolved_by", auth.user[0].id);
  //   }
  //   fd.append("ticket_to_update", ticket_id);
  //   fd.append("new_admin_status", adminStatus);
  //   fd.append("explination", explanation);

  //   adminStatusUpdate(fd);
  // };
  let singleTicket;
  tickets
    ? (singleTicket = tickets.sort((left, right) => {
        return moment.utc(right.created).diff(moment.utc(left.created));
      }))
    : (singleTicket = null);    
  if (!tickets || tickets.length === 0 || !filTickets ) {
    // singleTicket = <LoaderPlaceholder />;
    singleTicket = ""
  } else {
    
    singleTicket = filTickets.map(ticket => (
      <Link
        to={`/pending-tickets/${ticket.ti_id}`}
        key={ticket.ti_id}
        style={{ margin: "0 5px" }}
      >
        <Segment className="individual-ticket-reporting-wrapper">
          <ReactTooltip />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <h4>
                  {ticket.ti_ticket_author_name} | {ticket.ti_priority}
                </h4>
                <hr className="style-six" />
                {/* <h4>{ticket.ti_subject}</h4> */}
                <h4>Assigned To : {ticket.ticket_assigned_to ? ticket.ticket_assigned_to : "UNASSIGNED"}</h4>
                <hr className="style-six" />
                Created:{" "}
                <Moment date={ticket.created} format="MM/DD/YYYY HH:mm" />
              </Grid.Column>
              <Grid.Column>
                {ticket.ti_type === "Question" ? (
                  <Icon
                    name="question"
                    data-tip="Question"
                    size="large"
                    color="red"
                  />
                ) : ticket.ti_type === "Incident" ? (
                  <Icon
                    name="exclamation triangle"
                    data-tip="Incident"
                    size="large"
                    color="red"
                  />
                ) : ticket.ti_type === "Problem" ? (
                  <Icon
                    name="exclamation"
                    data-tip="Problem"
                    size="large"
                    color="red"
                  />
                ) : ticket.ti_type === "Task" ? (
                  <Icon
                    name="calendar check outline"
                    data-tip="Task"
                    size="large"
                    color="red"
                  />
                ) : null}
                <p>Ticket ID: {ticket.ti_id}</p>
                <p>Status: {ticket.ti_admin_status}</p>
                <p>{ticket.ti_description.slice(0, 50)}...see more.</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Link>
    ));
  }
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return <div >
     <Form>
     <div class="ui icon input"  style={{ width: "100%" }}>       
          <input
          onChange={searchTickets}         
          placeholder="Search Ticket"          
        />
          <i aria-hidden="true" class="search icon"></i></div>
      </Form>
    {singleTicket}<i aria-hidden="true" ></i></div>;
     
};
const mapStateToProps = state => ({
  auth: state.auth,
  attachments: state.tickets.attachments,
  tickets: state.tickets,
  redirect: state.redirect,
  comments: state.comments
});
export default connect(mapStateToProps, {
  get_ITticket_comments,
  checkNewStatus,
  add_ITticket_comment,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
})(ModalTicketTest);
