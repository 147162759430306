import {
  NEW_TICKET_SUCCESS,
  NEW_TICKET_FAILURE,
  GET_ADMIN_TICKETS,
  ADMIN_TICKET_FALIURE,
  CLEAR_ADMIN_TICKET_SEARCH,
  ADMIN_ADD_TICKET_COMMENTS,
  GET_ALL_USER_TICKETS,
  SEND_BUG,
  ADD_SUGGESTION,
  GET_BUGS,
  ADMIN_UPDATE,
  GET_TICKET_ATTACHMENTS,
  CLEAR_TICKET_ATTACHMENTS,
  GET_ALL_IT_USERS,
  CHANGE_TICKETS_ASSIGNED_TO,
  GET_ADMIN_TICKETS_SINGLE,
  GET_IT_TICKET_ADMIN_ATTACHMENTS
} from "../Actions/types";
// import update from "immutability-helper";
const initialState = {
  message: null,
  ticket: null,
  tickets: null,
  bugs: null,
  loading:true,
  attachments:null,
  admin_attachments: null,
  allActiveItUsers: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_TICKET_ATTACHMENTS: 
    return {
      ...state,
      loading:true,
      attachments:null
    }
    case GET_TICKET_ATTACHMENTS:
      return {
        ...state,
        attachments:payload,
        loading:false
      }
    case GET_IT_TICKET_ADMIN_ATTACHMENTS:
      return {
        ...state,
        admin_attachments:payload,
        loading:false
      }
    case NEW_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload
      };
    case NEW_TICKET_FAILURE:
      return {
        ...state
      };
    case GET_ADMIN_TICKETS:
      return {
        ...state,
        loading: false,
        tickets: payload,
        singleTickets: null
      };
      case GET_ADMIN_TICKETS_SINGLE:
      return {
        ...state,
        loading: false,
        singleTickets: payload,
        tickets: null
      };
    // case ADMIN_UPDATE:
    //   return state.tickets.map(ticket => {
    //     if (ticket.ti_id === payload.ti_id) {
    //       return {
    //         ...ticket,
    //         ti_admin_status: payload.ti_admin_status
    //       };
    //     }
    //     return ticket;
    //   });

    case ADMIN_TICKET_FALIURE:
      return { ...state, message: payload };
    case CLEAR_ADMIN_TICKET_SEARCH:
      return {
        ...state,
        ticket: null,
        tickets: null
      };
    case ADMIN_ADD_TICKET_COMMENTS:
      // i need to figure this out
      return {
        ...state,
        tickets: payload
      };
    case GET_ALL_USER_TICKETS:
      if (payload.length === 0) {
        return {
          ...state,
          tickets: null
        };
      } else {
        return {
          ...state,
          tickets: payload
        };
      }
    case GET_BUGS:
      return {
        ...state,
        bugs: payload
      };
    case SEND_BUG:
      return {
        ...state
      };
    case ADD_SUGGESTION:
      return {
        ...state
      };
    case GET_ALL_IT_USERS:
      return {
        ...state,
        allActiveItUsers: payload,
        loading:false
    };  
    case CHANGE_TICKETS_ASSIGNED_TO:
      return {
        ...state
      }
    default:
      return state;
  }
}