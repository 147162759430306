import {
  GET_ALL_PENDING_DATAOPS,
  CHANGE_DATAOPS_ASSIGNED_TO,
  CHANGE_DATAOPS_STATUS,
  GET_DATA_TICKET_ATTACHMENTS,
  CLEAR_ADMIN_DR_SEARCH,
  GET_ALL_DR_USERS,
  GET_SINGLE_PENDING_DATAOPS,
  GET_DATA_TICKET_ADMIN_ATTACHMENTS
} from "../Actions/types";
const initialState = {
  requests: null,
  pending: null,
  attachments:null,
  assignedUser: null
};
export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PENDING_DATAOPS:
      if (payload.length === 0) {
        return {
          ...state,
          requests: null,
          singleRequests: null
        };
      } else {
        return {
          ...state,
          requests: payload,
          singleRequests: null
        };
      }
      case GET_SINGLE_PENDING_DATAOPS:
      if (payload.length === 0) {
        return {
          ...state,
          singleRequests: null,
          requests: null
        };
      } else {
        return {
          ...state,
          singleRequests: payload,
          requests: null
        };
      }
      case GET_DATA_TICKET_ATTACHMENTS:
        return {
          ...state,
          attachments:payload
        }
        case GET_DATA_TICKET_ADMIN_ATTACHMENTS:
          return {
            ...state,
            admin_attachments:payload,
            loading:false
          }
        case GET_ALL_DR_USERS:
          return {
            ...state,
            assignedUser: payload,
            loading:false
        };
    case CHANGE_DATAOPS_STATUS:
    case CHANGE_DATAOPS_ASSIGNED_TO:
      return {
        ...state,
        requests: payload
      };
      case CLEAR_ADMIN_DR_SEARCH: // added malik start //
        return {
          ...state,
          dataOps: null
        };   // added malik end //
    default:
      return state;
  }
}