import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

import {
  Container,
  Form,
  Grid,
  Input,
  Icon,
  Modal,
  Button,
  Dropdown
} from "semantic-ui-react";
import { modifyLookup,saveIntakeForm } from "../../../Redux/Actions/accessRequestActions";
import { throttle } from "lodash";
import { Redirect } from "react-router-dom";
import {
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  setRequestedAccessModification,
} from "../../../Redux/Actions/inventoryActions";
registerPlugin(FilePondPluginImagePreview);
function IntakeForm({
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  auth,
  redirect,
  inventory,
  setRequestedAccessModification,
  saveIntakeForm
}) {
  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
    ticketAttachment_two: "",
    ticketAttachment_three: "",
    ticketAttachment_four: "",
  });

  const {
    ticketAttachment_one,
    ticketAttachment_two,
    ticketAttachment_three,
    ticketAttachment_four,
  } = picture;

  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
        ticketAttachment_two: !fileItems[1] ? null : fileItems[1].file,
        ticketAttachment_three: !fileItems[2] ? null : fileItems[2].file,
        ticketAttachment_four: !fileItems[3] ? null : fileItems[3].file,
      });
    }
  };

  const [checkBoxArr, setcheckBoxArr] = useState([
    {
      label: "Have members personal information, such as name, address, etc.?",
    },
    {
      label: "Have members medical information?",
    },
    {
      label: "Contact members via written correspondence?",
    },
    {
      label: "Contact members via telephone?",
    },
    {
      label: "Contact members in-person?",
    },
    {
      label: "Mail information to members?",
    },
    {
      label: "Interact with members to improve their quality of health?",
    },
    {
      label: "Make decisions on behalf of CNC-NC?",
    },
    {
      label:
        "Take direction from CNC-NC regarding any decisions that need to be made?",
    },
    {
      label: "Perform a “one-time” service for CNC-NC?",
    },
    {
      label: "Perform an “on-demand” services for CNC-NC?",
    },
  ]);

  const [checkBoxArr1, setcheckBoxArr1] = useState([
    {
      label: "Debarment Screening Completed?",
    },
    {
      label: "Exclusion Screening Completed?",
    },
    {
      label: "Is the vendor an FDR?",
    },
    {
      label: "If FDR, has Code of Conduct been sent?",
    },
    {
      label: "Was an entry made the in the Contracts Library for the vendor?",
    },
  ]);

  const [cnc_nc_requestername, setcnc_nc_requestername] = useState("");
  const [todayDate, settodayDate] = useState("");
  const [dateServiceToBegin, setdateServiceToBegin] = useState("");
  const [contractProperty, setcontractProperty] = useState("");
  const [criticalDate, setcriticalDate] = useState("");
  const [fdrType, setfdrType] = useState("");
  const [serviceRequire, setserviceRequire] = useState("");
  const [vendorContactName, setvendorContactName] = useState("");
  const [vendorContactEmail, setvendorContactEmail] = useState("");
  const [vendorLegalName, setvendorLegalName] = useState("");
  const [vendorFunctions, setvendorFunctions] = useState("");
  const [serviceCharge, setserviceCharge] = useState("");

  const [processing, setProcessing] = useState(false)

  const userChecked = (index, value) => {
    let tempArr = JSON.parse(JSON.stringify(checkBoxArr));
    tempArr[index]["value"] = value;
    setcheckBoxArr(tempArr);
  };
 
  const [migrateToHome,setmigrateToHome] = useState(false)

  const handleSubmit = (e) => {
      e.preventDefault()
      if(!(cnc_nc_requestername&&todayDate&&dateServiceToBegin&&contractProperty&&criticalDate&&fdrType&&serviceRequire&& vendorContactName&& vendorContactEmail&& vendorLegalName&& vendorFunctions&&serviceCharge&&checkBoxArr)){
        return alert("All Fields are Mandatory")
      }
      if(JSON.parse(JSON.stringify(checkBoxArr)).map(x=>x['value']).filter(Boolean).length !== JSON.parse(JSON.stringify(checkBoxArr)).length){
          return alert("Please Fill All Values From Table 1")
      }
      const formData = new FormData()
    formData.append("cnc_nc_requestername", cnc_nc_requestername);
    formData.append("todayDate", todayDate);
    formData.append("dateServiceToBegin", dateServiceToBegin);
    formData.append("contractProperty", contractProperty);
    formData.append("criticalDate", criticalDate);
    formData.append("fdrType", fdrType);
    formData.append("serviceRequire", serviceRequire);
    formData.append("vendorContactName", vendorContactName);
    formData.append("vendorContactEmail", vendorContactEmail);
    formData.append("vendorLegalName", vendorLegalName);
    formData.append("vendorFunctions", vendorFunctions);
    formData.append("serviceCharge", serviceCharge);
    formData.append("checkBoxArr", JSON.stringify(checkBoxArr));
    formData.append("ticketAttachment_one", ticketAttachment_one);
    formData.append("ticketAttachment_two", ticketAttachment_two);
    formData.append("ticketAttachment_three", ticketAttachment_three);
    formData.append("ticketAttachment_four", ticketAttachment_four);
    formData.append("userId", auth.user[0].id);
    setProcessing(true)
    saveIntakeForm(formData)
    .then(()=>{
      setmigrateToHome(true)
        //  setcnc_nc_requestername("");
        //  settodayDate("");
        //  setdateServiceToBegin("");
        //  setcontractProperty("");
        //  setcriticalDate("");
        //  setfdrType("");
        //  setserviceRequire("");
        //  setvendorContactName("");
        //  setvendorContactEmail("");
        //  setvendorLegalName("");
        //  setvendorFunctions("");
        //  setserviceCharge("");
        //  setcheckBoxArr([
        //     {
        //       label: "Have members personal information, such as name, address, etc.?",
        //     },
        //     {
        //       label: "Have members medical information?",
        //     },
        //     {
        //       label: "Contact members via written correspondence?",
        //     },
        //     {
        //       label: "Contact members via telephone?",
        //     },
        //     {
        //       label: "Contact members in-person?",
        //     },
        //     {
        //       label: "Mail information to members?",
        //     },
        //     {
        //       label: "Interact with members to improve their quality of health?",
        //     },
        //     {
        //       label: "Make decisions on behalf of CNC-NC?",
        //     },
        //     {
        //       label:
        //         "Take direction from CNC-NC regarding any decisions that need to be made?",
        //     },
        //     {
        //       label: "Perform a “one-time” service for CNC-NC?",
        //     },
        //     {
        //       label: "Perform an “on-demand” services for CNC-NC?",
        //     },
        //   ])
    })
  };

  if(migrateToHome){
    return <Redirect to="/" />
  }

  return (
    <>
    {processing ? <Container style={{marginTop:"10px", padding:"auto 10px",textAlign:"center"}}>
    <h1>Submitting, Please Wait ...</h1>
    </Container> : <Container style={{marginTop:"10px", padding:"auto 10px"}}>
      <h1>Intake Form</h1>
      <div className="mainContainer">
      <div className="topContent">
        <h3>Background</h3>
        <p>
          The purpose of the Contract Request Intake Form is to streamline the
          contracting process to ensure business areas have external vendor
          resources to support tactical and strategic goals of CNC-NC. Every
          contract request must include this completed document, along with the
          contract documents.
        </p>
        <p>
          <b>
            Dollar Amount: If a contract totals less than $10,000, after adding
            all costs over the life of the contract, once this Form is completed
            and the requesting party has been notified that the exclusion and/or
            preclusion checks are completed satisfactorily, Compliance does not
            need to review the contract any further, unless the requesting party
            asks Compliance to review the contract.
          </b>
          Reasons that the requesting party may want Compliance to review a
          contract would be if the contract includes legal language or items the
          requesting party does not understand.
        </p>
        <p>
          <b>Two Types of Vendors:</b> There are two types of vendors: vendors
          that are <b style={{ textDecoration: "underline" }}>NOT</b> designated
          as first-tier, downstream or related (FDR) entities and those are
          designated as an FDR.
        </p>
        <p>
          In general, vendors that are{" "}
          <b style={{ textDecoration: "underline" }}>NOT</b> FDRs include
          restaurants, office supplies, member giveaways, paper shredding
          vendors, computer hardware and most software, rental car companies,
          furniture suppliers, event sponsors, trophy and awards vendors,
          construction and repair vendors, and organizations that provide
          training and videos. This is{" "}
          <b style={{ textDecoration: "underline" }}>NOT</b> an exhaustive list.
        </p>
        <p>
          In general, vendors that{" "}
          <b style={{ textDecoration: "underline" }}>ARE</b> FDRs have direct
          contact with our members via in-person engagements, telephone,
          surveys, or sales agents taking enrollment applications. FDRs may have
          member data (i.e., enrollment and clinical information) that is used
          to perform a function that would otherwise be conducted by CNC-NC.
          FDRs usually make decisions on behalf of CNC-NC and perform functions
          that CNC-NC is required to undertake based on our agreement with CMS.
          For example, Beacon processes Part C medical claims and enrollment
          applications, which makes it an FDR.
        </p>
        <p>
          All fields{" "}
          <b style={{ textDecoration: "underline" }}>must be completed</b> prior
          to submission to:{" "}
          <b style={{ textDecoration: "underline", color: "blue" }}>
            Contracts@healthteamadvantage.com
          </b>
          . Please also include the proposed vendor agreement, statement of work
          or terms and conditions.
        </p>
        <p>
          <b>Required Information</b>
        </p>
        <div style={{ display: "flex" }}>
          <div className="flex33">
            <label className='label-new'>CNC-NC Requestor Name</label>
          </div>
          <div className="flex33">
            <label className='label-new'>Today’s Date</label>
          </div>
          <div className="flex33">
            <label className='label-new'>Date Service to Begin</label>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className="flex33">
            <input className='input-new'
              type="text"
              value={cnc_nc_requestername}
              name="cnc_nc_requestername"
              onChange={(e) => setcnc_nc_requestername(e.target.value)}
            />
          </div>
          <div className="flex33">
            <input className='input-new'
              type="date"
              onChange={(e) => settodayDate(e.target.value)}
              value={todayDate}
              name="todayDate"
            />
          </div>
          <div className="flex33">
            <input className='input-new'
              type="date"
              value={dateServiceToBegin}
              name="dateServiceToBegin"
              onChange={(e) => setdateServiceToBegin(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className="flex33">
            <label className='label-new'>Contract Property</label>
          </div>
          <div className="flex33">
            <label className='label-new'>
              Critical Date(s) Before
              <br />
              Service to Begin
            </label>
          </div>
          <div className="flex33">
            <label className='label-new'>FDR/Non-FDR</label>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className="flex33">
            <select className='select-new'
              value={contractProperty}
              name="contractProperty"
              onChange={(e) => setcontractProperty(e.target.value)}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="Low (3 Months)">Low (3 Months)</option>
              <option value="Medium (2 Months)">Medium (2 Months)</option>
              <option value="High (1 Months)">High (1 Months)</option>
              <option value="Critical (2 Weeks)">Critical (2 Weeks)</option>
            </select>
          </div>
          <div className="flex33">
            <input className='input-new'
              type="date"
              value={criticalDate}
              name="criticalDate"
              onChange={(e) => setcriticalDate(e.target.value)}
            />
          </div>
          <div className="flex33">
            <select className='select-new'
              value={fdrType}
              name="fdrType"
              onChange={(e) => setfdrType(e.target.value)}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="FDR">FDR</option>
              <option value="Non-FDR">Non-FDR</option>
            </select>
          </div>
        </div>
        <div style={{ display: "flex" }}>
        <div className="flex33">
          <label className='label-new'>Will Service Require IT Review</label>
        </div>
        <div className="flex33">
          <label className='label-new'>Vendor Contact Name</label>
        </div>
        <div className="flex33">
          <label className='label-new'>Vendor Contact Email</label>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div className="flex33">
          <select className='select-new'
            value={serviceRequire}
            onChange={(e) => setserviceRequire(e.target.value)}
            name="serviceRequire"
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="flex33">
          <input className='input-new'
            type="text"
            onChange={(e) => setvendorContactName(e.target.value)}
            value={vendorContactName}
            name="vendorContactName"
          />
        </div>
        <div className="flex33">
          <input className='input-new'
            type="email"
            onChange={(e) => setvendorContactEmail(e.target.value)}
            value={vendorContactEmail}
            name="vendorContactEmail"
          />
        </div>
      </div>
      </div>
      
      <br />
      
      <div style={{ marginTop: "10px" }}>
        <label className='label-new'>Vendor Full Legal Name and Mailing Address</label>
      </div>
      <div style={{ marginTop: "10px" }}>
        <input className='input-new'
          type={"text"}
          style={{ width: "100%" }}
          value={vendorLegalName}
          onChange={(e) => setvendorLegalName(e.target.value)}
          name="vendorLegalName"
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <label className='label-new'>
          Briefly describe the function(s) to be performed by the vendor.
        </label>
      </div>
      <div style={{ marginTop: "10px" }}>
        <textarea
        className="textarea-new"
          onChange={(e) => setvendorFunctions(e.target.value)}
          value={vendorFunctions}
          name="vendorFunctions"
        />
      </div>
      
      <br />
     
      <div className="thirdPage">
        <div>
          <label className='label-new'>Does this service or product exceed $10,000?</label>
        </div>
        <div style={{ display: "flex", padding: "0px 140px" }}>
          <div style={{ flexBasis: "40%" }}>
            <input 
              onChange={(e) => setserviceCharge("Yes")}
              type="radio"
              value={'Yes'}
              name="serviceCharge"
              checked={serviceCharge==='Yes'}
            />{" "}
            Yes
          </div>
          <div style={{ flexBasis: "40%" }}>
            <input 
              onChange={(e) => setserviceCharge("No")}
              type="radio"
              value='No'
              name="serviceCharge"
              checked={serviceCharge==='No'}
            />{" "}
            No
          </div>
        </div>
        <p>
          Please complete the following survey questions regarding the proposed
          vendor’s services.
        </p>
        <table className="table-new">
          <thead>
            <tr>
              <th>Vendor will ...</th>
              <th>Yes</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            {checkBoxArr &&
              checkBoxArr.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.label}</td>
                    <td>
                      <input 
                        type="radio"
                        value='Yes'
                        onChange={(e) => userChecked(i, "Yes")}
                        name={i}
                        checked={x['value'] === 'Yes'}
                      />{" "}
                      Yes
                    </td>
                    <td>
                      <input 
                        type="radio"
                        value='No'
                        onChange={(e) => userChecked(i, "No")}
                        name={i}
                        checked={x['value'] === 'No'}
                      />{" "}
                      No
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Form.Field>
          <label>Attachment: </label>
          <span>up to four</span>
          <FilePond
            maxFiles={4}
            files={
              (ticketAttachment_one,
              ticketAttachment_two,
              ticketAttachment_three,
              ticketAttachment_four)
            }
            allowMultiple={true}
            allowImagePreview="false"
            onupdatefiles={(fileItems) => handleFilepondUpdate(fileItems)}
          />
        </Form.Field>
        <button
          type="button"
          className="buttonSubmit"
          onClick={(e) => handleSubmit(e)}
        >
          Submit
        </button>
        <br />
        <br />
      </div>
    </div>
    </Container>}
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  redirect: state.redirect,
  inventory: state.inventory,
});
export default connect(mapStateToProps, {
  modifyLookup,
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  setRequestedAccessModification,
  saveIntakeForm
})(IntakeForm);