import React, { useState, useEffect } from "react";
import { Input, Form, Button, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { addRegularInventory } from "../../../Redux/Actions/inventoryActions";
import DatePicker from "react-datepicker";
import {
  getAllInventory,
  clearAllInventory
} from "../../../Redux/Actions/inventoryActions";
const inventoryStatuses = [
  { key: "IS", value: "IN STOCK", text: "IN STOCK" },
  { key: "NW", value: "NEW / NEVER USED", text: "NEW / NEVER USED" }
];
const inventoryType = [
  { key: "PC", value: "PC TOWER", text: "PC TOWER" },
  { key: "LT", value: "LAPTOP", text: "LAPTOP" },
  { key: "MT", value: "MONITOR", text: "MONITOR" },
  { key: "PH", value: "PHONE", text: "PHONE" },
  { key: "DS", value: "DOCKING STATION", text: "DOCKING STATION" },
  { key: "OT", value: "OTHER", text: "OTHER" }
];

  function AddInventoryPane({ addRegularInventory,getAllInventory,
    clearAllInventory }) {
    useEffect(() => {
      clearAllInventory()
      getAllInventory()
    }, [getAllInventory,clearAllInventory]);
  const [inventory, setInventory] = useState({
    asset_tag: "",
    serial_number: "",
    description: "",
    status: "",
    type: "",
    other_description: "",
    warranty_expiry_date:"" 
  });
  const {
    asset_tag,
    serial_number,
    description,
    status,
    type,
    other_description,
    warranty_expiry_date
  } = inventory;
  const onInputChange = e => {
    setInventory({
      ...inventory,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (type !== "OTHER") {
      setInventory({
        ...inventory,
        other_description: ""
      });
    }
  },[other_description]);

  
  const handleNewInventory = e => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("asset_tag", asset_tag);
    fd.append("serial_number", serial_number);
    fd.append("description", description);
    fd.append("type", type);
    if (type === "OTHER") {
      fd.append("other_description", other_description);
    }
    if(warranty_expiry_date){
      const ISODueDate = warranty_expiry_date.toISOString().split("T")[0] + " 00:00:00";
    fd.append("warranty_expiry_date", ISODueDate)
    }
    addRegularInventory(fd).then(() => {
      setInventory({
        ...inventory,
        asset_tag: "",
        serial_number: "",
        description: "",
        status: "",
        type: "",
        other_description: "",
        warranty_expiry_date: ""
      });
    });
  };

  const handleDueDate = (date) => {
    setInventory({
      ...inventory,
      warranty_expiry_date: date,
    });
  };

  return (
    <Form onSubmit={e => handleNewInventory(e)}>
      <Form.Field>
        <Input
          placeholder="Asset Tag"
          value={asset_tag}
          name="asset_tag"
          onChange={onInputChange}
        />
      </Form.Field>
      <Form.Field>
        <Input
          placeholder="Serial Number"
          value={serial_number}
          name="serial_number"
          onChange={onInputChange}
        />
      </Form.Field>
      <Form.Field>
        <Input
          placeholder="Model"
          value={description}
          name="description"
          onChange={onInputChange}
        />
      </Form.Field>
      {/* <Form.Field>
        <Dropdown
          placeholder="Item Status"
          options={inventoryStatuses}
          value={status}
          selection
          onChange={(event, data) =>
            setInventory({
              ...inventory,
              status: data.value
            })
          }
        />
      </Form.Field> */}
      <Form.Field>
        <Dropdown
          placeholder="Item Type"
          options={inventoryType}
          value={type}
          selection
          onChange={(event, data) =>
            setInventory({
              ...inventory,
              type: data.value
            })
          }
        />
      </Form.Field>
      {type === "OTHER" ? (
        <Form.Field>
          <span style={{ fontSize: "12px" }}>Max 100 Characters</span>
          <Input
            placeholder="Other Description"
            value={other_description}
            name="other_description"
            onChange={onInputChange}
          />
        </Form.Field>
      ) : null}
      <Form.Field>
      <DatePicker
                onChange={handleDueDate}
                selected={warranty_expiry_date}
                placeholderText="Warranty Expiration"
                        name="warranty_expiry_date"
                      />
        </Form.Field>
      <Form.Field style={{ textAlign: "right" }}>
        <Button basic color="green">
          Submit
        </Button>
      </Form.Field>
    </Form>
  );
}

export default connect(null, { addRegularInventory,getAllInventory,
  clearAllInventory })(AddInventoryPane);