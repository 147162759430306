// for now this is for the admins, need to refactor to use anywhere.

import React, { useState } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import {
  checkNewStatus,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
} from "../../../Redux/Actions/ticketActions";
import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {
  get_ITticket_comments,
  add_ITticket_comment
} from "../../../Redux/Actions/commentActions";
import { Redirect, Link } from "react-router-dom";
import {
  Segment,
  Image,
  Grid,
  Icon,
  Modal,
  Header,
  Form,
  Input,
  Button,
  Dropdown,
  TextArea,
  Loader,
  Dimmer,
  Placeholder
} from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

const IndividualTicket = ({
  tickets: { tickets },
  comments,
  redirect,
  get_ITticket_comments,
  checkNewStatus,
  auth,
  add_ITticket_comment,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments,
  attachments
}) => {
  const username = auth.token.name;
  const [itComment, setItComment] = useState({
    it_comment: ""
  });
  const [rootCauseExplanation, setRootCauseExplanation] = useState({
    explanation: ""
  });
  const { explanation } = rootCauseExplanation;
  const [adminStatus, setAdminStatus] = useState("");
  const { it_comment } = itComment;
  const handleAddITComment = (e, id, user) => {

    const urlLocation = window.location.href;
    const fd = new FormData();
    fd.append("it_comment", it_comment);
    fd.append("user", user)
    fd.append("id", id);
    fd.append("commentor", username);
    fd.append("commentor_id", auth.user[0].id);
    fd.append("authorEmail", tickets[0].ti_ticket_author_email)
    add_ITticket_comment(fd).then(() => {
      setItComment({
        ...itComment,
        it_comment: ""
      });
    });
  };
  const adminStatusOptions = [
    { key: "PNA", value: "ACTION NEEDED", text: "ACTION NEEDED" },
    { key: "P", value: "PENDING", text: "PENDING" },
    { key: "RES", value: "RESOLVED", text: "RESOLVED" }
  ];
  const handleModalOpen = (event, data, id) => {
   
    const fd = new FormData();
    fd.append("ticket_type", "IT");
    fd.append("it_ticket_id", id);
    get_ITticket_comments(fd);
    checkNewStatus(fd);
    getTicketAttachments(fd);
  };
  const handleModalClose = () => {    
    clearAttachments();
  };
  const handleITCommentChange = e => {
    setItComment({
      ...itComment,
      it_comment: e.target.value
    });
  };
  const handleRootExplination = e => {
    setRootCauseExplanation({
      ...rootCauseExplanation,
      explanation: e.target.value
    });
  };
  const handleRootCauseSubmit = (e, ticket_id) => {
    e.preventDefault();
    const fd = new FormData();
    if (adminStatus === "RESOLVED") {
      fd.append("resolved_by", auth.user[0].id);
    }
    fd.append("ticket_to_update", ticket_id);
    fd.append("new_admin_status", adminStatus);
    fd.append("explination", explanation);

    adminStatusUpdate(fd);
  };
  let singleTicket;
  tickets
    ? (singleTicket = tickets.sort((left, right) => {
        return moment.utc(right.created).diff(moment.utc(left.created));
      }))
    : (singleTicket = null);
  if (!tickets || tickets.length === 0) {
    singleTicket = <LoaderPlaceholder />;
  } else {
    singleTicket = tickets.map(ticket => (
      <Modal
        onOpen={(event, data, id) => handleModalOpen(event, data, ticket.ti_id)}
        key={ticket.ti_id}
        onClose={handleModalClose}
        trigger={
          <Segment
            className="individual-ticket-reporting-wrapper"
            // style={
            //   ticket.ti_priority === "LOW"
            //     ? {
            //         borderTop: "15px solid #f2e6ff",
            //         overflow: "hidden"
            //       }
            //     : ticket.ti_priority === "NORMAL"
            //     ? {
            //         borderTop: "15px solid #bcddf5",
            //         overflow: "hidden"
            //       }
            //     : ticket.ti_priority === "HIGH"
            //     ? {
            //         borderTop: "15px solid #ffd9b3",
            //         overflow: "hidden"
            //       }
            //     : ticket.ti_priority === "URGENT"
            //     ? {
            //         borderTop: "15px solid #ff9980",
            //         overflow: "hidden"
            //       }
            //     : {}
            // }
          >
            <ReactTooltip />
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <h4>{ticket.ti_ticket_author_name}</h4>
                  <hr className="style-six" />
                  <h4>{ticket.ti_subject}</h4>
                  <hr className="style-six" />
                  Created:{" "}
                  <Moment date={ticket.created} format="MM/DD/YYYY HH:mm" />
                </Grid.Column>
                <Grid.Column>
                  {ticket.ti_type === "Question" ? (
                    <Icon
                      name="question"
                      data-tip="Question"
                      size="large"
                      color="red"
                    />
                  ) : ticket.ti_type === "Incident" ? (
                    <Icon
                      name="exclamation triangle"
                      data-tip="Incident"
                      size="large"
                      color="red"
                    />
                  ) : ticket.ti_type === "Problem" ? (
                    <Icon
                      name="exclamation"
                      data-tip="Problem"
                      size="large"
                      color="red"
                    />
                  ) : ticket.ti_type === "Task" ? (
                    <Icon
                      name="calendar check outline"
                      data-tip="Task"
                      size="large"
                      color="red"
                    />
                  ) : null}
                  <p>Status: {ticket.ti_admin_status}</p>
                  <p>{ticket.ti_description.slice(0, 50)}...see more.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        }
        closeIcon
      >
        <Modal.Header>
          Ticket ID: {ticket.ti_id}
          <span style={{ float: "right" }}>
            Status: {ticket.ti_admin_status}
          </span>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>Issue: {ticket.ti_subject}</Header>
            <p>
              From: {ticket.ti_ticket_author_name} |{" "}
              {ticket.ti_ticket_author_email}{" "}
            </p>

            {ticket.is_new_access ? (
              <Link to={`/new-hire-IT-stage/${ticket.newhire_access_id}`}>
                Continue to Ticket
              </Link>
            ) : (
              <p>Description: {ticket.ti_description}</p>
            )}

            {!attachments ? (
              <Segment>
                <Dimmer active>
                  <Loader inverted>Loading Attachments</Loader>
                </Dimmer>
                <Placeholder style={{ height: 50, width: 50 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Segment>
            ) : attachments.length === 0 ? (
              <div>No Attachments</div>
            ) : (
              <div style={{ margin: "5px" }}>
                <h4>
                  Attachments: <span>Click to enlarge</span>
                </h4>
                <Modal
                  trigger={
                    <p
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      View Attachments
                    </p>
                  }
                  closeIcon
                  id="attachment-view-modal"
                >
                  <Grid columns="equal">
                    <Grid.Row>
                     {attachments.map(attachment => (
                       <Grid.Column>
                       <Modal
                         trigger={
                           <Image
                             src={attachment.path}
                             alt="Attachment"
                           />
                         }
                         closeIcon
                         basic={true}
                       >
                         <Modal.Content>
                           <Image
                             src={attachment.path}
                             alt="Attachment"
                             fluid
                           />
                         </Modal.Content>
                       </Modal>
                     </Grid.Column>
                     ))}
                    </Grid.Row>
                  </Grid>
                </Modal>
              </div>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Form onSubmit={(e, id, user) => handleAddITComment(e, ticket.ti_id, comments.itComments ? comments.itComments[comments.itComments.length -1].commentor_id :auth.user[0].id)}>
            <hr />
            <div>
              <h3 style={{ textAlign: "left" }}>Comments:</h3>
              <div style={{ textAlign: "left" }}>
                {comments.itComments
                  ? comments.itComments.map((comment, index) => (
                      <div key={index}>
                        {comment.commentor} : {comment.comment}
                        <span style={{ float: "right" }}>
                          <Moment
                            date={comment.comment_time}
                            format="MM/DD/YY LTS"
                          />
                        </span>
                        <hr />
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <Form.Field>
              <Input
                type="text"
                name="itComment"
                value={it_comment}
                id="Comment"
                onChange={handleITCommentChange}
              />
            </Form.Field>
            <Form.Field>
              <Button type="submit" basic color="green">
                Add Comment
              </Button>
            </Form.Field>
          </Form>
          {auth.user && auth.user[0].privilege_level < 3 ? (
            <Grid stackable>
              <Grid.Row>
                <Grid.Column>
                  <Form
                    onSubmit={(e, ticket_id) =>
                      handleRootCauseSubmit(e, ticket.ti_id)
                    }
                  >
                    <h4 style={{ textAlign: "left" }}>
                      Root Cause and Admin Status
                    </h4>
                    <Form.Field style={{ textAlign: "left" }}>
                      <Dropdown
                        options={adminStatusOptions}
                        placeholder="Status Action"
                        value={adminStatus}
                        onChange={(event, data) => {
                          setAdminStatus(data.value);
                        }}
                        inline
                      />
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        value={explanation}
                        name="explanation"
                        placeholder="What was the resolution?"
                        onChange={handleRootExplination}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Button basic color="blue">
                        Submit Root Explination
                      </Button>
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : null}
        </Modal.Actions>
      </Modal>
    ));
  }
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return <div>{singleTicket}</div>;
};
const mapStateToProps = state => ({
  auth: state.auth,
  attachments: state.tickets.attachments,
  tickets: state.tickets,
  redirect: state.redirect,
  comments: state.comments
});
export default connect(mapStateToProps, {
  get_ITticket_comments,
  checkNewStatus,
  add_ITticket_comment,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
})(IndividualTicket);
