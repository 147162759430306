import axios from "axios";

import {GET_ALL_TICKETS_FOR_REPORTING, MODAL_OPEN, REPORTING_DATA, INVENTORY_REPORTING_DATA} from './types';

export const getAllTicketsForReporting = body => async dispatch => {
    try {
        const res = await axios.post('/api/ticket-reporting/get-all-it-tickets', body)
        dispatch({
            type:GET_ALL_TICKETS_FOR_REPORTING,
            payload: res.data
        })
    } catch (error) {
         const res = {data:{message:"There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    }

}
export const getSelectedTicketsForReporting = (body) => async dispatch => {
    try {
        const res = await axios.post('/api/ticket-reporting/get-tickets-in-time-frame', body)
    } catch (error) {
         const res = {data:{message:"There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
    }
}

export const getReportingData = (body) => async (dispatch) => {
    try {
      const res = await axios.post(
        "/api/ticket-reporting/get-reporting-data",
        body
      );
      dispatch({
        type: REPORTING_DATA,
        payload: res.data,
      });
    } catch (error) {
      const res = {
        data: {
          message:
            "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
        },
      };
      dispatch({
        type: MODAL_OPEN,
        payload: res.data,
      });
    }
  };

  export const getInventoryReportingData = (body) => async (dispatch) => {
    try {
      const res = await axios.post(
        "/api/ticket-reporting/get-inventory-reporting-data",
        body
      );
      dispatch({
        type: INVENTORY_REPORTING_DATA,
        payload: res.data,
      });
    } catch (error) {
      const res = {
        data: {
          message:
            "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
        },
      };
      dispatch({
        type: MODAL_OPEN,
        payload: res.data,
      });
    }
  };