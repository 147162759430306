import React from 'react'
import ConfirmProfile from '../Main-Layout/Dashboard/DashboardComponents/ConfirmProfile'
import { connect } from "react-redux";
function ConfirmUser({auth}) {
    return (
        <div>
          
            <ConfirmProfile props={auth} />
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.auth
  });
export default connect(mapStateToProps, null)(ConfirmUser);
