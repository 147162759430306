import {
  GET_ALL_DATA_COMMENTS,
  ADD_COMMENT,
  GET_ALL_IT_COMMENTS,
  GET_AR_COMMENTS,
  RESET_COMMENTS,
  MODAL_OPEN
} from "./types";
import Axios from "axios";
import { setAlert } from "./alertActions";
export const getDataTicketComments = body => async dispatch => {
  try {
    const res = await Axios.post("/api/data-ops/get-data-comments", body);
    dispatch({
      type: GET_ALL_DATA_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const addITSetupRequestComment = body => async dispatch => {
  try {
    const res = await Axios.post(
      "/api/access-request/add-access-request-comment",
      body
    );
    dispatch({
      type: ADD_COMMENT
    });
    dispatch({
      type: GET_AR_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const addModifyRequestComment = body => async dispatch => {
  try {
    const res = await Axios.post(
      "/api/access-request/add-modify-request-comment",
      body
    );
    dispatch({
      type: ADD_COMMENT
    });
    dispatch({
      type: GET_AR_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const addDataComment = body => async dispatch => {
  try {
    const res = await Axios.post("/api/data-ops/add-data-comments", body);
    dispatch({
      type: ADD_COMMENT
    });
    dispatch({
      type: GET_ALL_DATA_COMMENTS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const get_ar_request_comments = body => async dispatch => {
  try {
    const res = await Axios.post("/api/access-request/get-ar-comments", body);
    dispatch({
      type: GET_AR_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const getModifyRequestComments = body => async dispatch => {
  try {
    const res = await Axios.post(
      "/api/access-request/get-modify-request-comments",
      body
    );
    dispatch({
      type: GET_AR_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

export const get_ITticket_comments = body => async dispatch => {
  try {
    const res = await Axios.post("/api/tickets/get-it-comments", body);
    dispatch({
      type: GET_ALL_IT_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};


export const addDashboardTicketComment = body => async dispatch => {
  try {
    const res = await Axios.post('/api/tickets/add-dashboard-ticket-comment', body);
    dispatch({
      type: ADD_COMMENT
    });
    dispatch({
      type: GET_ALL_IT_COMMENTS,
      payload: res.data
    });
  } catch (error) {
    const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
}


export const add_ITticket_comment = body => async dispatch => {
  try {
    const res = await Axios.post("/api/tickets/add-it-comments", body);
    dispatch({
      type: ADD_COMMENT
    });
    dispatch({
      type: GET_ALL_IT_COMMENTS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const resetTicketComments = () => async dispatch => {
  dispatch({
    type: RESET_COMMENTS
  });
};

export const addQRDataComment = body => async dispatch => {
  try {
    const res = await Axios.post("/api/qr-tickets/add-data-comments", body);
    dispatch({
      type: ADD_COMMENT
    });
    dispatch({
      type: GET_ALL_DATA_COMMENTS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getDataQRComments = body => async dispatch => {
  try {
    const res = await Axios.post("/api/qr-tickets/get-data-comments", body);
    dispatch({
      type: GET_ALL_DATA_COMMENTS,
      payload: res.data
    });
  } catch (error) {
     const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
}; 