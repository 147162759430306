import axios from "axios";
import { setAlert } from "./alertActions";
import {
  GET_CHANGED_EMPLOYEE_REQUEST,
  GET_CHANGED_EMPLOYEE_SUCESS,
  GET_CHANGED_EMPLOYEE_FAILED,
  MODAL_OPEN,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCESS,
  UPDATE_EMPLOYEE_FAILED,
} from "./types";

export const getEmployeeDetails = (body, user_email) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CHANGED_EMPLOYEE_REQUEST,
    });
    const res = await axios.post(
      "/api/change-employee-request/select-user-record",
      { id: body, user_email: user_email }
    );
    dispatch({
      type: GET_CHANGED_EMPLOYEE_SUCESS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error retrieving the access requests, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch(setAlert(res.data.message, "danger"));

    dispatch({
      type: GET_CHANGED_EMPLOYEE_FAILED,
      payload: res.data,
    });
  }
};

export const updateEmployeeRecord = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_EMPLOYEE_REQUEST,
    });
    const res = await axios.post(
      "/api/change-employee-request/update-employee-record",
      { payload: body }
    );
    dispatch({
      type: UPDATE_EMPLOYEE_SUCESS,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error retrieving the access requests, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch(setAlert(res.data.message, "danger"));
    dispatch({
      type: UPDATE_EMPLOYEE_FAILED,
      payload: res.data,
    });
  }
};