import React, { useState, useEffect } from "react";
import { Input, Form, Button, Message, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { addSystemProducts } from "../../../Redux/Actions/inventoryActions";
import DatePicker from "react-datepicker";
import {
  getAllInventory,
  clearAllInventory,
} from "../../../Redux/Actions/inventoryActions";

function AddSystemProducts({
  addSystemProducts,
  getAllInventory,
  clearAllInventory,
}) {
  const convertToSlug = (Text) => {
    return Text.toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^\w-]+/g, "");
  };
  useEffect(() => {
    clearAllInventory();
    getAllInventory();
  }, [getAllInventory, clearAllInventory]);
  const [systemProduct, setSystemProduct] = useState({
    productName: "",
    pocName: "",
    pocEmail: "",
    pocPhone: "",
    responsibility: "",
    vendor: "",
  });
  const [errors, setErrors] = useState("");

  const { productName, pocName, pocEmail, pocPhone, responsibility, vendor } =
    systemProduct;
  const onInputChange = (e) => {
    setSystemProduct({
      ...systemProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidation = () => {
    let fields = systemProduct;
    console.log("fields: ", fields);
    let errors = {};
    let formIsValid = true;

    //Name
    if (!fields["productName"]) {
      formIsValid = false;
      errors["productName"] = "Name Cannot be empty";
    }
    //PocName
    if (!fields["pocName"]) {
      formIsValid = false;
      errors["pocName"] = "Poc Name Cannot be empty";
    }

    //Email
    if (!fields["pocEmail"]) {
      formIsValid = false;
      errors["pocEmail"] = "Cannot be empty";
    }

    if (typeof fields["pocEmail"] !== "undefined") {
      let lastAtPos = fields["pocEmail"].lastIndexOf("@");
      let lastDotPos = fields["pocEmail"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["pocEmail"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["pocEmail"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["pocEmail"] = "Email is not valid";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleNewSystemProduct = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const fd = new FormData();
      const slug = convertToSlug(productName);
      fd.append("productName", productName);
      fd.append("system_shortname", slug);
      fd.append("pocName", pocName);
      fd.append("pocEmail", pocEmail);
      fd.append("pocPhone", pocPhone);
      fd.append("responsibility", responsibility);

      fd.append("vendor", vendor);

      addSystemProducts(fd).then(() => {
        setSystemProduct({
          ...systemProduct,
          productName: "",
          pocName: "",
          pocEmail: "",
          pocPhone: "",
          responsibility: "",
          vendor: "",
        });
      });
    }
  };

  return (
    <Form onSubmit={(e) => handleNewSystemProduct(e)}>
      <Form.Field
        control={Input}
        placeholder="Name"
        value={productName}
        name="productName"
        onChange={onInputChange}
        error={!!errors?.productName}
      />
      {errors?.productName && (
        <p className="text-danger">{errors?.productName}</p>
      )}

      <Form.Field
        control={Input}
        value={pocName}
        name="pocName"
        onChange={onInputChange}
        placeholder="POC Name"
        error={!!errors?.pocName}
      />
      {errors?.pocName && <p className="text-danger">{errors?.pocName}</p>}
      <Form.Field
        control={Input}
        value={pocEmail}
        name="pocEmail"
        onChange={onInputChange}
        placeholder="POC Email"
        error={!!errors?.pocEmail}
      />
      {errors?.pocEmail && <p className="text-danger">{errors?.pocEmail}</p>}
      <Form.Field>
        <Input
          type="text"
          placeholder="POC Phone"
          value={pocPhone}
          name="pocPhone"
          onChange={onInputChange}
        />
      </Form.Field>

      <Form.Field>
        <Input
          type="text"
          placeholder="Responsibility"
          value={responsibility}
          name="responsibility"
          onChange={onInputChange}
        />
      </Form.Field>

      <Form.Field>
        <Input
          type="text"
          placeholder="Vendor"
          value={vendor}
          name="vendor"
          onChange={onInputChange}
        />
      </Form.Field>

      <Form.Field style={{ textAlign: "right" }}>
        <Button basic color="green">
          Submit
        </Button>
      </Form.Field>
    </Form>
  );
}

export default connect(null, {
  addSystemProducts,
  getAllInventory,
  clearAllInventory,
})(AddSystemProducts);