import React, { useEffect } from "react";
import { Tab } from "semantic-ui-react";
import { Container } from "semantic-ui-react";
import NewHire from "./NewHire";
import ModifyAccess from "./ModifyAccess";
import IntakeForm from "./IntakeForm";
import ChangeEmployee from "../ChangeEmployee";

import TerminationRequest from "./TerminationRequest";
import { clearUsersForTermination } from "../../../Redux/Actions/accessRequestActions";
import { connect } from "react-redux";
import { lookupSoftwareSuggestions } from "../../../Redux/Actions/inventoryActions";
const HRAccessRequestMain = ({
  auth,
  clearUsersForTermination,
  lookupSoftwareSuggestions,
}) => {
  
  useEffect(() => {
    return () => {
      clearUsersForTermination();
      lookupSoftwareSuggestions();
    };
  });

  React.useEffect(() => {
    lookupSoftwareSuggestions();
  }, []);
  let panes = [
    {
      menuItem: "New Hire",
      render: () => (
        <Tab.Pane>
          <NewHire />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Termination Request",
      render: () => (
        <Tab.Pane>
          <TerminationRequest />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Change Employee Request",
      render: () => (
        <Tab.Pane>
          <ChangeEmployee />
        </Tab.Pane>
      ),
    },
  ];

  // if((auth.user && auth.user[0] && (auth.user[0].privilege_level === 1 || auth.user[0].privilege_level === 4 || (auth.user[0].department === "Human Resources" && auth.user[0].isManager === "Yes")))){
  //   panes.push({ menuItem: "Termination Request", render: () => <Tab.Pane><TerminationRequest /></Tab.Pane> })
  // }
  // if(auth.user && auth.user[0] && (auth.user[0].department === "Human Resources" || auth.user[0].privilege_level === 1 || auth.user[0].privilege_level === 4 )){
  //   panes.unshift({ menuItem: "New Hire", render: () => <Tab.Pane><NewHire /></Tab.Pane> })
  // }
  return (
    <Container style={{ paddingTop: "30px" }}>
      <Tab panes={panes} />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  clearUsersForTermination,
  lookupSoftwareSuggestions,
})(HRAccessRequestMain);
