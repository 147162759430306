import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  MICROSOFT_LOADED,
  GET_MICROSOFT_GRAPH_DATA,
  LOAD_ME_MICROSOFT,
  LOAD_MY_MANAGER_MICROSOFT,
  GET_MY_PHOTO
} from "../Actions/types";
import { authContext } from "../../adalConfig";
import jwtDecode from "jwt-decode";
const initialState = {
  token: jwtDecode(localStorage.getItem("adal.idtoken")),
  isAuthenticated: null,
  confirmUser: false,
  loading: true,
  user: null,
  registerModal: false,
  microsoftGraphData: null,
  my_microsoftGraphData: null,
  my_manager_microsoftGraphData: null,
  my_photo: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      if (payload.length === 0) {
        return {
          ...state,
          isAuthenticated: null,
          confirmUser: false,
          loading: true,
          user: null,
          registerModal: false,
          microsoftGraphData: null
        };
      } else {
        return {
          ...state,
          isAuthenticated: true,
          loading: false,
          user: payload
        };
      }
    /* falls through */
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        registerModal: true
      };
    /* falls through */
    case MICROSOFT_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        registerModal: false
      };
    /* falls through */
    case GET_MICROSOFT_GRAPH_DATA:
      return {
        ...state,
        microsoftGraphData: payload
      };
    case LOAD_ME_MICROSOFT:
      return {
        ...state,
        my_microsoftGraphData: payload
      };
    case LOAD_MY_MANAGER_MICROSOFT:
      return {
        ...state,
        my_manager_microsoftGraphData: payload
      };
      case GET_MY_PHOTO:
        return{
          ...state,
          my_photo: payload
        }
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
      authContext.logOut();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    /* falls through */
    default:
      return state;
  }
}
