import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "semantic-ui-react";
function CommentsMain({ props, ticket, addComment, getComments, commmentor }) {
  useEffect(() => {}, []);
  const [comments, setComment] = useState({
    comment: ""
  });
  const { comment } = comments;
  const handleCommentChange = e => {
    setComment({
      ...comments,
      comment: e.target.value
    });
  };
  const handleCommentSubmit = () => {
    const fd = new FormData();
    fd.append("ticket_type", props);
    if (props === "dataops") {
      fd.append("data_comment", comment);
    }
    if (props === "itticket") {
      fd.append("it_comment", comment);
    }
    fd.append("id", ticket);
    fd.append("commentor", commmentor.first_name + " " + commmentor.last_name);

    addComment(fd).then(() => {
      setComment({
        ...comments,
        comment: ""
      });
    });
  };
  return (
    <Form onSubmit={() => handleCommentSubmit()}>
      <Form.Field>
        <Input onChange={handleCommentChange} name="comment" value={comment} />
      </Form.Field>
      <Form.Field style={{ textAlign: "right" }}>
        <Button basic color="blue">
          Submit Comment
        </Button>
      </Form.Field>
    </Form>
  );
}

export default CommentsMain;
