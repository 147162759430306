import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getMAnagerApprovedAccessRequests,
  newHireITFinish
} from "../../../Redux/Actions/accessRequestActions";
import { addNewHIreInventory,clearAllInventory,setNoMessage,getInventoryModelsDropdown, getInventoryByEmail, removeInventoryFromUser } from "../../../Redux/Actions/inventoryActions";
import {
  addITSetupRequestComment,
  get_ar_request_comments
} from "../../../Redux/Actions/commentActions";
import { Redirect } from "react-router-dom";
import { Container, Form, Input, Button, Modal, Grid,Dropdown } from "semantic-ui-react";
import Moment from "react-moment";
import _ from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faTimes } from "@fortawesome/free-solid-svg-icons";

function ITSetupRequest({
  match,
  getMAnagerApprovedAccessRequests,
  accessRequests,
  addITSetupRequestComment,
  get_ar_request_comments,
  auth,
  comments,
  addNewHIreInventory,
  inventory,
  newHireITFinish,
  redirect,
  setNoMessage,
  getInventoryModelsDropdown,
  getInventoryByEmail,
  removeInventoryFromUser
}) {
  useEffect(() => {
    const fd = new FormData();
    fd.append("get_manager_approved_access_request", match.params.id);
    getMAnagerApprovedAccessRequests(fd);
    clearAllInventory()
    setNoMessage()
    getInventoryModelsDropdown()
    getAlreadyAddedInventory()
  }, []);

  function getAlreadyAddedInventory(){
    const fd = new FormData();
    fd.append("get_manager_approved_access_request", match.params.id);
    getInventoryByEmail(fd)
  }

  const [arComment, setARcomment] = useState({
    ar_comment: ""
  });
  const [hardware, setHardware] = useState({
    new_email: "",
    asa_tag: "",
    description: "",
    model_number: "",
    inType: "",
    other_description: "",
    warranty_expiry_date: ""
  });
  const [buttonText, setButtonText] = useState({
    text: "Add"
  });
  const [modalOpen, setModalOpen] = useState({
    open: false
  });
  const { open } = modalOpen;
  const { text } = buttonText;
  const { asa_tag, description, model_number, new_email,inType,warranty_expiry_date,other_description } = hardware;

  // const handleCommentSubmit = (e, accessRequestId) => {
  //   e.preventDefault();
  //   const fd = new FormData();
  //   fd.append("ar_to_comment_on", accessRequestId);
  //   fd.append("ar_comment", ar_comment);
  //   fd.append("commentor", auth[0].first_name + " " + auth[0].last_name);
  //   addITSetupRequestComment(fd).then(() => {
  //     setARcomment({
  //       ...arComment,
  //       ar_comment: ""
  //     });
  //   });
  // };

  const handleModalOpen = () => {
    setModalOpen({
      ...modalOpen,
      open: true
    });
   if (inventory && inventory.inventory){
    setHardware({
      model_number:null,
      new_email:inventory.inventory[0]["in_use_by_email"]
    })
   }
  };
  const handleModalClose = () => {
    setModalOpen({
      ...modalOpen,
      open: false
    });
    setHardware({
      ...hardware,
      asa_tag: "",
      description: "",
      model_number: ""
    })
  };
  const handleARCommentChange = e => {
    setARcomment({
      ...arComment,
      ar_comment: e.target.value
    });
  };

  const onHardwareChange = e => {
    setHardware({
      ...hardware,
      [e.target.name]: e.target.value
    });
  };
  const finishNewHireRequest = e => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("resolved_by",auth[0].id);
    fd.append(
      "it_ticket_to_update",
      accessRequests.pendingUser[0].IT_ticket_id
    );
    fd.append(
      "access_request_ro_update",
      accessRequests.pendingUser[0].ac_user_id
    );
    newHireITFinish(fd).then(()=>{
      showPopup("Thank you, your access stage has been I.T COMPLETED.", "success")
    })
  };

const [errMsg, setErrMsg] = useState(false)

const inventoryType = [
  { key: "PC", value: "PC TOWER", text: "PC TOWER" },
  { key: "LT", value: "LAPTOP", text: "LAPTOP" },
  { key: "MT", value: "MONITOR", text: "MONITOR" },
  { key: "PH", value: "PHONE", text: "PHONE" },
  { key: "OT", value: "OTHER", text: "OTHER" }
];

const handleDueDate = (date) => {
  setHardware({
    ...hardware,
    warranty_expiry_date: date,
  });
};

const [hitApi, saveHitApi] = useState(false)

  React.useEffect(()=>{
      if(!errMsg && inventory.message && inventory.msgtype === "success"){
        showPopup(inventory.message.message, inventory.msgtype) 
      }
      if(hitApi && inventory.inventory && inventory.inventory.length > 0){
        setHardware({
          model_number:null,
          new_email:inventory.inventory[0]["in_use_by_email"]
        })
        saveHitApi(false)
      }
  })

  const removeInventory = asset => {
    const fd = new FormData();
    fd.append("inventory_to_modify", asset);
    setErrMsg(false)
    setNoMessage()
    removeInventoryFromUser(fd).then(() => {
      showPopup("Inventory Removed From User", "success")
      getInventoryModelsDropdown()
      saveHitApi(true)
      getAlreadyAddedInventory()
    });
  };

  const handleHardwareSubmit = e => {
    e.preventDefault(e);
    const fd = new FormData();
    fd.append("ar_id", accessRequests.pendingUser[0].users_id);
    fd.append("new_email", new_email);
    fd.append("model_number", model_number);
    setErrMsg(false)
    setNoMessage()
    addNewHIreInventory(fd)
      .then(() => {
        getInventoryModelsDropdown()
        saveHitApi(true)
        setHardware({
          ...hardware,
          model_number:"",
          asa_tag: "",
          description: "",
          warranty_expiry_date: "",
          inType:"",
          other_description:""
        });
        getAlreadyAddedInventory()
        // handleModalClose();
      })
      .catch(error => {
        
      });
  };

  const showPopup = (msg, type) => {
    toastr.options = {
      positionClass: "toast-top-center",
      hideDuration: 300,
      timeOut: 2000,
      closeButton: false,
      progressBar: true,
      preventDuplicates:true
    };
    toastr.clear();
    setErrMsg(true)
    if(type === "error"){
      setTimeout(() => toastr.error(msg), 300);
    }
    if(type === "success"){
      setTimeout(() => toastr.success(msg), 300);
    }
  }

  let dropDownValues = []

  if(inventory && inventory.modelsDropdown && Array.isArray(inventory.modelsDropdown) && inventory.modelsDropdown.length > 0){
    dropDownValues = inventory.modelsDropdown.map(x=>{
      return {
        key:x.model_number,
        value: x.model_number,
        text:x.description+", Asset Tag: " + x.asa_tag
      }
    })
  }

  const { ar_comment } = arComment;
  let pendingNewUser;
  if (accessRequests.pendingUser) {
    pendingNewUser = accessRequests.pendingUser[0];
  }
  let newHireData;

  const goBack = () => {
    window.location.href = `/pending-tickets/${pendingNewUser['IT_ticket_id']}`
  }
  accessRequests.pendingUser
    ? (newHireData = (
        <Grid columns="equal">
          <Grid.Row style={{marginTop:"10px"}}>
        <h3>
        <FontAwesomeIcon
                          icon={faBackward}
                          color="black"
                          onClick={goBack}
                          style={{fontSize:"25", marginLeft:"10",cursor:"pointer"}}
                        />
        </h3>
            <Grid.Column>
            <div className="cardNewHire">
                      <div className="containerNewHire">
              <h3>Employee Info: </h3>
              <hr />
              <p>
                <span style={{ fontWeight: "bold" }}></span>
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>New Employee Name: </span>
                {pendingNewUser.employee_first_name}{" "}
                {pendingNewUser.employee_last_name}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Department: </span>
                {pendingNewUser.department}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Job Title: </span>
                {pendingNewUser.job_title}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Manager Name: </span>
                {pendingNewUser.manager_name}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Manager Email: </span>
                {pendingNewUser.manager_email}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Employee Start Date:{" "}
                </span>
                <Moment
                  date={pendingNewUser.employee_start_date}
                  format="MM/DD/YYYY"
                />
              </p>
              


              {pendingNewUser.personal_email && <p>
                <span style={{ fontWeight: "bold" }}>Personal Email: </span>
                {pendingNewUser.personal_email}
              </p>}
              {pendingNewUser.phone_number && <p>
                <span style={{ fontWeight: "bold" }}>Phone Number: </span>
                {pendingNewUser.phone_number}
              </p>}
      </div>
      </div>
      <div className="cardNewHire">
                      <div className="containerNewHire">
              <h3>Requested Hardware and Printers</h3>
              <hr />
              {pendingNewUser.desktop_tower ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>Desktop Tower</span>
                </p>
              ) : null}
              {pendingNewUser.usb_headset ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>USB Headset</span>
                </p>
              ) : null}
              {pendingNewUser.laptop ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>Laptop</span>
                </p>
              ) : null}
              {pendingNewUser.wireless_keyboard_mouse ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    Wireless Keyboard and Mouse
                  </span>
                </p>
              ) : null}
              {pendingNewUser.other_items ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>Other Items:</span>
                  {pendingNewUser.other_items}
                </p>
              ) : null}
              {pendingNewUser.num_monitors ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    {pendingNewUser.num_monitors} monitors
                  </span>
                </p>
              ) : null}
              {pendingNewUser.cell_phone_policy ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>Cell Policy</span>
                </p>
              ) : null}
              {pendingNewUser.bw_toshiba_call_center ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    Black & White Toshiba Near Call Center{" "}
                  </span>
                </p>
              ) : null}
              {pendingNewUser.white_bluewall ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    White Printer by Blue Wall{" "}
                  </span>
                </p>
              ) : null}
              {pendingNewUser.blk_toshiba_finance ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    Black Toshiba Near Finance{" "}
                  </span>
                </p>
              ) : null}
              {pendingNewUser.all_printers ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    Requested to print to all computers.
                  </span>
                </p>
              ) : null}
              <p>
                    <span style={{ fontWeight: "bold" }}>
                      Additional Comments:{" "}
                    </span>
                    {pendingNewUser?.special_request_info}
                  </p>

              </div>
              </div>
              <div className="cardNewHire">
                      <div className="containerNewHire">
              <h3>System Accesses Requested: </h3>
              <hr />
              {accessRequests.pendingRequests ? (
                _.orderBy(accessRequests.pendingRequests, ["system_application"], ["asc"]).map(software => (
                  <div className="chip" style={{backgroundColor:"#55B4C8"}} key={software.id}>
                  <div className="chip-content">{software.system_application}</div>
                </div>
                ))
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  No Software Requested
                </span>
              )}
  </div>
  </div>

            </Grid.Column>
            <Grid.Column>
              <h4>Inventory: </h4>
              
              <Modal
                onClose={handleModalClose}
                open={open}
                trigger={
                  <>
                  <Button
                    onClick={handleModalOpen}
                    disabled={!pendingNewUser.canTakeAction}
                    style={{ float: "right",marginTop: "-30px" }}
                    basic
                    color="blue"
                  >
                    Add Hardware & Email
                  </Button>
                  <br/>
                  </>
                }
                closeIcon
              >
                <Modal.Header>
                  Hardware to be added?
                </Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <Form>
                      <Form.Field>
                        <Input
                          placeholder="New Employee Email"
                          onChange={onHardwareChange}
                          name="new_email"
                          value={new_email}
                        />
                      </Form.Field>
                      <Form.Field>
                        {/* <Input
                          placeholder="Model"
                          onChange={onHardwareChange}
                          name="description"
                          value={description}
                        /> */}
                        <Dropdown
                          options={dropDownValues}
                          placeholder="Select"
                          value={model_number}
                          onChange={(event, data) => {
                            setHardware({
                              ...hardware,
                              model_number:data.value
                            })
                          }}
                          selection
                        />
                      </Form.Field>
                      <Form.Field style={{ textAlign: "right" }}>
                        <Button
                          basic
                          color="red"
                          type="button"
                          onClick={() =>
                            handleModalClose()
                          }
                        >
                          Cancel
                        </Button>
                        <Button type="submit" basic color="green" onClick={e => handleHardwareSubmit(e)}>
                          {inventory && inventory.inventory && inventory.inventory.length > 0 ? "Add Additional Device" : "Add"}
                        </Button>
                        <Button
                          basic
                          color="blue"
                          type="button"
                          onClick={() =>
                            handleModalClose()
                          }
                        >
                          Done
                        </Button>
                        {inventory.message ? (
                          <div style={{ color: "red" }}>
                            {inventory.message.message}
                          </div>
                        ) : null}
                      </Form.Field>
                    </Form>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              {!inventory.inventory
                ? null
                : inventory.inventory.map((item, index) => (
                    <div key={index} className="cardNewHire">
                     {pendingNewUser.canTakeAction && <div>
                      <FontAwesomeIcon
                      onClick={()=>removeInventory(item.asa_tag)}
                    icon={faTimes}
                    disabled
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      marginRight: "10px",
                      float:"right"
                    }}
                    color="red"
                  />
                        </div>}
                      <div className="containerNewHire">
                      <p style={{ fontWeight: "bold" }}>Email : <span style={{ fontWeight: "400" }}>{item.in_use_by_email}</span></p>
                      <p style={{ fontWeight: "bold" }}>Description : <span style={{ fontWeight: "400" }}>{item.description}</span></p>
                      <p style={{ fontWeight: "bold" }}>Model Number : <span style={{ fontWeight: "400" }}>{item.model_number}</span></p>
                      <p style={{ fontWeight: "bold" }}>Asset Tag : <span style={{ fontWeight: "400" }}>{item.asa_tag}</span></p>
                      </div>
                    </div>
                  ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {pendingNewUser.canTakeAction && <Form onSubmit={e => finishNewHireRequest(e)}>
                <Button
                  basic
                  color="green"
                  style={{ float: "right" }}
                  disabled={!new_email ? true : false}
                >
                  Save and Finish
                </Button>
              </Form>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))
    : (newHireData = <div>loading</div>);
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return <Container>{newHireData}</Container>;
}
const mapStateToProps = state => ({
  accessRequests: state.accessRequests,
  auth: state.auth.user,
  comments: state.comments,
  inventory: state.inventory,
  redirect: state.redirect
});
export default connect(mapStateToProps, {
  getMAnagerApprovedAccessRequests,
  addITSetupRequestComment,
  get_ar_request_comments,
  addNewHIreInventory,
  newHireITFinish,
  clearAllInventory,
  setNoMessage,
  getInventoryModelsDropdown,
  getInventoryByEmail,
  removeInventoryFromUser
})(ITSetupRequest);