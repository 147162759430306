import React, { useState, useCallback, useEffect, useRef } from "react";
import { throttle } from "lodash";
import Moment from "react-moment";
import {
  Button,
  Segment,
  Form,
  Grid,
  Checkbox,
  Transition,
  Input,
  Icon,
  Modal,
  Dropdown
} from "semantic-ui-react";
import {
  newHireAccessRequestOne,
  newHireAccessRequestTwo,
  newHireAccessFinalRequest
} from "../../../Redux/Actions/accessRequestActions";
import {
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  clearAllSelectedSoftware
} from "../../../Redux/Actions/inventoryActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function ManagerStage({
  newHire,
  newHireAccessRequestOne,
  newHireAccessRequestTwo,
  auth,
  redirect,
  lookupSoftwareSuggestions,
  inventory,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  newHireAccessFinalRequest,
  clearAllSelectedSoftware
}) {

  useEffect(() => {
    lookupSoftwareSuggestions();
  }, []);

  const loadSoftwareProducts = useRef(true);


  const [modalOpen, setmodalOpen] = useState({
    first_modal_open: false,
    second_modal_open: false,
    third_modal_open: false,
    no_product_modal: false
  });
  const {
    first_modal_open,
    second_modal_open,
    third_modal_open,
    no_product_modal
  } = modalOpen;
  const [noproductsNeeded, setnoproductsNeeded] = useState({
    notneeded: false
  });
  const { notneeded } = noproductsNeeded;
  const [noPrintersNeeded, setnoPrintersNeeded] = useState({
    noPrinters: true
  });
  const { noPrinters } = noPrintersNeeded;
  const [firstManagerDataStage, setFirstManagerDataStage] = useState({
    employee_start_date: "",
    cube_off_number: "",
    cell_phone_policy: newHire?.cell_phone_policy === 0 ? false : true,
    desktop_tower: newHire?.desktop_tower === 0 ? false : true,
    laptop: newHire?.laptop === 0 ? false : true,
    usb_headset: newHire?.usb_headset === 0 ? false : true,
    wireless_keyboard_and_mouse:
      newHire?.wireless_keyboard_mouse === 0 ? false : true,
    monitor: newHire?.num_monitors || "",
    other_accessories: newHire?.other_items || "",
  });
  // const [secondManagerDatastage, setSecondMAnagerDatastage] = useState("");
  const [managerDataStage, setManagerDataStage] = useState({
    bw_toshiba_call_center: newHire?.bw_toshiba_call_center ? true : false,
    white_bluewall: newHire?.white_bluewall ? true : false,
    blk_toshiba_finance: newHire?.blk_toshiba_finance ? true : false,
    all_printers: newHire?.all_printers ? true : false,
    special_request_info: newHire?.special_request_info || "",
  });
  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: []
  });
  const { 
    cursor,
    //  result 
    } = highlightSelection;
  const [visibility, setVisibility] = useState({
    first_stage_visible: true,
    second_stage_visible: false,
    third_stage_visible: false
  });
  const [softwareInventory, setSoftwareInventory] = useState("");
  const [userselectedPro, usersetSelectedPro] = useState([])
  

  function handleSoftwareInventory(data,i){
    
    let originalProArr = JSON.parse(JSON.stringify(softwareInventory)).filter(x=>x['system_application'] !== data['system_application'])
    setSoftwareInventory(
      originalProArr.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0))
    )
    let newArr = JSON.parse(JSON.stringify(userselectedPro))
    newArr.push(data)
    usersetSelectedPro(
      newArr
    )
  }

  function handleSelectedSoftwareInventory(data,i){
    let originalProArr = JSON.parse(JSON.stringify(userselectedPro)).filter(x=>x['system_application'] !== data['system_application'])
    usersetSelectedPro(
      originalProArr
    )
    let newArr = JSON.parse(JSON.stringify(softwareInventory))
    newArr.push(data)
    setSoftwareInventory(
      newArr.sort((a,b) => (a.system_application > b.system_application) ? 1 : ((b.system_application > a.system_application) ? -1 : 0))
    )
  }

  useEffect(() => {
    if (!loadSoftwareProducts.current) {
      return;
    }
    if (newHire?.softwareAccess?.length > 0) {
      loadSoftwareProducts.current = false;

      usersetSelectedPro(newHire?.softwareAccess);
    }
  }, [newHire?.softwareAccess]);
  const delayedQuery = useCallback(
    throttle(q => sendQuery(q), 500),
    []
  );

  const {
    first_stage_visible,
    second_stage_visible,
    third_stage_visible
  } = visibility;
  const onInputChange = e => {
    setManagerDataStage({
      ...managerDataStage,
      [e.target.name]: e.target.value
    });
  };
  const firstOnCheckboxChange = (e, props) => {
    setFirstManagerDataStage({
      ...firstManagerDataStage,
      [props.name]: props.checked
    });
  };
  const firstOnInputChange = e => {
    setFirstManagerDataStage({
      ...firstManagerDataStage,
      [e.target.name]: e.target.value
    });
  };
  const onCheckboxChange = (e, props) => {
    setManagerDataStage({
      ...managerDataStage,
      [props.name]: props.checked
    });
  };

  const onDatepickerChange = date => {
    setFirstManagerDataStage({
      ...firstManagerDataStage,
      employee_start_date: date
    });
  };
  // this is the defining the variables for use state above
  const {
    bw_toshiba_call_center,
    white_bluewall,
    blk_toshiba_finance,
    all_printers,
    special_request_info
  } = managerDataStage;

  const {
    employee_start_date,    cube_off_number,
    cell_phone_policy,
    wireless_keyboard_and_mouse,
    desktop_tower,
    laptop,
    usb_headset,
    monitor,
    other_accessories
  } = firstManagerDataStage;

  
  const { formOk } = redirect;

  const [selectedPro, setSelectedPro] = useState({})

  React.useEffect(()=>{
    if(selectedPro && Object.values(selectedPro).length > 0){
      Object.values(selectedPro).forEach(x=>{
        if(x){
          handleMouseKeyClick(x)
        }
      })
    }
  }, [selectedPro])

  const handleMouseKeyClick = (highlightedText) => {
    // var highlightedText = event.target.innerText;
    const fd = new FormData();
    fd.append("added_software", highlightedText);
    // if(!checkAlreadyIn(id)){
      
    // }else{
    //   console.log("not in")
    //   removeSelectedSoftwareFromList(id)
    // }
    getSelectedProduct(fd).then(() => {
      sethighlightSelection({
        ...highlightSelection,
        cursor: 0,
      });
    });
  };

  const handleDropdownChange = (e,data) => {
    setSelectedPro({
      ...selectedPro,
      [data["name"]]: data.value
    })
  }

  

  const handleAutoProductChange = e => {
    setSoftwareInventory(e.target.value);
    delayedQuery(e.target.value);
  };
  const sendQuery = query => {
    if (query.length > 0) {
      lookupSoftwareSuggestions(query);
    } else {
      clearSoftwareSuggestions();
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === 38) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor - 1
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor + 1
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        var highlightedText = selected[i].innerText;
        const fd = new FormData();
        fd.append("added_software", highlightedText);
        getSelectedProduct(fd).then(() => {
          setSoftwareInventory("");
          clearSoftwareSuggestions();
          sethighlightSelection({
            ...highlightSelection,
            cursor: 0
          });
        });
      }
    }
  };

  React.useEffect(()=>{
    if(inventory && inventory.suggested_software_products && inventory.suggested_software_products.length > 0){
      if (softwareInventory.length === 0) {
        const res = inventory?.suggested_software_products?.filter(
          (x) => !userselectedPro?.some((y) => y.product_id === x.id)
        );
        let arr = res.length > 0 ? res : inventory.suggested_software_products;
        setSoftwareInventory(
          arr.sort((a, b) =>
            a.system_application > b.system_application
              ? 1
              : b.system_application > a.system_application
              ? -1
              : 0
          )
        );
      } else {
        // let { access } = inventory;
        // const res = softwareInventory.filter(
        //   (x) => !access.some((y) => y.product_id === x.id)
        // );
        // setSoftwareInventory(res);
      }
    }
  }, [inventory])

  const handleSubmitTwo = (e, id) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("users_id", id);
    if (userselectedPro.length > 0) {
      userselectedPro.map(product =>
        fd.append(product.system_shortname, product.system_application)
      );

      newHireAccessRequestTwo(fd).then(() => {
        showPopup("Data Updated Successfully for " + newHire.employee_first_name + " " + newHire.employee_last_name)
        setmodalOpen({
          ...modalOpen,
          second_modal_open: false
        });
        setVisibility({
          ...visibility,

          second_stage_visible: false,
          third_stage_visible: true
        });
      });
    }
  };

  const handleFirstSubmit = (e, id, formOk) => {
    e.preventDefault();
   
    const fd = new FormData();
    fd.append("first_name", newHire.employee_first_name);
    fd.append("last_name", newHire.employee_last_name);
    fd.append("access_request_to_update", id);
    const result = Object.keys(firstManagerDataStage).map(function(key) {
      return [key, firstManagerDataStage[key]];
    });
    result.map(fdItem => fd.append(fdItem[0], fdItem[1]));

    newHireAccessRequestOne(fd).then(() => {
      showPopup("Data Updated Successfully for " + newHire.employee_first_name + " " + newHire.employee_last_name)
      setmodalOpen({
        ...modalOpen,
        first_modal_open: false
      });
      setVisibility({
        ...visibility,
        first_stage_visible: false,
        second_stage_visible: true
      });
    });
  };

  const handleThirdSubmit = (e, id) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("user_id", auth.user[0].id);
    fd.append("manager_approved_email", auth.user[0].email);
    fd.append(
      "manager_approved_name",
      auth.user[0].first_name + auth.user[0].last_name
    );
    fd.append("access_request_to_update", id);
    fd.append("bw_toshiba_call_center", bw_toshiba_call_center);
    fd.append("white_bluewall", white_bluewall);
    fd.append("blk_toshiba_finance", blk_toshiba_finance);
    fd.append("all_printers", all_printers);
    fd.append("special_request_info", special_request_info);
    newHireAccessFinalRequest(fd)
  };

  const showPopup = (msg) => {
    toastr.options = {
      positionClass: "toast-top-center",
      hideDuration: 300,
      timeOut: 2000,
      closeButton: false,
      progressBar: true,
    };
    toastr.clear();
    setTimeout(() => toastr.success(msg), 300);
  }

  const returnRequiredObject = (x) => {
    return {
      ...x,
      key: x["system_application"],
      text: x["system_application"],
      value: x["system_application"]
    }
  }
  // cone - 1, beaco  - 2, envi - 3, argu - 4, cotivi - 5, other - 0

  let envision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let beaconh = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let conehealth = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let cotiviti = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let sharepoint = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let argusdentalvision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allenvision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allbeaconh = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allconehealth = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allcotiviti = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]
  let allargusdentalvision = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }] 
  let allsharepoint = [{
    key: "",
    value: "",
    text: "Select",
    email: "",
  }]

  if(inventory && inventory.suggested_software_products && inventory.suggested_software_products.length > 0){
    inventory.suggested_software_products.forEach(x=>{
      if(x && x["poc_email"]){
        getType(x)
      }
    })
  }

  function getType(x){
    if(!x["category"]){
      return false;
    }
    let tempObj = returnRequiredObject(x)
     if(x["category"].match(/1/gi)){
      conehealth.push(tempObj)
      allconehealth.push(tempObj)
    }
    if(x["category"].match(/5/gi)){
      cotiviti.push(tempObj)
      allcotiviti.push(tempObj)
    }
    if(x["category"].match(/4/gi)){
      argusdentalvision.push(tempObj)
      allargusdentalvision.push(tempObj)
    }
    if(x["category"].match(/2/gi)){
      beaconh.push(tempObj)
      allbeaconh.push(tempObj)
    }
    if(x["category"].match(/3/gi)){
      envision.push(tempObj)
      allenvision.push(tempObj)
    }
    if(x["category"].match(/0/gi)){
      sharepoint.push(tempObj)
      allsharepoint.push(tempObj)
    }
  }

  const checkAlreadyIn = (array, allArray) => {
    if(inventory.software_products && inventory.software_products.length > 0){
      let alreadyIds = JSON.parse(JSON.stringify(inventory.software_products)).map(x=>x["id"])    
      array = JSON.parse(JSON.stringify(allArray)).filter(x=>{
        if(!alreadyIds.includes(x.id)){
          return true
        }
      })
    }
        return array
  }

  function removeChip(item){
    if(!item){
      return;
    }
    removeSelectedSoftwareFromList(item.id)
    getType(item)
  }

  if (formOk) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ minHeight: "85vh" }}>
      <Form.Field>
        <h3>Employee Info </h3>
        <Grid columns="equal" stackable>
          <Grid.Row style={{marginBottom: "10px"}}>
            <Grid.Column>
              <b>Name:&nbsp;</b>{newHire.employee_first_name} {newHire.employee_last_name}         
            </Grid.Column>
            <Grid.Column>
              <b>Department:&nbsp;</b>{newHire.department}
            </Grid.Column>
            <Grid.Column>
              <b>Job Title:&nbsp;</b> {newHire.job_title}
            </Grid.Column>
           
            
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Transition visible={first_stage_visible} duration={1000}>
        <div style={{ position: "absolute", width: "96%" }}>
          <Form>
            <Form.Field>
              <Grid columns="equal" stackable>
                <Grid.Row >
                <Grid.Column>
                   
                      <b>Cell Policy:&nbsp;</b>
                      <Checkbox
                        label="Yes"
                        name="cell_phone_policy"
                        checked={cell_phone_policy}
                        value="true"
                        onChange={firstOnCheckboxChange}
                      />
                   
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Form.Field>
              <Grid columns="equal" stackable>
                <Grid.Row>
                  <Grid.Column>
                    <h3>Computer and accessories</h3>
                    <hr />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                  <Grid.Column>
                    <Segment className="access-focus">
                      <Checkbox
                        label="Desktop Tower"
                        name="desktop_tower"
                        checked={desktop_tower}
                        value="true"
                        onChange={firstOnCheckboxChange}
                      />
                    </Segment>
                    <Segment className="access-focus">
                      <Checkbox
                        label="Laptop"
                        name="laptop"
                        value="true"
                        checked={laptop}
                        onChange={firstOnCheckboxChange}
                        className="checkbox-focus-class"
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment className="access-focus">
                      <Checkbox
                        label="USB Headset"
                        name="usb_headset"
                        checked={usb_headset}
                        value="true"
                        onChange={firstOnCheckboxChange}
                        className="checkbox-focus-class"
                      />
                    </Segment>
                    <Segment className="access-focus">
                      <Checkbox
                        label="Wireless Keyboard & Mouse"
                        name="wireless_keyboard_and_mouse"
                        checked={wireless_keyboard_and_mouse}
                        value="true"
                        onChange={firstOnCheckboxChange}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment className="access-focus">
                      <input
                        type="text"
                        placeholder="Enter Number of Monitors"
                        value={monitor}
                        name="monitor"
                        onChange={firstOnInputChange}
                      />
                    </Segment>
                    <Segment className="access-focus">
                      <input
                        type="text"
                        placeholder="Other Items"
                        name="other_accessories"
                        value={other_accessories}
                        onChange={firstOnInputChange}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column style={{ textAlign: "right" }}>
                  <Button
                    basic
                    type="button"
                    color="red"
                    onClick={() =>
                      setFirstManagerDataStage({
                        employee_start_date: "",
                        cube_off_number: "",
                        cell_phone_policy: false,
                        desktop_tower: false,
                        laptop: false,
                        usb_headset: false,
                        wireless_keyboard_and_mouse: false,
                        monitor: "",
                        other_accessories: ""
                      })
                    }
                  >
                    Reset
                    <Icon name="dont" style={{ marginLeft: "5px" }} />
                  </Button>
                  <Modal
                    open={first_modal_open}
                    trigger={
                      <span>
                        <Button
                          color="green"
                          basic
                          type="button"
                          disabled={!desktop_tower && !laptop ? true : false}

                          onClick={() =>
                            setmodalOpen({
                              ...modalOpen,
                              first_modal_open: true
                            })
                          }
                        >
                          Review
                        </Button>
                        <p style={{margin:"10px"}}>Part 1 of 3</p>
                      </span>
                    }
                  >
                    <Modal.Header>Equipment Needed</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <Grid stackable columns="equal">
                          <Grid.Row>
                          {employee_start_date &&<Grid.Column>
                              
                                <label className="newLabel">
                                  Start date : &nbsp;
                                </label>
                                <Moment date={employee_start_date
                                      .toISOString()} format="MM/DD/YY" />
                                    
                                  
                              
                            </Grid.Column>}

                            {cube_off_number && <Grid.Column>
                             
                                <label className="newLabel">
                                  Cubicle / Office : &nbsp;
                                </label>
                                {cube_off_number}
                              
                            </Grid.Column>}
                            
                            {cell_phone_policy && <Grid.Column>
                             <label className="newLabel">Cell Phone Policy : &nbsp;</label> {cell_phone_policy ? "Yes" : "No"}
                            </Grid.Column>}

                          </Grid.Row>
                          
                          <Grid.Row>
                           <Grid.Column>
                           {monitor && <> <label className="newLabel">Number of Monitors : &nbsp; </label>{monitor}</>}
                              
                            </Grid.Column>
                             <Grid.Column>
                             {other_accessories && <><label className="newLabel">Other Accessories : &nbsp; </label>{other_accessories}</>}
                            </Grid.Column>
                            <Grid.Column>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                          <Grid.Column>
                            <label className="newLabel"> Computer and Accessories Needed : </label>                            
                            {desktop_tower ? "Desktop Tower, " : null}
                              {laptop ? "Laptop, " : null}
                              {usb_headset ? "USB Headset, " : null}
                              {wireless_keyboard_and_mouse ? (
                                "Wireless Keyboard & Mouse"
                              ) : null}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        basic
                        color="red"
                        type="button"
                        onClick={() =>
                          setmodalOpen({
                            ...modalOpen,
                            first_modal_open: false
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        basic
                        color="green"
                        onClick={(e, id) =>
                          handleFirstSubmit(e, newHire.ac_user_id)
                        }
                      >
                        Continue
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Grid.Column>
              </Grid>
            </Form.Field>
          </Form>
        </div>
      </Transition>
      <Transition visible={second_stage_visible} duration={1000}>
        <div style={{ position: "absolute", width: "96%" }}>
          <Form>
            <hr />
            <Form.Field>
              <Grid columns="equal">
              <Grid.Row>
                  <Grid.Column style={{ textAlign: "right" }}>
                    <Modal
                      open={no_product_modal}
                      trigger={
                        <Button
                          type="button"
                          basic
                          disabled={
                            inventory.software_products
                              ? inventory.software_products.length > 0
                              : false
                          }
                          onClick={() =>
                            setmodalOpen({
                              ...modalOpen,
                              no_product_modal: true
                            })
                          }
                        >
                          No Products Needed
                          <Icon
                            name="minus circle"
                            style={{ marginLeft: "5px" }}
                          />
                        </Button>
                      }
                      closeIcon
                    >
                      <Modal.Header>Are you sure ?</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <h4>
                            Are you sure that there are no products needed?
                          </h4>
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          type="button"
                          basic
                          color="red"
                          onClick={() =>
                            setmodalOpen({
                              ...modalOpen,
                              no_product_modal: false
                            })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          basic
                          color="green"
                          onClick={() => {
                            setnoproductsNeeded({
                              ...noproductsNeeded,
                              notneeded: true
                            });

                            setmodalOpen({
                              ...modalOpen,
                              no_product_modal: false
                            });
                          }}
                        >
                          Continue
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
            <Grid.Column width="7">
               <h3 style={{textDecoration:"underline"}}>Select a product From below</h3>
               </Grid.Column>
               <Grid.Column width="1">
               
               </Grid.Column>
               <Grid.Column width="7">
               <h3 style={{textDecoration:"underline"}}>Selected Products</h3>
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width="7">
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
              {softwareInventory && softwareInventory.length > 0 && softwareInventory.map((x,i)=>{
                return (
                  <button onClick={()=>handleSoftwareInventory(x,i)} type="button" class='btn-new first' key={i}>{x['system_application']}</button>
                )
              })}
              </div>
              </Grid.Column>
              <Grid.Column width="1">
              <div class="vl"></div>
              </Grid.Column>
              <Grid.Column width="7">
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
              {userselectedPro && userselectedPro.length > 0 && userselectedPro.map((x,i)=>{
                return (
                  <button onClick={()=>handleSelectedSoftwareInventory(x,i)} type="button" class='btn-new first btn-new-selected' key={i}>{x['system_application']}</button>
                )
              })}
              </div>
              </Grid.Column>
            </Grid.Row>
              {/* <Grid.Row>

               <Grid.Column>
                 <label>Envision</label>
                 <Dropdown name="Envision" placeholder='Envision' fluid selection options={checkAlreadyIn(envision,allenvision)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column>
                 <label>Beaconh</label>
               <Dropdown placeholder='beaconh' name="beaconh" fluid selection options={checkAlreadyIn(beaconh,allbeaconh)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column>
                 <label>Conehealth</label>
               <Dropdown placeholder='conehealth' name="conehealth" fluid selection options={checkAlreadyIn(conehealth,allconehealth)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column>
                 <label>Cotiviti</label>
               <Dropdown placeholder='cotiviti' name="cotiviti" fluid selection options={checkAlreadyIn(cotiviti,allcotiviti)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column>
                 <label>Argusdentalvision</label>
               <Dropdown placeholder='argus' name="argusdentalvision" fluid selection options={checkAlreadyIn(argusdentalvision,allargusdentalvision)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
               <Grid.Column>
                 <label>Others</label>
               <Dropdown placeholder='others' name="others" fluid selection options={checkAlreadyIn(sharepoint,allsharepoint)} onChange={(e, data)=>handleDropdownChange(e, data)} />
               </Grid.Column>
            </Grid.Row>      
            <Grid.Row>
                 {inventory.software_products &&
                  inventory.software_products.map((product, i) => {
                    return (
                      // <div
                      //   style={{
                      //     padding: "10px",
                      //     border: "1px solid black",
                      //     margin: "10px",
                      //     borderRadius:"20px",
                      //     textAlign:"center",
                      //     cursor:"pointer",
                      //   }}
                        
                      // >
                      //   {product.background}{product.system_application}
                      // </div>
                      <div className="chip" style={{backgroundColor:"black"}} key={product.id}>
                      <div className="chip-content">{product.system_application}</div>
                      <div className="chip-close-software" onClick={()=>removeChip(product)}>
                          <svg className="chip-svg-software" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
                      
                      </div>
                    </div>
                    );
                  })} 
            </Grid.Row>           */}
                <Grid.Row>
                  <Grid.Column style={{ textAlign: "right" }}>
                    <Button type="button" color="red" basic>
                      Reset
                      <Icon name="dont" style={{ marginLeft: "5px" }} />
                    </Button>
                    <Modal
                      open={second_modal_open}
                      trigger={
                        !notneeded ? (
                          <span>
                            <Button
                              type="button"
                              color="green"
                              basic
                              disabled={userselectedPro.length === 0}
                              onClick={() =>
                                setmodalOpen({
                                  ...modalOpen,
                                  second_modal_open: true
                                })
                              }
                            >
                              Review
                            </Button>
                            <p style={{margin:"10px"}}>Part 2 of 3</p>
                          </span>
                        ) : (
                          <span>
                            <Button
                              basic
                              color="green"
                              onClick={() =>
                                setVisibility({
                                  ...visibility,
                                  first_stage_visible: false,
                                  second_stage_visible: false,
                                  third_stage_visible: true
                                })
                              }
                            >
                              Next
                              <Icon name="arrow right" />
                            </Button>
                            <p style={{margin:"10px"}}>Part 2 of 3</p>
                          </span>
                        )
                      }
                      closeIcon
                    >
                      <Modal.Header>Products Needed</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          {userselectedPro
                            ? userselectedPro.map(prod => (
                                <p
                                  key={prod.id}
                                  style={{
                                    borderBottom: "1px solid #90278E",
                                    margin: "0px",
                                    padding: "5px"
                                  }}
                                >
                                  {prod.system_application}
                                </p>
                              ))
                            : null}
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          onClick={() =>
                            setmodalOpen({
                              ...modalOpen,
                              second_modal_open: false
                            })
                          }
                          basic
                          color="red"
                          type="button"
                        >
                          Cancel
                        </Button>
                        <Button
                          basic
                          color="green"
                          type="button"
                          onClick={(e, id) =>
                            handleSubmitTwo(e, newHire.users_id)
                          }
                        >
                          Continue
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
          </Form>
        </div>
      </Transition>

      <Transition visible={third_stage_visible} duration={1000}>
        <div style={{ position: "absolute", width: "96%" }}>
          <Form>
            <Form.Field>
              <Grid columns="equal">
              <Grid.Row style={{paddingBottom:"0px"}}>
                  <Grid.Column>
                    <h3>Printer Preference</h3>
                    <hr />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment className="access-focus">
                      <Checkbox
                        label="Black Toshiba near call center"
                        name="bw_toshiba_call_center"
                        checked={bw_toshiba_call_center}
                        value="true"
                        onChange={onCheckboxChange}
                      />
                    </Segment>
                    <Segment className="access-focus">
                      <Checkbox
                        label="White printer on blue wall"
                        name="white_bluewall"
                        checked={white_bluewall}
                        value="true"
                        onChange={onCheckboxChange}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment className="access-focus">
                      <Checkbox
                        label="Black toshiba near Finance office"
                        name="blk_toshiba_finance"
                        checked={blk_toshiba_finance}
                        value="true"
                        onChange={onCheckboxChange}
                      />
                    </Segment>
                    <Segment className="access-focus">
                      <Checkbox
                        label="All Printers"
                        name="all_printers"
                        checked={all_printers}
                        value="true"
                        onChange={onCheckboxChange}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingBottom:"0px",paddingTop:"0px"}}>
                  <Grid.Column style={{ textAlign: "right" }}>
                    <Button
                      type="button"
                      basic
                      onClick={() =>
                        setnoPrintersNeeded({
                          ...noPrintersNeeded,
                          noPrinters: false
                        })
                      }
                    >
                      No Printers Needed
                      <Icon name="minus circle" style={{ marginLeft: "5px" }} />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>

            <Form.Field>
              <Grid columns="equal">
              <Grid.Row style={{paddingBottom:"0px"}}>
                  <Grid.Column>
                    <h3>Any additional information or special request?</h3>
                    <hr />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingBottom:"0px"}}>
                  <Grid.Column>
                    <Segment className="access-focus">
                      <textarea
                        placeholder="Addition special info"
                        name="special_request_info"
                        value={special_request_info}
                        onChange={onInputChange}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Grid columns="equal">
            <Grid.Row style={{paddingBottom:"0px"}}>
                <Grid.Column>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="button"
                      basic
                      onClick={() =>
                        setVisibility({
                          ...visibility,
                          first_stage_visible: false,
                          second_stage_visible: true,
                          third_stage_visible: false
                        })
                      }
                    >
                      <Icon name="arrow left" />
                      Back
                    </Button>
                    <Button
                      type="button"
                      color="red"
                      onClick={() =>
                        setManagerDataStage({
                          ...managerDataStage,
                          bw_toshiba_call_center: false,
                          white_bluewall: false,
                          blk_toshiba_finance: false,
                          all_printers: false,
                          special_request_info: ""
                        })
                      }
                      basic
                    >
                      Reset
                      <Icon name="dont" style={{ marginLeft: "5px" }} />
                    </Button>
                    <Modal
                      open={third_modal_open}
                      trigger={
                        <span>
                        <Button
                          type="button"
                          basic
                          color="green"
                          onClick={() =>
                            setmodalOpen({
                              ...modalOpen,
                              third_modal_open: true
                            })
                          }
                          disabled={
                            noPrinters &&
                            !bw_toshiba_call_center &&
                            !white_bluewall &&
                            !blk_toshiba_finance &&
                            !all_printers
                          }
                        >
                          Review
                          <Icon
                            name="clipboard check"
                            style={{ marginLeft: "5px" }}
                          />
                        </Button>
                        <p>Step 3 of 3</p>
                        </span>
                      }
                    >
                      <Modal.Header>Printers and Special Requests</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <ul>
                          {!all_printers && bw_toshiba_call_center &&
                            <li>Toshiba Near Call Center</li>
                          }
                          {!all_printers && white_bluewall &&
                            <li>Printer near the Blue Wall</li>
                          }
                          {!all_printers && blk_toshiba_finance &&
                            <li>Black Toshiba Near Finance</li>
                          }
                            </ul>
                          {all_printers &&
                            <ul>
                              <li>Toshiba Near Call Center</li>
                              <li>Printer near the Blue Wall</li>
                              <li>Black Toshiba Near Finance</li>
                            </ul>
                          }
                          {
                            !noPrinters &&
                            <li>No Printers and Special Requests Required for this Hire</li> 
                          }
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          type="button"
                          basic
                          color="red"
                          onClick={() =>
                            setmodalOpen({
                              ...modalOpen,
                              third_modal_open: false
                            })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          color="green"
                          basic
                          onClick={(e, id) =>
                            handleThirdSubmit(e, newHire.ac_user_id)
                          }
                        >
                          Finalize
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </Transition>
    </div>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  redirect: state.redirect,
  inventory: state.inventory
});
export default connect(mapStateToProps, {
  newHireAccessRequestOne,
  lookupSoftwareSuggestions,
  clearSoftwareSuggestions,
  getSelectedProduct,
  removeSelectedSoftwareFromList,
  newHireAccessRequestTwo,
  newHireAccessFinalRequest,
  clearAllSelectedSoftware
})(ManagerStage);