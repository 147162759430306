import React, {useState} from 'react'
import {Container, Form, Input, Button} from 'semantic-ui-react';
import {addSuggestion} from '../../../Redux/Actions/ticketActions';
import { connect } from "react-redux";

const Suggestions =({addSuggestion}) => {
    const [suggestion, setSuggestion] = useState({
        suggeestionBody:''
    })
    const onChange = e => {    
        setSuggestion({
         ...suggestion,
          [e.target.name]: e.target.value
        });
      }
      const onSubmit = e =>{
          e.preventDefault();
          const fd = new FormData();
          fd.append('suggeestionBody',suggeestionBody)
          addSuggestion(fd).then(()=>{
            setSuggestion({
                suggeestionBody:''
               });
          });
      }
    const {suggeestionBody} = suggestion;
    return (
        <Container>
            <h3>Please review the suggestions before making a new one! Thank you.</h3>
            <Form onSubmit={e => onSubmit(e)}>
                <Form.Field>
                    <Input 
                    value={suggeestionBody}
                    name='suggeestionBody'
                    onChange={onChange}
                    />

                </Form.Field>
                <Form.Field>
                    <Button type='submit'>Submit</Button>
                </Form.Field>
            </Form>
        </Container>
    )
}

export default connect(null,{addSuggestion})(Suggestions);
