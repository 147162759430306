import React, { useEffect, useState, useCallback } from "react";
import { throttle } from "lodash";
import {
  getAllInventory,
  modifyITinventory,
  lookupUsersForInventory,
  clearUsersForInventory,
  clearAllInventory,
  allDBUsers,
  getInventoryComments,
  AddInventoryComments
} from "../../../Redux/Actions/inventoryActions";
import { connect } from "react-redux";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTools,
  faWindowClose,
  faDesktop,
  faLaptop,
  faPhone,
  faKeyboard,
  faQuestionCircle,
  faExclamation,
  faCommentAlt,
  faCommentDots,
  faArrowUp,
  faArrowDown,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  List,
  Loader,
  Segment,
  Dimmer,
  Grid,
  Button,
  Input,
  Dropdown,
  Form,
  Modal,
  TextArea,
} from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash"; 
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx"


const inventoryStatuses = [
  { key: "RT", value: "RETIRED", text: "RETIRED" },
  { key: "RA", value: "ASSIGNED", text: "ASSIGNED" },
  { key: "AV", value: "AVAILABLE", text: "AVAILABLE" },
  { key: "IR", value: "IN REPAIR", text: "IN REPAIR" },
  { key: "NI", value: "NEW INVENTORY", text: "NEW INVENTORY" },
];
const statusFilter = [
  { key: "", value: "", text: "Select" },
  ...(JSON.parse(JSON.stringify(inventoryStatuses))),
  { key: "IN", value: "IN USE", text: "IN USE" }
]
const inventoryType = [
  { key: "PC", value: "PC TOWER", text: "PC TOWER" },
  { key: "LT", value: "LAPTOP", text: "LAPTOP" },
  { key: "MT", value: "MONITOR", text: "MONITOR" },
  { key: "PH", value: "PHONE", text: "PHONE" },
  { key: "OT", value: "OTHER", text: "OTHER" },
];
function AllInventory({
  getAllInventory,
  inventory,
  modifyITinventory,
  lookupUsersForInventory,
  clearUsersForInventory,
  users,
  clearAllInventory,
  allInven,
  allDBUsers,
  allUsers,
  getInventoryComments,
  invComments,
  AddInventoryComments,
  auth,
  inventoryModels
}) {
  useEffect(() => {
    setIsLoading(true);
    allDBUsers();
    getAllInventory().then(() => {
      setIsLoading(false);
    });
    return () => {
      clearAllInventory();
    };
  }, [getAllInventory, clearAllInventory]);

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(inventory);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
    FileSaver.saveAs(data, `Inventory-${Date.now()}` + '.xlsx');
  }

  const [modifyInventory, setModifyInventory] = useState({
    inventoryIdInModification: "",
    newDescription: "",
    newAssetTag: "",
    newSerialNumber: "",
    newStatus: "",
    newUser: "",
    newUserID: "",
    newType: "",
    warranty_expiry_date: "",
  });
  const {
    newDescription,
    newAssetTag,
    newSerialNumber,
    newStatus,
    newUser,
    inventoryIdInModification,
    newUserID,
    newType,
    warranty_expiry_date,
  } = modifyInventory;
  console.log("inventoryModels", inventoryModels)
  const sendInfoToDatabase = (
    id,
    description,
    status,
    asa_tag,
    model_number,
    type,
    warranty
  ) => {
    const fd = new FormData();
    if (newStatus && newStatus.match(/ASSIGNED/gi)) {
      if (!userLookup) {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 2000,
          closeButton: false,
          progressBar: true,
        };
        toastr.clear();
        setTimeout(() => toastr.error("Please Choose Reassigned User"), 0);
        return false;
      }
    }
    if (userLookup) {
      // let index = allUsers.findIndex(x=>x.first_name === userLookup.split(" ")[1] && x.last_name === userLookup.split(" ")[0])
      // if(index === -1){
      fd.append("reassign_user", userLookupId);
      // }
    }
    fd.append("description", newDescription ? newDescription : description);
    fd.append("status", newStatus ? newStatus : status);
    fd.append("asa_tag", newAssetTag ? newAssetTag : asa_tag);
    fd.append("model_number", newSerialNumber ? newSerialNumber : model_number);
    fd.append("inventory_item_to_modify", id);
    fd.append("new_type", newType ? newType : type);
    if (warranty_expiry_date) {
      const ISODueDate =
        warranty_expiry_date.toISOString().split("T")[0] + " 00:00:00";
      fd.append("warranty_expiry_date", ISODueDate);
    } else {
      fd.append("warranty_expiry_date", warranty);
    }
    setIsLoading(true);
    modifyITinventory(fd).then(() => {
      setTimeout(() => {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 2000,
          closeButton: false,
          progressBar: true,
        };
        toastr.clear();
        setTimeout(() => toastr.success("Data Updated"), 0);
        setIsState(false);
        setIsLoading(false);
        setComment("");
        setOpen(false);

        setVisibility({
          ...itemVisible,
          itemVisible: "",
        });
        setModifyInventory({
          ...modifyInventory,
          inventoryIdInModification: "",
          newDescription: "",
          newAssetTag: "",
          newSerialNumber: "",
          newStatus: "",
          newUserID: "",
          newType: "",
          warranty_expiry_date: "",
        });
        getAllInventory().then((res) => {
          if (!isSet) {
            setAllItems(inventory);
            setIsState(true);
          }
        });
        fillchoosStatus("")
        fillchoosModel("")
        setUSerLookup("");
        setUSerLookupId("");
      }, 1000);
    });
  };
  const [choosStatus, fillchoosStatus] = useState("")
  const [choosModel, fillchoosModel] = useState("")
  const [visibility, setVisibility] = useState([]);
  const { itemVisible } = visibility;

  const [reassignVisibility, setReassignVisibility] = useState({
    isVisible: false,
  });

  const { isVisible } = reassignVisibility;
  const changeVisibility = (id) => {
    setVisibility({
      ...itemVisible,
      itemVisible: id,
    });
    setModifyInventory({
      ...modifyInventory,
      inventoryIdInModification: id,
      newDescription: "",
      newAssetTag: "",
      newSerialNumber: "",
      newStatus: "",
      newUser: "",
      newUserID: "",
      newType: "",
      warranty_expiry_date: "",
    });
    setReassignVisibility({
      ...reassignVisibility,
      isVisible: false,
    });
  };

  const removeVisibility = () => {
    setVisibility({
      ...itemVisible,
      itemVisible: "",
    });
    setModifyInventory({
      ...modifyInventory,
      inventoryIdInModification: "",
      newDescription: "",
      newAssetTag: "",
      newSerialNumber: "",
      newStatus: "",
      newUser: "",
      newUserID: "",
      newType: "",
      warranty_expiry_date: "",
    });
  };
  const handleInventoryChange = (e) => {
    if (e && e.target && e.target.name) {
      setModifyInventory({
        ...modifyInventory,
        [e.target.name]: e.target.value,
      });
    } else {
      setModifyInventory({
        ...modifyInventory,
        warranty_expiry_date: e,
      });
    }
  };
  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });
  const {
    cursor,
    // result
  } = highlightSelection;
  const [userLookup, setUSerLookup] = useState("");
  const [userLookupId, setUSerLookupId] = useState("");
  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );

  React.useEffect(() => {
    clearUsersForInventory();
  }, [userLookup]);

  const handleAutoProductChange = (e) => {
    setUSerLookup(e.target.value);
    delayedQuery(e.target.value);
  };

  const sendQuery = (query) => {
    if (query.length > 0) {
      lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        // const fd = new FormData();
        // fd.append("reassign_user", selected[i].getAttribute("data-key"));

        // need to get the name nd user from the database and make the change immediaty
        setModifyInventory({
          ...modifyInventory,
          newUser: selected[i].getAttribute("data-name"),
          newUserID: selected[i].getAttribute("data-key"),
        });
        setUSerLookup("");
        setUSerLookupId("");
        clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0,
        });
      }
    }
  };

  const [allItems, setAllItems] = useState([]);
  const [isSet, setIsState] = useState(false);

  // let allItems = inventory;

  if (inventory) {
    if (!isSet) {
      setAllItems(inventory);
      setIsState(true);
    }
  }

  const handleInputChange = (e) => {
    if (e.target.value) {
      var regexSearch = new RegExp(e.target.value, "gi");
      let tempRoutes = [];
      JSON.parse(JSON.stringify(inventory)).forEach((x) => {
        if (
          (x["asa_tag"] && x["asa_tag"].match(regexSearch)) ||
          (x["description"] && x["description"].match(regexSearch)) ||
          (x["in_use_by_email"] && x["in_use_by_email"].match(regexSearch)) ||
          (x["in_use_by_first"] && x["in_use_by_first"].match(regexSearch)) ||
          (x["in_use_by_last"] && x["in_use_by_last"].match(regexSearch)) ||
          (x["model_number"] && x["model_number"].match(regexSearch)) ||
          (x["other_description"] &&
            x["other_description"].match(regexSearch)) ||
          (x["status"] && x["status"].match(regexSearch))
        ) {
          tempRoutes.push(x);
        }
      });
      setAllItems(tempRoutes);
    } else {
      setAllItems(inventory);
    }
  };

const [statusSort, setstatusSort] = useState("")

  const handleSort = (key) => {
    let order;
    if(key === "status"){
      if(!statusSort){
        setstatusSort("asc")
        order = "asc"
      }
      if(statusSort === "asc"){
        setstatusSort("desc")
        order = "desc"
      }
      if(statusSort === "desc"){
        setstatusSort("")
        order = ""
      }
    }
    sortArray(order);
  }

  const handleStatusFilter = (key) => {
   
    if(!key){
      fillchoosStatus("")
      setAllItems(inventory)
      return false;
    }
    let tempData = JSON.parse(JSON.stringify(inventory)).filter(x=>x["status"] === key)
    setAllItems(tempData);
    fillchoosStatus(key)
  }

  const handleModelFilter = (key) => {
    if(!key){
      fillchoosModel("")
      setAllItems(inventory)
      return false;
    }
    let tempData = JSON.parse(JSON.stringify(inventory)).filter(x=>x["description"] === key)
    setAllItems(tempData);
    fillchoosModel(key)
  }

  const sortArray = (order) => {
    if(!order){
      setAllItems(inventory)
      return false;
    }
    let tempData = _.orderBy(allItems, ["status"], [order])
    setAllItems(tempData);
  }

  function setRequesterFunction(name, id) {
    setUSerLookup(name.trim());
    setUSerLookupId(id);
  }

  const [isLoading, setIsLoading] = useState(false);

  const checkTodayDate = (date) => {
    let dateToday = moment().subtract(0, "days");
    let dateDifference = dateToday.diff(date, "days");
    if (dateDifference > 0) {
      return true;
    }
    return false;
  };

  const colorCoding = (status) => {
    let color = "";
    if (status === "RETIRED") {
      color = "red";
    }
    if (status === "IN USE") {
      color = "blue";
    }
    if (status === "IN REPAIR") {
      color = "#FFA500";
    }
    if (status === "AVAILABLE") {
      color = "#39e600";
    }
    if (status === "NEW INVENTORY") {
      color = "grey";
    }
    return color;
  };

  const [open, setOpen] = useState(false);

  const [comment, setComment] = useState("");

  const noPress = () => {
    setComment("");
    setOpen(false);
  };

  const openPopup = (item) => {
    getInveComm()
    setOpen(true);
  };

  const getInveComm = () => {
    const fd = new FormData()
    fd.append("inventory_id", itemVisible)
    getInventoryComments(fd)
  }

  const addInveComm = () => {
    const fd = new FormData()
    fd.append("inventory_id", itemVisible)
    fd.append("comment", comment)
    fd.append("addedBy", auth.user[0].email)
    AddInventoryComments(fd).then(()=>{
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.success("Comment Added Successfully"), 0);
      getInveComm()
      setComment("")
    })
  }

  const submitComment = () => {
    if(comment){
      addInveComm()
    }else{
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Please Add Some Comment"), 0);
      return false;
    }
  }

  const getLength = (status) =>{
    let tempData = JSON.parse(JSON.stringify(inventory))
    let length = 0
    if(!status){
      length = tempData.length
    }else{
      let data = tempData.filter(x=>x["status"] === status)
      if(data){
        length = data.length
      }
    }
    return length
  }

  return (
    <Container>
      <Modal size="fullscreen" open={open}>
      <Modal.Header>
          <span>Add Comments</span>
          <span onClick={() => noPress()} style={{float:"right", fontSize: "30px", cursor:"pointer"}}>&times;</span>
        </Modal.Header>
        <Modal.Content>
          <Input
            placeholder="Add Comments"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{ width: "100%" }}
          />
          <div style={{ float: "right", marginTop: "10px" }}>
            <Button negative onClick={() => noPress()}>
              Cancel
            </Button>
            <Button positive onClick={() => submitComment(false)}>
              Submit
            </Button>
          </div>
          <h4>Comments History</h4>
          {invComments && invComments.length > 0
            && <table className="ui celled table">
            <thead>
              <tr>
                <th>Comment</th>
                <th>Date</th>
                <th>Added By</th>
              </tr>
            </thead>
            <tbody>
            {invComments && invComments.length > 0 && invComments.map((x,i)=>{
              return (
                <tr key={i}>
                  <td >{x["comment"]}</td>
                <td ><Moment
                            date={new Date(x["created"])}
                            format="MM/DD/YY LTS"
                          /></td>
                <td >{x["addedByUser"]}</td>
              </tr>
              )
            })}
            </tbody>
          </table>
          }

          {
              !(invComments && invComments.length > 0) && <span style={{textAlign:"center"}}>No Comment History</span>
            }
        </Modal.Content>
      </Modal>
      {isLoading && (<Grid.Column>            
              <div className="loadingNew">
                <div className="loaderNew"></div>
              </div>
          </Grid.Column>)}
      <Grid columns="equal">
        <Grid.Row>
        <Grid.Column style={{ textAlign: "right" }}>
            <Input
              placeholder="Search ..."
              onChange={handleInputChange}
              icon="search"
              style={{ maxWidth: "100%" }}
            />
            {/* <Button positive onClick={() => exportToCSV()}>
              Download
            </Button> */}
            <FontAwesomeIcon
                          icon={faFileExcel}
                          color="green"
                          style={{fontSize:"25", marginLeft:"10",cursor:"pointer"}}
                          onClick={exportToCSV}
                        />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column style={{color:colorCoding("IN USE"), fontWeight:"bold"}}>
            IN USE : {getLength("IN USE")}
            </Grid.Column>
            <Grid.Column style={{color:colorCoding("ASSIGNED"), fontWeight:"bold"}}>
            ASSIGNED : {getLength("ASSIGNED")}
            </Grid.Column>
            <Grid.Column style={{color:colorCoding("AVAILABLE"), fontWeight:"bold"}}>
            AVAILABLE : {getLength("AVAILABLE")}
            </Grid.Column>
            <Grid.Column style={{color:colorCoding("IN REPAIR"), fontWeight:"bold"}}>
            IN REPAIR : {getLength("IN REPAIR")}
            </Grid.Column>
            <Grid.Column style={{color:colorCoding("RETIRED"), fontWeight:"bold"}}>
            RETIRED : {getLength("RETIRED")}
            </Grid.Column>
          <Grid.Column style={{fontWeight:"bold"}}>
            Total Inventory : {getLength("")}
            </Grid.Column>  
            </Grid.Row>
      </Grid>
      {allItems ? (
        <List divided>
          <ReactTooltip />
          <Grid divided style={{ padding: "10px 0px" }}>
            <Grid.Row>
              <Grid.Column style={{ fontWeight: "bold" }} width="2">
                Asset Tag:
              </Grid.Column>
              <Grid.Column style={{ fontWeight: "bold" }} width="2">
                In Use By:
              </Grid.Column>
              {/* <Grid.Column style={{ fontWeight: "bold", cursor:"pointer", color:"darkmagenta" }} width="2" onClick={()=>handleSort("status")}>
                Status: &nbsp; 
                { statusSort === "asc" &&
                  <FontAwesomeIcon
                  icon={faArrowUp}
                />
                }
                { statusSort === "desc" &&
                  <FontAwesomeIcon
                  icon={faArrowDown}
                />
                }
              </Grid.Column> */}
              <Grid.Column style={{ fontWeight: "bold", cursor:"pointer", color:"darkmagenta" }} width="2">
              <Dropdown
                            placeholder="Status"
                            options={statusFilter}
                            inline
                            value={choosStatus}
                            onChange={(event, data)=>handleStatusFilter(data.value)}
                          />
              </Grid.Column>

              <Grid.Column style={{ fontWeight: "bold" }} width="3">
              <Dropdown
                            placeholder="Model"
                            options={inventoryModels}
                            inline
                            value={choosModel}
                            onChange={(event, data)=>handleModelFilter(data.value)}
                          />
              </Grid.Column>
              <Grid.Column style={{ fontWeight: "bold" }} width="2">
                Type:
              </Grid.Column>

              <Grid.Column style={{ fontWeight: "bold" }} width="2">
                Serial Number:
              </Grid.Column>
              <Grid.Column style={{ fontWeight: "bold" }} width="2">
                Expiration Date:
              </Grid.Column>
              <Grid.Column style={{ fontWeight: "bold" }} width="1">
                Edit:
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {allItems.map((item) => (
            <List.Item key={item.id} style={{ padding: "10px 0px" }}>
              <List.Content>
                <Grid divided>
                  <Grid.Row>
                    <Grid.Column width="2"> {item.asa_tag}</Grid.Column>
                    <Grid.Column width="2">
                      {newUser && inventoryIdInModification === item.id ? (
                        <span>{newUser}</span>
                      ) : item.in_use_by_email ? (
                        <span>
                          {item.in_use_by_last}, {item.in_use_by_first}
                        </span>
                      ) : item.status === "IN REPAIR" ? (
                        <span>MAINTENANCE</span>
                      ) : item.status === "AVAILABLE" ? (
                        <span>IT</span>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width="2"
                      style={{
                        color: colorCoding(item.status),
                        fontWeight: "bold",
                      }}
                    >
                      {item.status}
                    </Grid.Column>

                    <Grid.Column width="3">{item.description}</Grid.Column>
                    <Grid.Column width="2" style={{ textAlign: "center" }}>
                      {item.type === "PC TOWER" ? (
                        <FontAwesomeIcon
                          icon={faKeyboard}
                          data-tip="PC Tower"
                        />
                      ) : item.type === "LAPTOP" ? (
                        <FontAwesomeIcon icon={faLaptop} data-tip="Laptop" />
                      ) : item.type === "PHONE" ? (
                        <FontAwesomeIcon icon={faPhone} data-tip="Phone" />
                      ) : item.type === "MONITOR" ? (
                        <FontAwesomeIcon icon={faDesktop} data-tip="Monitor" />
                      ) : item.type === "OTHER" ? (
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          data-tip="Monitor"
                        />
                      ) : item.type === null ? (
                        <FontAwesomeIcon
                          icon={faExclamation}
                          color="red"
                          data-tip="No Type Selected"
                        />
                      ) : null}
                    </Grid.Column>

                    <Grid.Column width="2">{item.model_number}</Grid.Column>
                    <Grid.Column width="2">
                      {item.warranty_expiry_date && (
                        <span
                          style={{
                            color: checkTodayDate(item.warranty_expiry_date)
                              ? "red"
                              : "black",
                            fontWeight: checkTodayDate(
                              item.warranty_expiry_date
                            )
                              ? "bolder"
                              : "",
                          }}
                        >
                          <Moment
                            date={new Date(item.warranty_expiry_date)}
                            format="MM/DD/YY"
                          />
                        </span>
                      )}
                    </Grid.Column>
                    <Grid.Column width="1">
                      {itemVisible === item.id ? (
                        <FontAwesomeIcon
                          icon={faWindowClose}
                          style={{ float: "right", cursor: "pointer" }}
                          color="red"
                          onClick={removeVisibility}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTools}
                          style={{ float: "right", cursor: "pointer" }}
                          color="orange"
                          onClick={(id) => changeVisibility(item.id)}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  {itemVisible === item.id &&
                  inventoryIdInModification === item.id ? (
                    <React.Fragment>
                      <Grid.Row>
                        <Grid.Column width="2">
                          <Input
                            placeholder={item.asa_tag}
                            value={newAssetTag}
                            name="newAssetTag"
                            onChange={handleInventoryChange}
                            style={{ maxWidth: "100%" }}
                          />
                        </Grid.Column>

                        <Grid.Column width="2">
                          {isVisible &&
                          inventoryIdInModification === item.id ? (
                            <Input
                              onKeyDown={handleKeyDown}
                              onChange={handleAutoProductChange}
                              value={userLookup}
                              placeholder="Search Users..."
                              icon="search"
                              style={{ maxWidth: "100%" }}
                            />
                          ) : null}

                          {users ? (
                            <div
                              style={{
                                position: "absolute",
                                background: "white",
                                width: "91%",
                                border: "1px solid #bfbfbf",
                                borderRadius: "3px",
                                zIndex: "99999",
                              }}
                            >
                              {users.length === 0 ? (
                                <p style={{ padding: "5px", margin: "0px" }}>
                                  No Results Found
                                </p>
                              ) : (
                                users.map((user, i) => (
                                  <p
                                    style={{ padding: "5px", margin: "0px" }}
                                    key={user.id}
                                    onClick={() => {
                                      setRequesterFunction(
                                        user.last_name + " " + user.first_name,
                                        user.id
                                      );
                                    }}
                                    data-key={user.id}
                                    data-name={
                                      user.last_name + ", " + user.first_name
                                    }
                                    className={
                                      cursor === i
                                        ? "active-software-selection"
                                        : null
                                    }
                                  >
                                    {user.first_name} <br /> {user.last_name}
                                  </p>
                                ))
                              )}
                            </div>
                          ) : null}
                        </Grid.Column>
                        <Grid.Column width="2">
                          <Dropdown
                            style={{ minWidth: "0em" }}
                            placeholder={item.status}
                            options={inventoryStatuses}
                            value={newStatus}
                            selection
                            onChange={(event, data) => {
                              setModifyInventory({
                                ...modifyInventory,
                                newStatus: data.value,
                              });
                              if (data.value === "ASSIGNED") {
                                setReassignVisibility({
                                  ...reassignVisibility,
                                  isVisible: true,
                                });
                              } else {
                                setReassignVisibility({
                                  ...reassignVisibility,
                                  isVisible: false,
                                });
                              }
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column width="3">
                          <Input
                            placeholder={item.description}
                            value={newDescription}
                            name="newDescription"
                            onChange={handleInventoryChange}
                            style={{ maxWidth: "100%" }}
                          />
                        </Grid.Column>
                        <Grid.Column width="2">
                          <Dropdown
                            style={{ minWidth: "0em", maxWidth: "100%" }}
                            placeholder="Item Type"
                            options={inventoryType}
                            value={newType}
                            selection
                            onChange={(event, data) =>
                              setModifyInventory({
                                ...modifyInventory,
                                newType: data.value,
                              })
                            }
                          />
                        </Grid.Column>

                        <Grid.Column width="2">
                          <Input
                            placeholder={item.model_number}
                            value={newSerialNumber}
                            name="newSerialNumber"
                            onChange={handleInventoryChange}
                            style={{ maxWidth: "100%" }}
                          />
                        </Grid.Column>
                        <Grid.Column width="2">
                          <Form>
                            <Form.Field>
                              <DatePicker
                                onChange={handleInventoryChange}
                                selected={warranty_expiry_date}
                                placeholderText={item.warranty_expiry_date}
                                name="warranty_expiry_date"
                              />
                            </Form.Field>
                          </Form>
                        </Grid.Column>
                        <Grid.Column width="1">
                          <FontAwesomeIcon
                            onClick={() => openPopup(item)}
                            icon={faCommentDots}
                            style={{
                              float: "right",
                              cursor: "pointer",
                              color: "blue",
                              fontSize: "25px",
                            }}
                            color="red"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column style={{ textAlign: "right" }}>
                          <Button
                            color="green"
                            basic
                            onClick={(
                              id,
                              description,
                              status,
                              asa_tag,
                              model_number,
                              type,
                              warranty_expiry_date
                            ) =>
                              sendInfoToDatabase(
                                item.id,
                                item.description,
                                item.status,
                                item.asa_tag,
                                item.model_number,
                                item.type,
                                item.warranty_expiry_date
                              )
                            }
                          >
                            Submit
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  ) : null}
                </Grid>
              </List.Content>
            </List.Item>
          ))}
        </List>
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </Container>
  );
}
const mapStateToProps = (state) => ({
  inventory: state.inventory.inventory,
  inventoryModels:state.inventory.inventoryModels,
  users: state.inventory.users,
  allInven: state.inventory.inventory,
  allUsers: state.inventory.allUsers,
  invComments: state.inventory.invComments,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getAllInventory,
  modifyITinventory,
  lookupUsersForInventory,
  clearUsersForInventory,
  clearAllInventory,
  allDBUsers,
  getInventoryComments,
  AddInventoryComments
})(AllInventory);