import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {
  getAllIntakeFormRequests,
  getManagerPendingARRequests,
  getSingleIntakeForm
} from "../../../Redux/Actions/accessRequestActions";
import { Container, Segment, Modal, Grid, Button,Input } from "semantic-ui-react";
import ManagerStage from "./ManagerStage";
import PendingModifyRequest from "./PendingModifyRequest";
// import IndividualAccessRequests from './IndividualAccessRequests';
import _ from "lodash";
import Moment from "react-moment";
import { Link } from "react-router-dom";
function IntakeRequests({
  getAllIntakeFormRequests,
  accessRequests,
  getManagerPendingARRequests,
  modal,
  auth,
  getSingleIntakeForm
}) {


  useEffect(() => {
    const fd = new FormData();
    if (auth.user) {
      fd.append("user_email", auth.user[0].email);
      getAllIntakeFormRequests(fd);
    }       
  }, [auth]);

  const [searchParam, setSearchParam] = useState("")

  const handleInputChange = (e) => {
    let value = e.target.value
    setSearchParam(value)
  }

  

  return (
    <Container>
      <Grid columns="equal">
                    <Grid.Row>
                      <Grid.Column>
                        <h1>All Compliance Request:</h1>
                        </Grid.Column>
                        <Grid.Column style={{textAlign: "right",marginTop:"10px"}}>
                        <Input
              placeholder="Search ..."
              icon="search"
              onChange={handleInputChange}
              style={{ maxWidth: "100%" }}
            />
                        </Grid.Column>
                        </Grid.Row>
                        </Grid>
      
      {accessRequests && accessRequests.intakeForms && accessRequests.intakeForms.length > 0 && accessRequests.intakeForms.map((eachIntake,eachIntakeIndex)=>{
        return (
          <>
          {(!searchParam || eachIntake.requesterName.match(new RegExp(searchParam,"gi"))) && <Segment style={{ cursor: "pointer" }} key={eachIntakeIndex}>
                  <Grid columns="equal">
                    <Grid.Row>
                      <Grid.Column>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Requestor:{" "}
                          </span>
                          {eachIntake.requesterName}
                        </p>
                        
                      </Grid.Column>
                      <Grid.Column style={{ textAlign: "right" }}>
                      <Link
        to={`/compilance-form/${eachIntake.id}`}
        key={eachIntake.id}
        style={{ margin: "0 5px" }}
      >
                        <Button basic color="green" type='button'>
                        {eachIntake.status === 'Review' ? "Review Form" : "Completed"}
                        </Button>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                    {eachIntake.created_at ? (
                      <Grid.Row style={{ marginTop: "-10px",
                        paddingTop: "unset" }}>
                        <Grid.Column>
                          <span style={{ fontWeight: "bold" }}>
                            Created Date:{" "}
                          </span>
                          <Moment date={eachIntake.created_at} format="MM/DD/YY LTS" />
                        </Grid.Column>
                      </Grid.Row>
                    ) : null}
                  </Grid>
                </Segment>}
                </>
        )
      })}
    </Container>
  );
}
const mapStateToProps = state => ({
  accessRequests: state.accessRequests,
  modal: state.modal,
  auth: state.auth
});
export default connect(mapStateToProps, {
  getAllIntakeFormRequests,
  getManagerPendingARRequests,
  getSingleIntakeForm
})(IntakeRequests);
