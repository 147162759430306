import React, { useState } from "react";
import { Input, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { addinUseInventory } from "../../../Redux/Actions/inventoryActions";
import DatePicker from "react-datepicker";

function AddCurrentInventory({ addinUseInventory }) {
  const [inventory, setInventory] = useState({
    asset_tag: "",
    serial_number: "",
    description: "",
    in_use_by_email: "",
    in_use_by_first: "",
    in_use_by_last: "",
    warranty_expiry_date:"" 
  });
  const {
    asset_tag,
    serial_number,
    description,
    in_use_by_email,
    in_use_by_first,
    in_use_by_last,
    warranty_expiry_date
  } = inventory;
  const onInputChange = e => {
    setInventory({
      ...inventory,
      [e.target.name]: e.target.value
    });
  };
  const handleNewInventory = e => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("asset_tag", asset_tag);
    fd.append("serial_number", serial_number);
    fd.append("description", description);
    fd.append("in_use_by_email",in_use_by_email);
    fd.append("in_use_by_first",in_use_by_first);
    fd.append("in_use_by_last",in_use_by_last);
    if(warranty_expiry_date){
      const ISODueDate = warranty_expiry_date.toISOString().split("T")[0] + " 00:00:00";
    fd.append("warranty_expiry_date", ISODueDate)
    }
    addinUseInventory(fd).then(() => {
      setInventory({
        ...inventory,
        asset_tag: "",
        serial_number: "",
        description: "",
        warranty_expiry_date: ""
      });
    });
  };

  const handleDueDate = (date) => {
    setInventory({
      ...inventory,
      warranty_expiry_date: date,
    });
  };

  return (
    <Form onSubmit={e => handleNewInventory(e)}>
      <Form.Field>
        <Input
          placeholder="Asset Tag"
          value={asset_tag}
          name="asset_tag"
          onChange={onInputChange}
        />
      </Form.Field>
      <Form.Field>
        <Input
          placeholder="Serial Number"
          value={serial_number}
          name="serial_number"
          onChange={onInputChange}
        />
      </Form.Field>
      <Form.Field>
        <Input
          placeholder="Model"
          value={description}
          name="description"
          onChange={onInputChange}
        />
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Input
          placeholder="User First Name"
          value={in_use_by_first}
          name="in_use_by_first"
          onChange={onInputChange}
        />
        <Form.Input
          placeholder="User Last Name"
          value={in_use_by_last}
          name="in_use_by_last"
          onChange={onInputChange}
        />
        <Form.Input
          placeholder="User Email"
          value={in_use_by_email}
          name="in_use_by_email"
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Field>
      <DatePicker
                onChange={handleDueDate}
                selected={warranty_expiry_date}
                placeholderText="Warranty Expiration"
                        name="warranty_expiry_date"
                      />
        </Form.Field>
      <Form.Field style={{ textAlign: "right" }}>
        <Button basic color="green">
          Submit
        </Button>
      </Form.Field>
    </Form>
  );
}

export default connect(null, { addinUseInventory })(AddCurrentInventory);