import React, { useEffect } from "react";
import { Button, Grid, Loader, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  addModifyRequestComment,
  getModifyRequestComments
} from "../../../Redux/Actions/commentActions";
import { getPendingProductRequested } from "../../../Redux/Actions/inventoryActions";
import {
  denyModificationRequest,
  hrApproveTermination,
  managerApproveModifyRequest
} from "../../../Redux/Actions/accessRequestActions";
import Moment from "react-moment";

function PendingModifyRequest({
  modify,
  product,
  getPendingProductRequested,
  auth,
  comments,
  addModifyRequestComment,
  getModifyRequestComments,
  denyModificationRequest,
  redirect,
  hrApproveTermination,
  managerApproveModifyRequest
}) {
  useEffect(() => {
    const fd = new FormData();
    fd.append("ar_to_comment_on", modify.id);
    fd.append("product_to_lookup", modify.product_requested);
    // getPendingProductRequested(fd);
    // getModifyRequestComments(fd);
  }, [getPendingProductRequested, getModifyRequestComments]);
  // I DON'T REMEMBER IF WE NEED THIS

  // const [arComment, setARcomment] = useState({
  //   ar_comment: ""
  // });
  // const handleARCommentChange = e => {
  //   setARcomment({
  //     ...arComment,
  //     ar_comment: e.target.value
  //   });
  // };
  // const { ar_comment } = arComment;
  // const handleCommentSubmit = (e, accessRequestId) => {
  //   e.preventDefault();
  //   const fd = new FormData();
  //   fd.append("ar_to_comment_on", accessRequestId);
  //   fd.append("ar_comment", ar_comment);
  //   fd.append("commentor", auth[0].first_name + " " + auth[0].last_name);
  //   addModifyRequestComment(fd).then(() => {
  //     setARcomment({
  //       ...arComment,
  //       ar_comment: ""
  //     });
  //   });
  // };
  // const handleModificaionApprove = id => {
  
  // };
  const handleDenyModificationRequest = (
    denier,
    modification_type,
    ticket_id
  ) => {
    const fd = new FormData();
    fd.append("denier", denier);
    fd.append("modification_type", modification_type);
    fd.append("ticket_id", ticket_id);
    denyModificationRequest(fd);
  };

  const handleModifcationApprove = (
    approver,
    modification_type,
    ticket_id,
    softwareID,
    person_modified
  ) => {
    const fd = new FormData();
    fd.append("approver", approver);
    fd.append("modification_type", modification_type);
    fd.append("ticket_id", ticket_id);
    fd.append("product_requested", softwareID);
    fd.append("person_modified", person_modified)
    if (modification_type === "USER REQUESTED") {      
      managerApproveModifyRequest(fd);
    } else {
      hrApproveTermination(fd);
    }
  };
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return (
    <Grid columns="equal" stackable style={{ fontSize: "20px" }}>
      <Grid.Row>
        <Grid.Column>
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            {modify.requestor_first} {modify.requester_last}{" "}
          </span>
          {modify.modification_type === "USER REQUESTED" ? (
            <span>
              is requesting access to:{" "}
              <span style={{ fontWeight: "bold", color: "#1B75BB" }}>
                {modify.system_application}
              </span>
            </span>
          ) : null}

          {modify.modification_type === "TERMINATION" ? (
            <span>
              <span style={{ fontSize: "20px" }}>
                is requesting termination of:
              </span>
              <hr />
              <span>
                <p style={{ fontWeight: "bold" }}>
                  {modify.modified_last}, {modify.modified_first}{" "}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Manager: </span>
                  {modify.modified_manager}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Dept: </span>
                  {modify.department}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Job Title: </span>
                  {modify.dept_specialization}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Created Time: </span>
                  <Moment date={modify.created} format="MM/DD/YY LTS" />
                </p>
              </span>
            </span>
          ) : (
            <span> </span>
          )}
          {product[0] ? (
            <span style={{ fontWeight: "bold" }}>
              {product[0].system_application}
            </span>
          ) : (
            <Loader active inline />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ textAlign: "right" }}>
          <Button
            basic
            color="red"
            onClick={(denier, modification_type, ticket_id) =>
              handleDenyModificationRequest(
                auth[0].id,
                modify.modification_type,
                modify.id
              )
            }
          >
            Deny
          </Button>

          <Modal
            trigger={
              <Button basic color="green">
                Approve
              </Button>
            }
            closeIcon
          >
            <Modal.Content>
              <h2>Are you sure?</h2> Press "X" to go back.
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={(approver, modification_type, ticket_id, softwareID, person_modified) =>
                  handleModifcationApprove(
                    auth[0].id,
                    modify.modification_type,
                    modify.id,
                    modify.softwareID,
                    modify.person_modified
                  )
                }
              >
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
const mapStateToProps = state => ({
  comments: state.comments.arComments,
  auth: state.auth.user,
  product: state.inventory.software_products,
  redirect: state.redirect
});
export default connect(mapStateToProps, {
  addModifyRequestComment,
  getModifyRequestComments,
  denyModificationRequest,
  getPendingProductRequested,
  hrApproveTermination,
  managerApproveModifyRequest
})(PendingModifyRequest);
