import React, { useEffect, useState } from "react";
import { Container, Grid, Modal } from "semantic-ui-react";
import { Form, Button, Input } from "semantic-ui-react";
import { Paper } from "@material-ui/core";
import { Tab } from "semantic-ui-react";
import {
  get_ar_request_comments,
  getModifyRequestComments,
  get_ITticket_comments,
  getDataTicketComments,
  resetTicketComments,
  add_ITticket_comment,
  addDataComment
} from "../../../../Redux/Actions/commentActions";

import { getAllDashboardTickets,getAllAssignedDashboardTickets,clearDashTickets } from "../../../../Redux/Actions/dashboardActions";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import IndependentDashboardTicket from "./IndependentDashboardTicket";
import TerminationAccessTicketsModal from "./TicketTypes/TerminationAccessTicketsModal";
import DataOpsRequestModal from "./TicketTypes/DataOpsRequestModal";
import NewITTicketModal from "./TicketTypes/NewITTicketModal";
import DataAccessTicketsModal from "./TicketTypes/DataAccessTicketsModal";
import {
  saveMicrosoftToDatabase
} from "../../../../Redux/Actions/userActions";
function UserDashboard({
  auth,
  dashboard_tickets,
  getAllDashboardTickets,
  get_ar_request_comments,
  getModifyRequestComments,
  get_ITticket_comments,
  getDataTicketComments,
  comments,
  resetTicketComments,
  add_ITticket_comment,
  addDataComment,
  getUserPicFromMicrosoft,
  getAllAssignedDashboardTickets,
  assigned_dashboard_tickets,
  myMicroData,
  manMicroData,
  saveMicrosoftToDatabase
}) {
  useEffect(() => {
    setIsLoading(true)
    getAllTabData()
    setSaveCurre(false)
    // getUserPicFromMicrosoft();
  }, []);
  const [saveCurrentUserData, setSaveCurre] = useState(false)

  const saveUserData = () => {
    if(!saveCurrentUserData && myMicroData && manMicroData){
      let data = {}
      if(myMicroData){
        data["email"] = myMicroData["userPrincipalName"]?.trim()
        data["dept_specialization"] = myMicroData["jobTitle"]?.trim()
        data["first_name"] = myMicroData["displayName"]?.split(" ")[0]?.trim()
        data["last_name"] = myMicroData["displayName"]?.split(" ")[1]?.trim()
        data["department"] = myMicroData["department"]?.trim()
      }
      if(manMicroData){
        data["manager_name"] = manMicroData["displayName"]?.trim()
        data["manager_email"] = manMicroData["userPrincipalName"]?.trim()
      }
      const fd = new FormData();
      if(data && typeof data === "object" && Object.keys(data).length > 0){
        Object.keys(data).map(x=>{
          fd.append(x, data[x])
        })
        fd.append("update", true)
      }
      saveMicrosoftToDatabase(fd)
      setSaveCurre(true)
    }
  }

  saveUserData();

  const [isLoading, setIsLoading] = useState(false);

  const [allCombine, setAllCombine] = useState([])

  const [isSetAllCombine, setisSetAllCombine] = useState(false)

  const [allAssigned, setAllAssigned] = useState([])

  const [isSetAllAssigned, setisSetAllAssigned] = useState(false)

  const getAllTabData = () =>{
    setIsLoading(true)
    const fd = new FormData();
    fd.append("email", auth.user[0].email);
    fd.append("user_id", auth.user[0].id);
    fd.append("user_assigned", auth.user[0].first_name+" "+auth.user[0].last_name)    
    getAllDashboardTickets(fd).then(()=>{
      setTimeout(()=>{
        setAllCombine(dashboard_tickets && dashboard_tickets.dashboard_tickets ? dashboard_tickets.dashboard_tickets.sort((left, right) => {
          return moment.utc(right.created).diff(moment.utc(left.created));
        }) : [])
        setIsLoading(false)
      },500)
    })
    getAllAssignedDashboardTickets(fd)
    setIsLoading(false)
  }

  const getGreetingTime = m => {
    var g = null;
    if (!m || !m.isValid()) {
      return;
    }
    var split_afternoon = 12;
    var split_evening = 17;
    var currentHour = parseFloat(m.format("HH"));
    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      g = "afternoon";
    } else if (currentHour >= split_evening) {
      g = "evening";
    } else {
      g = "morning";
    }
    return g;
  };
  const spanbold = {
    fontWeight: "bold"
  };

  const handleModalOpen = (event, data, id) => {
    let fd = new FormData();
    // fd.append(id[0], id[1]);
    if (id[0] === "dr_id") {
      fd.append("data_ticket_id", id[1]);
      getDataTicketComments(fd);
    }
    if (id[0] === "ti_id") {
      fd.append("it_ticket_id", id[1]);
      get_ITticket_comments(fd);
    }
    if (id[0] === "ac_user_id") {
      get_ar_request_comments(fd);
    }
    if (id[0] === "id") {
      getModifyRequestComments(fd);
    }
  };

  const handleClose = () => {
    resetTicketComments();
  };

  const [missingDataModal, setMissingDataModal] = useState(false);
  const user = auth.user[0];
  const paraStyle = {
    padding: "0",
    margin: "0"
  };
  const spanStyle = {
    marginRight: "15px",
    position: "relative",
    top: "-2px"
  };
  const inputStyle = {
    cursor: "pointer",
    marginRight: "2px"
  };

  const [ticketType, setTicketType] = useState("")
  const [ticketType1,setTicketType1] = useState("")

  const onChange = e => {
    setTicketType(e.target.value);
  };

  const onChange1 = e => {
    setTicketType1(e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    setAllCombine([])
    setAllAssigned([])
    const fd = new FormData();
    fd.append("email", auth.user[0].email);
    fd.append("user_id", auth.user[0].id);
    fd.append("user_assigned", auth.user[0].first_name+" "+auth.user[0].last_name)
    fd.append("ticketType", ticketType ? ticketType.toUpperCase() : "")
    getAllDashboardTickets(fd).then(()=>{
      // setAllCombine(dashboard_tickets.dashboard_tickets.sort((left, right) => {
      //   return moment.utc(right.created).diff(moment.utc(left.created));
      // }))
      // setIsLoading(false)
      setTimeout(()=>{
        setAllCombine(dashboard_tickets && dashboard_tickets.dashboard_tickets ? dashboard_tickets.dashboard_tickets.sort((left, right) => {
          return moment.utc(right.created).diff(moment.utc(left.created));
        }) : [])
        setIsLoading(false)
      },500)
    })
  }

  const onAssignedSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    setAllCombine([])
    setAllAssigned([])
    const fd = new FormData();
    fd.append("email", auth.user[0].email);
    fd.append("user_id", auth.user[0].id);
    fd.append("user_assigned", auth.user[0].first_name+" "+auth.user[0].last_name)
    fd.append("ticketType", ticketType1 ? ticketType1.toUpperCase() : "")
    getAllAssignedDashboardTickets(fd).then(()=>{
      setIsLoading(false)
    })
  }  

  const searchTickets = e =>{
    let value = e.target.value
    let tempArray = []
    if(value){     
      var regexSearch = new RegExp(e.target.value,"gi");
      tempArray = [...dashboard_tickets.dashboard_tickets].filter(x=>{
        if(x && JSON.stringify(x) && JSON.stringify(x).match(regexSearch)){
          return true
        }
      })
    }
    if(!value){
      tempArray = []
    }
   
    // setisSetAllCombine(false)
    // setAllCombine(tempArray && tempArray.length > 0 ? tempArray.sort((left, right) => {
    //   return moment.utc(right.created).diff(moment.utc(left.created));
    // }) : [])
    dashboard_tickets.dashboard_tickets = []
  }

  const searchAssignedTickets = e =>{
    let value = e.target.value
    let tempArray = []
    if(value){
            var regexSearch = new RegExp(e.target.value,"gi");
      tempArray = [...assigned_dashboard_tickets.assigned_dashboard_tickets].filter(x=>{
        if(x && JSON.stringify(x) && JSON.stringify(x).match(regexSearch)){
          return true
        }
      })
    }
    if(!value){
      tempArray = [...assigned_dashboard_tickets.assigned_dashboard_tickets]
    }
    setAllAssigned(tempArray && tempArray.length > 0 ? tempArray.sort((left, right) => {
      return moment.utc(right.created).diff(moment.utc(left.created));
    }) : [])
  }

  let panes = [
    { menuItem: "My Info", render: () => <Tab.Pane><Grid columns={2} divided stackable style={{ borderBottom: "1px solid grey", marginTop:"5px" }}>
        <Grid.Row>
          <Grid.Column width="6">
            <Paper elevation={1} style={{ padding: "10px" }}>
              <p style={paraStyle}>
                {user.first_name} {user.last_name}
                <hr /> 
                <label className="newLabel"> Name - </label> {user.first_name} {user.last_name} 
              </p>
              {/* <p style={paraStyle}>{user.email}</p>
              <p style={paraStyle}>{user.department}</p>
              <p style={paraStyle}>{user.dept_specialization}</p>
              <p style={paraStyle}>{user.manager_name}</p> */}
              <p style={paraStyle}><label className="newLabel"> Email - </label> {user.email}</p>
              <p style={paraStyle}><label className="newLabel">Title  - </label> {user.dept_specialization}</p>
              <p style={paraStyle}><label className="newLabel">Department - </label> {user.department}</p>
              <p style={paraStyle}><label className="newLabel">Manager Name - </label> {user.manager_name}</p>
            </Paper>
          </Grid.Column>
          </Grid.Row>
          </Grid></Tab.Pane>},
    { menuItem: "My Ticket Statuses", render: () => 
    <Tab.Pane><Grid style={{marginTop:"5px"}}>
      <Grid.Row>
        <Grid.Column width="8">
            <Form
              className="admin-ticket-form-wrapper"
              onSubmit={e => onSubmit(e)}
            >
              <Form.Field>
                <label>Select Ticket Types</label>
                {/* <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketType"
                  value="new"
                  checked={ticketType === "new"}
                />
                <span style={spanStyle}>New</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketType"
                  value="opened"
                  checked={ticketType === "opened"}
                />
                <span style={spanStyle}>Opened</span> */}
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketType"
                  value=""
                  checked={ticketType === ""}
                />
                <span style={spanStyle}>Current</span>
                <input
                  style={inputStyle}
                  onChange={onChange}
                  type="radio"
                  name="ticketType"
                  value="resolved"
                  checked={ticketType === "resolved"}
                />
                <span style={spanStyle}>Resolved</span>
              </Form.Field>
              <Button
                color="red"
                type="reset"
                basic
                onClick={() => {
                  setTicketType("");
                }}
              >
                Clear Results
              </Button>
              <Button type="submit" basic color="green">
                Go!
              </Button>
            </Form>
          </Grid.Column>
    <Grid.Column width="8" style={{ maxHeight: "900px", overflowY: "scroll" }}>
    <Form>
     <Input
        placeholder="Search Ticket"
        onChange={searchTickets}
        icon="search"
        style={{ width: "100%" }}
         />
      </Form>
            {!isLoading
              ? 
              dashboard_tickets.dashboard_tickets.sort((left, right) => {
                return moment.utc(right.created).diff(moment.utc(left.created));
              }).map((tick, index) => (
                <IndependentDashboardTicket props={tick} key={index} callFrom={"myTicket"} />
              )) 

              : null}
          </Grid.Column>
          </Grid.Row>
          </Grid></Tab.Pane>}
  ]

  if(auth.user[0] && (auth.user[0].privilege_level === 1 || auth.user[0].privilege_level === 3 || auth.user[0].privilege_level === 5 || auth.user[0].privilege_level === 6 || auth.user[0].privilege_level === 2)){
    panes.push({ menuItem: "My Assigned Tickets", render: () => <Tab.Pane><Grid style={{marginTop:"5px"}}>
    <Grid.Row>
      <Grid.Column width="8">
          <Form
            className="admin-ticket-form-wrapper"
            onSubmit={e => onAssignedSubmit(e)}
          >
            <Form.Field>
              <label>Select Ticket Types</label>
              <input
                style={inputStyle}
                onChange={onChange1}
                type="radio"
                name="ticketType1"
                value=""
                checked={ticketType1 === ""}
              />
              <span style={spanStyle}>Current</span>
              <input
                style={inputStyle}
                onChange={onChange1}
                type="radio"
                name="ticketType1"
                value="resolved"
                checked={ticketType1 === "resolved"}
              />
              <span style={spanStyle}>Resolved</span>
            </Form.Field>
            <Button
              color="red"
              type="reset"
              basic
              onClick={() => {
                setTicketType1("");
              }}
            >
              Clear Results
            </Button>
            <Button type="submit" basic color="green">
              Go!
            </Button>
          </Form>
        </Grid.Column>
  <Grid.Column width="8" style={{ maxHeight: "900px", overflowY: "scroll" }}>
  <Form>
        <Input
        placeholder="Search Ticket"
        onChange={searchAssignedTickets}
        icon="search"
        style={{ width: "100%" }}
         />
      </Form>
          {assigned_dashboard_tickets
            ? assigned_dashboard_tickets.assigned_dashboard_tickets.sort((left, right) => {
              return moment.utc(right.created).diff(moment.utc(left.created));
            }).map((tick, index) => (
              <IndependentDashboardTicket props={tick} key={index} callFrom={"assignedTo"} />
            ))
            : null}
        </Grid.Column>
        </Grid.Row>
        </Grid></Tab.Pane> })
  }

  const handleTabChange = (e, data) => {
    setIsLoading(true)
    getAllTabData() 
  }

  return (
    <Container>
      <Modal open={missingDataModal}></Modal>
      <h2
        style={{ borderBottom: "1px solid grey", padding: "15px 0" }}
        className="grey-text-color"
      >
        {" "}
        Good {getGreetingTime(moment())} {user.first_name}
      </h2>

      <Container maxWidth="lg">
      <Grid.Row>
          <Grid.Column>
            {isLoading && (
              <div className="loadingNew">
                <div className="loaderNew"></div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Tab onTabChange={(e)=> handleTabChange(e)} panes={panes} />
        </Container>
      {/* <Grid stackable style={{ borderBottom: "1px solid grey" }}>
        <Grid.Row>
          <Grid.Column width="4">
            <h3 className="grey-text-color">My Info: </h3>
            <Paper elevation={1} style={{ padding: "10px" }}>
              <p style={paraStyle}>
                {user.first_name} {user.last_name}
                <hr /> 
              </p>
              <p style={paraStyle}>{user.email}</p>
              <p style={paraStyle}>{user.department}</p>
              <p style={paraStyle}>{user.dept_specialization}</p>
              <p style={paraStyle}>{user.manager_name}</p>
            </Paper>
          </Grid.Column>
          <Grid.Column width="6" style={{ maxHeight: "900px", overflowY: "scroll" }}>
            <h3 className="grey-text-color">Ticket Statuses:</h3>
            {combines
              ? combines.map((tick, index) => (
                <IndependentDashboardTicket props={tick} key={index} />
              ))
              : null}
          </Grid.Column>
          <Grid.Column width="6" style={{ maxHeight: "900px", overflowY: "scroll" }}>
            <h3 className="grey-text-color">My Assigned Tickets:</h3>
            {assignedCombines
              ? assignedCombines.map((tick, index) => (
                <IndependentDashboardTicket props={tick} key={index} />
              ))
              : null}
          </Grid.Column>
        </Grid.Row>
      </Grid> */}
    </Container>
  );
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
    comments: state.comments,
    dashboard_tickets: state.dashboard_tickets,   
    assigned_dashboard_tickets: state.assigned_dashboard_tickets,
    myMicroData: state.auth.my_microsoftGraphData,
    manMicroData: state.auth.my_manager_microsoftGraphData
  }
};
export default connect(mapStateToProps, {
  getAllDashboardTickets,
  get_ar_request_comments,
  getModifyRequestComments,
  get_ITticket_comments,
  getDataTicketComments,
  resetTicketComments,
  add_ITticket_comment,
  addDataComment,
  getAllAssignedDashboardTickets,
  saveMicrosoftToDatabase
  // getUserPicFromMicrosoft
})(UserDashboard);