import {
  ADD_INVENTORY,
  GET_INVENTORY,
  GET_SOFTWARE_SUGGESTIONS,
  CLEAR_SOFTWARE_SUGGESTIONS,
  SET_SOFTWARE,
  REMOVE_SELECTED_SOFTWARE,
  GET_PRODUCT_FROM_DATABASE,
  GET_ALL_INVENTORY,
  GET_USERS_FOR_INVENTORY,
  CLEAR_GET_USERS_FOR_INVENTORY,
  CLEAR_ALL_INVENTORY,
  GET_ALL_USERS_FOR_INVENTORY,
  CLEAR_SELECTED_SOFTWARE,
  SET_NO_MESSAGE,
  GET_ALL_DROPDOWN_MODELS,
  GET_ALL_INVENTORY_COMMENTS,
  ADD_SYSTEM_PRODUCTS,
  GET_ACCESS_BY_USER,

  REMOVE_ACCESS_FROM_USER_REQUEST,
  REMOVE_ACCESS_FROM_USER_SUCESS,
  REMOVE_ACCESS_FROM_USER_FAILURE,
} from "../Actions/types";
import _ from "lodash";
const initialState = {
  loading: true,
  inventory: null,
  access: [],
  message: null,
  suggested_software_products: null,
  software_products: [],
  users: null,
  inventoryModels: [],
  addSystemProduct: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_ALL_INVENTORY:
      return {
        ...state,
        inventory: null,
      };
    case ADD_INVENTORY:
      return {
        ...state,
        loading: false,
      };
    case GET_INVENTORY:      
      if (payload && payload.message) {        
        return {
          ...state,
          loading: false,
          inventory: payload.data,
          message: { message: payload.message },
          msgtype: "success",
        };
      } else {
        return {
          ...state,
          loading: false,
          inventory: payload,
          message: { message: payload.message },
          msgtype: "success",
        };
      }
    case GET_ALL_INVENTORY:
      if (payload.length === 0) {
        return {
          ...state,
          inventory: null,
          inventoryModels: [],
        };
      } else {
        return {
          ...state,
          inventory: payload,
          inventoryModels: [
            ...[{ key: "", value: "", text: "Select" }],
            ...payload
              .map((x) => x["description"])
              .filter((item, pos) => {
                return (
                  payload.map((x) => x["description"]).indexOf(item) === pos
                );
              })
              .filter(Boolean)
              .map((x) => {
                return { key: x, value: x, text: x };
              }),
          ],
        };
      }
    case GET_SOFTWARE_SUGGESTIONS:
      return {
        ...state,
        suggested_software_products: payload,
        software_products: [],
      };
    case SET_SOFTWARE:
      return {
        ...state,
        software_products: _.uniqBy(
          [...state.software_products, ...payload],
          "id"
        ),
      };
    case CLEAR_SELECTED_SOFTWARE:
      return {
        ...state,
        software_products: [],
      };
    case REMOVE_SELECTED_SOFTWARE:
      return {
        ...state,
        software_products: state.software_products.filter(
          (software_products) => software_products.id !== payload
        ),
      };
    case GET_PRODUCT_FROM_DATABASE:
      return {
        ...state,
        software_products: payload,
      };
    case CLEAR_GET_USERS_FOR_INVENTORY:
      return {
        ...state,
        users: null,
      };
    case CLEAR_SOFTWARE_SUGGESTIONS:
      return {
        ...state,
        suggested_software_products: null,
      };
    case GET_USERS_FOR_INVENTORY:
      return {
        ...state,
        users: payload,
      };
    case GET_ALL_USERS_FOR_INVENTORY:
      return {
        ...state,
        allUsers: payload,
      };
    case SET_NO_MESSAGE:
      return {
        ...state,
        msgtype: "",
      };
    case GET_ALL_DROPDOWN_MODELS:
      return {
        ...state,
        modelsDropdown: payload,
      };
    case GET_ALL_INVENTORY_COMMENTS:
      return {
        ...state,
        invComments: payload,
      };
    case ADD_SYSTEM_PRODUCTS:
      return {
        ...state,
        message: payload,
      };
    case GET_ACCESS_BY_USER:
      return {
        ...state,
        access: payload,
      };

      case REMOVE_ACCESS_FROM_USER_REQUEST:
      return {
        ...state,
      };

    case REMOVE_ACCESS_FROM_USER_SUCESS:
      return {
        ...state,
        message: payload,
      };

    case REMOVE_ACCESS_FROM_USER_FAILURE:
      return {
        ...state,
        message: payload,
      };
    default:
      return state;
  }
}
