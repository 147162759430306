// for now this is for the admins, need to refactor to use anywhere.
// {/* // added malik  start// */}
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";

import {
  checkNewStatus,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
} from "../../../Redux/Actions/ticketActions";
import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {
  get_ITticket_comments,
  add_ITticket_comment
} from "../../../Redux/Actions/commentActions";
import { Redirect, Link } from "react-router-dom";
import { Segment, Grid,Form, Icon } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

const ModalTicketTest = ({
  tickets: { tickets },
  redirect,
  auth,
  dataOps
}) => {
  const username = auth.token.name;
  const [itComment, setItComment] = useState({
    it_comment: ""
  });
  const [rootCauseExplanation, setRootCauseExplanation] = useState({
    explanation: ""
  });

  const [allTickets,setAllTickets] = useState('')
  const [filTickets, setFilterTikcets] = useState('')
  const { explanation } = rootCauseExplanation;
  const { it_comment } = itComment;
 useEffect(()=>{ 
   setFilterTikcets(dataOps)
   setAllTickets(dataOps)
 },[dataOps])
  const searchTickets = (event) => {
    let searchValue = event.currentTarget.value;    
    if(searchValue){
      var array = [...dataOps];
      //var value = action.payload;
      var index = array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
      var items = index;
      setFilterTikcets(items);     
    }else{
      setFilterTikcets(allTickets)
    }

  }
  let singleTicket;
  dataOps
    ? (singleTicket = dataOps.sort((left, right) => {
        return moment.utc(right.created).diff(moment.utc(left.created));
      }))
    : (singleTicket = null);    
  if (!dataOps || dataOps.length === 0 || !filTickets ) {
    singleTicket = "";
  } else {

    singleTicket = filTickets.map(ticket => (
      <Link
        to={`/pending-qr/${ticket.qr_id}`}
        key={ticket.qr_id}
        style={{ margin: "0 5px" }}
      >
        <Segment className="individual-ticket-reporting-wrapper">
          <ReactTooltip />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <h4>
                  {ticket.dr_ticket_author_name} | {ticket.dr_priority}
                </h4>
                <hr className="style-six" />
                {/* <h4>{ticket.dr_subject}</h4> */}
                <h4>Assigned To : {ticket.dr_assigned_to}</h4>
                <hr className="style-six" />
                Created:{" "}
                <Moment date={ticket.created} format="MM/DD/YYYY HH:mm" />
              </Grid.Column>
              <Grid.Column>
                {ticket.dr_type === "Question" ? (
                  <Icon
                    name="question"
                    data-tip="Question"
                    size="large"
                    color="red"
                  />
                ) : ticket.dr_type === "Incident" ? (
                  <Icon
                    name="exclamation triangle"
                    data-tip="Incident"
                    size="large"
                    color="red"
                  />
                ) : ticket.dr_type === "Problem" ? (
                  <Icon
                    name="exclamation"
                    data-tip="Problem"
                    size="large"
                    color="red"
                  />
                ) : ticket.dr_type === "Task" ? (
                  <Icon
                    name="calendar check outline"
                    data-tip="Task"
                    size="large"
                    color="red"
                  />
                ) : null}
                <p>Ticket ID: {ticket.qr_id}</p>
                <p>Status: {ticket.dr_admin_status}</p>
                <p>{ticket.dr_description.slice(0, 50)}...see more.</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Link>
    ));
  }
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return <div >
     <Form>
     <div class="ui icon input"  style={{ width: "100%" }}>       
          <input
          onChange={searchTickets}         
          placeholder="Search Ticket"          
        />
          <i aria-hidden="true" class="search icon"></i></div>
      </Form>
    {singleTicket}<i aria-hidden="true" ></i></div>;

};
const mapStateToProps = state => ({
  auth: state.auth,
  attachments: state.tickets.attachments,
  tickets: state.tickets,
  redirect: state.redirect,
  comments: state.comments,
  dataOps: state.dataOps.requests
});
export default connect(mapStateToProps, {
  get_ITticket_comments,
  checkNewStatus,
  add_ITticket_comment,
  adminStatusUpdate,
  getTicketAttachments,
  clearAttachments
})(ModalTicketTest);