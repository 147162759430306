// Need to refactor the modal

import React from "react";
import { Image, Container, Modal, Button } from "semantic-ui-react";
import mainLogo from "../../images/Logos/hta-main-logo.png";
import Alert from "../Common/Alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {closeModal} from '../../Redux/Actions/modalActions';

const  Header = ({modal,closeModal, auth}) => {  
  return (
    <header>
      <Modal open={modal.open}>
          <Modal.Content>
            <h3 style={{textAlign:'center'}}>{!modal.modalMessage ? null : modal.modalMessage.message}</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={closeModal}>Ok</Button>
          </Modal.Actions>
      </Modal>
      <div id="alert-container">
        <Alert />
      </div>
      <Container>
        {/* <Link to="/">
          <Image src={mainLogo} size="medium" />
        </Link> */}
        <h3 className="grey-text-color">
          Online Ticketing System{" "}
          <span className="purple-accent-font">plus</span>
        </h3>
      </Container>
    </header>
  );
}
const mapStateToProps = state => ({  
  modal: state.modal,
  auth: state.auth
}); 
export default connect(mapStateToProps, {closeModal})(Header);
