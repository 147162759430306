import React, { useState, useEffect, useCallback } from "react";
import { throttle } from "lodash";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Dropdown,
  Button,
  Form,
  Grid,
  Modal,
  Segment,
  Dimmer,
  Loader,
  Placeholder,
  Image,
  Input,
  TextArea,
} from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import {
  getDataQRComments,
  addQRDataComment,
} from "../../../Redux/Actions/commentActions";
import {
  getPendingDevRequestsQR,
  changeQRAssignedTo,
  chageQRTicketStatus,
  getAllPendingQR,
  addAdminQRAttachment,
  getIndividualQRInfo,
  getAllActiveQRUser,
} from "../../../Redux/Actions/dataOpsActions";
import {
  getQRTicketAttachments,
  getQRTicketAdminAttachments,
} from "../../../Redux/Actions/ticketActions";
import {
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers,
} from "../../../Redux/Actions/inventoryActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faWindowClose,
  faDesktop,
  faLaptop,
  faPhone,
  faKeyboard,
  faQuestionCircle,
  faExclamation,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { FORM_DATA_PROCESS } from "../../../Redux/Actions/types";
import DatePicker from "react-datepicker";
registerPlugin(FilePondPluginImagePreview);

function IndivitualDataOp({
  getPendingDevRequestsQR,
  dataOps,
  getDataQRComments,
  addQRDataComment,
  comments,
  auth,
  changeQRAssignedTo,
  chageQRTicketStatus,
  getAllPendingQR,
  getQRTicketAttachments,
  attachments,
  addAdminQRAttachment,
  getIndividualQRInfo,
  match,
  getAllActiveQRUser,
  assignedUser,
  lookupUsersForInventory,
  clearUsersForInventory,
  users,
  allDBUsers,
  allUsers,
  getQRTicketAdminAttachments,
  admin_attachments,
}) {
  useEffect(() => {
    getAllActiveQRUser();
    setAlready(false);
    setAlreadyAss(false);
    setRequesterFunction("");
    setAssignedTo("");
    allDBUsers();
    setEdit(true);
    const fd = new FormData();
    fd.append("qr_id", match.params.id);
    fd.append("data_ticket_id", match.params.id);
    getQRTicketAttachments(fd);
    getDataQRComments(fd);
    getIndividualQRInfo(fd);
    getQRTicketAdminAttachments(fd);
  }, []);

  // requester name
  const [requesterName, setRequesterName] = useState("");
  // React.useEffect(() => {
  //   clearUsersForInventory();
  // }, [requesterName]);

  const handleAutoProductChange = (e) => {
    setRequesterName(e.target.value.trim());
    delayedQuery(e.target.value);
  };

  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );

  const sendQuery = (query) => {
    if (query.length > 0) {
      console.log(query);
      lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };

  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });
  const { cursor } = highlightSelection;

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        // const fd = new FormData();
        // fd.append("reassign_user", selected[i].getAttribute("data-key"));

        // need to get the name nd user from the database and make the change immediaty
        setRequesterName("");
        // clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0,
        });
      }
    }
  };

  function setRequesterFunction(name) {
    console.log(name);
    setRequesterName(name.trim());
  }

  const [alreadyReq, setAlready] = useState(false);
  const [alreadyAss, setAlreadyAss] = useState(false);

  const [assignedTo, setAssignedTo] = useState({});
  const [currentStatus, setcurrentStatus] = useState("");
  const [checkAssigned, setCheckAssigned] = useState("");

  if (assignedUser && Array.isArray(assignedUser) && assignedUser.length > 0) {
    assignedUser = assignedUser.map((x) => {
      return {
        key: x.id,
        value: x.first_name + " " + x.last_name,
        text: x.first_name + " " + x.last_name,
        email: x.email,
      };
    });
    assignedUser.unshift({
      key: "",
      value: "",
      text: "SELECT ASSIGNED TO",
      email: "",
    });
    if (dataOps && dataOps[0] && dataOps[0].requesterName) {
      if (!alreadyReq) {
        setRequesterFunction(dataOps[0].requesterName);
        setAlready(true);
      }
    }
    if (dataOps && dataOps[0] && dataOps[0].dr_assigned_to) {
      if (!alreadyAss) {
        setAssignedTo(dataOps[0].dr_assigned_to);
        setAlreadyAss(true);
      }
    }
  }

  const ticketStatus = [
    { key: "PE", value: "PENDING", text: "PENDING" },
    { key: "IN PR", value: "IN PROGRESS", text: "IN PROGRESS" },
    { key: "RE", value: "RESOLVED", text: "RESOLVED" },
  ];

  // const propCode = "dataops";

  // const [rootCauseExplanation, setRootCauseExplanation] = useState({
  //   explanation: "",
  // });
  // const { explanation } = rootCauseExplanation;
  // added malik  start//
  const [isLoading, setIsLoading] = useState(false);
  // added malik end //
  const onSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("selected_devops_request", checkAssigned);
    getPendingDevRequestsQR(fd);
  };

  const handleModalOpen = (event, data, id) => {
    const fd = new FormData();
    fd.append("data_ticket_id", id);
    getQRTicketAttachments(fd);
    getDataQRComments(fd);
  };

  // const handleRootExplination = (e) => {
  //   setRootCauseExplanation({
  //     ...rootCauseExplanation,
  //     explanation: e.target.value,
  //   });
  // };

  const handleClosedModal = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("selected_devops_request", checkAssigned);
    getPendingDevRequestsQR(fd);
  };
  const [dataOpscomments, setDataOpsComment] = useState({
    comment: "",
  });
  const { comment } = dataOpscomments;
  const handleCommentChange = (e, data) => {
    e.preventDefault();
    setDataOpsComment({
      ...comments,
      comment: e.target.value,
    });
  };

  function changeSpecialCharacters(text) {
    if (!text) {
      return;
    }
    var entities = [
      ['amp', '&'],
      ['apos', '\''],
      ['#x27', '\''],
      ['#x2F', '/'],
      ['#39', '\''],
      ['#47', '/'],
      ['lt', '<'],
      ['gt', '>'],
      ['nbsp', ' '],
      ['quot', '"']
  ];

  for (var i = 0, max = entities.length; i < max; ++i) 
      text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

  return text;
  }

  const handleCommentSubmit = (e, id, user) => {
    const fd = new FormData();
    const urlLocation = window.location.href;
    fd.append("data_comment", comment);
    fd.append("id", id);
    fd.append("url_location", urlLocation);
    fd.append("commentor_id", auth.user[0].id);
    fd.append(
      "commentor",
      auth.user[0].first_name + " " + auth.user[0].last_name
    );
    fd.append("authorEmail", dataOps[0].dr_ticket_author_email)
    fd.append("user", user)

    addQRDataComment(fd).then(() => {
      setDataOpsComment({
        ...comments,
        comment: "",
      });
    });
  };

  // added malik  start//
  const handleFinalSubmit = (e, id) => {
    setIsLoading(true);
    const fd = new FormData();
    if (currentStatus === "RESOLVED") {
      fd.append("resolved_by", auth.user[0].id);
      // fd.append("explination", explanation);
    }
    fd.append("set_dr_ticket_status", currentStatus);
    fd.append("ticket_to_change_status", id);
    fd.append("ticket_to_assign", id);
    if(!dataOps[0].dr_assigned_to || (dataOps[0].dr_assigned_to && dataOps[0].dr_assigned_to.match(/unassig/gi))){
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Please Assign Ticket To Someone"), 300);
      return;
    }
    chageQRTicketStatus(fd).then((res) => {
      const fd = new FormData();
      fd.append("qr_id", match.params.id);
      fd.append("data_ticket_id", match.params.id);
      getQRTicketAttachments(fd);
      getDataQRComments(fd);
      getIndividualQRInfo(fd);
      setcurrentStatus("");
      setIsLoading(false);
    });
  };

  const handleRequestName = () => {
    if (!requesterName) {
      return;
    }

    if (!requesterName) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Please Enter Requester Name"), 300);
      return false;
    }

    setIsLoading(true);
    const fd = new FormData();
    fd.append("ticket_to_assign", dataOps[0].qr_id);
    fd.append("setRequestedName", requesterName);
    changeQRAssignedTo(fd).then((res) => {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(
        () => toastr.success("Requester Name Is Changed To " + requesterName),
        300
      );
      fd.append("qr_id", match.params.id);
      fd.append("data_ticket_id", match.params.id);
      getQRTicketAttachments(fd);
      getDataQRComments(fd);
      getIndividualQRInfo(fd);
      setIsLoading(false);
    });
  };

  const [requesterEmail, setRequesterEmail] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");
  // added malik  end//

  const handleAssignedTo = (e, id, data) => {
    if (!data) {
      return false;
    }
    setIsLoading(true);
    const fd = new FormData();
    let index = assignedUser.findIndex((x) => x.value === data);
    if (index !== -1) {
      fd.append("assigned_to_email", assignedUser[index]["email"]);
    }
    fd.append("ticket_to_change_status", id);
    fd.append("set_assigned_to", data);
    fd.append("ticket_to_assign", id);
    changeQRAssignedTo(fd).then((res) => {
      // added malik  start//
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.success("Ticket is Assgined To " + data), 300);
      fd.append("qr_id", match.params.id);
      fd.append("data_ticket_id", match.params.id);
      getQRTicketAttachments(fd);
      getDataQRComments(fd);
      getIndividualQRInfo(fd);
      setIsLoading(false);
      // added malik  end//
    });
  };

  const handleAssignTo = (event, data) => {
    console.log(data);
    setAssignedTo(data);
    handleAssignedTo(event, dataOps[0].qr_id, data);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
  });
  const { ticketAttachment_one } = picture;
  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
      });
    }
  };

  const handleItAttachmentSubmit = (id) => {
    const fd = new FormData();
    fd.append("qr_id", id);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    addAdminQRAttachment(fd).then(() => {
      addAttachment({
        ticketAttachment_one: "",
      });
      const fd = new FormData();
      fd.append("qr_id", match.params.id);
      fd.append("data_ticket_id", match.params.id);
      getQRTicketAttachments(fd);
      getDataQRComments(fd);
      getIndividualQRInfo(fd);
      getQRTicketAdminAttachments(fd);
    });
  };
  
  const [dates, setDates] = useState({
    claimsBeginingDate: "",
    claimsEndDate: "",
    dueDate: "",
  });
  const { claimsBeginingDate, claimsEndDate, dueDate } = dates;
  const [ticketData, setTicketData] = useState({
    ticketType: "DATA",
    ticketSubject: "",
    ticketDescription: "",
    itHelpType: "",
    ticketPriority: "",
    organization: "",
    dataUse1:"",
    dataUse:"",
    fileLocation:"",
    fileLocationLink:""
  });

  const handleDueDate = (date) => {
    console.log(date);
    setDates({
      ...dates,
      dueDate: date,
    });
  };
  const handleBeginingDate = (date) => {
    setDates({
      ...dates,
      claimsBeginingDate: date,
    });
  };
  const handleEndDate = (date) => {
    setDates({
      ...dates,
      claimsEndDate: date,
    });
  };

  const {
    ticketType,
    ticketSubject,
    ticketDescription,
    itHelpType,
    ticketPriority,
    organization,
    dataUse1,
    dataUse,
    fileLocation,
    fileLocationLink
  } = ticketData;

  const spanStyle = {
    marginRight: "15px",
    position: "relative",
    top: "-2px",
  };
  const inputStyle = {
    cursor: "pointer",
    marginRight: "2px",
  };

  const onChange = (e) =>
    setTicketData({ ...ticketData, [e.target.name]: e.target.value });

  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);

  function handleEdit() {
    setEdit(false);
    setSave(true);
  }

  function handleSave(e) {
    setEdit(true);
    setSave(false);
    updateData(e);
  }

  function updateData(e) {
    const fd = new FormData();
    if (claimsBeginingDate && !claimsEndDate) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Claim End Date is Mandatory"), 300);
      return false;
    }
    if (!claimsBeginingDate && claimsEndDate) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Claim Start Date is Mandatory"), 300);
      return false;
    }
    if (claimsBeginingDate && claimsEndDate) {
      const ISOFromDate =
        claimsBeginingDate.toISOString().split("T")[0] + " 00:00:00";
      const ISOToDate = claimsEndDate.toISOString().split("T")[0] + " 23:59:59";
      fd.append("claimsBeginingDate", ISOFromDate);
      fd.append("claimsEndDate", ISOToDate);
    }
    const ISODueDate = dueDate.toISOString().split("T")[0] + " 23:59:59";
    if (!requesterName) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Requester Name is Mandatory"), 300);
      return false;
    }
    fd.append("user_id", auth.user[0].id);
    fd.append("ticketType", ticketType);
    fd.append("ticketSubject", ticketSubject);
    fd.append("ticketDescription", ticketDescription);
    fd.append("itHelpType", itHelpType);
    fd.append("dataUse", dataUse)
    fd.append("dataUseType", dataUse1)
    fd.append("fileLocation", fileLocation)
    fd.append("fileLocationLink", fileLocationLink)
    fd.append("ticketPriority", ticketPriority);
    fd.append("organization", organization);
    fd.append("dueDate", ISODueDate);
    fd.append("requesterName", requesterName);
    fd.append("requesterEmail", requesterEmail)
    fd.append("requesterPhone", requesterPhone)
    fd.append("ticket_to_assign", dataOps[0].qr_id);
    fd.append("isUpdate", true);
    setIsLoading(true);
    changeQRAssignedTo(fd).then((res) => {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 2000,
        closeButton: false,
        progressBar: true,
      };
      toastr.clear();
      setTimeout(() => toastr.success("Data Updated"), 300);
      fd.append("qr_id", match.params.id);
      fd.append("data_ticket_id", match.params.id);
      getQRTicketAttachments(fd);
      getDataQRComments(fd);
      getIndividualQRInfo(fd);
      setIsLoading(false);
    });
  }

  const [alredyFetchData, setalredyFetchData] = useState(false);

  React.useEffect(() => {
    // clearUsersForInventory()
    if (dataOps && dataOps && Array.isArray(dataOps) && dataOps.length > 0) {
      if (!alredyFetchData) {
        setalredyFetchData(true);
        setTicketData({
          ticketType: "QualityRequest",
          ticketSubject: dataOps[0].dr_subject,
          ticketDescription: dataOps[0].dr_description,
          itHelpType: dataOps[0].dr_type,
          ticketPriority: dataOps[0].dr_priority,
          organization: dataOps[0].dr_organization,
          dataUse: dataOps[0].dataUse,
          dataUse1: dataOps[0].dataUseType,
          fileLocation: dataOps[0].fileLocation,
          fileLocationLink: dataOps[0].fileLocationLink,
        });
        setRequesterName(dataOps[0].requesterName);
        setRequesterEmail(dataOps[0].requesterEmail)
        setRequesterPhone(dataOps[0].requesterPhone)
        setDates({
          claimsBeginingDate: dataOps[0].dr_claims_begining_date
            ? new Date(dataOps[0].dr_claims_begining_date)
            : "",
          claimsEndDate: dataOps[0].dr_claims_end_date
            ? new Date(dataOps[0].dr_claims_end_date)
            : "",
          dueDate: dataOps[0].dr_due_date
            ? new Date(dataOps[0].dr_due_date)
            : "",
        });
      }
    }
  }, [requesterName, ticketData, picture, dataOps]);

  const [openAttachment, setOpenAttachment] = useState("");
  const [open, setOpen] = useState(false);

  function openPopup(attachment) {
    if(attachment.path && (attachment.path.match(/.xls/gi) || 
    attachment.path.match(/.xlsx/gi) || 
    attachment.path.match(/.doc/gi) || 
    attachment.path.match(/.pdf/gi))){
      console.log(window.location.origin+attachment.path)
      downloadFile(window.location.origin+attachment.path,attachment.path)
      return true
    }
    setOpen(true);
    setOpenAttachment(attachment.path);
  }

  function downloadFile(fileURL,filename) {
    if (
      fileURL        
    ) {
        var save = document.createElement("a");
        console.log(__dirname)
        save.href = fileURL;
        save.target = "_blank";
        save.download = filename.substring(filename.lastIndexOf("/") + 1)
        var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
    }
  }

  let dataopsindividual;
  dataOps
    ? (dataopsindividual = (
        <div>
          <Grid columns="equal" stackable>
            <Grid.Row>
              <Grid.Column>
                <Modal
                  open={open}
                  closeIcon
                  basic={true}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                >
                  <Modal.Content>
                  <Image src={openAttachment} alt="Attachment" fluid />
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                style={{
                  fontSize: "25px",
                  textAlign: "right",
                  marginRight: "66px",
                }}
              >
                {edit && dataOps[0].dr_admin_status !== "RESOLVED" && (
                  <div onClick={(e) => handleEdit(e)}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    color="orange"
                  />
                  <span style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}>Click Here to edit</span>
                  </div>
                )}
                {save && (
                  <span onClick={(e) => handleSave(e)}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ cursor: "pointer", marginTop: "10px", marginRight:"10px" }}
                      color="orange"
                    />
                    <span style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}>Click Here to Save</span>
                  </span>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <span
                  className="colorBrandColor"
                  style={{ fontWeight: "bold" }}
                >
                  Ticket ID:{" "}
                </span>
                {dataOps[0].qr_id}
              </Grid.Column>
              <Grid.Column>
                <span
                  style={{ fontWeight: "bold" }}
                  className="colorBrandColor"
                >
                  Status:
                </span>
                &nbsp;{dataOps[0].dr_admin_status}
              </Grid.Column>
            </Grid.Row>

            {edit && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Priority:{" "}
                    </span>
                    {dataOps[0].dr_priority}
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Created Date:{" "}
                    </span>
                    <Moment date={dataOps[0].created} format="MM/DD/YY LTS" />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Claims Beginning Date:{" "}
                    </span>{" "}
                    <Moment
                      date={dataOps[0].dr_claims_begining_date}
                      format="MM/DD/YY LTS"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Claims Ending Date:{" "}
                    </span>{" "}
                    <Moment
                      date={dataOps[0].dr_claims_end_date}
                      format="MM/DD/YY LTS"
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      From:{" "}
                    </span>
                    {dataOps[0].dr_ticket_author_name} |{" "}
                    {dataOps[0].dr_ticket_author_email}
                    {/* <span style={{ float: "right" }}>
                  
                </span> */}
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Organization:{" "}
                    </span>{" "}
                    {dataOps[0].dr_organization}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Due By:{" "}
                    </span>{" "}
                    <Moment
                      date={dataOps[0].dr_due_date}
                      format="MM/DD/YY LTS"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Requester Name:{" "}
                    </span>{" "}
                    {requesterName}
                  </Grid.Column>
                </Grid.Row>

                  {/* start */}
                <Grid.Row>
                
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Requester Email:{" "}
                    </span>{" "}
                    {requesterEmail}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Data Use:{" "}
                    </span>{" "}
                    {dataUse1} | {dataUse}
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      File Location:{" "}
                    </span>{" "}
                    {fileLocation} | {fileLocationLink}
                  </Grid.Column>
                </Grid.Row>
                {/* end */}
                <Grid.Row>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Issue:{" "}
                    </span>{" "}
                    {dataOps[0].dr_subject}
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="colorBrandColor"
                    >
                      Assigned To:{" "}
                    </span>
                    &nbsp;
                    <Dropdown
                      placeholder="Assign To"
                      options={assignedUser}
                      disabled={dataOps[0].dr_admin_status === "RESOLVED"}
                      onChange={(event, data) => {
                        handleAssignTo(event, data.value);
                      }}
                      value={assignedTo}
                      selection
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    {dataOps[0].is_new_access ? (
                      <Link
                        to={`/new-hire-IT-stage/${dataOps[0].newhire_access_id}`}
                      >
                        Continue to Ticket
                      </Link>
                    ) : (
                      <>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="colorBrandColor"
                        >
                          Description:{" "}
                        </span>
                        {dataOps[0].dr_description}
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {!edit && (
              <Grid.Row>
                <Form style={{ width: "100%" }}>
                <Form.Field>
          <Link to="/new-ticket/IT" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>IT Ticket</span>{" "}
          </Link>
          <Link to="/new-ticket/Data" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>Data Ticket</span>{" "}
          </Link>
          <input
            type="radio"
            name="ticketType"
            value="QualtityRequest"
            onChange={onChange}
            checked={ticketType === "QualtityRequest"}
            style={inputStyle}
          />
          <span style={spanStyle}>Quality Request</span>
        </Form.Field>
        <Form.Field>
          <label className="mandatory-star">Subject:</label>
          <Input
            type="text"
            maxLength="50"
            onChange={onChange}
            name="ticketSubject"
            placeholder="Please enter the subject of the ticket"
            value={ticketSubject}
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 50
          </span>
        </Form.Field>
        <Form.Field>
          <label className="mandatory-star">Description:</label>
          <TextArea
          maxLength="1000"
            name="ticketDescription"
            value={ticketDescription}
            onChange={onChange}
            placeholder="Please enter a brief description for the ticket"
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 1000
          </span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">Type:</label>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Question"
            checked={itHelpType === "Question"}
          />
           <span style={spanStyle}>Question</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="File Pull"
            checked={itHelpType === "File Pull"}
          />
          <span style={spanStyle}>File Pull</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="File Push"
            checked={itHelpType === "File Push"}
          />
          <span style={spanStyle}>File Push</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Data Request"
            checked={itHelpType === "Data Request"}
          />
          <span style={spanStyle}>Data Request</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Table Load"
            checked={itHelpType === "Table Load"}
          />
          <span style={spanStyle}>Table Load</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Task"
            checked={itHelpType === "Task"}
          />
          <span style={spanStyle}>Task</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Other"
            checked={itHelpType === "Other"}
          />
          <span style={spanStyle}>Other </span>
          {itHelpType === "Other" && <input
            type="text"
            name="itHelpOtherType"
            onChange={onChange}
          />}
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label>
          <div className="row" style={{display:"flex"}}>
          <div className="col-2 mandatory-star">
          Data Use:
          </div>
          <div className="col-2" style={{marginLeft:"30px",marginTop:"3px"}}>
          <input
            type="radio"
            name="dataUse1"
            onChange={onChange}
            style={inputStyle}
            value="Internal"
            checked={dataUse1 === "Internal"}
          />
          <span style={spanStyle}>Internal</span>
          </div>
          <div className="col-2" style={{marginLeft:"10px",marginTop:"3px"}}>
          <input
            type="radio"
            name="dataUse1"
            onChange={onChange}
            style={inputStyle}
            value="External"
            checked={dataUse1 === "External"}
          />
          <span style={spanStyle}>External</span>
          </div>
            </div>
          </label>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="HEDIS"
            checked={dataUse === "HEDIS"}
          />
           <span style={spanStyle}>HEDIS</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Stars"
            checked={dataUse === "Stars"}
          />
          <span style={spanStyle}>Stars</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Gaps"
            checked={dataUse === "Gaps"}
          />
          <span style={spanStyle}>Gaps</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Provider Report"
            checked={dataUse === "Provider Report"}
          />
          <span style={spanStyle}>Provider Report</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Scorecard"
            checked={dataUse === "Scorecard"}
          />
          <span style={spanStyle}>Scorecard</span>
          <input
            type="radio"
            name="dataUse"
            onChange={onChange}
            style={inputStyle}
            value="Risk"
            checked={dataUse === "Risk"}
          />
          <span style={spanStyle}>Risk</span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">File Location:</label>
          
          {/* <input
            type="text"
            name="fileLocation"
            onChange={onChange}
            value={fileLocation}
          /> */}
          <input
            type="radio"
            name="fileLocation"
            onChange={onChange}
            style={inputStyle}
            value="URL(Links To Files)"
            checked={fileLocation === "URL(Links To Files)"}
          />
          <span style={spanStyle}>URL(Links To Files)</span>
          <input
            type="radio"
            name="fileLocation"
            onChange={onChange}
            style={inputStyle}
            value="SharePoint Link"
            checked={fileLocation === "SharePoint Link"}
          />
           <span style={spanStyle}>SharePoint Link</span>
          <input
            type="radio"
            name="fileLocation"
            onChange={onChange}
            style={inputStyle}
            value="FTP Site(link,user & pw)"
            checked={fileLocation === "FTP Site(link,user & pw)"}
          />
          <span style={spanStyle}>FTP Site(link,user & pw)</span>
          <br/>
          {fileLocation && <input
            type="text"
            name="fileLocationLink"
            onChange={onChange}
            value={fileLocationLink}
          />}

        </Form.Field>
        <Form.Field
          style={
            ticketPriority === "LOW"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#f2e6ff"
                }
              : ticketPriority === "NORMAL"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#bcddf5"
                }
              : ticketPriority === "HIGH"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ffd9b3"
                }
              : ticketPriority === "URGENT"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ff9980"
                }
              : {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px"
                }
          }
        >
          <label className="mandatory-star">Priority:</label>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="LOW"
            checked={ticketPriority === "LOW"}
          />
          <span style={spanStyle}>Low</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="NORMAL"
            checked={ticketPriority === "NORMAL"}
          />
          <span style={spanStyle}>Normal</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="HIGH"
            checked={ticketPriority === "HIGH"}
          />
          <span style={spanStyle}>High</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="URGENT"
            checked={ticketPriority === "URGENT"}
          />
          <span style={spanStyle}>Urgent</span>
        </Form.Field>

        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px"
          }}
        >
          <label className="mandatory-star">Organization</label>
          <input
            type="radio"
            name="organization"
            value="Requesting"
            onChange={onChange}
            checked={organization === "Requesting"}
            style={inputStyle}
          />
          <span style={spanStyle}>Requesting</span>
          <input
            type="radio"
            name="organization"
            value="HTA"
            onChange={onChange}
            checked={organization === "HTA"}
            style={inputStyle}
          />
          <span style={spanStyle}>HTA</span>
          <input
            type="radio"
            name="organization"
            value="THN"
            onChange={onChange}
            checked={organization === "THN"}
            style={inputStyle}
          />
          <span style={spanStyle}>THN</span>
          <input
            value="Cone"
            name="organization"
            onChange={onChange}
            checked={organization === "Cone"}
            type="radio"
            style={inputStyle}
          />
          <span style={spanStyle}>Cone</span>
          <input
            type="radio"
            name="organization"
            onChange={onChange}
            style={inputStyle}
            value="Other"
            checked={organization === "Other"}
          />
          <span style={spanStyle}>Other </span>
          {organization === "Other" && <input
            type="text"
            name="organizationOther"
            onChange={onChange}
          />}
        </Form.Field>
        <Form.Group widths='equal'>
        <Form.Field>
          <label className="mandatory-star">Due Date</label>
          <DatePicker
            onChange={handleDueDate}
            selected={dueDate}
            name="dueDate"
          />
        </Form.Field>
        <Form.Field>
        <label style={{fontWeight: "bold", fontSize: "12px"}} className="mandatory-star">Requester Name</label>
        <Input
            onChange={(e)=> setRequesterFunction(e)}
            value={requesterName}
            placeholder="Requester Name..."
            style={{ maxWidth: "auto", width:"auto" }}
          />
          </Form.Field>
          <Form.Field>
        <label style={{fontWeight: "bold", fontSize: "12px"}} className="mandatory-star">Requester Email</label>
        <Input
            onChange={(e)=> setRequesterEmail(e.target.value)}
            value={requesterEmail}
            placeholder="Requester Email..."
            style={{ maxWidth: "auto", width:"auto" }}
          />
          </Form.Field>
          <Form.Field>
        <label style={{fontWeight: "bold", fontSize: "12px"}} className="mandatory-star">Requester Phone</label>
        <Input
            onChange={(e)=> setRequesterPhone(e.target.value)}
            value={requesterPhone}
            placeholder="Requester Phone..."
            style={{ maxWidth: "auto", width:"auto" }}
          />
          </Form.Field>
          </Form.Group>
        <Form.Group widths='equal'>
        <Form.Field>
          <label>End Date For Claims:</label>
          <DatePicker
            onChange={handleBeginingDate}
            selected={claimsBeginingDate}
            name="claimsBeginingDate"
          />
        </Form.Field>
        <Form.Field>
          <label>Beginning Date For Claims</label>
          <DatePicker
            onChange={handleEndDate}
            selected={claimsEndDate}
            name="claimsEndDate"
            value={claimsEndDate}
          />
        </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Row>
            )}

            {edit && <Grid.Row>
              <Grid.Column>
                {!attachments ? (
                  <Segment>
                    <Dimmer active>
                      <Loader inverted>Loading Attachments</Loader>
                    </Dimmer>
                    <Placeholder style={{ height: 50, width: 50 }}>
                      <Placeholder.Image />
                    </Placeholder>
                  </Segment>
                ) : attachments.length === 0 ? (
                  <div>No Attachments</div>
                ) : (
                  <div style={{ margin: "5px" }}>
                    <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                    <Grid stackable>
                      <Grid.Row>
                        {attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "120px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                Attachment {index+1}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>}
          </Grid>
          {/* // added malik  start// */}
          {dataOps[0].dr_admin_status === "RESOLVED" ? (
            <React.Fragment>
              {/* This is for comments to display resolved tickets */}

              <Grid.Row>
                <Grid.Column>
                  <span
                    style={{ fontWeight: "bold" }}
                    className="colorBrandColor"
                  >
                    Comments:
                  </span>
                  <span style={{ float: "right" }}></span>
                  <div style={{ textAlign: "left" }}>
                    {comments.dataComments
                      ? comments.dataComments.map((comment, index) => (
                          <div key={index}>
                            {comment.qr_commentor_name} :{" "}
                            {changeSpecialCharacters(comment.comment)}
                            <span style={{ float: "right" }}>
                              <Moment
                                date={comment.comment_time}
                                format="MM/DD/YY LTS"
                              />
                            </span>
                            <hr style={{ marginTop: "20px" }} />
                          </div>
                        ))
                      : null}
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <h4>
                    This ticket has been resolved by {dataOps[0].resolved_first}{" "}
                    {dataOps[0].resolved_last}, and cannot be opened.
                  </h4>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row style={{ padding: "0" }}>
                <Grid.Column>
                  <h5>Resolved Comment: </h5>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ padding: "0" }}>
                <Grid.Column>
                  <p>{dataOps[0].resolved_comment}</p>
                </Grid.Column>
              </Grid.Row> */}
            </React.Fragment>
          ) : (
            <>
            {edit && <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Form
                    onSubmit={(e, id, user) =>
                      handleCommentSubmit(
                        e,
                        dataOps[0].qr_id,
                        comments.dataComments.length > 0
                          ? comments.dataComments[
                              comments.dataComments.length - 1
                            ].user_id
                          : auth.user[0].id
                      )
                    }
                  >
                    <hr />
                    <div>
                      <h3
                        style={{ textAlign: "left" }}
                        className="colorBrandColor"
                      >
                        Comments:
                      </h3>
                      <div style={{ textAlign: "left" }}>
                        {comments.dataComments
                          ? comments.dataComments.map((comment, index) => (
                              <div key={index}>
                                {comment.qr_commentor_name} :
                                {changeSpecialCharacters(comment.comment)}
                                <span style={{ float: "right" }}>
                                  <Moment
                                    date={comment.comment_time}
                                    format="MM/DD/YY LTS"
                                  />
                                </span>
                                <hr style={{ marginTop: "20px" }} />
                              </div>
                            ))
                          : null}
                      </div>
                    </div>

                    <Form.Field>
                      <Input
                        type="text"
                        name="itComment"
                        value={comment}
                        id="Comment"
                        onChange={handleCommentChange}
                      />
                    </Form.Field>
                    <Form.Field style={{ textAlign: "right" }}>
                      <Button
                        type="submit"
                        basic
                        color="green"
                        disabled={comment === ""}
                      >
                        Add Comment
                      </Button>
                    </Form.Field>
                    <hr />
                    <Grid.Row>
                      <Grid.Column>
                        {!admin_attachments ? (
                          <Segment>
                            <Dimmer active>
                              <Loader inverted>Loading Attachments</Loader>
                            </Dimmer>
                            <Placeholder style={{ height: 50, width: 50 }}>
                              <Placeholder.Image />
                            </Placeholder>
                          </Segment>
                        ) : admin_attachments.length === 0 ? (
                          <div>No Attachments</div>
                        ) : (
                          <div style={{ margin: "5px" }}>
                            <h4 className="colorBrandColor">
                              <span>View Attachments</span>
                            </h4>
                            <Grid stackable>
                      <Grid.Row>
                        {admin_attachments.map((attachment, index) => (
                          <Grid.Column key={index} style={{ width: "120px" }}>
                            {attachment && attachment.path && (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => openPopup(attachment)}
                              >
                                Attachment {index+1}
                              </span>
                            )}
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                      </Grid>
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <hr />
                    <Form.Field>
                      <label
                        style={{ textAlign: "left" }}
                        className="colorBrandColor"
                      >
                        Attachment:{" "}
                      </label>

                      <FilePond
                        maxFiles={1}
                        files={ticketAttachment_one}
                        allowMultiple={false}
                        allowImagePreview="false"
                        onupdatefiles={(fileItems) =>
                          handleFilepondUpdate(fileItems)
                        }
                      />
                      <Button
                        style={{ float: "right", cursor: "pointer" }}
                        type="button"
                        basic
                        color="orange"
                        onClick={(id) =>
                          handleItAttachmentSubmit(dataOps[0].qr_id)
                        }
                      >
                        Attachment Upload
                      </Button>
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form
                    onSubmit={(e, ticket_id) =>
                      handleFinalSubmit(e, dataOps[0].qr_id)
                    }
                  >
                    <h4
                      style={{ textAlign: "left" }}
                      className="colorBrandColor"
                    >
                      Ticket Status: {dataOps[0].dr_admin_status}
                    </h4>
                    <Form.Field style={{ textAlign: "left" }}>
                      <Dropdown
                        options={ticketStatus}
                        value={currentStatus}
                        placeholder="Select"
                        onChange={(event, data) => {
                          setcurrentStatus(data.value);
                        }}
                        inline
                      />
                    </Form.Field>
                    {/* <Form.Field>
                      <TextArea
                        maxLength="400"
                        value={explanation}
                        name="explanation"
                        placeholder="What was the resolution.?"
                        disabled={currentStatus !== "RESOLVED"}
                        onChange={handleRootExplination}
                      />
                      <span
                        style={{
                          padding: "5px",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        Please enter comment(s) upto 400 word.
                      </span>
                    </Form.Field> */}
                    <Form.Field style={{ textAlign: "right" }}>
                      <Button
                        basic
                        color="green"
                        disabled={
                          dataOps[0].dr_admin_status === "RESOLVED" ||
                          !currentStatus
                        }
                      >
                        Submit
                      </Button>
                      {/* ...Added some logic above for validation...08/17/2020 */}
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>}
            <Grid.Row>
              <Grid.Column
                style={{
                  fontSize: "25px",
                  textAlign: "right",
                  marginRight: "66px",
                }}
              >
                {save && (
                  <span onClick={(e) => handleSave(e)}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ cursor: "pointer", marginTop: "10px", marginRight:"10px" }}
                      color="orange"
                    />
                    <span style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}>Click Here to Save</span>
                  </span>
                )}
              </Grid.Column>
            </Grid.Row>
            </>
          )}
        </div>
      ))
    : // {/* // added malik end */}
      (dataopsindividual = (
        <Segment>
          <Dimmer active>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Placeholder style={{ height: 50, width: 50 }}>
            <Placeholder.Image />
          </Placeholder>
        </Segment>
      ));
  return (
    <Container style={{ paddingTop: "10px" }}>{dataopsindividual}</Container>
  );
}
const mapStateToProps = (state) => ({
  dataOps: state.dataOps.singleRequests,
  comments: state.comments,
  auth: state.auth,
  attachments: state.dataOps.attachments,
  assignedUser: state.assignedUser ? state.assignedUser.assignedUser : [],
  users: state.inventory.users,
  allUsers: state.inventory.allUsers,
  admin_attachments: state.dataOps.admin_attachments,
});
export default connect(mapStateToProps, {
  getPendingDevRequestsQR,
  getDataQRComments,
  addQRDataComment,
  changeQRAssignedTo,
  chageQRTicketStatus,
  getAllPendingQR,
  getQRTicketAttachments,
  addAdminQRAttachment,
  getIndividualQRInfo,
  getAllActiveQRUser,
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers,
  getQRTicketAdminAttachments,
})(IndivitualDataOp);