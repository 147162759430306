import axios from "axios";
import { setAlert } from "./alertActions";
// import setAuthToken from "../../utils/setAuthToken";
import {
  NEW_TICKET_SUCCESS,
  NEW_TICKET_FAILURE,
  GET_ADMIN_TICKETS,
  ADMIN_TICKET_FALIURE,
  CLEAR_ADMIN_TICKET_SEARCH,
  GET_ALL_USER_TICKETS,
  MODAL_OPEN,
  SEND_BUG,
  ADD_SUGGESTION,
  GET_BUGS,
  FORM_NOT_OK,
  FORM_OK,
  ADMIN_UPDATE,
  GET_TICKET_ATTACHMENTS,
  CLEAR_TICKET_ATTACHMENTS,
  GET_ALL_DASHBOARD_TICKETS,
  GET_DATA_TICKET_ATTACHMENTS,
  GET_ALL_IT_USERS,
  CHANGE_TICKETS_ASSIGNED_TO,
  GET_ADMIN_TICKETS_SINGLE,
  GET_DATA_TICKET_ADMIN_ATTACHMENTS,
  GET_IT_TICKET_ADMIN_ATTACHMENTS
} from "./types";

//    New IT ticket
export const newTicketITTicket = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/new-ticket/IT", body);
    dispatch({
      type: NEW_TICKET_SUCCESS,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
    dispatch({
      type: FORM_OK,
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK,
      });
    }, 1000);
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: NEW_TICKET_FAILURE,
    });
  }
};

//    New DATA ticket
export const newDataTicket = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/new-ticket/Data", body);
    dispatch({
      type: NEW_TICKET_SUCCESS,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
    dispatch({
      type: FORM_OK,
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK,
      });
    }, 1000);
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      // errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      const res = {
        data: {
          message:
            "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
        },
      };
      dispatch({
        type: MODAL_OPEN,
        payload: res.data,
      });
    }
    dispatch({
      type: NEW_TICKET_FAILURE,
    });
  }
};

// Get the admin tickets with paramaters
export const getAdminTickets = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/admin-it-tickets", body);
    dispatch({
      type: GET_ADMIN_TICKETS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ADMIN_TICKET_FALIURE,
    });
  }
};

export const getIndividualTicket = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/single-it-ticket", body);
    dispatch({
      type: GET_ADMIN_TICKETS_SINGLE,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
// check the ticket status for new
export const checkNewStatus = (body) => async (dispatch) => {
  try {
    await axios.post("/api/tickets/check-ticket-status", body);
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

// Admin ticket form fucntion
export const adminStatusUpdate = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/update-it-ticket-status", body);
    dispatch({
      type: ADMIN_UPDATE,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
    // dispatch({
    //   type: FORM_OK,
    // });
    // setTimeout(() => {
    //   dispatch({
    //     type: FORM_NOT_OK,
    //   });
    // }, 1000);
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

// get all my user tickets..
export const getUserTickets = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/all-my-tickets", body);
    dispatch({
      type: GET_ALL_USER_TICKETS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const addBug = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/enhancements/bugs", body);
    dispatch({
      type: SEND_BUG,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const addSuggestion = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/enhancements/suggestions", body);
    dispatch({
      type: ADD_SUGGESTION,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const setBugResolved = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/enhancements/all-bugs/set-bug-resolved",
      body
    );
    dispatch({
      type: GET_BUGS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getAllBugs = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/enhancements/all-bugs", body);
    dispatch({
      type: GET_BUGS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const getTicketAttachments = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/get-ticket-attachments", body);
    dispatch({
      type: GET_TICKET_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getDataTicketAttachments = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/tickets/get-dataticket-attachments",
      body
    );
    dispatch({
      type: GET_DATA_TICKET_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};


export const getDataTicketAdminAttachments = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/tickets/get-dataticket-admin-attachments",
      body
    );
    dispatch({
      type: GET_DATA_TICKET_ADMIN_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getSingleDashboardTicket = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/tickets/get-single-dashboard-ticket",
      body
    );
    dispatch({
      type: GET_ALL_DASHBOARD_TICKETS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const addAdminItAttachment = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/tickets/admin-it-attachment-upload",
      body
    );
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const removeTicketFromDashboard = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/dashboard/remove-from-user-dashboard",
      body
    );
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    }); 
  } catch (error) {
    console.log(error);
  }
};
export const clearAttachments = () => async (dispatch) => {
  dispatch({ type: CLEAR_TICKET_ATTACHMENTS });
};
export const clearAdminTicketSearch = () => (dispatch) => {
  dispatch({ type: CLEAR_ADMIN_TICKET_SEARCH });
};

// Get All Active IT Users
export const getAllActiveItUser = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/tickets/get-all-it-users");
    dispatch({
      type: GET_ALL_IT_USERS,
      payload: res.data,
    });
  } catch (err) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

// assigned ticket // added malik start //
export const changeTicketsAssignedTo = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/assign-it-ticket", body);
    dispatch({
      type: CHANGE_TICKETS_ASSIGNED_TO,
      payload: res.data,
    });
    // dispatch({
    //   type: MODAL_OPEN,
    //   payload: {
    //     message:"The IT Ticket is Reassigned"
    //   },
    // });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

// added malik start //

export const getITTicketAdminAttachments = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/tickets/get-itticket-admin-attachments",
      body
    );
    dispatch({
      type: GET_IT_TICKET_ADMIN_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const addPostAttachment = (body) => async(dispatch) =>{
  try {
    const res = await axios.post("/api/tickets/addPostAttachments", body);

  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: NEW_TICKET_FAILURE,
    });
  }
}

export const newQRTicket = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/new-ticket/QR", body);
    dispatch({
      type: NEW_TICKET_SUCCESS,
      payload: res.data,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
    dispatch({
      type: FORM_OK,
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK,
      });
    }, 1000);
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      // errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      const res = {
        data: {
          message:
            "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
        },
      };
      dispatch({
        type: MODAL_OPEN,
        payload: res.data,
      });
    }
    dispatch({
      type: NEW_TICKET_FAILURE,
    });
  }
};

export const getQRTicketAttachments = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/qr-tickets/get-dataticket-attachments",
      body
    );
    dispatch({
      type: GET_DATA_TICKET_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};


export const getQRTicketAdminAttachments = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/qr-tickets/get-dataticket-admin-attachments",
      body
    );
    dispatch({
      type: GET_DATA_TICKET_ADMIN_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
}; 

export const ReopenAndMakeTicketClone = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/tickets/reopen-and-make-ticket-clone", body);
    dispatch({
      type: NEW_TICKET_SUCCESS,
    });
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
    dispatch({
      type: FORM_OK,
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK,
      });
    }, 1000);
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: NEW_TICKET_FAILURE,
    });
  }
};