import { GET_ALL_APPLICATIONS } from '../Actions/types';

const initialState = {
    loading:true,
    applications: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
  case GET_ALL_APPLICATIONS:
      return {
          ...state,
          loading:false,
          applications:payload
      }
    default:
      return state;
  }
}