import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { throttle } from "lodash";
import {
  Container,
  Input,
  Segment,
  Grid
} from "semantic-ui-react";
import {
  lookupUserProfile,
  clearUserProfileSearch,
  getTheLookedUpUser
} from "../../../Redux/Actions/profileActions";

function AutoSuggest({
  lookupUserProfile,
  userProfile,
  clearUserProfileSearch,
  profileSuggestions,
  getTheLookedUpUser
}) {
  const [userQuery, setUserQuery] = useState("");

  const delayedQuery = useCallback(
    throttle(q => sendQuery(q), 500),
    []
  );

  const onChange = e => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  const sendQuery = query => {
    if (query.length > 0) {
      lookupUserProfile(query);
    } else {
      clearUserProfileSearch();
    }
  };
  const getTheIDOfUser = id => {
    const fd = new FormData();
    fd.append("id_to_get", id);
    getTheLookedUpUser(fd);
  };
  return (
    <Container>
      <h1>Autosuggest test</h1>
      <Grid>
        <Grid.Row>
          <Grid.Column width="6">
            <Input
              style={{ width: "100%" }}
              icon="search"
              placeholder="Search..."
              onChange={onChange}
              value={userQuery}
            />
            {profileSuggestions
              ? profileSuggestions.map(user => (
                  <Segment
                    key={user.id}
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                    onClick={id => {
                      getTheIDOfUser(user.id);
                    }}
                  >
                    {user.first_name + " " + user.last_name}
                  </Segment>
                ))
              : null}
            {profileSuggestions && profileSuggestions.length === 0 ? (
              <Segment>No Users Found</Segment>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
const mapStateToProps = state => ({
  isLoading: state.profile.isLoading,
  userProfile: state.profile.user_profile,
  profileSuggestions: state.profile.suggestions
});

export default connect(mapStateToProps, {
  lookupUserProfile,
  clearUserProfileSearch,
  getTheLookedUpUser
})(AutoSuggest);
