import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Loader } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  saveMicrosoftToDatabase,
  loadMicrosoftGraphUserData,
  loadMicrosoftGraphManagerData
} from "../../../../Redux/Actions/userActions";
import clsx from "clsx";
// import LoaderPlaceholder from "../../../Common/LoaderPlaceholder";
const useStyles = makeStyles(theme => ({
  buttonEdit: {
    margin: theme.spacing(1),
    minWidth: "200px",
    background: "#9B3E9A",
    color: "white"
  },
  buttonConfirm: {
    margin: theme.spacing(1),
    minWidth: "200px",
    background: " #1B75BB",
    color: "white"
  },
  input: {
    display: "none"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  paperPadding: {
    padding: theme.spacing(3, 2),
    position: "relative"
  },
  payComConfirm: {
    background: "rgba(0, 98, 66,1)",
    color: "white",
    "&:hover": {
      background: "rgba(0, 98, 66,0.6)"
    }
  }
}));
function ConfirmProfile({
  props,
  saveMicrosoftToDatabase,
  loadMicrosoftGraphUserData,
  loadMicrosoftGraphManagerData
}) {
  // const [loader, setLoader] = useState(false);
  const [department, setDepartment] = useState("");
  // const { dept } = department;
  useEffect(() => {
    loadMicrosoftGraphUserData();
    loadMicrosoftGraphManagerData();
  }, [loadMicrosoftGraphManagerData, loadMicrosoftGraphUserData]);
  const [middleInitial, setMiddleInitial] = useState("");
  const classes = useStyles();
  const submitHandler = event => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("department", props.my_microsoftGraphData.department);
    fd.append("first_name", props.my_microsoftGraphData.givenName);
    fd.append("middle_initial", middleInitial);
    fd.append("last_name", props.my_microsoftGraphData.surname);
    fd.append("email", props.my_microsoftGraphData.mail);
    fd.append("manager_name", props.my_manager_microsoftGraphData.displayName);
    fd.append("manager_email", props.my_manager_microsoftGraphData.mail);
    fd.append("dept_specialization", props.my_microsoftGraphData.jobTitle);
    saveMicrosoftToDatabase(fd).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });   
  };

  return (
    <Container maxWidth="lg" style={{ paddingTop: "30px" }}>
      <CssBaseline />
      {!props.user ? (
        <Grid columns="equal" stackable>
          <Grid.Row stretched>
            <Grid.Column>
              <Paper className={classes.paperPadding}>
                <Typography variant="h5" gutterBottom>
                  Please confirm the details below
                </Typography>
                <Typography variant="h6">Full Name:</Typography>

                {props.my_microsoftGraphData ? (
                  <Typography variant="body1" gutterBottom>
                    {props.my_microsoftGraphData.givenName +
                      " " +
                      props.my_microsoftGraphData.surname}
                  </Typography>
                ) : (
                  <Loader active inline />
                )}

                <Typography variant="h6">Job Title:</Typography>

                {props.my_microsoftGraphData ? (
                  <Typography variant="body1" gutterBottom>
                    {props.my_microsoftGraphData.jobTitle}
                  </Typography>
                ) : (
                  <Loader active inline />
                )}

                <Typography variant="h6">Department:</Typography>

                {props.my_microsoftGraphData ? (
                  <Typography variant="body1">
                    {props.my_microsoftGraphData.department}
                  </Typography>
                ) : (
                  <Loader active inline />
                )}

                <Typography variant="h6">Email:</Typography>

                {props.my_microsoftGraphData ? (
                  <Typography variant="body1">
                    {props.my_microsoftGraphData.mail}
                  </Typography>
                ) : (
                  <Loader active inline />
                )}

                <Typography variant="h6">Manager:</Typography>

                {props.my_manager_microsoftGraphData ? (
                  <Typography variant="body1">
                    {props.my_manager_microsoftGraphData.displayName}
                  </Typography>
                ) : (
                  <Loader active inline />
                )}

                <Typography variant="h6">Manager Email:</Typography>

                {props.my_manager_microsoftGraphData ? (
                  <Typography variant="body1">
                    {props.my_manager_microsoftGraphData.mail}
                  </Typography>
                ) : (
                  <Loader active inline />
                )}

              </Paper>
            </Grid.Column>
            <Grid.Column>
              <Paper className={classes.paperPadding}>
                <form onSubmit={submitHandler}>
                  <Typography variant="h5" gutterBottom>
                    Please enter the following information to continue
                  </Typography>
                  {/* <Typography variant="h6">
                    What department are you currently in?
                  </Typography>
                  <TextField
                    id=""
                    label="Department"
                    className={clsx(classes.textField, classes.dense)}
                    margin="dense"
                    value={department}
                    onChange={event => {
                      setDepartment(event.target.value);
                    }}
                  /> */}

                  <Typography variant="h6">
                    If you have a middle initial, please enter it below.
                  </Typography>
                  <TextField
                    id=""
                    label="Middle Initial"
                    className={clsx(classes.textField, classes.dense)}
                    margin="dense"
                    value={middleInitial}
                    inputProps={{
                      maxLength: 1
                    }}
                    onChange={event => {
                      setMiddleInitial(event.target.value);
                    }}
                  />

                  <div style={{ float: "right", marginTop: "50px" }}>
                    <Button
                      variant="contained"
                      className={classes.buttonConfirm}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  </div>
                </form>
              </Paper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Redirect to="/" />
      )}
    </Container>
  );
}

export default connect(null, {
  saveMicrosoftToDatabase,
  loadMicrosoftGraphUserData,
  loadMicrosoftGraphManagerData
})(ConfirmProfile);