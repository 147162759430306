import { GET_ALL_APPLICATIONS, MODAL_OPEN } from "./types";

import axios from "axios";
export const getallApplications = () => async dispatch => {
    try {
        const res = await axios.get('/api/applications/get-all-applications');
        dispatch({
            type:GET_ALL_APPLICATIONS,
            payload: res.data
        })
    } catch (error) {
        const res = {data:{message:"There was an error in your request. Please refresh and try again. This issue has been sent to the I.T department Thank you"}}   
        dispatch({
          type: MODAL_OPEN,
          payload: res.data
        });
    }
}