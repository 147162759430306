import { GET_ALL_TICKETS_FOR_REPORTING, REPORTING_DATA,INVENTORY_REPORTING_DATA } from "../Actions/types";

const initialState = {
  tickets: null,
  reportingData: null
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
      case GET_ALL_TICKETS_FOR_REPORTING:
          return{
              ...state,
              tickets: payload
          }
          case REPORTING_DATA:
          return{
              ...state,
              reportingData: payload
          }
          case INVENTORY_REPORTING_DATA:
          return{
              ...state,
              reportingInvData: payload
          }
    default:
      return state;
  }
}
