import axios from "axios";
// import { setAlert } from "./alertActions";
// import setAuthToken from "../../utils/setAuthToken";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGOUT,
  MODAL_OPEN,
  MICROSOFT_LOADED,
  GET_MICROSOFT_GRAPH_DATA,
  LOAD_ME_MICROSOFT,
  LOAD_MY_MANAGER_MICROSOFT,
  
} from "./types";
import { adalApiFetch } from "../../adalConfig";
// import { contextsSymbol } from "express-validator/src/base";
export const microSoftSuccess = () => async dispatch => {
  try {
    loadUser();
    if (!localStorage.getItem("adal.idtoken")) {
      dispatch({
        type: LOGIN_FAIL
      });
    } else {
      dispatch({
        type: MICROSOFT_LOADED
      });
    }
  } catch (error) {
     const res = {data:{message:"There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};
export const loadMicrosoftGraphUserData = () => async dispatch => {
  adalApiFetch(fetch, "https://graph.microsoft.com/v1.0/me/?$select=userPrincipalName,department,businessPhones,displayName, givenName, mail, jobTitle, mobilePhone, officeLocation, preferredLanguage, surname, id", {})
    .then(response => {
      response.json().then(responseJson => {
        dispatch({
          type: LOAD_ME_MICROSOFT,
          payload: responseJson
        });
      });
    })
    .catch(error => {
      console.error(error);
    });
};
export const loadMicrosoftGraphManagerData = () => async dispatch => { 
  adalApiFetch(fetch, "https://graph.microsoft.com/v1.0/me/manager", {})
    .then(response => {
      response.json().then(responseJson => {
        dispatch({
          type: LOAD_MY_MANAGER_MICROSOFT,
          payload: responseJson
        });
      });
    })
    .catch(error => {
      console.error(error);
    });
};

// export const getUserPicFromMicrosoft = () => async dispatch => {

//   adalApiFetch(fetch, "https://graph.microsoft.com/v1.0/me/photo/$value", {})
//     .then(response => {
//       response.json().then(responseJson => {
//         console.log(responseJson)
//         // dispatch({
//         //   type: GET_MY_PHOTO,
//         //   payload: responseJson
//         // });
//       });
//     })
//     .catch(error => {
//       console.error(error);
//     });
// }
export const saveMicrosoftToDatabase = data => async dispatch => {
  try {
    const res = await axios.post("/api/user/db/enter-user", data);
    if(res.data && res.data.isRefresh){
      window.location.reload()
    }else if(res.data && res.data.message && !res.data.isRefresh){
      dispatch({
        type: MODAL_OPEN,
        payload: res.data
      });
    }
  } catch (error) {
     const res = {data:{message:"There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you"}}   
    dispatch({
      type: MODAL_OPEN,
      payload: res.data
    });
  }
};

// // Load User
export const loadUser = body => async dispatch => {
  try {
    const res = await axios.post("/api/user/load-user", body);
    localStorage.setItem("userData", JSON.stringify(res.data))
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};
// // Load User
export const loadMicrosoftGraph = body => async dispatch => {
  try {
    const res = await axios.get("/api/user/microsoft-graph-data");
    dispatch({
      type: GET_MICROSOFT_GRAPH_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Logout / Clear Profile
export const logout = () => dispatch => {
  localStorage.clear();
  dispatch({ type: LOGOUT });
};
