import React from "react";
import { Container } from "semantic-ui-react";
import { Tab,Input } from "semantic-ui-react";
import AddInventoryPane from "./AddInventoryPane";
import AddCurrentInventory from "./AddCurrentInventory";
import AddSystemProducts from "./AddSystemProducts";

import AllInventory from "./AllInventory";
const panes = [
  {
    menuItem: "Add New Inventory",
    render: () => <Tab.Pane><AddInventoryPane /></Tab.Pane>
  },
  // {
  //   menuItem: "Add Current Inventory",
  //   render: () => <Tab.Pane><AddCurrentInventory /></Tab.Pane>
  // },
  {
    menuItem: "All Inventory",
    render: () => <Tab.Pane><AllInventory /></Tab.Pane>
  },
  {
    menuItem: "Add System Products",
    render: () => (
      <Tab.Pane>
        <AddSystemProducts />
      </Tab.Pane>
    ),
  },
];
function InventoryMain() {
  return (
    <Container style={{paddingTop:'30px'}}>
      <Tab panes={panes} />
    </Container>
  );
}

export default InventoryMain;
