import React, { useState, useCallback, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import {
  Container,
  Segment,
  Input,
  Form,
  Grid,
  TextArea,
  Checkbox,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import {
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers,
} from "../../../Redux/Actions/inventoryActions";

import {
  getEmployeeDetails,
  updateEmployeeRecord,
} from "../../../Redux/Actions/changeEmployee";
import {
  newHireHRFormStart,
  getDepartmentDropdown,
} from "../../../Redux/Actions/accessRequestActions";
import { throttle } from "lodash";
import { lookupSoftwareSuggestions } from "../../../Redux/Actions/inventoryActions";
const Index = ({
  employeeDetails,
  departmentList,
  inventory,
  lookupSoftwareSuggestions,
  auth,
  allUsers,
  getDepartmentDropdown,
  users,
  allDBUsers,
  lookupUsersForInventory,
  clearUsersForInventory,
  getEmployeeDetails,
  updateEmployeeRecord,
}) => {
  const [changeEmployeeData, setchangeEmployeeData] = useState({
    new_last_name: "",
    employee_last_name: "",
    employee_first_name: "",
    manager_email: "",
    department: "",
    job_title: "",
    new_first_name: "",
    effective_date: "",
    cube_off_number: "",
    supervisor_name: "",
    writingField: null,
    isNameChange: false,
    manager_name: "",
    business_cards: false,
    isManager: null,
    additional_storage: false,
    special_request_info: "",
    requested_date_to_move: "",
    box_needed,
  });

  let deptDropwDown = [
    {
      key: "",
      value: "",
      text: "SELECT",
    },
  ];
  let deptArray = departmentList;

  if (deptArray && Array.isArray(deptArray) && deptArray.length > 0) {
    deptArray.forEach((x) => {
      deptDropwDown.push({
        key: x,
        value: x,
        text: x,
      });
    });
  }
  const { suggested_software_products } = inventory;
  const [softwareInventory, setSoftwareInventory] = useState(
    suggested_software_products || []
  );

  useEffect(() => {
    getDepartmentDropdown();
    setSoftwareInventory(suggested_software_products);
    lookupSoftwareSuggestions();
    allDBUsers();
  }, []);
  console.log("employeeDetails: ", employeeDetails);
  const { employee, loading } = employeeDetails;

  const [userLookup, setUSerLookup] = useState("");
  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );

  const preFillForm = useCallback(() => {
    if (employee) {
      function padTo2Digits(num) {
        if (num) return num.toString().padStart(2, "0");
      }

      function formatDate(date) {
        if (date != "Invalid Date") {
          return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
          ].join("/");
        } else {
          return null;
        }
      }

      const { user, accessRequest, changedHistory } = employee;

      setchangeEmployeeData({
        user_id: user[0]?.id,
        new_last_name: user[0]?.last_name,
        employee_last_name: user[0]?.last_name,
        employee_first_name: user[0]?.first_name,
        manager_email: user[0]?.manager_email,
        department: user[0]?.department,
        job_title: user[0]?.dept_specialization,
        new_first_name: user[0]?.first_name,
        cube_off_number: user[0]?.cubicle_office_num,
        isNameChange: false,
        business_cards: changedHistory[0]?.busniess_card === "Y" ? true : false,
        manager_name: employee?.accessRequest[0]?.manager_name,
        supervisor_name: employee?.accessRequest[0]?.manager_name,
        box_needed: changedHistory[0]?.box_needed === "Y" ? true : false,
        requestor_id: auth?.user[0]?.id,
        additional_storage:
          changedHistory[0]?.additional_storage === "Y" ? true : false,
        requestor_name:
          auth?.user[0]?.first_name + " " + auth?.user[0]?.last_name,
        requestor_email: auth?.user[0]?.email,
      });
    }
  }, [employee]);

  useEffect(() => {
    preFillForm();
  }, [employee]);

  const {
    isNameChange,
    new_first_name,
    employee_last_name,
    employee_first_name,
    new_last_name,
    effective_date,
    manager_name,
    supervisor_name,
    job_title,
    business_cards,
    department,
    cube_off_number,
    isManager,
    writingField,
    additional_storage,
    requested_date_to_move,
    box_needed,
  } = changeEmployeeData;

  const onChange = (e) => {
    setchangeEmployeeData({
      ...changeEmployeeData,
      [e.target.name]: e.target.value,
    });
  };

  const sendQuery = (query) => {
    if (query.length > 0) {
      lookupUsersForInventory(query);
    } else {
      lookupUsersForInventory();
    }
  };
  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });

  const {
    cursor,
    // result
  } = highlightSelection;

  const handleAutoProductChange = (e) => {
    // if (e.target.name === "supervisor_name") {
    // } else {
    // }
    setUSerLookup(e.target.value);
    setchangeEmployeeData({
      ...changeEmployeeData,
      [e.target.name]: e.target.value,
      writingField: e.target.name,
    });
    delayedQuery(e.target.value);
  };
  const onInputChange = (e) => {
    setchangeEmployeeData({
      ...changeEmployeeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        setchangeEmployeeData({
          ...changeEmployeeData,
          [e.target.name]: e.target.value,
          writingField: e.target.name,
        });
        clearUsersForInventory();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0,
        });
      }
    }
  };

  function setRequesterFunction(key, name, id) {
    setchangeEmployeeData({
      ...changeEmployeeData,
      [key]: name,
    });

    if (key === "employee_first_name" && allUsers && allUsers.length > 0) {
      let manEmail = allUsers.find((x) => x["id"] === id);
      getEmployeeDetails(id, auth.user[0].email);
      if (manEmail) {
        setchangeEmployeeData({
          ...changeEmployeeData,
          employee_last_name: manEmail["last_name"],
          employee_first_name: manEmail["first_name"],
          new_first_name: manEmail["first_name"],
          new_last_name: manEmail["last_name"],
        });
      }
    }
    if (key === "supervisor_name" && allUsers && allUsers.length > 0) {
      let manEmail = allUsers.find((x) => x["id"] === id);

      if (manEmail) {
        setchangeEmployeeData({
          ...changeEmployeeData,
          manager_email: manEmail["email"],
          supervisor_name: name,
          manager_name: name,
        });
      }
    }
    clearUsersForInventory();
  }

  const onCheckboxChange = (e, props) => {
    setchangeEmployeeData({
      ...changeEmployeeData,
      [props.name]: props.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let { user, accessRequest } = employee;

    let newObj = {
      ...changeEmployeeData,
      requested_date_to_move: requested_date_to_move
        ? moment(requested_date_to_move).format("YYYY-MM-DD")
        : "",
      effective_date: effective_date
        ? moment(effective_date).format("YYYY-MM-DD")
        : "",
      user_id: user[0]?.id,
      manager_id: user[0]?.id,
      dashboard_user: auth?.user[0]?.id,
      it_ticket_id: accessRequest[0]?.IT_ticket_id,
      ac_user_id: accessRequest[0]?.ac_user_id,
    };

    updateEmployeeRecord(newObj).then(() => {});
    //newHireHRFormStart(fd).then(() => {});
  };

  return (
    <Container>
      <h2 style={{ paddingTop: "30px" }}>
        Employee Change Form
        <span id="accessTipsAndTricks">To be completed by HR</span>
      </h2>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Grid columns="equal" stackable>
            <Grid.Row>
              <Grid.Column>
                <h3>Employee Information</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="First Name"
                    onKeyDown={handleKeyDown}
                    onChange={handleAutoProductChange}
                    value={employee_first_name}
                    name="employee_first_name"
                    required
                  />
                  {users && writingField === "employee_first_name" ? (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        width: "91%",
                        border: "1px solid #bfbfbf",
                        borderRadius: "3px",
                        zIndex: "99999",
                      }}
                    >
                      {users.length === 0 ? (
                        <p style={{ padding: "5px", margin: "0px" }}>
                          No Results Found
                        </p>
                      ) : (
                        users.map((user, i) => (
                          <p
                            style={{ padding: "5px", margin: "0px" }}
                            key={user.id}
                            onClick={() => {
                              setRequesterFunction(
                                "employee_first_name",
                                user.first_name + " " + user.last_name,
                                user.id
                              );
                            }}
                            data-key={user.id}
                            data-name={user.first_name + " " + user.last_name}
                            className={
                              cursor === i ? "active-software-selection" : null
                            }
                          >
                            {user.first_name}
                          </p>
                        ))
                      )}
                    </div>
                  ) : null}
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    name="employee_last_name"
                    value={employee_last_name}
                    disabled
                    required
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    label="Want to Change Name?"
                    name="isNameChange"
                    checked={isNameChange}
                    onChange={onCheckboxChange}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {isNameChange && (
              <Grid.Row stretched>
                <Grid.Column>
                  <Segment className="access-focus">
                    <Input
                      type="text"
                      placeholder="First Name"
                      onChange={onInputChange}
                      value={new_first_name}
                      name="new_first_name"
                      //onChange={onChange}
                      required
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment className="access-focus">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      onChange={onInputChange}
                      name="new_last_name"
                      value={new_last_name}
                      required
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column>
                <h3>Status Change</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="New Title"
                    value={job_title}
                    onChange={onInputChange}
                    name="job_title"
                    required
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="access-focus customDateStyle">
                  <DatePicker
                    onChange={(event, data) => {
                      onInputChange({
                        target: {
                          name: "effective_date",
                          value: event,
                        },
                      });
                    }}
                    name="effective_date"
                    value={effective_date}
                    selected={effective_date}
                    placeholderText="Effective Date"
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column>
                <Segment className="access-focus">
                  <Dropdown
                    style={{ width: "100%" }}
                    placeholder="Department"
                    options={deptDropwDown}
                    // onChange={onInputChange}
                    onChange={(event, data) => {
                      onInputChange({
                        target: {
                          name: "department",
                          value: data.value,
                        },
                      });
                    }}
                    name="department"
                    value={department}
                    required
                    selection
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="access-focus">
                  <Input
                    type="text"
                    placeholder="Supervisor Name"
                    // onChange={onInputChange}
                    onKeyDown={handleKeyDown}
                    onChange={handleAutoProductChange}
                    name="supervisor_name"
                    value={supervisor_name}
                    required
                  />
                  {users && writingField === "supervisor_name" ? (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        width: "91%",
                        border: "1px solid #bfbfbf",
                        borderRadius: "3px",
                        zIndex: "99999",
                      }}
                    >
                      {users.length === 0 ? (
                        <p style={{ padding: "5px", margin: "0px" }}>
                          No Results Found
                        </p>
                      ) : (
                        users.map((user, i) => (
                          <p
                            style={{ padding: "5px", margin: "0px" }}
                            key={user.id}
                            onClick={() => {
                              setRequesterFunction(
                                "supervisor_name",
                                user.first_name + " " + user.last_name,
                                user.id
                              );
                            }}
                            data-key={user.id}
                            data-name={user.first_name + " " + user.last_name}
                            className={
                              cursor === i ? "active-software-selection" : null
                            }
                          >
                            {user.first_name} <br /> {user.last_name}
                          </p>
                        ))
                      )}
                    </div>
                  ) : null}
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <h3>Facility Change</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment className="access-focus">
                  <Checkbox
                    label="New Business Cards"
                    name="business_cards"
                    checked={business_cards}
                    onChange={onCheckboxChange}
                  />
                </Segment>
                <Segment className="access-focus">
                  <Checkbox
                    label="Additional/New Storage"
                    name="additional_storage"
                    checked={additional_storage}
                    onChange={onCheckboxChange}
                    className="checkbox-focus-class"
                  />
                </Segment>
                <Segment className="access-focus">
                  <Checkbox
                    label="Box Needed"
                    name="box_needed"
                    checked={box_needed}
                    onChange={onCheckboxChange}
                  />
                </Segment>
              </Grid.Column>

              <Grid.Column>
                <Segment className="access-focus">
                  <input
                    type="text"
                    placeholder="New Cubicle/OfficeNumber"
                    name="cube_off_number"
                    value={cube_off_number}
                    onChange={onInputChange}
                  />
                </Segment>
                <Segment className="access-focus customDateStyle">
                  <DatePicker
                    minDate={
                      new Date(new Date().setDate(new Date().getDate() + 7))
                    }
                    onChange={(event, data) => {
                      onInputChange({
                        target: {
                          name: "requested_date_to_move",
                          value: event,
                        },
                      });
                    }}
                    name="requested_date_to_move"
                    value={requested_date_to_move}
                    selected={requested_date_to_move}
                    placeholderText="Requested Date to Move"
                  />
                  <label>
                    * Date needs to be at minimum 1 week from today’s date
                  </label>
                </Segment>
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Form.Field>
        <Form.Field style={{ textAlign: "right" }}>
          <Button
            type="submit"
            disabled={!employee?.user?.length > 0}
            basic
            color="green"
          >
            Submit
          </Button>
        </Form.Field>
      </Form>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  termination: state.accessRequests.termination,
  auth: state.auth,
  employeeDetails: state.changeEmployee,
  departmentList: state.accessRequests.departmentList,
  users: state.inventory.users,
  allUsers: state.inventory.allUsers,
  inventory: state.inventory,
  statesss: state,
});
export default connect(mapStateToProps, {
  newHireHRFormStart,
  getDepartmentDropdown,
  lookupUsersForInventory,
  clearUsersForInventory,
  getEmployeeDetails,
  allDBUsers,
  lookupSoftwareSuggestions,
  updateEmployeeRecord,
})(Index);