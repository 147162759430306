import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { Dimmer, Loader, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { getSingleDashboardAccessRequest } from "../../../../../Redux/Actions/dashboardActions";
function AccessRequestTicketsModal({
  getSingleDashboardAccessRequest,
  match,
  ticket,
}) {
  useState(() => {
    const fd = new FormData();
    fd.append("term_id", match.params.id);
    getSingleDashboardAccessRequest(fd);
  });
  let accessTicket;
  ticket
    ? (accessTicket = (
        <Grid columns="equal" divided="vertically">
          <Grid.Row>
            <Grid.Column>
              <h3>New Hire: </h3>
              <h4>{`${ticket[0].employee_last_name}, ${ticket[0].employee_first_name}`}</h4>
            </Grid.Column>
            <Grid.Column>
              <h3>Job Title: </h3>
              <h4>{`${ticket[0].job_title}`}</h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h3>Start Date: </h3>
              <h4>
                <Moment
                  date={ticket[0].employee_start_date}
                  format="MM/DD/YY HH:mm"
                />
              </h4>
            </Grid.Column>
            <Grid.Column>
              <h3>Ticket Created Date: </h3>
              <h4>
                <Moment date={ticket[0].created} format="MM/DD/YY HH:mm" />
              </h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h3>Status: </h3>
              <h4>{ticket[0].access_stage}</h4>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))
    : (accessTicket = (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ));
  return <Container>{accessTicket}</Container>;
}

const mapStateToProps = (state) => ({
  ticket: state.dashboard_tickets.dashboard_tickets,
});
export default connect(mapStateToProps, { getSingleDashboardAccessRequest })(
  AccessRequestTicketsModal
);
