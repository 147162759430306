import React, { useState } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment'
import {
  addDataComment,
  getDataTicketComments
} from "../../../../../Redux/Actions/commentActions";
import { Grid } from "semantic-ui-react";
import CommentsMain from "../../../../Common/Inventory/Comments/CommentsMain";
function DataAccessTicketsModal({
  props,
  comments,
  auth,
  addDataComment,
  getDataTicketComments
}) {
  useState(() => {
    const fd = new FormData();
    fd.append("data_ticket_id", props.dr_id);
    getDataTicketComments(fd);
  }, []);
  const propCode = "dataops";
  return (
    <Grid columns="equal" stackable>
      <Grid.Row>
        <Grid.Column>
          <p>
            <span style={{ fontWeight: "bold" }}>Subject: </span>
            {props.dr_subject}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Description: </span>
            {props.dr_priority}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Type: </span>
            {props.dr_type}
          </p>
        </Grid.Column>
        <Grid.Column>
          <p>
            <span style={{ fontWeight: "bold" }}>Org: </span>
            {props.dr_organization}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Claims Begining: </span>
            {props.dr_claims_begining_date}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Claims End: </span>
            {props.dr_claims_end_date}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <p>
            <span style={{ fontWeight: "bold" }}>Status: </span>
            {props.dr_admin_status}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Assigned To: </span>
            {props.dr_assigned_to}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>DUE BY: </span>
            {props.dr_due_date}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {comments.dataComments ? (
            <div style={{borderTop:'2px solid #1B75BB', paddingTop:'10px'}}>
              <h3>Comments:</h3>
              {comments.dataComments.map(comment => (
                <span key={comment.id}>
                  <p style={{ textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {comment.data_commentor_name}:
                    </span>{" "}
                    {comment.comment}
                    <span style={{float:'right'}}><Moment date={comment.comment_time} format='MM/DD/YY LTS' /></span>
                  </p>
                  <hr />
                </span>
              ))}
            </div>
          ) : null}
          <CommentsMain
            props={propCode}
            ticket={props.dr_id}
            addComment={addDataComment}
            getComments={getDataTicketComments}
            commmentor={auth.user[0]}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
const mapStateToProps = state => ({
  comments: state.comments,
  auth: state.auth
});
export default connect(mapStateToProps, {
  addDataComment,
  getDataTicketComments
})(DataAccessTicketsModal);
