import axios from "axios";
import { setAlert } from "./alertActions";
import {
  ADD_INVENTORY,
  GET_INVENTORY,
  CLEAR_SOFTWARE_SUGGESTIONS,
  GET_SOFTWARE_SUGGESTIONS,
  SET_SOFTWARE,
  REMOVE_SELECTED_SOFTWARE,
  MODAL_OPEN,
  FORM_OK,
  FORM_NOT_OK,
  GET_PRODUCT_FROM_DATABASE,
  GET_ALL_INVENTORY,
  GET_USERS_FOR_INVENTORY,
  CLEAR_GET_USERS_FOR_INVENTORY,
  CLEAR_ALL_INVENTORY,
  GET_ALL_USERS_FOR_INVENTORY,
  CLEAR_SELECTED_SOFTWARE,
  SET_NO_MESSAGE,
  GET_ALL_DROPDOWN_MODELS,
  GET_ALL_INVENTORY_COMMENTS,
  ADD_SYSTEM_PRODUCTS,
  GET_ACCESS_BY_USER,
  REMOVE_ACCESS_FROM_USER_REQUEST,
  REMOVE_ACCESS_FROM_USER_SUCESS,
  REMOVE_ACCESS_FROM_USER_FAILURE,
} from "./types";

export const addNewHIreInventory = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/add-newhire-inventory", body);
    dispatch({
      type: ADD_INVENTORY,
    });
    // dispatch({
    //   type: GET_INVENTORY,
    //   payload: res.data
    // });
    if (res.data && typeof res.data === "object" && res.data.message) {
      dispatch({
        type: MODAL_OPEN,
        payload: res.data,
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const lookupSoftwareSuggestions = (body) => async (dispatch) => {
  const fd = new FormData();
  fd.append("software_search", body);
  try {
    const res = await axios.post("/api/inventory/lookup-software", fd);
    dispatch({
      type: GET_SOFTWARE_SUGGESTIONS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getInventoryModelsDropdown = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/inventory/get-inventory-models-dropdown",
      body
    );
    dispatch({
      type: GET_ALL_DROPDOWN_MODELS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getInventoryByEmail = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/get-inventory-by-email", body);
    dispatch({
      type: GET_ALL_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const lookupUsersForInventory = (body) => async (dispatch) => {
  try {
    const fd = new FormData();
    fd.append("inventory_user_search", body);
    const res = await axios.post(
      "/api/inventory/lookup-users-for-inventory",
      fd
    );
    dispatch({
      type: GET_USERS_FOR_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const allDBUsers = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/all-db-users", {});
    dispatch({
      type: GET_ALL_USERS_FOR_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const clearUsersForInventory = () => (dispatch) => {
  dispatch({ type: CLEAR_GET_USERS_FOR_INVENTORY });
};

export const getSelectedProduct = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/add-software", body);
    dispatch({
      type: SET_SOFTWARE,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const addProfileInventory = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/add-profile-inventory", body);
    
    dispatch({
      type: ADD_INVENTORY,
    });
    dispatch({
      type: GET_INVENTORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INVENTORY,
      payload: err.response.data,
    });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
export const addRegularInventory = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/it-add-new-inventory", body);
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
export const addinUseInventory = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/inventory/it-add-current-inventory",
      body
    );
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const removeAcessFromUser = (body) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_ACCESS_FROM_USER_REQUEST,
    });
    const res = await axios.post(
      "/api/inventory/remove-access-from-user",
      body
    );
    dispatch({
      type: REMOVE_ACCESS_FROM_USER_SUCESS,
      payload: res?.data?.message,
    });
    if (res.status === 200) {
      dispatch(setAlert(res?.data?.message, "success"));
    }
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: REMOVE_ACCESS_FROM_USER_FAILURE,
    });

    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const removeItemFromUser = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/remove-item-from-user", body);
    dispatch({
      type: GET_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const setRequestedAccessModification = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/access-request/request-more-accesses",
      body
    );
    await dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
    await dispatch({
      type: FORM_OK,
    });
    setTimeout(() => {
      dispatch({
        type: FORM_NOT_OK,
      });
    }, 1000);
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const clearSoftwareSuggestions = () => (dispatch) => {
  dispatch({ type: CLEAR_SOFTWARE_SUGGESTIONS });
};
export const removeSelectedSoftwareFromList = (id) => (dispatch) => {
  dispatch({ type: REMOVE_SELECTED_SOFTWARE, payload: id });
};
export const clearAllSelectedSoftware = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_SOFTWARE, payload: "" });
};
export const setNoMessage = () => (dispatch) => {
  dispatch({ type: SET_NO_MESSAGE, payload: "" });
};
export const getPendingProductRequested = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/access-request/product-from-products",
      body
    );
    dispatch({
      type: GET_PRODUCT_FROM_DATABASE,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getUserInventory = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/get-users-inventory", body);
    dispatch({
      type: GET_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getAllInventory = (body) => async (dispatch) => {
  try {
    const res = await axios.get("/api/inventory/get-all-current-inventory");
    dispatch({
      type: GET_ALL_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
export const modifyITinventory = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/it-modify-inventory", body);
    dispatch({
      type: GET_ALL_INVENTORY,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const clearAllInventory = () => async (dispatch) => {
  dispatch({ type: CLEAR_ALL_INVENTORY });
};

export const getInventoryComments = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/inventory/get-all-inventory-comments",
      body
    );
    dispatch({
      type: GET_ALL_INVENTORY_COMMENTS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const AddInventoryComments = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/add-inventory-comments", body);
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const removeInventoryFromUser = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/inventory/remove-inventory-from-user",
      body
    );
    // dispatch({
    //   type: MODAL_OPEN,
    //   payload: res.data
    // });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const addSystemProducts = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/inventory/it-add-system-products", body);
    dispatch({
      type: ADD_SYSTEM_PRODUCTS,
      payload: res.data.message,
    });
    if (res.status === 201) {
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getAccessbyUser = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/profile/lookup-access-by-id", body);
    dispatch({
      type: GET_ACCESS_BY_USER,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
