import React from "react";
import {
  Container,
  Grid,
  Placeholder
} from "semantic-ui-react";
import { connect } from "react-redux";
import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {loadMicrosoftGraph} from '../../../Redux/Actions/userActions';
const UserProfile = ({ user,loadMicrosoftGraph }) => {







  return (
    <Container>
      {user ? (
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <h1 className="krub">Welcome {user.first_name}</h1>
              <h3 className="krub">You can review your data below</h3>
              <hr className="style-six" style={{ margin: "20px 0" }} />
            </Grid.Column>
            <Grid.Column width={4}>
              <Placeholder
                style={{ height: 150, width: 150, borderRadius: "50%" }}
              >
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={6}>
              <h3>
                {user.first_name}&nbsp;{user.middle_initial}.{user.last_name}
              </h3>
              <h4>{user.job_title}</h4>
              <h4>{user.email}</h4>
            </Grid.Column>
            <Grid.Column width={6}>
              <p>Reset Password:</p>
          
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <LoaderPlaceholder />
      )}
    </Container>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(
  mapStateToProps,
  {loadMicrosoftGraph}
)(UserProfile);
