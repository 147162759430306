import React, { useEffect, useReducer } from "react";
import { Container } from "@material-ui/core";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { getUserTicketHistory } from "../../../Redux/Actions/dashboardActions";
function HistoryMainIndex({ auth, getUserTicketHistory }) {
  useEffect(() => {
    const fd = new FormData();
    // fd.append("user", auth.user[0].email);
    getUserTicketHistory(fd);
  }, []);

  return (
    <Container maxWidth="lg">
      <Table celled></Table>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  history: state.dashboard_tickets.dashboard_tickets,
});
export default connect(mapStateToProps, { getUserTicketHistory })(
  HistoryMainIndex
);
