// // Created by Thomas Flock Jr on 8/12/2019
// // https://github.com/mazer-rakham

// EVERYTHING NEEDS REFACTORING!
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import "./App.scss";
// import { Provider } from "react-redux";
import store from "./store";
import Header from "./Components/Main-Layout/Header";

import { microSoftSuccess } from "./Redux/Actions/userActions";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Components/Main-Layout/Dashboard/Dashboard";
import MainNavigation from "./Components/Main-Layout/MainNavigation";

import NewDataTicket from "./Components/Main-Layout/Tickets/DataRequest.js";
import ITticket from "./Components/Main-Layout/Tickets/ITticket";
import Footer from "./Components/Main-Layout/Footer";
import "react-image-lightbox/style.css";
import MyTickets from "./Components/Main-Layout/TicketReporting/MyTickets";
import Suggestions from "./Components/Main-Layout/Suggestions/Suggestions";
import RoadMap from "./Components/Main-Layout/RoadMap.js/RoadMap";
import AllBugs from "./Components/Main-Layout/ReportABug/AllBugs";
// import ConfirmEmail from "./Components/Auth/ConfirmEmail";
import UserProfile from "./Components/Main-Layout/UserProfile/UserProfile";
import UserLookup from "./Components/Main-Layout/UserLookup/UserLookup";
import TicketReportMain from "./Components/Main-Layout/TicketReporting/TicketReportMain";

import PendingAccessRequests from "./Components/Main-Layout/AccessRequest/PendingAccessRequests";
import PendingTicketsMain from "./Components/Main-Layout/PendingTickets/PendingTicketsMain";
import DataOpsMain from "./Components/Main-Layout/DataOps/DataOpsMain";
import { connect } from "react-redux";
import InventoryMain from "./Components/Main-Layout/Inventory/InventoryMain";
import { loadUser, logout } from "./Redux/Actions/userActions";

import ConfirmUser from "./Components/Auth/ConfirmUser";
import AccessRequestMain from "./Components/Main-Layout/AccessRequest/AccessRequestMain";
import ITSetupRequest from "./Components/Main-Layout/AccessRequest/ITSetupRequest";
import AutoSuggest from "./Components/Main-Layout/UserLookup/AutoSuggest";
import IndividualTicket from "./Components/Main-Layout/PendingTickets/IndividualTicket";
import ITTicketMain from "./Components/Main-Layout/PendingTickets/ITTicketMain";
import DashboardUrlFix from "./Components/Main-Layout/Dashboard/UserDashboard/DashboardUrlFix";
import NewITTicketModal from "./Components/Main-Layout/Dashboard/UserDashboard/TicketTypes/NewITTicketModal";
import StageOne from "./Components/Main-Layout/AccessRequest/ManagerStages/StageOne";
import ApplicationsMain from "./Components/Main-Layout/Applicaitions/ApplicationsMain";
import DataOpsRequestModal from './Components/Main-Layout/Dashboard/UserDashboard/TicketTypes/DataOpsRequestModal';
import TerminationAccessTicketsModal from "./Components/Main-Layout/Dashboard/UserDashboard/TicketTypes/TerminationAccessTicketsModal";
import ModifyAccessTicketsModal from "./Components/Main-Layout/Dashboard/UserDashboard/TicketTypes/ModifyAccessTicketsModal";
import AccessRequestTicketsModal from "./Components/Main-Layout/Dashboard/UserDashboard/TicketTypes/AccessRequestTicketsModal";
import IndivitualDataOp from "./Components/Main-Layout/DataOps/IndivitualDataOp";
import HistoryMainIndex from "./Components/Main-Layout/History/HistoryMainIndex";
import InventoryReportMain from "./Components/Main-Layout/TicketReporting/InventoryReport";
import QualtityRequestTicket from "./Components/Main-Layout/Tickets/QualityRequest";
import QRMain from "./Components/Main-Layout/QualityRequest/DataOpsMain";
import IndivitualQR from "./Components/Main-Layout/QualityRequest/IndivitualDataOp";
import IntakeRequests from "./Components/Main-Layout/AccessRequest/IntakeRequests";
import IntakeCompilanceForm from "./Components/Main-Layout/AccessRequest/IntakeCompilanceForm";
import HRAccessRequestMain from "./Components/Main-Layout/AccessRequest/HRAccessRequestMain";
if (localStorage.getItem("adal.idtoken")) {
  setAuthToken(localStorage.getItem("adal.idtoken"));
}

const Routes = ({ auth, loadUser, isAuthenticated, logout }) => {
  useEffect(() => {
    store.dispatch(microSoftSuccess());
    const fd = new FormData();
    fd.append("email", auth.token.upn);
    loadUser(fd).then(() => {});
  }, []);

  return (
    <div>
      <Header />

      <div id="fixed-menu-wrapper">
        <Route path="/confirm-user" component={ConfirmUser} />
        <PrivateRoute path="/" component={MainNavigation} />
        <PrivateRoute exact path="/new-ticket/IT" component={ITticket} />
        <PrivateRoute exact path="/pending-data-ops" component={DataOpsMain} />
        <PrivateRoute exact path="/pending-data-ops/:id" component={IndivitualDataOp} />
        <PrivateRoute exact path="/pending-qr" component={QRMain} />
        <PrivateRoute exact path="/pending-qr/:id" component={IndivitualQR} />
        <PrivateRoute exact path="/new-ticket/Quality" component={QualtityRequestTicket} />
        <PrivateRoute exact path="/new-ticket/Data" component={NewDataTicket} />
        <Route exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/my-tickets" component={MyTickets} />
        <PrivateRoute
          exact
          path="/new-hire-IT-stage/:id"
          component={ITSetupRequest}
        />

        <PrivateRoute exact path="/suggestions" component={Suggestions} />
        <PrivateRoute exact path="/roadmap" component={RoadMap} />
        <PrivateRoute exact path="/bug-reports" component={AllBugs} />
        <PrivateRoute exact path="/user-profile" component={UserProfile} />
        <PrivateRoute exact path="/user-lookup" component={UserLookup} />
        <PrivateRoute exact path="/autosuggest" component={AutoSuggest} />
        <PrivateRoute
          exact
          path="/pending-access-requests"
          component={PendingAccessRequests}
        />
        <PrivateRoute
          exact
          path="/compilance-form/:id"
          component={IntakeCompilanceForm}
        />
        <PrivateRoute
          exact
          path="/intake-forms-requests"
          component={IntakeRequests}
        />
        <PrivateRoute exact
          path="/dataops-request/:id"
          component={DataOpsRequestModal}
        />
        <PrivateRoute exact
          path="/termination-request/:id"
          component={TerminationAccessTicketsModal}
        />
        <PrivateRoute exact
          path="/modify-request/:id"
          component={ModifyAccessTicketsModal}
        />
        <PrivateRoute exact
          path="/access-request/:id"
          component={AccessRequestTicketsModal}
        />
         <PrivateRoute
          exact
          path="/pending-access-requests/stage-one/:id"
          component={StageOne}
        />
        <PrivateRoute    
          exact      
          path="/pending-tickets"
          component={PendingTicketsMain}
        />
         <PrivateRoute    
          exact      
          path="/third-party-applications"
          component={ApplicationsMain}
        />
         <PrivateRoute    
          exact      
          path="/my-ticket-history"
          component={HistoryMainIndex}
        />
        <PrivateRoute    
          exact      
          path="/pending-tickets/:id"
          component={ITTicketMain}
        />
         <PrivateRoute    
          exact      
          path="/it-ticket/:id"
          component={NewITTicketModal}
        />
        <PrivateRoute
          exact
          path="/access-request"
          component={AccessRequestMain}
        />
        <PrivateRoute
          exact
          path="/hr"
          component={HRAccessRequestMain}
        />
        <PrivateRoute
          exact
          path="/ticket-reporting"
          component={TicketReportMain}
        />
        <PrivateRoute exact path="/inventory" component={InventoryMain} />
        <PrivateRoute exact path="/inventory-reporting" component={InventoryReportMain} />
        <Footer />
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { loadUser, logout })(Routes);
