// Created by Thomas Flock Jr on 8/12/2019
// https://github.com/mazer-rakham

// this needs refactoring!!!!!!!!!
import React, { useState,useEffect, useCallback } from "react";
import { throttle } from "lodash";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
  lookupUsersForInventory,
  clearUsersForInventory,
  allDBUsers
} from "../../../Redux/Actions/inventoryActions";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { Form, Input, TextArea, Container, Button, Grid } from "semantic-ui-react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newTicketITTicket } from "../../../Redux/Actions/ticketActions";
import { Link, Redirect } from "react-router-dom";
import { loadUser } from "../../../Redux/Actions/userActions";
registerPlugin(FilePondPluginImagePreview);

function NewItTicket ({ newTicketITTicket, redirect, auth, loadUser, lookupUsersForInventory,
  clearUsersForInventory,users,allDBUsers,allUsers }) {
    useEffect(()=>{
      allDBUsers()
    }, [])
  const [ticketData, setTicketData] = useState({
    ticketType: "IT",
    ticketSubject: "",
    ticketDescription: "",
    itHelpType: "",
    ticketPriority: "",
  });
  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: []
  });
  const {
    cursor
  } = highlightSelection;

  const [picture, addAttachment] = useState({
    ticketAttachment_one: "",
    ticketAttachment_two: "",
    ticketAttachment_three: "",
    ticketAttachment_four: "",
  });
  const spanStyle = {
    marginRight: "15px",
    position: "relative",
    top: "-2px",
  };
  const [requesterName, setRequesterName] = useState("");

  React.useEffect(() => {    
    clearUsersForInventory()
  }, [requesterName]);

  const handleAutoProductChange = e => {
    setRequesterName(e.target.value.trim());
    delayedQuery(e.target.value);
  };

  const delayedQuery = useCallback(
    throttle(q => sendQuery(q), 500),
    []
  );

  const sendQuery = query => {
    if (query.length > 0) {
            lookupUsersForInventory(query);
    } else {
      clearUsersForInventory();
    }
  };
  const inputStyle = {
    cursor: "pointer",
    marginRight: "2px",
  };
  const {
    ticketType,
    ticketSubject,
    ticketDescription,
    itHelpType,
    ticketPriority,
  } = ticketData;
  const {
    ticketAttachment_one,
    ticketAttachment_two,
    ticketAttachment_three,
    ticketAttachment_four,
  } = picture;
  React.useEffect(()=>{
    setRemainingDes(1000 - ticketDescription.length)
  },[ticketDescription])

  const [remainingDes, setRemainingDes]=useState(1000)

  const onChange = (e) => {
    setTicketData({
      ...ticketData,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // let index = allUsers.findIndex(x=>x.first_name === requesterName.split(" ")[0] && x.last_name === requesterName.split(" ")[1])
    // if(index === -1){
    //   toastr.options = {
    //     positionClass: "toast-top-center",
    //     hideDuration: 300,
    //     timeOut: 2000,
    //     closeButton: false,
    //     progressBar: true,
    //   }
    //   toastr.clear()
    //   setTimeout(() => toastr.error("Please Select User From The List"), 300)
    //   return false;
    // }
    const fd = new FormData();
    fd.append("user_id", auth.user[0].id);
    fd.append("ticketType", ticketType);
    fd.append("ticketSubject", ticketSubject);
    fd.append("ticketDescription", ticketDescription);
    fd.append("itHelpType", itHelpType);
    fd.append("ticketPriority", ticketPriority);
    fd.append("ticketAttachment_one", ticketAttachment_one);
    fd.append("ticketAttachment_two", ticketAttachment_two);
    fd.append("ticketAttachment_three", ticketAttachment_three);
    fd.append("ticketAttachment_four", ticketAttachment_four);
    fd.append("req_user_id", auth.user[0].id);
    // fd.append("requesterName", requesterName)
    newTicketITTicket(fd);
    //    for (var pair of fd.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]);
    // }
  };
  const handleFilepondUpdate = (fileItems) => {
    if (fileItems.length === 0) {
      addAttachment({
        ...picture,
        ticketAttachments: null,
      });
    } else {
      addAttachment({
        ...picture,
        ticketAttachment_one: !fileItems[0] ? null : fileItems[0].file,
        ticketAttachment_two: !fileItems[1] ? null : fileItems[1].file,
        ticketAttachment_three: !fileItems[2] ? null : fileItems[2].file,
        ticketAttachment_four: !fileItems[3] ? null : fileItems[3].file,
      });
    }
  };
  const handleKeyDown = e => {
    if (e.keyCode === 38) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor - 1
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection(prevState => ({
        ...prevState,
        cursor: prevState.cursor + 1
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {       
        setRequesterName("");       
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0
        });
      }
    }
  };
  function setRequesterFunction(name){
        setRequesterName(name.trim())
  }
  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <h1>New IT Ticket</h1>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Field>
          <input
            type="radio"
            name="ticketType"
            value="IT"
            onChange={onChange}
            checked={ticketType === "IT"}
            style={inputStyle}
          />
          <span style={spanStyle}>IT Ticket</span>
          <Link to="/new-ticket/Data" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>DATA Request</span>
          </Link>
          <Link to="/new-ticket/Quality" style={{ color: "black" }}>
            <input type="radio" style={inputStyle} />
            <span style={spanStyle}>Quality Request</span>{" "}
          </Link>
        </Form.Field>
        <Form.Field>
          <label>Subject:</label>
          <Input
            maxLength="50"
            type="text"
            onChange={onChange}
            name="ticketSubject"
            placeholder="Please enter the subject of the ticket"
            value={ticketSubject}
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
            Max Length 50
          </span>
        </Form.Field>
        <Form.Field>
          <label>Description:</label>
          <TextArea
            maxLength="1000"
            name="ticketDescription"
            value={ticketDescription}
            onChange={onChange}
            placeholder="Please enter a brief description for the ticket"
          />
          <span style={{ padding: "5px", color: "red", fontSize: "12px" }}>
          Max Length 1000
          </span>
          <span style={{ padding: "5px", color: "red", fontSize: "12px", float:"right" }}>
          {remainingDes} Characters remaining
          </span>
        </Form.Field>
        <Form.Field
          style={{
            border: "0.5px solid #e6e6e6",
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <label>Type:</label>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Question"
            checked={itHelpType === "Question"}
          />
          <span style={spanStyle}>Question</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Incident"
            checked={itHelpType === "Incident"}
          />
          <span style={spanStyle}>Incident</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Problem"
            checked={itHelpType === "Problem"}
          />
          <span style={spanStyle}>Problem</span>
          <input
            type="radio"
            name="itHelpType"
            onChange={onChange}
            style={inputStyle}
            value="Task"
            checked={itHelpType === "Task"}
          />
          <span style={spanStyle}>Task</span>
        </Form.Field>
        <Form.Field
          style={
            ticketPriority === "LOW"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#f2e6ff",
                }
              : ticketPriority === "NORMAL"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#bcddf5",
                }
              : ticketPriority === "HIGH"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ffd9b3",
                }
              : ticketPriority === "URGENT"
              ? {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#ff9980",
                }
              : {
                  border: "0.5px solid #e6e6e6",
                  padding: "10px",
                  borderRadius: "4px",
                }
          }
        >
          <label>Priority:</label>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="LOW"
            checked={ticketPriority === "LOW"}
          />
          <span style={spanStyle}>Low</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="NORMAL"
            checked={ticketPriority === "NORMAL"}
          />
          <span style={spanStyle}>Normal</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="HIGH"
            checked={ticketPriority === "HIGH"}
          />
          <span style={spanStyle}>High</span>
          <input
            type="radio"
            name="ticketPriority"
            onChange={onChange}
            style={inputStyle}
            value="URGENT"
            checked={ticketPriority === "URGENT"}
          />
          <span style={spanStyle}>Urgent</span>
        </Form.Field>
        
        <Form.Field>
          <label>Attachment: </label>
          <span>up to four</span>
          <FilePond
            maxFiles={4}
            files={
              (ticketAttachment_one,
              ticketAttachment_two,
              ticketAttachment_three,
              ticketAttachment_four)
            }
            allowMultiple={true}
            allowImagePreview="false"
            onupdatefiles={(fileItems) => handleFilepondUpdate(fileItems)}
          />
        </Form.Field>
        <Form.Field>
          <Button
            basic
            color="red"
            type="reset"
            onClick={() => {
              setTicketData({
                ticketType: "",
                ticketSubject: "",
                ticketDescription: "",
                itHelpType: "",
                ticketPriority: "",
                ticketAttachments: "",
              });
              addAttachment({
                ...picture,
                ticketAttachment_one: "",
                ticketAttachment_two: "",
                ticketAttachment_three: "",
                ticketAttachment_four: "",
              });
            }}
          >
            Clear
          </Button>
          <Button
            type="submit"
            basic
            color="green"
            disabled={
              !ticketSubject ||
              !ticketDescription ||
              !itHelpType ||
              !ticketPriority
            }
          >
            Submit
          </Button>
        </Form.Field>
      </Form>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  tickets: state.tickets,
  redirect: state.redirect,
  auth: state.auth, users: state.inventory.users,
  allUsers: state.inventory.allUsers
});
export default connect(mapStateToProps, { newTicketITTicket, loadUser,lookupUsersForInventory,
  clearUsersForInventory,allDBUsers })(
  NewItTicket
);
