import { combineReducers } from "redux";
import auth from "./userReducer";
import alert from "./alertReducer";
import modal from "./modalReducer";
import tickets from "./ticketReducer";
import redirect from "./formSubmitReducer";
import profile from "./profileReducer";
import accessRequests from "./accessRequestReducer";
import dataOps from './dataOpsReducer';
import comments from './commentReducer';
import notifications from './notificationReducer';
import inventory from './inventoryReducer'
import dashboard_tickets from './dashboardReducer';
import reportingTickets from './reportingReducer';
import applications from './applicationReducer';
import allActiveItUsers from "./ticketReducer";
import assignedUser from "./dataOpsReducer";
import assigned_dashboard_tickets from "./dashboardReducer";
import changeEmployee from "./changeEmployeeReducer";

export default combineReducers({
  auth,
  alert,
  modal,
  tickets,
  redirect,
  profile,
  accessRequests,
  dataOps,
  comments,
  notifications,
  inventory,
  dashboard_tickets,
  reportingTickets,
  applications,  
  allActiveItUsers,
  assignedUser,
  assigned_dashboard_tickets,
  changeEmployee

});
