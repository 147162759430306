import React, { useEffect } from "react";
import { connect } from "react-redux";
// import LoaderPlaceholder from "../../Common/LoaderPlaceholder";
import {
  getAllPendingAccessRequests,
  getManagerPendingARRequests
} from "../../../Redux/Actions/accessRequestActions";
import { Container, Segment, Modal, Grid, Button } from "semantic-ui-react";
import ManagerStage from "./ManagerStage";
import PendingModifyRequest from "./PendingModifyRequest";
// import IndividualAccessRequests from './IndividualAccessRequests';
import _ from "lodash";
import Moment from "react-moment";
function PendingAccessRequests({
  getAllPendingAccessRequests,
  accessRequests,
  getManagerPendingARRequests,
  modal,
  auth
}) {


  useEffect(() => {
    const fd = new FormData();
    if (auth.user) {
      fd.append("user_email", auth.user[0].email);
      getAllPendingAccessRequests(fd);
    }       
  }, [auth]);

  const { pendingRequests, modifyRequests,intakeForms } = accessRequests;
  let individualRequests;
  if (
    (!pendingRequests || pendingRequests === 0 || pendingRequests === null) &&
    (!modifyRequests || modifyRequests === 0 || modifyRequests === null)
  ) {
    individualRequests = (
      <div>There are no pending requests at the moment.</div>
    );
  } else if (
    !pendingRequests ||
    pendingRequests === 0 ||
    pendingRequests === null
  ) {
    individualRequests = _.orderBy([...modifyRequests], ["created"], ["desc"]);;
  } else if (
    !modifyRequests ||
    modifyRequests === 0 ||
    modifyRequests === null
  ) {
    individualRequests = _.orderBy(pendingRequests, ["created"], ["desc"]);
  } else {
    individualRequests = _.orderBy([...pendingRequests, ...modifyRequests], ["created"], ["desc"]);
  }

  function openPopup(attachment) {
    let tempAtt = attachment.replace("./uploads/","").trim()
    if(tempAtt && (tempAtt.match(/.xls/gi) || 
    tempAtt.match(/.xlsx/gi) || 
    tempAtt.match(/.doc/gi) || 
    tempAtt.match(/.pdf/gi))){
      console.log("tempAtt")
      downloadFile(window.location.origin+tempAtt,tempAtt)
      return true
    }
  }

  function downloadFile(fileURL,filename) {
    if (
      fileURL        
    ) {
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        save.download = filename.substring(filename.lastIndexOf("/") + 1)
        var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
    }
  }

  return (
    <Container>
      <h1>All pending approvals</h1>
      {individualRequests.length > 0 ? (
        <div>
          {individualRequests.map((request, index) => (
            <Modal
              key={index}
              trigger={
                <Segment style={{ cursor: "pointer", position: "relative" }}>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Requestor:{" "}
                        </span>
                        {request.ac_user_id
                          ? request.requestor
                          : request.requester_last +
                          ", " +
                          request.requestor_first}
                      </p>

                    </Grid.Column>
                    <Grid.Column>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Type: {request.id} </span>
                          {request?.inHistory ? <span>History Changed</span> : request.modification_type ? (
                            <span>Modify Request</span>
                          ) : (
                            <span>New Hire</span>
                          )}
                        </p>


                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ marginTop: "-25px" }}>
                    <Grid.Column>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Status: </span>
                        {request.access_stage ? (
                          <span>{request.access_stage}</span>
                        ) : (
                          <span>{request.access_status}</span>
                        )}
                      </p>
                    </Grid.Column>

                    <Grid.Column>
                      {typeof request.requestor == "number" ? null : (
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Employee Name:{" "}
                          </span>
                          {request.employee_first_name}{" "}
                          {request.employee_last_name}
                        </p>
                      )}

                      {request.modification_type ? (
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Modify Type:{" "}
                          </span>
                          {request.modification_type}
                        </p>
                      ) : null}
                    </Grid.Column>

                  </Grid.Row>
                  {request.modification_type &&
                    request.modification_type === "TERMINATION" ? (
                    <Grid.Row style={{ marginTop: "-25px" }}>
                      <Grid.Column>
                        <span style={{ fontWeight: "bold" }}>
                          Person scheduled for termination:{" "}
                        </span>
                        {request.modified_last +
                          ", " +
                          request.modified_first}
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                  {request.created ? (
                    <Grid.Row style={{ marginTop: "-25px" }}>
                      <Grid.Column>
                        <span style={{ fontWeight: "bold" }}>
                          Created Date:{" "}
                        </span>
                        <Moment date={request.created} format="MM/DD/YY LTS" />
                      </Grid.Column>
                      <Grid.Column>
                        <span style={{ fontWeight: "bold" }}>
                          Personal Email:{" "}
                        </span>
                        {request.personal_email || "NA"}
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                  <Grid.Row style={{ marginTop: "-25px" }}>
                    <Grid.Column>
                      <span style={{ fontWeight: "bold" }}>
                        Request Pending for:{" "}
                      </span>
                      {request.manager_name ? request.manager_name : request?.modified_manager}
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ fontWeight: "bold" }}>
                        Phone Number:{" "}
                      </span>
                      {request.phone_number || "NA"}
                    </Grid.Column>
                  </Grid.Row>
                  <div style={{ position: "absolute", right: "0px", bottom: "15px", marginTop: "-25px" }}>
                    <Grid.Column style={{ textAlign: "right" }}>
                      <Button basic color="green">
                        Review
                      </Button>
                    </Grid.Column>
                  </div>
                </Grid>
              </Segment>
              }
              closeIcon
            >
              <Modal.Header>
                {request.modification_type &&
                request.modification_type === "TERMINATION" ? (
                  <span>
                    {request.requestor_first} is requesting an employee
                    termination.
                  </span>
                ) : request.modification_type ? (
                  <span>
                    {request.requestor_first + " " + request.requester_last} is requesting
                    additional accesses
                  </span>
                ) : (
                  <span>You have a New Hire!</span>
                )}
              </Modal.Header>
              <Modal.Content>
                {request.modification_type ? (
                  <PendingModifyRequest modify={request} />
                ) : (
                  <ManagerStage newHire={request} />
                )}
              </Modal.Content>
            </Modal>
          ))}
        </div>
      ) : (
        <div>{individualRequests}</div>
      )}
      
    </Container>
  );
}
const mapStateToProps = state => ({
  accessRequests: state.accessRequests,
  modal: state.modal,
  auth: state.auth
});
export default connect(mapStateToProps, {
  getAllPendingAccessRequests,
  getManagerPendingARRequests
})(PendingAccessRequests);
