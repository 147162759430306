import {
  GET_ALL_PENDING_ACCESS_REQUESTS,
  START_NEW_HIRE_FORM,
  GET_MANAGER_PENDING_ACCESS_REQUEST,
  ACCRESS_REQUEST_LOOKUP,
  GET_USERS_FOR_TERMINATION,
  CLEAR_USERS_FOR_TERMINATION,
  SELECT_USER_FOR_TERMINATION,
  CLEAR_ALL_TERMINATION,
  GET_IT_NEWHIRE_REQUEST,
  GET_DROPDOWN_LIST,
  GET_HR_LIST,
  GET_ALL_INTAKE_REQUESTS,
  GET_SINGLE_INTAKE
} from "../Actions/types";

const initialState = {
  loading: true,
  pendingUser: null,
  pendingRequests: null,
  modifyRequests: null,
  termination: null,
  tobeTerminated: null,
  intakeForms:null,
  intakeForm:null
};
export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PENDING_ACCESS_REQUESTS:
    case GET_MANAGER_PENDING_ACCESS_REQUEST:
      if (payload.pendingRequests.length === 0) {
        return {
          ...state,
          loading: false,
          pendingRequests: null,
          modifyRequests: payload.modifyRequests
        };
      }
      if (payload.modifyRequests.length === 0) {
        return {
          ...state,
          loading: false,
          modifyRequests: null,
          pendingRequests: payload.pendingRequests
        };
      }
      return {
        ...state,
        loading: false,
        pendingRequests: payload.pendingRequests,
        modifyRequests: payload.modifyRequests
      };
      case GET_IT_NEWHIRE_REQUEST:  
      console.log("get newhire")     
        return {
          ...state,
          pendingUser: payload.user,
          pendingRequests: payload.access
        }
    case ACCRESS_REQUEST_LOOKUP:
      return {
        ...state,
        loading: false,
        modifyRequests: payload
      };
    case GET_USERS_FOR_TERMINATION:
      return {
        ...state,
        loading: false,
        termination: payload
      };
    case CLEAR_USERS_FOR_TERMINATION:
      return {
        ...state,
        termination: null,
        tobeTerminated: null
      };
    case CLEAR_ALL_TERMINATION:
      return {
        ...state,
        termination: null,
        tobeTerminated: null
      };
    case SELECT_USER_FOR_TERMINATION:
      return {
        ...state,
        tobeTerminated: payload
      };
      case GET_HR_LIST:
    return {
      ...state,
      hrList: payload
    };
      case GET_DROPDOWN_LIST:
        return {
          ...state,
          departmentList: payload
        };
        case GET_ALL_INTAKE_REQUESTS:
          return {
            ...state,
            intakeForms: payload,
            intakeForm:null
          };
          case GET_SINGLE_INTAKE:
            console.log("payload",payload)
            return {
              ...state,
              intakeForm:payload
            }
    case START_NEW_HIRE_FORM:
      return {
        ...state
      };
    default:
      return state;
  }
}
