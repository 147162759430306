import { FORM_NOT_OK, FORM_OK } from "../Actions/types";

const initialState = {
  formOk: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FORM_NOT_OK:
      return {
        ...state,
        formOk: false
      };
    case FORM_OK:
      return {
        ...state,
        formOk: true
      };
    default:
      return state;
  }
}
