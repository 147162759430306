import React, { useState, useCallback, useEffect } from "react";
import { throttle } from "lodash";
import { Input, Form, Grid, Button, Modal, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
  lookupUsersForTermination,
  clearUsersForTermination,
  selectedUserToTerminate,
  terminateEmployee,
  clearAllTermination,
  getHRDropdown
} from "../../../Redux/Actions/accessRequestActions";
import { switchRedirect } from "../../../Redux/Actions/formActions";
function TerminationRequest({
  lookupUsersForTermination,
  clearUsersForTermination,
  termination,
  selectedUserToTerminate,
  terminated,
  terminateEmployee,
  auth,
  redirect,
  switchRedirect,
  getHRDropdown,
  hrList
}) {

  useEffect(() => {
    getHRDropdown()
  }, []);

  const [lookupUser, setLookupUser] = useState({
    newUser: "",
    newUserID: "",
  });

  if (
    hrList &&
    Array.isArray(hrList) &&
    hrList.length > 0
  ) {
    hrList = hrList.map((x) => {
      return {
        key: x.id,
        value: x.id,
        text: x.first_name + " " + x.last_name,
        email: x.email,
      };
    });
    hrList.unshift({
      key: "",
      value: "",
      text: "SELECT",
      email: "",
    });
  }

  const [confirmModal, setConfirmModal] = useState(false);

  const handleTerminationConfirm = (user_id, user_name) => {
    const newDate = moment(termDate).format("YYYY-MM-DD HH:mm:ss");

    const fd = new FormData();
    fd.append("user_terminated_id", user_id);
    fd.append("user_terminated_name", user_name);
    fd.append("requestor", auth.id);
    fd.append(
      "terminationDate",
      newDate
    );
    fd.append("equipRecovery", equipRecovery);
    if(immediateTermination){
      fd.append("immediate", true);
    }
    let quipRecindex = hrList.find(x=>x["key"] === equipRecovery)
    if(quipRecindex && Object.keys(quipRecindex).length > 0 && quipRecindex["email"]){
      fd.append("equipRecoveryEmail", quipRecindex["email"])
    }
    terminateEmployee(fd);
  };

  const { newUser, newUserID } = lookupUser;
  const handleTermDate = date => {
    setDates(date);
  };

  const [termDate, setDates] = useState("");
  const [highlightSelection, sethighlightSelection] = useState({
    cursor: 0,
    result: [],
  });
  const {
    cursor,
    // result
  } = highlightSelection;
  const [userLookup, setUSerLookup] = useState("");
  const delayedQuery = useCallback(
    throttle((q) => sendQuery(q), 500),
    []
  );

  const handleAutoProductChange = (e) => {
    setUSerLookup(e.target.value);
    delayedQuery(e.target.value);
  };

  const sendQuery = (query) => {
    if (query.length > 0) {
      lookupUsersForTermination(query);
    } else {
      clearUsersForTermination();
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40) {
      sethighlightSelection((prevState) => ({
        ...prevState,
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13) {
      const selected = document.getElementsByClassName(
        "active-software-selection"
      );
      for (var i = 0; i < selected.length; i++) {
        const fd = new FormData();
        fd.append("user_to_reminate", selected[i].getAttribute("data-key"));
        selectedUserToTerminate(fd);
        setUSerLookup("");
        clearUsersForTermination();
        sethighlightSelection({
          ...highlightSelection,
          cursor: 0,
        });
      }
    }
  };
  const handleMouseKeyClick = (id) => {
    const fd = new FormData();
    fd.append("user_to_reminate", id);
    selectedUserToTerminate(fd);
    setUSerLookup("");
    clearUsersForTermination();
    sethighlightSelection({
      ...highlightSelection,
      cursor: 0,
    });
  };
  const handleNo = () => {
    switchRedirect();
  };

  const setConfirmationModal = () => {
    if(!termDate){ 
      toastr.options = { positionClass: "toast-top-center", hideDuration: 300, timeOut: 2000, closeButton: false, progressBar: true, } 
      toastr.clear() 
      setTimeout(() => toastr.error("Termination Date and Time is Mandatory"), 300) 
      return false; 
    }
    if(!equipRecovery){
      toastr.options = { positionClass: "toast-top-center", hideDuration: 300, timeOut: 2000, closeButton: false, progressBar: true, } 
      toastr.clear() 
      setTimeout(() => toastr.error("Equipment Recovery User is Mandatory"), 300) 
      return false; 
    }
    setConfirmModal(true)
  }

  const [equipRecovery, setequipRecovery] = useState("")
  const [immediateTermination, setimmediateTermination] = useState("")

  const { formOk } = redirect;
  if (formOk) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      <h1 style={{marginTop:"10px"}}>Please search the name for the person to be terminated</h1>
      <Form>
        <Input
          onKeyDown={handleKeyDown}
          onChange={handleAutoProductChange}
          value={userLookup}
          placeholder="Search Users..."
          icon="search"
        />
      </Form>
      {termination ? (
        <div
          style={{
            position: "absolute",
            background: "white",
            width: "91%",
            border: "1px solid #bfbfbf",
            borderRadius: "3px",
            zIndex: "99999",
          }}
        >
          {termination.length === 0 ? (
            <p style={{ padding: "5px", margin: "0px" }}>No Results Found</p>
          ) : (
            termination.map((term, i) => (
              <p
                onClick={(id) => handleMouseKeyClick(term.id)}
                style={{ padding: "5px", margin: "0px", cursor: "pointer" }}
                key={term.id}
                data-key={term.id}
                data-name={term.last_name + ", " + term.first_name}
                className={cursor === i ? "active-software-selection" : null}
              >
                {term.first_name} <br /> {term.last_name}
              </p>
            ))
          )}
        </div>
      ) : null}

      {terminated ? (
        <Grid
          columns="equal"
          stackable
          style={{
            paddingTop: "50px",
            marginTop: "50px",
            borderTop: "3px solid black",
            paddingLeft: "15px"
          }}
        >
          <h3>Employee Info</h3>
          <Grid.Row>
            <Grid.Column>
              <p>
                <span style={{ fontWeight: "bold" }}>Employee: </span>
                {terminated.user[0].first_name} {terminated.user[0].last_name}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Manager: </span>
                {terminated.user[0].manager_name}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Manager Email: </span>
                {terminated.user[0].manager_email}
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <span style={{ fontWeight: "bold" }}>Employee Email: </span>
                {terminated.user[0].email}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Job Title: </span>
                {terminated.user[0].dept_specialization}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Department: </span>
                {terminated.user[0].department}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column width="8">
          <Form>
          <Form.Field>
          <label className="mandatory-star">Termination Date</label>
          <DatePicker
            onChange={handleTermDate}
            selected={termDate}
            name=""
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
          </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width="3"> 
          <Form>
          <Form.Field>
          <label className="mandatory-star">Equipment Recovery</label>
          <Dropdown
          style={{width:"100%"}}
          placeholder="Equipment"
          options={hrList}
          onChange={(event,data) => {
            setequipRecovery(data.value); 
          }}
          name="equipRecovery"
          value={equipRecovery}
          required
          selection
          />
          </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width="3"> 
          <Form>
          <Form.Field>
          <label className="mandatory-star">Immediate Termination</label>
          <Dropdown
          style={{width:"100%"}}
          placeholder="Immediate"
          options={[
            {
              key:"Select",
              value:"",
              text:"Select"
            },
            {
              key:"Yes",
              value:true,
              text:"Yes",
            },
            {
              key:"No",
              value:false,
              text:"No",
            }
          ]}
          onChange={(event,data) => {
            setimmediateTermination(data.value); 
          }}
          name="immediateTermination"
          value={immediateTermination}
          required
          selection
          />
          </Form.Field>
            </Form>
          </Grid.Column>
            </Grid.Row>
          <h3>System/Application Accesses: </h3>

          <Grid.Row>
            <Grid.Column>
              {terminated.employee_accesses && terminated.employee_accesses.length > 0 ? (
                terminated.employee_accesses.map((access) => (
                  // <p key={access.id}>{access.system_application}</p>
                  <div className="chip" style={{backgroundColor:"#1b75bb"}} key={access.id}>
                  <div className="chip-content">{access.system_application}</div>
                </div>
                ))
              ) : (
                <span>This employee has no software access</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <h3>Equipment: </h3>

          <Grid.Row>
            <Grid.Column>
              {terminated.hardware && terminated.hardware.length > 0 ? (
                terminated.hardware.map((access) => (
                  // <p key={access.id}>{access.model_number}</p>
                  <div className="chip" style={{backgroundColor:"#1b75bb"}} key={access.id}>
                  <div className="chip-content">{access.model_number}</div>
                </div>
                ))
              ) : (
                <span>This employee has no Equipment.</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <h2>Schedule termination of this employee?</h2>
          </Grid.Row>
          <Grid.Row>
            <p>
              <Button basic color="red" onClick={() => handleNo()}>
                No
              </Button>
              <Modal
                open={confirmModal}
                trigger={
                  <Button
                    basic
                    color="green"
                    onClick={() => setConfirmationModal(true)}
                  >
                    Yes
                  </Button>
                }
              >
                <Modal.Header>Are you sure?</Modal.Header>
                <Modal.Content>This is irreversible!</Modal.Content>
                <Modal.Actions>
                  <Button color="red" onClick={() => setConfirmModal(false)}>
                    No I am not sure.
                  </Button>
                  <Button
                    color="green"
                    onClick={(user_id, user_name) =>
                      handleTerminationConfirm(
                        terminated.user[0].id,
                        terminated.user[0].last_name +
                          ", " +
                          terminated.user[0].first_name
                      )
                    }
                  >
                    Yes I am sure
                  </Button>
                </Modal.Actions>
              </Modal>
            </p>
          </Grid.Row>
        </Grid>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({
  termination: state.accessRequests.termination,
  terminated: state.accessRequests.tobeTerminated,
  auth: state.auth.user[0],
  redirect: state.redirect,
  hrList: state.accessRequests.hrList,
});
export default connect(mapStateToProps, {
  lookupUsersForTermination,
  clearUsersForTermination,
  selectedUserToTerminate,
  terminateEmployee,
  switchRedirect,
  getHRDropdown
})(TerminationRequest);