import React from "react";
import { Grid } from "semantic-ui-react";
import { Paper } from "@material-ui/core";
import Moment from "react-moment";
import { Link } from "react-router-dom";
function IndependentDashboardTicket({ props, callFrom, ...rest }) {
  let urlLinkMain = props.ac_user_id
    ? `access-request/${props.ac_user_id}`
    : props.id && props.modification_type === 'TERMINATION'
    ? `termination-request/${props.id}`
    : props.id && props.modification_type === 'USER REQUESTED'
    ? `modify-request/${props.id}`
    : props.ti_id
    ? `${callFrom === "assignedTo" ? "pending-tickets" : "it-ticket"}/${props.ti_id}`
    : props.dr_id
    ? `${callFrom === "assignedTo" ? "pending-data-ops" : "dataops-request"}/${props.dr_id}`
    : null;
  return (
    <Link to={urlLinkMain}>
      <Paper
        style={{ padding: "10px", margin: "10px auto", cursor: "pointer" }}
        {...rest}
      >
        <Grid columns="equal" stackable>
          <Grid.Row style={{ paddingBottom: "5px" }}>
            <Grid.Column>
              <h4 style={{ borderBottom: "1px solid #90278E" }}>
                Ticket ID:{" "}
                <span>
                  {props.ac_user_id || props.id || props.dr_id || props.ti_id}
                </span>
              </h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p style={{ margin: "0" }}>
                <span style={{ fontWeight: "bold" }}>Status: </span>
                {props.ti_admin_status
                  ? props.ti_admin_status.toUpperCase()
                  : null || props.dr_admin_status
                  ? props.dr_admin_status.toUpperCase()
                  : null || props.access_stage
                  ? props.access_stage.toUpperCase()
                  : null || props.access_status
                  ? props.access_status.toUpperCase()
                  : null}
              </p>
              <p style={{ margin: "0" }}>
                <span style={{ fontWeight: "bold" }}>Type: </span>
                {props.ti_id ? (
                  <span>IT Ticket</span>
                ) : props.dr_id ? (
                  <span>Data Request</span>
                ) : (
                  <span>Access Request</span>
                )}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Created: </span>
                <Moment date={props.created} format="MM/DD/YY HH:mm" />
              </p>
            </Grid.Column>
            <Grid.Column>
              {props.ti_subject ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>Subject: </span>{" "}
                  {props.ti_subject}
                </div>
              ) : props.dr_subject ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>Subject: </span>
                  {props.dr_subject}
                </div>
              ) : props.modification_type ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    Modification Type:{" "}
                  </span>
                  {props.modification_type}
                </div>
              ) : (
                <div>
                  <span style={{ fontWeight: "bold" }}>Employee: </span>
                  {props.employee_last_name}, {props.employee_first_name}
                </div>
              )}
              {props.ti_admin_status && props.ti_admin_status === "RESOLVED" ? (
                <p>
                  <span style={{ fontWeight: "bold" }}>Resolved By: </span>
                  {props.first_name} {props.last_name}
                </p>
              ) : null}
            </Grid.Column>
            <Grid.Column>
              <div>
                {props.ti_description ? (
                  <div>
                    <span style={{ fontWeight: "bold" }}>Description: </span>
                    {props.ti_description}
                  </div>
                ) : props.dr_description ? (
                  <div>
                    <span style={{ fontWeight: "bold" }}>Description: </span>
                    {props.dr_description}
                  </div>
                ) : null}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p style={{ textAlign: "right" }}>
                <span
                  style={{
                    border: "1px solid #90278E",
                    color: "#90278E",
                    padding: "5px 10px",
                    borderRadius: "3px"
                  }}
                >
                  View Full
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Paper>
    </Link>
  );
}

export default IndependentDashboardTicket;