import axios from "axios";
// import { setAlert } from "./alertActions";
import {
  LOAD_USER_PROFILE,
  RESET_PROFILE_SEARCH,
  GET_USER_PROFILE_SUGGESTIONS,
  CLEAR_ALL_LOOKUP_DATA,
  MODAL_OPEN,
} from "./types";
export const lookupUserProfile = (body) => async (dispatch) => {
  const fd = new FormData();
  fd.append("profile_to_lookup", body);
  try {
    const res = await axios.post("/api/profile/user-profile-lookup", fd);
    dispatch({
      type: GET_USER_PROFILE_SUGGESTIONS,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

export const getTheLookedUpUser = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/api/profile/lookup-profile-by-id", body);
    dispatch({
      type: LOAD_USER_PROFILE,
      payload: res.data,
    });
    dispatch({ type: RESET_PROFILE_SEARCH });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};

// Logout / Clear Profile
export const clearUserProfileSearch = () => (dispatch) => {
  dispatch({ type: RESET_PROFILE_SEARCH });
};

export const clearAllLookupDAta = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_LOOKUP_DATA });
};
export const updateUserProfile = (body) => async (dispatch) => {
  console.log("hi");
  try {
    const res = await axios.post("/api/profile/update-user-profile", body);
    dispatch({
      type: LOAD_USER_PROFILE,
      payload: res.data,
    });
  } catch (error) {
    const res = {
      data: {
        message:
          "There was an error in your request, please refresh the page. This issue has been sent to the I.T department Thank you",
      },
    };
    dispatch({
      type: MODAL_OPEN,
      payload: res.data,
    });
  }
};
