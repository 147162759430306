import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { Dimmer, Loader, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { getSingleDashboardModifyRequest } from "../../../../../Redux/Actions/dashboardActions";
import Moment from "react-moment";
function ModifyAccessTicketsModal({
  getSingleDashboardModifyRequest,
  match,
  ticket,
}) {
  useState(() => {
    const fd = new FormData();
    fd.append("term_id", match.params.id);
    getSingleDashboardModifyRequest(fd);
  });
  let modticket;
  ticket
    ? (modticket = (
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <h3>Product Requested: </h3>
              <h4>{ticket[0].system_application}</h4>
            </Grid.Column>
            <Grid.Column>
              <h3>Status: </h3>
              <h4>{ticket[0].access_status}</h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h3>Requested: </h3>
              <h4>
                <Moment date={ticket[0].created} format="MM/DD/YY HH:mm" />
              </h4>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))
    : (modticket = (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ));
  return <Container maxWidth="lg">{modticket}</Container>;
}
const mapStateToProps = (state) => ({
  ticket: state.dashboard_tickets.dashboard_tickets,
});
export default connect(mapStateToProps, { getSingleDashboardModifyRequest })(
  ModifyAccessTicketsModal
);
