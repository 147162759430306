import React from "react";

import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
// import TicketReportMain from "../TicketReporting/TicketReportMain";

import UserDashboard from "./UserDashboard/UserDashboard";

import { Redirect } from "react-router-dom";
const Dashboard = ({ auth }) => {
 

  const { user } = auth;
  let dashboard;
  if (!Array.isArray(user) || !user.length) {
    return <Redirect to="/confirm-user" />;
  } else {
    dashboard = <UserDashboard />;
  }
  return <Container>{dashboard}</Container>;
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, null)(Dashboard);
