import { GET_ALL_DASHBOARD_TICKETS, GET_SINGLE_DASHBOARD_DATA_OP, GET_ALL_ASSIGNED_DASHBOARD_TICKETS } from "../Actions/types";

const initialState = {
  dashboard_tickets: null,
  loading: true,
  assigned_dashboard_tickets:null
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_DASHBOARD_TICKETS:      
      return {
        ...state,
        loading: false,
        dashboard_tickets: payload
      };
      case GET_SINGLE_DASHBOARD_DATA_OP:
        return {
          ...state,
          dashboard_tickets:payload
        }
        case GET_ALL_ASSIGNED_DASHBOARD_TICKETS:      
      return {
        ...state,
        loading: false,
        assigned_dashboard_tickets: payload
      };
    default:
      return state;
  }
}
